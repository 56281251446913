import { Alert, Snackbar } from '@mui/material';
import React, { useState,useEffect } from 'react';
import ICONS from "../features/images";
import "../css/bootstrap.min.css";
import "../css/custom.css";
import { useNavigate, useLocation } from "react-router-dom";
import { saveLoginData } from '../features/counter/APIs';
import { connect } from 'react-redux';
import axios from "axios";
import { ClipLoader } from 'react-spinners';
import { useCookies } from 'react-cookie';
import OtpInput from 'react-otp-input';
import { useRef } from 'react';
function OtpVerification(props) {
  let location = useLocation();
  let navigate = useNavigate();
  const [cookies, setCookie] = useCookies(['user']);
  const [loading, setLoading] = useState(false)
  const [showSplash, SetshowSplash] = useState(true)
  const [open, setOpen] = React.useState(false);
  const [openWrongDetails, setOpenWrongDetails] = useState(false);
  const [error, setError] = useState(null);
  const [otp, setOtp] = useState("");
  const [otpNull,setOtpNull] = useState(false);
  const [timer, setTimer] = useState('00');
  const [resend,setResend] = useState(false);
  const [otpLength,setOtpLength] = useState(false);
const handleChange = (otp) =>{
    setOtp(otp);
    if(otp.length === 6){
      setOtpLength(false);
    }
}
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleCloseWrongDetails = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenWrongDetails(false);
  };
useEffect(() => {
  setTimeout(() => {
    localStorage.removeItem('message');
    SetshowSplash(false)
  }, 3000);
  
  handleClick();
},[])

const handleClick = () => {
  let localVal  = localStorage.getItem("logOutDone");
  if(localVal === 'loginAgain'){
    setOpen(true);
  }
};

const getInputVal = (val) =>{
  alert("12345678");
}

  const login = () => {
    if(otp === ""){
      setOtpNull(true);
      setOtpLength(false);
      return false;
    }
    if(otp.length !== 6){
      setOtpLength(true);
      setOtpNull(false);
      return false;
    }else{
      setOtpLength(false);
      setOtpNull(false);
    }
    setLoading(true)
    // axios.post(`http://54.206.48.250:8082/v1/security/jwt/validateToken`,
    axios.post(`http://api.gaitglobal.com:8082/v1/security/jwt/validateToken`,
      {
        "username": location?.state?.username,
        "password": location?.state?.password,
        "code": otp
      }).then((res) => {
      localStorage.setItem("userInfo" , JSON.stringify(res.data.user));
        if(res.data.status == 401){
          alert("it's four");
        }
        if (res.data.status == "500" || res.data.status == "200L") {
          // axios.post(`http://54.206.48.250:8082/v1/security/jwt/logout`,
          axios.post(`http://api.gaitglobal.com:8082/v1/security/jwt/logout`,
            {
              "username": location?.state?.username
            }).then((res) => {
              login()
            })
        } else {
          if (res.data.token !== null) {
            props.saveLoginData(res)
            if (res.data.token) {
              navigate("/main");
              setTimeout(()=>{
                localStorage.setItem("status" , "Shown");
              }, 2000);
            }
          } else {
            setOpen(true)
          }
        }
      }).catch((err) => {
        if(err.response.status === 401){
          setOpenWrongDetails(true);
          setLoading(false)
        }
      })
  }
  const setRememberme = () =>{
      let uName = location?.state?.username;
      let uPassword = location?.state?.password;
      setCookie('username', uName, { path: '/' });
      setCookie('password', uPassword, { path: '/' });
  }
  
  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    return {
        total, hours, minutes, seconds
    };
}


const startTimer = (e) => {
    let { total, hours, minutes, seconds } 
                = getTimeRemaining(e);
    if (total >= 0) {
        setTimer(
            (seconds > 60 ? seconds : seconds)
        )
    }
    if(total <=0){
      setResend(true);
    }
}

const callResend = () =>{
  setResend(false);
  clearTimer(getDeadTime());
}

const Ref = useRef(null);
const clearTimer = (e) => { 
    setTimer('60');
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
        startTimer(e);
    }, 1000)
    Ref.current = id;
}

const getDeadTime = () => {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + 60);
    return deadline;
}
useEffect(() => {
    clearTimer(getDeadTime());
}, []);
const onClickReset = () => {
    clearTimer(getDeadTime());
}
  return (
    <div className='sign-in-bg' style={{ backgroundImage: `url(${ICONS.gradient})`, backgroundRepeat: "no-repeat" }}>
      <Snackbar open={open} autoHideDuration={4000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
        Your session has timed out. Please login again.
        </Alert>
      </Snackbar>
      <Snackbar open={openWrongDetails} autoHideDuration={2000} onClose={handleCloseWrongDetails} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseWrongDetails} severity="error" sx={{ width: '100%' }}>
        You have entered wrong otp.
        </Alert>
      </Snackbar>
      <div className="overlay" >
        <main className="form-signin">
          <div className="container">
            <div className="row justify-content-end align-items-center h-100vh">
              <div className="col-lg-4 col-md-6">
                <div className="form-body p-md-5 p-3 py-5 w-100 form-area">
                    <div className="row d-flex align-items-center ">
                      <div className="col-md-12 text-center">
                        <img className="mb-3" src={ICONS.Gait_complete_logo} alt="" />
                      </div>
                      {/* <div className="col-md-12 mb-4">
                        <h1 className="h3 fw-normal">Welcome !</h1>
                      </div> */}
                      <div className="col-md-12 mb-2 otp-text">
                        <h2>Check your email for a code</h2>
                        <p>We’ve sent a 6-character code to</p>
                        <p className="otp-email">{location?.state?.username}</p>
                             <OtpInput
                                value={otp}
                                onChange={handleChange}
                                onPress={getInputVal}
                                numInputs={6}
                                separator={<span style={{ width: "8px" }}></span>}
                                className="otp-input"
                                isInputNum={true}
                                shouldAutoFocus={true}
                                inputStyle={{
                                  border: "1px solid #BEBEBE",
                                  borderRadius: "8px",
                                  width: "54px",
                                  height: "40px",
                                  fontSize: "12px",
                                  color: "#000",
                                  fontWeight: "400",
                                  caretColor: "blue",
                                  fontFamily:'Poppins'
                                }}
                                focusStyle={{
                                  border: "1px solid #636363",
                                  outline: "none"
                                }}
                            />
                        <p>Re-send in <span style={{color:'#FF6161'}}>{timer}</span> seconds</p>
                        {otpNull && 
                        <div className='otp-reuqire'>
                          <p>Otp is required</p>
                        </div>
                        }
                        {otpLength &&
                        <div className='otp-reuqire'>
                        <p>Otp length should be 6</p>
                      </div>
                        }
                      </div>
                      <div className="col-md-12 mb-4">
                        {resend === true ? (
                          <button type="submit" onClick={() =>{callResend()}} className="btn green-light-bg text-white w-100">
                          Re-send
                        </button>
                        ):(
                          <button type="submit" onClick={() =>{login()}} className="btn green-light-bg text-white w-100">
                          Submit <ClipLoader color={"#fff"} loading={loading} size={18} />
                        </button>
                        )}
                        
                      </div>
                      <p style={{color:'red', fontSize:60}}>{error}</p>
                      <div className="col-md-12 text-center">
                        <p className='spam-folder'>Can’t find your code? Check your spam folder!</p>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}

const mapDispatchToProps = {
  saveLoginData: saveLoginData,

}

export default connect(null, mapDispatchToProps)(OtpVerification);
