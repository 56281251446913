import React, { useState } from 'react';
import * as Yup from "yup";
import { Formik, Form, ErrorMessage, Field } from "formik";
import { useNavigate } from "react-router-dom";
import { TextInput } from '../../components/TextField';
import { SiteAreaInput } from '../../components/SiteAreaInput';
import { Button } from "@mui/material";
import axiosClient from '../../axios';
import axios from '../../axios';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { HaInput } from '../../components/HaInput';
import { save_site_data, viewallusers, view_carbon_types, view_organizations_list, view_site_types, view_soil_types, view_vegetation_types } from '../../features/Apis/Index';
const AddSites = () => {
    let nav = useNavigate();
    const [soiltypes, setsoiltypes] = useState([]);
    const [carbontypes, setcarbontypes] = useState([]);
    const [vegtypes, setvegtypes] = useState([]);
    const [sitetypes, setSiteTypes] = useState([]);
    const [showOrganizations, setShowOrganizations] = useState([]);
    const user = useSelector((state) => state.loginData?.user);
    // get all soil type
    const getSoilTypes = () => {
        axios.get(view_soil_types).then(res => {
          let tepm = res.data.gaitObj
          setsoiltypes(tepm)
        })
      }
    // get all carbon
      const getCarbonTypes = () => {
        axios.get(view_carbon_types).then(res => {
          let tepm = res.data.gaitObj
          setcarbontypes(tepm)
        })
      }

      const getvegTypes = () => {
        axios.get(view_vegetation_types).then(res => {
          let tepm = res.data.gaitObj
          setvegtypes(tepm)
        })
      }

      const getSiteTypes = () => {
        axios.get(view_site_types).then(res => {
          let tepm = res.data.gaitObj
          setSiteTypes(tepm)
        })
      }

      const getAllUsers = (orgid, orgcode) => {
        axiosClient.get(`${viewallusers}${orgid}/${orgcode}`).then((res) => {
        })
      }

      const getOrganization = () => {
        axios.get(view_organizations_list).then((res) => {
          setShowOrganizations(res.data.gaitObj);
        });
      };

      const addSite = (values) => {
        axiosClient.post(save_site_data, {
          "sitename":values?.sitename,
            "sitetypeidref":{
                "sitetypeid":parseInt(values?.siteType)
            },
            "soiltypeidref":{
                "soiltypeid":parseInt(values?.soilType)
            },
            "carbontypeidref":{
                "carbontypeid":values?.carbonType
            },
            "vegitationtypes":values?.vegetationType,
            "sitearea":40.23,
            "sitelocation":values?.sitelocation,
            "longitude":values?.sitelogitude,
            "latitude":values?.sitelatitude,
            "country":values?.sitelocation,
            "ownername":values?.ownerName,
            "ownercontact": values?.ownerContact,
            "vegtativearea":parseInt(values?.vegitativearea),
            "non_vegtativearea":parseInt(values?.nonvegitativearea),
            "isactive":1,
            "insertedby":user?.orguserid,
            "modifiedby":user?.orguserid,
            "shapefile":values?.shapefile,
            "orgidref":parseInt(values?.organization),
        }).then(res => {
          alert("Site added successfully");
          localStorage.setItem('addedSite', true);
          nav("/sites");
        })
      }
      useEffect(()=>{
        getSoilTypes(); 
        getCarbonTypes();
        getvegTypes();
        getSiteTypes();
        getOrganization();
        getAllUsers(user?.personidref?.orgidref?.orgid, user?.personidref?.orgidref?.orgcode)
      },[])
    const validate = Yup.object({
        sitename: Yup.string()
          .required("Site Name is require"),
          siteType : Yup.string()
          .required("Site Type is require"),
          email: Yup.string().email("Email is invalid").required("Email is required"),
        sitelogitude: Yup.string().required("Site logitude is required"),
        sitelatitude: Yup.string().required("Site latitude is require"),
          soilType: Yup.string().required("Soil type in required"),
          carbonType: Yup.string().required("PCarbon type is required"),
          vegetationType: Yup.string().required("Vegetation type is required"),
          ownercontact: Yup.string().required("Contact field is required."),
          ownername: Yup.string()
          .required("Owner name is required."),
          sitearea:Yup.string()
          .required('Site area is required'),
          vegitativearea: Yup.string()
          .required('Vegitative area is required'),
          nonvegitativearea: Yup.string()
          .required('Non-Vegitative area is required'),
          isactive:Yup.number()
          .required("Status is required"),
          organization: Yup.number()
          .required("Organization is required"),
      });
  return (
    <Formik
    initialValues={{
        sitename: "",
        siteType: "",
        sitelogitude: "",
        sitelatitude: "",
        shapefile: "",
        soilType: "",
        carbonType: "",
        vegetationType: "",
        ownername: "",
        ownercontact: "",
        email:'',
        sitearea:'',
        vegitativearea:'',
        nonvegitativearea:'',
        isactive:'',
        organization:""
    }}
    validationSchema={validate}
    onSubmit={(values) => {
        addSite(values);
    }}
  >
    {(formik) => (
        <Form>
            <Button
            onClick={(e) => {
              nav("/sites");
            }}
            style={{
              marginLeft: "auto",
              display: "block",
              width: 140,
              background: "linear-gradient(0deg, #55AA8B, #55AA8B), #FEFEFE",
              borderRadius: 4,
              color: "white",
            }}
          >
            View All
          </Button>
            <div className="row form-top sites-content">
              <div className="col-md-3 col-sm-12">
                <TextInput label="Site Name" name="sitename" type="text" placeholder="First Name"/>
              </div>
              <div className="col-md-3 col-sm-12">
                <div className="d-flex justify-content-between align-item-center">
                  <div>
                  <label className="form-label">Site Type</label>
                  </div>
                  <div>
                  </div>
                </div>
                <Field as="select" name="siteType" className="form-select">
                  <option>-Select-</option>
                  {sitetypes?.map((val, index) => {
                    return (
                      <option value={val.sitetypeid} key={index}>
                        {val.sitetype}
                      </option>
                    );
                  })}
                </Field>
                <ErrorMessage
                  name="siteType"
                  render={msg => <div className="error">
                    &nbsp;Please select site type </div>}
                />
              </div>
              <div className="col-md-6 col-sm-12">
              <span className='site-text'>Site Areas</span>
                  <div className='row'>
                    <div className='col-md-4 col-sm-12'>
                    <HaInput label="" name="sitearea" type="text" placeholder="Areas"/>
                    </div>
                    <div className='col-md-4 col-sm-12'>
                    <SiteAreaInput label="" name="vegitativearea" type="text" placeholder="Vegetation"/>
                    </div>
                    <div className='col-md-4 col-sm-12'>
                    <SiteAreaInput label="" name="nonvegitativearea" type="text" placeholder="Non-Vegetation"/>
                    </div>
                  </div>
              </div>
              <div className='row addsite-body'>
                <div className='col-md-3 col-sm-12'>
                <TextInput label="Site Location" name="sitelocation" type="text" placeholder="Location"/>
                </div>
                <div className='col-md-3 col-sm-12'>
                <TextInput label="Longitude" name="sitelogitude" type="text" placeholder="Longitude"/>
                </div>
                <div className='col-md-3 col-sm-12'>
                <TextInput label="Latitude" name="sitelatitude" type="text" placeholder="Latitude"/>
                </div>
                <div className='col-md-3 col-sm-12'>
                <TextInput label="Shape file" name="shapefile" type="text" placeholder="https://"/>
                </div>
              </div>
              <div className='row addsite-body'>
              <div className="col-md-3 col-sm-12">
                <div className="d-flex justify-content-between align-item-center">
                  <div>
                  <label className="form-label">Soil Type</label>
                  </div>
                  <div>
                  </div>
                </div>
                <Field as="select" name="soilType" className="form-select">
                  <option >-Select-</option>
                  {soiltypes?.map((val, index) => {
                    return (
                      <option value={val.soiltypeid} key={index}>
                        {val.soiltype}
                      </option>
                    );
                  })}
                </Field>
                <ErrorMessage
                  name="soilType"
                  render={msg => <div className="error">
                    &nbsp;Please select soil type </div>}
                />
              </div>
              <div className="col-md-3 col-sm-12">
                <div className="d-flex justify-content-between align-item-center">
                  <div>
                  <label className="form-label">Carbon Type</label>
                  </div>
                  <div>
                  </div>
                </div>
                <Field as="select" name="carbonType" className="form-select">
                  <option>-Select-</option>
                  {carbontypes?.map((val, index) => {
                    return (
                      <option value={val.carbontypeid} key={index}>
                       {val.carbontype}
                      </option>
                    );
                  })}
                </Field>
                <ErrorMessage
                  name="carbonType"
                  render={msg => <div className="error">
                    &nbsp;Please select carbon type </div>}
                />
              </div>
              <div className="col-md-3 col-sm-12">
                <div className="d-flex justify-content-between align-item-center">
                  <div>
                  <label className="form-label">Vegetation Type</label>
                  </div>
                  <div>
                  </div>
                </div>
                <Field as="select" name="vegetationType" className="form-select">
                  <option>-Select-</option>
                  {vegtypes?.map((val, index) => {
                    return (
                      <option value={val.vegitaiontypeid} key={index}>
                        {val.vegitationtype}
                      </option>
                    );
                  })}
                </Field>
                <ErrorMessage
                  name="vegetationType"
                  render={msg => <div className="error">
                    &nbsp;Please select vegitation type </div>}
                />
              </div>
              <div className="col-md-3 col-sm-12">
                <div className="d-flex justify-content-between align-item-center">
                  <div>
                  <label className="form-label">Status</label>
                  </div>
                  <div>
                  </div>
                </div>
                <Field as="select" name="isactive" className="form-select">
                  <option>-Select-</option>
                  <option value={1}>Active</option>
                  <option value={0}>InActive</option>
                </Field>
                <ErrorMessage
                  name="isactive"
                  render={msg => <div className="error">
                    &nbsp;Please select status </div>}
                />
              </div>
              </div>

              <div className='row addsite-body'>
                <div className='col-md-3 col-sm-12'>
                <TextInput label="Owner Name" name="ownername" type="text" placeholder="Owner Name"/>
                </div>
                <div className='col-md-3 col-sm-12'>
                <TextInput label="Owner Contact" name="ownercontact" type="text" placeholder="Phone Number"/>
                </div>
                <div className='col-md-3 col-sm-12'>
                <TextInput label="Owner Email" name="email" type="text" placeholder="Email"/>
                </div>
                <div className="col-md-3 col-sm-12">
                <div className="d-flex justify-content-between align-item-center">
                  <div>
                  <label className="form-label">Organization</label>
                  </div>
                  <div>
                  </div>
                </div>
                <Field as="select" name="organization" className="form-select">
                  <option>-Select-</option>
                  {showOrganizations?.map((val, index) => {
                        return (<option value={val.orgid} key={index}>{val.orgname} </option>)
                    })}
                 
                </Field>
                <ErrorMessage
                  name="organization"
                  render={msg => <div className="error">
                    &nbsp;Please select organization </div>}
                />
          
              </div>
              </div>
              <div className="d-flex justify-content-end bottom-button add-sitebtn">
              <Button
                style={{
                  marginLeft: 15,
                  display: "block",
                  width: 140,
                  borderRadius: 4,
                  borderColor: "#FF6E6E",
                  borderWidth: 1,
                  color: "#FF6E6E",
                  border: "1px solid",
                  fontFamily: "Poppins",
                  fontSize: 13,
                }}
                onClick={(e) => {
                  nav("/sites");
                }}
              >
                Cancel
              </Button>

              <Button
                style={{
                  marginLeft: 15,
                  display: "block",
                  width: 140,
                  background:
                    "linear-gradient(0deg, #55AA8B, #55AA8B), #FEFEFE",
                  borderRadius: 4,
                  color: "white",
                  fontFamily: "Poppins",
                  fontSize: 13,
                }}
                type="submit"
              >
                Create
              </Button>
            </div>
            </div>
        </Form>
    )}
    </Formik>
  )
}

export default AddSites;