import React from 'react';
import { ErrorMessage, useField } from 'formik';
import ICONS from '../features/images';
export const SiteAreaInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className="mb-2">
      <div className='addsites'>
      <input
        className={`form-control from-input shadow-none site-areas ${meta.touched && meta.error && 'is-invalid'}`}
        {...field} {...props}
        autoComplete="off"
      />
      <span>%</span>
      </div>
      <div>
        {meta.touched && meta.error && <img src={ICONS.WarningIcon}  /> ? 
      <div className='d-flex align-items-center'>
        <img src={ICONS.WarningIcon}  style={{maxWidth:10, width:'100%'}}/>&nbsp;<ErrorMessage component="div" name={field.name} className="error" />
        </div> : 
      null
      } </div>
    </div>
  )
}