import { Avatar, Button, Grid, Menu, MenuItem } from "@mui/material";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import axiosClient from "../axios";

function Header() {
  const sensor = useSelector((state) => state.sensorInfo);
  const loginData = useSelector((state) => state.loginData);
  let nav = useNavigate();
  const currentSite = useSelector((state) => state.currentSite?.gaitObj);
  const sitename = useSelector((state) => state.sitename);
  const location = useLocation();

  const [anchorEl, setAnchorEl] = useState(false);
  const open = anchorEl;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const logOutUser = () => {
    localStorage.setItem("message", true);
    logChek();
    axiosClient
      .post(`v1/users/logout`, {
        username: loginData?.user?.username,
      })
      .then((res) => {
        localStorage.removeItem("status");
        localStorage.clear();
        if(res?.status === 200){
        nav("/", {
          state: {
            msg: "test",
          },
        });
      }
      });
  };
  const logChek = () => {
    localStorage.setItem("logOutDone", "loginAgain");
    setTimeout(() => {
      localStorage.removeItem("logOutDone");
    }, 2000);
  };

  const token = useSelector((state) => state.loginData?.token);

  useEffect(() => {
    axiosClient.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    setTimeout(() => {
      logOutUser();
    }, 1000 * 60 * 60);
  }, []);
  useEffect(() => {}, [currentSite, sitename, sensor]);

  const renderHead = (locations) => {
    switch (locations) {
      case "/users":
        return <h3 style={{ color: "#000", fontWeight: 900 }}> Users</h3>;
      case "/sensors":
        return <h3 style={{ color: "#000", fontWeight: 900 }}> Sensors</h3>;
      case "/sites":
        return <h3 style={{ color: "#000", fontWeight: 900 }}> Sites</h3>;
      case "/track":
        return (
          <h3 style={{ color: "#000", fontWeight: 900 }}> Sensor Tracking</h3>
        );
      case "/create-user":
        return <h3 style={{ color: "#000", fontWeight: 900 }}> Create User</h3>;
      case "/user-profile":
        return (
          <h3 style={{ color: "#000", fontWeight: 900 }}> User Profile</h3>
        );
      case "/update-user":
        return (
          <h3 style={{ color: "#000", fontWeight: 900 }}>
            {" "}
            User Profile Update
          </h3>
        );
      case "/add-sites":
        return (
          <h3 style={{ color: "#000", fontWeight: 900 }}> Add New Site</h3>
        );
      case "/add-sensor":
        return (
          <h3 style={{ color: "#000", fontWeight: 900 }}>Add New Sensor</h3>
        );
      case "/edit-sensor":
        return <h3 style={{ color: "#000", fontWeight: 900 }}>Edit Sensor</h3>;
      case "/site-install":
        return <h3 style={{ color: "#000", fontWeight: 900 }}>Site Install</h3>;
      case "/report":
        return <h3 style={{ color: "#000", fontWeight: 900 }}>Site Report</h3>;
      case "/get-report":
        return <h3 style={{ color: "#000", fontWeight: 900 }}>Site Report</h3>;
      case "/edit-site":
        return <h3 style={{ color: "#000", fontWeight: 900 }}>Edit Site</h3>;
      case "/all-daily-report":
        return <h3 style={{ color: "#000", fontWeight: 900 }}>Site Report</h3>;
      case "/reports":
        return <h3 style={{ color: "#000", fontWeight: 900 }}>Site Report</h3>;
      case "/carbon-count":
        return (
          <h3 style={{ color: "#000", fontWeight: 900 }}>
            Carbon Emission/Absorption
          </h3>
        );
      case "/sensor-report":
        return (
          <h3 style={{ color: "#000", fontWeight: 900 }}>Flux Correlation</h3>
        );
      case "/site-analysis":
        return (
          <h3 style={{ color: "#000", fontWeight: 900 }}></h3>
        );
        case "/flux-correction" : 
        return (
          <h3 style={{ color: "#000", fontWeight: 900 }}></h3>
        );
        case "/add-projects" : 
        return (
          <h3 style={{ color: "#000", fontWeight: 900 }}>Add Project</h3>
        );
        case "/projects" : 
        return (
          <h3 style={{ color: "#000", fontWeight: 900 }}>Project</h3>
        );
        case "/projects-view" : 
        return (
          <h3 style={{ color: "#000", fontWeight: 900 }}>Project</h3>
        );
        case "/edit-projects" : 
        return (
          <h3 style={{ color: "#000", fontWeight: 900 }}>Project</h3>
        );
        case "/project-card" : 
        return (
          <h3 style={{ color: "#000", fontWeight: 900 }}>Project</h3>
        );
        case "/project-analysis" : 
        return (
          <></>
          // <h3 style={{ color: "#000", fontWeight: 900 }}>Project Analysis</h3>
        );
      default:
        return (
          <h3 style={{ color: "#000", fontWeight: 900 }}> Sensor Status</h3>
        );
    }
  };
  return (
    <Grid
      container
      spacing={2}
      justifyContent="space-between"
      alignItems="start"
    >
      <Grid item xs={5}>
        {location.pathname == "/main" || location.pathname == "/sensor" ? (
          <>
            {location.pathname == "/sensor" && sensor && (
              <h1 style={{ fontWeight: 700, fontFamily: "Poppins" }}>
                Sensor ID: {sensor?.gaitObj?.sensorid}
              </h1>
            )}
            {location.pathname !== "/reset-password" && (
              <div>
                <h3
                  style={{
                    color: location.pathname == "/main" ? "#000" : "#777",
                    fontWeight: 700,
                    fontFamily: "Poppins",
                  }}
                >
                  {" "}
                  Site: {currentSite?.sitename}
                </h3>
                <p className="country-name">Country: {currentSite?.country}</p>
              </div>
            )}
          </>
        ) : (
          <div style={{ marginTop: 20 }}>{renderHead(location.pathname)}</div>
        )}
      </Grid>
      <Grid item alignSelf="flex-end">
        <Button
          id="demo-positioned-button"
          aria-controls={open ? "demo-positioned-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          style={{ color: "#000", fontWeight: 700, fontFamily: "Poppins" }}
        >
          <Avatar
            style={{ marginRight: 10 }}
            alt={loginData?.user?.personidref.firstname}
            src="asd"
          />
          {loginData?.user?.personidref.firstname}{" "}
          {loginData?.user?.personidref.lastname}
          <KeyboardArrowDownIcon />
        </Button>
        <Menu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem onClick={(e) => nav("/reset-password")}>
            Reset Password
          </MenuItem>
          <MenuItem onClick={(e) => logOutUser()}>Logout</MenuItem>
        </Menu>
      </Grid>
    </Grid>
  );
}
export default Header;
