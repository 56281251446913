import React, { useState } from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import { useLocation, useNavigate } from "react-router-dom";
import { Formik, Form, ErrorMessage, Field } from "formik";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import * as Yup from "yup";
import { useEffect } from "react";
import axiosClient from "../../axios";
import { EditProjectInput } from "../../components/EditProjectInput";
import { TextInput } from "../../components/TextField";
import ICONS from "../../features/images/index";
import {saveAs} from "file-saver";
import EditIcon from '@mui/icons-material/Edit';
function EditProject(props) {
    const nav = useNavigate();
    const parms = useLocation();
    const [formikData, SetformikData] = useState({
      ageOfVegetation: "",
      annualDeforestationRate: "",
      annualNewPlantationRate: "",
      areaInHectres: "",
      bulkDensity: "",
      contactPerson: "",
      contactPersonAddress: "",
      contactPersonEmail: "",
      contactPersonNo: "",
      contactPersonTitle: "",
      createdAt: "",
      createdBy: "",
      geoReferencedSitemapImage: "",
      geoReferencedSitemapPresent: "",
      id: "",
      modifiedAt: "",
      modifiedBy: "",
      organicCarbon: "",
      organizationName: "",
      plantSpecies: "",
      projectCategory: "",
      projectName: "",
      shapeFileAvailable: "",
      shapefile: "",
      siteAddress: "",
      siteArea: "",
      siteName: "",
      siteOwnerContactNo: "",
      siteOwnerEmail: "",
      siteOwnerName: "",
      siteOwnerType: "",
      siteType: "",
      soilPh: "",
      soilType: "",
      subfieldNamePresent: "",
      subfieldsBoundariesPresent: "",
      vegetationType: "",
      subfieldNameFile  :"",
      geoReferencedSitemapImageFile: ""
    });
    const [siteTypes, setSiteTypes] = useState([]);
    const [siteProjectCategories, setSiteProjectCategories] = useState([]);
    const [siteOwnerTypes, setSiteOwnerTypes] = useState([]);
    const [siteVegetationTypes, setSiteVegetationTypes] = useState([]);
    const [ageOfVegetation, setAgeOfVegetation] = useState([]);
    const [siteSoilTypes, setSiteSoilTypes] = useState([]);
    const [sitePhType, setSitePhType] = useState([]);
    const [checksubfieldNameFile,setsubfieldNameFile] = useState();
    const [checkshapeFile, setCheckshapeFile] = useState(false);
    const [downloadShapeFile, setDownloadShapeFile] = useState();
    const [allCountryLists, setAllCountryLists] = useState();
    const [downloadSiteMapImg,setDownloadSiteMapImg] = useState();
    const [changeGeoRefrenceMap,setchangeGeoRefrenceMap] = useState(false);
    const [geoReferencedSitemapImg,setgeoReferencedSitemapImage] = useState();
    const [shapeFileHandleupload,setshapeFileHandleupload] = useState(false);
    const [changeGeoRefrenceMapEdit,setchangeGeoRefrenceMapEdit] = useState(false);
    const [checkshapefileIsAvilable,setCheckshapefileIsAvilable] = useState(false)
    useEffect(() =>{
      getSingleProject();
      getSiteTypes();
      getSiteProjectCategories();
      getSiteOwnerTypes();
      getSiteVegetationTypes();
      getSiteAgeOfVegetation();
      getSiteSoilTypes();
      getSitePHTypw();
      getAllCountry();
    }, []);
    const getAllCountry = () =>{
      axiosClient.get('https://countriesnow.space/api/v0.1/countries')
      .then(res =>{
        setAllCountryLists(res);
      })
    }
    const getSiteTypes = () => {
      axiosClient
        .get("v1/gait/lukup/sitetypes")
        .then((res) => {
          if (res.status === 200) {
            setSiteTypes(res);
          }
        })
        .catch((err) => {});
    };

    const getSiteProjectCategories = () => {
      axiosClient
        .get("v1/gait/lukup/prjctcategories")
        .then((res) => {
          if (res.status === 200) {
            setSiteProjectCategories(res);
          }
        })
        .catch((err) => {});
    };

    const getSiteOwnerTypes = () => {
      axiosClient
        .get("v1/gait/lukup/siteownertypes")
        .then((res) => {
          if (res.status === 200) {
            setSiteOwnerTypes(res);
          }
        })
        .catch((err) => {});
    };

    const getSiteVegetationTypes = () => {
      axiosClient
        .get("v1/gait/lukup/vegetationtypes")
        .then((res) => {
          if (res.status === 200) {
            setSiteVegetationTypes(res);
          }
        })
        .catch((err) => {});
    };

    const getSiteAgeOfVegetation = () => {
      axiosClient
        .get("v1/gait/lukup/ageofvegetation")
        .then((res) => {
          if (res.status === 200) {
            setAgeOfVegetation(res);
          }
        })
        .catch((err) => {});
    };

    const getSiteSoilTypes = () => {
      axiosClient
        .get("v1/gait/lukup/soiltypes")
        .then((res) => {
          if (res.status === 200) {
            setSiteSoilTypes(res);
          }
        })
        .catch((err) => {});
    };

    const getSitePHTypw = () => {
      axiosClient
        .get(
          "v1/gait/lukup/phtypes"
        )
        .then((res) => {
          if (res.status === 200) {
            setSitePhType(res);
          }
        })
        .catch((err) => {});
    };

    const checekHandleSubfield = (e) =>{
      setsubfieldNameFile(e.target.files[0]);
    }

    const geoReferencedSitemapImageFileHandle = (e) =>{
      setgeoReferencedSitemapImage(e.target.files[0]);
    }
    
    const HandleRadioButton2 = (val) => {
      setCheckshapeFile(val);
    };

    const handleShapeFileDownload = () =>{
      let url = downloadShapeFile;
      saveAs(url, url);
    }
    const handleSiteMapImg = () =>{
      let url = downloadSiteMapImg;
      saveAs(url, url);
    }
    const getSingleProject = () =>{
      axiosClient.get(`v1/gait/project/byId/${parms?.state?.projectId}`)
      .then(res =>{
  
        if(res.status === 200){
          let formikobj = {
            ageOfVegetation: res.data?.ageOfVegetation,
            annualDeforestationRate: res.data?.annualDeforestationRate,
            annualNewPlantationRate: res.data?.annualNewPlantationRate,
            areaInHectres: Number(res.data?.areaInHectres),
            bulkDensity: res.data?.bulkDensity,
            contactPerson: res.data?.contactPerson,
            contactPersonAddress: res.data?.contactPersonAddress,
            contactPersonEmail: res.data?.contactPersonEmail,
            contactPersonNo: res.data?.contactPersonNo,
            contactPersonTitle: res.data?.contactPersonTitle,
            geoReferencedSitemapImage: res.data?.geoReferencedSitemapImage,
            geoReferencedSitemapPresent: res.data?.geoReferencedSitemapPresent,
            organicCarbon: res.data?.organicCarbon,
            organizationName: res.data?.organizationName,
            plantSpecies: res.data?.plantSpecies,
            projectCategory: res.data?.projectCategory,
            projectName: res.data?.projectName,
            shapeFileAvailable: res.data?.shapeFileAvailable,
            shapefile: res.data?.shapefile,
            siteAddress: res.data?.siteAddress,
            siteArea: res.data?.siteArea,
            siteName: res.data?.siteName,
            siteOwnerContactNo: res.data?.siteOwnerContactNo,
            siteOwnerEmail: res.data?.siteOwnerEmail,
            siteOwnerName: res.data?.siteOwnerName,
            siteOwnerType: res.data?.siteOwnerType,
            siteType: res.data?.siteType,
            soilPh: res.data?.soilPh,
            soilType: res.data?.soilType,
            subfieldNamePresent: res.data?.subfieldNamePresent,
            subfieldsBoundariesPresent: res.data?.subfieldsBoundariesPresent,
            vegetationType: res.data?.vegetationType,
            subfieldNameFile  :res.data?.subfieldNameFile,
            geoReferencedSitemapImageFile: res.data?.geoReferencedSitemapImageFile,
            country:res.data?.country,
            status:res.data?.status
          }
          setDownloadShapeFile(formikobj?.shapefile);
          setDownloadSiteMapImg(formikobj?.geoReferencedSitemapImage);
          setCheckshapeFile(formikobj?.shapefile ? true:false)
          setchangeGeoRefrenceMap(formikobj?.geoReferencedSitemapPresent ? true:false)
          setchangeGeoRefrenceMapEdit(formikobj?.geoReferencedSitemapPresent ? true:false)
          setCheckshapefileIsAvilable(formikobj?.shapefile ? true : false);
          SetformikData(formikobj);
        }

      }).catch(err =>{

      })
    }

    const updateProjects = (val) => {
      var bodyFormData = new FormData();
      bodyFormData.append("id", parms?.state?.projectId)
      bodyFormData.append("ageOfVegetation", val.ageOfVegetation ? val.ageOfVegetation:"");
      bodyFormData.append("annualDeforestationRate", val.annualDeforestationRate ? val.annualDeforestationRate : "");
      bodyFormData.append("annualNewPlantationRate", val.annualNewPlantationRate ? val.annualNewPlantationRate : "");
      bodyFormData.append("areaInHectres", val.areaInHectres ? val.areaInHectres: 0.0 );
      bodyFormData.append("bulkDensity", val.bulkDensity ? val.bulkDensity:"");
      bodyFormData.append("contactPerson", val.contactPerson ? val.contactPerson :"");
      bodyFormData.append("contactPersonAddress", val.contactPersonAddress ? val.contactPersonAddress :"");
      bodyFormData.append("contactPersonEmail", val.contactPersonEmail ? val.contactPersonEmail :"");
      bodyFormData.append("contactPersonNo", val.contactPersonNo ? val.contactPersonNo :"");
      bodyFormData.append("contactPersonTitle", val.contactPersonTitle ? val.contactPersonTitle :"");
      bodyFormData.append("geoReferencedSitemapImage",val.geoReferencedSitemapImage ? val.geoReferencedSitemapImage : "");
      bodyFormData.append("geoReferencedSitemapPresent",val.geoReferencedSitemapPresent ? val.geoReferencedSitemapPresent :false);
      bodyFormData.append("organicCarbon", val.organicCarbon ? val.organicCarbon:"");
      bodyFormData.append("organizationName", val.organizationName ? val.organizationName :"");
      bodyFormData.append("plantSpecies", val.plantSpecies ? val.plantSpecies:"");
      bodyFormData.append("projectCategory", val.projectCategory ? val.projectCategory : "");
      bodyFormData.append("projectName", val.projectName);
      bodyFormData.append("shapeFileAvailable", checkshapefileIsAvilable ? true : false);
      bodyFormData.append("shapefile", val.shapefile ? val.shapefile :"");
      bodyFormData.append("siteAddress", val.siteAddress ? val.siteAddress :"");
      bodyFormData.append("siteArea", val.siteArea ? val.siteArea : 0.0);
      bodyFormData.append("siteName", val.siteName);
      bodyFormData.append("siteOwnerContactNo", val.siteOwnerContactNo ? val.siteOwnerContactNo:"");
      bodyFormData.append("siteOwnerEmail", val.siteOwnerEmail ? val.siteOwnerEmail :"");
      bodyFormData.append("siteOwnerName", val.siteOwnerName ? val.siteOwnerName : "");
      bodyFormData.append("siteOwnerType", val.siteOwnerType ? val.siteOwnerType : 0 );
      bodyFormData.append("siteType", val.siteType ? val.siteType : 0);
      bodyFormData.append("soilPh", val.soilPh ? val.soilPh:"");
      bodyFormData.append("soilType", val.soilType ? val.soilType : 0);
      bodyFormData.append("subfieldNamePresent", val.subfieldNamePresent ? val.subfieldNamePresent  :false);
      bodyFormData.append("subfieldsBoundariesPresent",val.subfieldsBoundariesPresent ? val.subfieldsBoundariesPresent : false);
      bodyFormData.append("vegetationType", val.vegetationType ? val.vegetationType : 0);
      bodyFormData.append("country", val.country ? val.country:"");
      // bodyFormData.append("shapefileAvailable", val.shapefileAvailable ? shapefileAvailable:false)
      bodyFormData.append("status", val.status ? val.status :"");
     
      geoReferencedSitemapImg && 
      bodyFormData.append("subfieldNameFile",  geoReferencedSitemapImg ? geoReferencedSitemapImg : null);
      checksubfieldNameFile &&
      bodyFormData.append("geoReferencedSitemapImageFile",checksubfieldNameFile ? checksubfieldNameFile : null);
      // axios({
      //   method: "post",
      //   url: "http://54.206.48.250:8082/v1/gait/project/save",
      //   data: bodyFormData,
      //   headers: {
      //     "Content-Type": "multipart/form-data",
      //     "Authorization":`Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJzYW50b3NoQGdhaXRnbG9iYWwuY29tIiwiZXhwIjoxNjcxNTU4Njg3LCJpYXQiOjE2NzE1NTUwODd9.r123H6-t9HVJT6Xl6PUUHmQfCHG3Om-59dy0uEWOsqSTvuSbhxzz_fFlivkTn1VkdjZs-NhAhC0l1yqow3ssOw`
      //   },
      // })
      //   .then(function (response) {
      //   })
      //   .catch(function (response) {
      //   });
      // const formData = new FormData(form);
      axiosClient
        .post(
          `v1/gait/project/save`,
          bodyFormData,
          {
            headers: {
              "Content-type": "multipart/form-data",
            },
          }
        )
        .then((res) => {
          // nav('/add-projects');
          // if (res.status === 200) {
          //   setSitePhType(res);
          // }
        })
        .catch((err) => {});
    };
    const validate = Yup.object({
      siteArea: Yup
      .number()
      .positive()
      .label('site area')
      .min(1),
      projectName : Yup.string()
        .required("Project name is require"),
        siteName:Yup.string()
        .required("Site name is required")
    });
  return (
    <div>
         
      <div className="siteheading-div">
        <h1 className="siteheading">Congo</h1>
      </div>
     
            <Formik
    initialValues={formikData}
    enableReinitialize ={true}
    validationSchema={validate}
    onSubmit={(values) => {
      updateProjects(values)
    }}
  >
    {(formik) => (
        <Form>
           <div className='add-morebtn'>
                <div className='d-flex justify-content-end'>
                    <div className='btnadd'><button type="submit">Save</button></div>
                    <div className='delete-ico'><button><DeleteIcon /></button></div>
                </div>
            </div>
      <div className="projects-info">
        <h2 className="rowmain-heading">Project Info</h2>
        <div className="row">
          <div className="col-md-3 col-sm-12">
            <div className="project-label edit-projects ">
              <EditProjectInput type="text" name="projectName" placeholder="Project Name" label={"Project Name"} />
            </div>
          </div>
          <div className="col-md-3 col-sm-12">
            <div className="project-label edit-projects">
              <EditProjectInput type="text" name="siteName" placeholder="Site Name" label={"Site Name"} />
            </div>
          </div>
          <div className="col-md-3 col-sm-12">
            <div className="project-label edit-projects">
              <EditProjectInput type="text" name="siteArea" placeholder="Site Area" label={"Site Area"} />
            </div>
          </div>
          <div className="col-md-3 col-sm-12">
                <div className="edit-projects">
                  <label className="form-label">Site Type</label>
                <Field as="select" name="siteType" className="form-select">
                  {siteTypes.data?.map((val, index) => {
                          return (
                            <option value={val.sitetypeid} key={index}>
                              {val.sitetype}
                            </option>
                          );
                        })}
                </Field>
                <ErrorMessage
                  name="siteType"
                  render={msg => <div className="error">
                    &nbsp;Please select site type </div>}
                />
                <div className="arrowdown-ico">
                <ArrowDropDownIcon />
                </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-12">
                <div className="edit-projects">
                  <label className="form-label">Project Category</label>
                
                <Field as="select" name="projectCategory" className="form-select">
                {formik.values.projectCategory ? (
                    <option selected value={formik.values.projectCategory}>{formik.values.projectCategory}</option>
                  ):""}
                  {siteProjectCategories.data?.map((val, index) => {
                          return (
                            <option value={val.project_type} key={index}>
                              {val.project_type}
                            </option>
                          );
                        })}
                </Field>
                <ErrorMessage
                  name="siteType"
                  render={msg => <div className="error">
                    &nbsp;Please select site type </div>}
                />
                <div className="arrowdown-ico">
                <ArrowDropDownIcon />
                </div>
                </div>
              </div>
          <div className="col-md-3 col-sm-12">
            <div className="project-label edit-projects">
                <EditProjectInput type="text" name="annualDeforestationRate" placeholder="Annual Deforestation Rate" label={"Annual Deforestation Rate"} />
            </div>
          </div>
          <div className="col-md-3 col-sm-12">
            <div className="project-label edit-projects">
                <EditProjectInput type="text" name="annualNewPlantationRate" placeholder="Annual New Plantation Rate" label={"Annual New Plantation Rate"} />
            </div>
          </div>
          <div className="col-md-3 col-sm-12">
          <div className="edit-projects">
                      <div className="d-flex justify-content-between align-item-center">
                        <div>
                          <label className="form-label">Country</label>
                        </div>
                        <div></div>
                      </div>
                      <Field
                        as="select"
                        name="country"
                        className="form-select"
                      >
                        <option>-Select-</option>
                        {allCountryLists?.data?.data?.map((val, index) => {
                          return (
                            <option value={val.country} key={index}>
                              {val.country}
                            </option>
                          );
                        })}
                      </Field>
                      <ErrorMessage
                        name="siteType"
                        render={(msg) => (
                          <div className="error">
                            &nbsp;Please select site type{" "}
                          </div>
                        )}
                      />
                    </div>
          </div>
          <div className="col-md-3 col-sm-12">
                <div className="edit-projects">
                  <label className="form-label">Status</label>
                
                <Field as="select" name="status" className="form-select">
                {/* {formik.values.projectCategory ? (
                    <option selected value={formik.values.projectCategory}>{formik.values.projectCategory}</option>
                  ):""} */}
                  {/* {siteProjectCategories.data?.map((val, index) => {
                          return (
                            <option value={val.project_type} key={index}>
                              {val.project_type}
                            </option>
                          );
                        })} */}
                       <option value="New">🟡 New </option>
                       <option value="Pre Analysis">🔴 Pre Analysis </option>
                       <option value="Site Analysis">🔵 Site Analysis </option>
                       <option value="Carbon Sequestration">🟢 Carbon Seq </option>
                       <option value="Methodology">🟣  Methodology </option>
                       <option value="Monitoring">🟠 Monitoring </option>
                       <option value="Completed">🔵   Completed </option>
                </Field>
                <ErrorMessage
                  name="siteType"
                  render={msg => <div className="error">
                    &nbsp;Please select site type </div>}
                />
                <div className="arrowdown-ico">
                <ArrowDropDownIcon />
                </div>
                </div>
              </div>
        </div>
      </div>

      <div className="projects-info">
        <h2 className="rowmain-heading">Site Owner</h2>
        <div className="row">
        <div className="col-md-3 col-sm-12">
                <div className="edit-projects">
                  <label className="form-label">Site Owner Type</label>
                
                <Field as="select" name="siteOwnerType" className="form-select">
                {/* {formik.values.siteOwnerType ? (
                    <option selected value={formik.values.siteOwnerType}>{formik.values.siteOwnerType}</option>
                  ):""} */}
                  {siteOwnerTypes?.data?.map((val, index) => {
                          return (
                            <option value={val.id} key={index}>
                              {val.site_owner_type}
                            </option>
                          );
                        })}
                </Field>
                <ErrorMessage
                  name="siteType"
                  render={msg => <div className="error">
                    &nbsp;Please select site type </div>}
                />
                <div className="arrowdown-ico">
                <ArrowDropDownIcon />
                </div>
                </div>
              </div>
          <div className="col-md-3 col-sm-12">
            <div className="project-label edit-projects">
              <EditProjectInput type="text" name="siteOwnerName" label={"Site Owner Name"} placeholder="Site Owner Name" />
            </div>
          </div>
          <div className="col-md-3 col-sm-12">
            <div className="project-label edit-projects">
              <EditProjectInput type="text" name="siteOwnerContactNo" label={"Site Owner Contact No"} placeholder="Site Owner Contact No" />
            </div>
          </div>
          <div className="col-md-3 col-sm-12">
            <div className="project-label edit-projects">
                <EditProjectInput type="text" name="siteOwnerEmail" label={"Site Owner Email"} placeholder="Site Owner Email" />
            </div>
          </div>
          <div className="col-md-9 col-sm-12">
            <div className="project-label edit-projects">
              <EditProjectInput type="text" name="siteAddress" label={"Address"} placeholder="Address" />
            </div>
          </div>
        </div>
      </div>


      <div className="projects-info">
        <h2 className="rowmain-heading">Project Proponent</h2>
        <div className="row">
       
          <div className="col-md-3 col-sm-12">
            <div className="project-label edit-projects">
              <EditProjectInput name="organizationName" type="text" placeholder="Organization name" label={"Organization name"}/>
            </div>
          </div>
          <div className="col-md-3 col-sm-12">
            <div className="project-label edit-projects">
              <EditProjectInput name="contactPerson" type="text" placeholder="Contact person" label={"Contact person"} />
            </div>
          </div>
          <div className="col-md-3 col-sm-12">
            <div className="project-label edit-projects">
               <EditProjectInput type="text" label={"Title"} placeholder="Title" name="contactPersonTitle" />
            </div>
          </div>
          <div className="col-md-3 col-sm-12">
            <div className="project-label edit-projects">
              <EditProjectInput type="text" label={"Contact No"} placeholder="Contact No" name="contactPersonNo" />
            </div>
          </div>
          <div className="col-md-3 col-sm-12">
            <div className="project-label edit-projects">
              <EditProjectInput type="text" label={"Email"} placeholder="Email" name="contactPersonEmail" />
            </div>
          </div>
          <div className="col-md-9 col-sm-12">
            <div className="project-label edit-projects">
              <EditProjectInput type="text" label={"Address"} placeholder="Address" name="contactPersonAddress" />
              
            </div>
          </div>
        </div>
      </div>
      <div className="projects-info">
        <h2 className="rowmain-heading">Shape File</h2>
                  
                  <div className="row">
                    <div className="col-md-3 col-sm-12">
                    <div className="project-label edit-projects">
                      <TextInput
                        label="Area (in hectares)*"
                        name="areaInHectres"
                        type="text"
                        placeholder="Area (in hectares)"
                      />
                      </div>
                    </div>
                    <div className="col-md-4   col-sm-12">
                      <div className="sub-fieldcheck">
                        <p>Is shape is available</p>
                        {formik?.values?.shapefile ? (
                           <div className="d-flex justify-content-between">
                           <div>
                             <label>
                               <Field
                                 type="radio"
                                 name="shapeFileAvailable"
                                 value="true"
                                 onClick={() => HandleRadioButton2(true)}
                                 checked
                               />
                               Available
                             </label>
                           </div>
                           <div>
                             <label>
                               <Field
                                 type="radio"
                                 name="shapeFileAvailable"
                                 value="false"
                                 onClick={() => HandleRadioButton2(false)}
                               />
                               Not Available
                             </label>
                           </div>
                         </div>
                        ):( <div className="d-flex justify-content-between">
                        <div>
                          <label>
                            <Field
                              type="radio"
                              name="shapeFileAvailable"
                              value="true"
                              onClick={() => HandleRadioButton2(true)}
                            />
                            Available
                          </label>
                        </div>
                        <div>
                          <label>
                            <Field
                              type="radio"
                              name="shapeFileAvailable"
                              value="false"
                              onClick={() => HandleRadioButton2(false)}
                              checked
                            />
                            Not Available
                          </label>
                        </div>
                      </div>)}
                       
                      </div>
                    </div>
                  </div>
                  <div className="row" >
                    {checkshapeFile && (
                      <>
                      {shapeFileHandleupload == false && 
                          <div className="col-md-3 col-sm-12">
                    <div className="project-label edit-projects">
                      <div className="project-shapefile">
              <p>Shape file</p>
              <div className="d-flex justify-content-around">
              <button onClick={()=>handleShapeFileDownload()} className="shape-link">View shape file</button>
              <div className="shape-delico" onClick={() =>{
                setshapeFileHandleupload(true)
              }}><EditIcon htmlColor="blue" /></div>
              </div>
              </div>
                      </div>
                    </div>
}
                    {shapeFileHandleupload == true &&
                     <div className="col-md-3 col-sm-12">
                     <div class="file-input">
                       {/* <TextInput
                         label={"Upload Site file"}
                         id="file-input"
                         type="file"
                         name="geoReferencedSitemapImageFile"
                         className="file-input__input"
                       /> */}
                       <label>Upload Shape file</label>
                        <input
                           label={"Upload Site file"}
                           id="file-input1"
                           type="file"
                           name="subfieldNameFile"
                           className="file-input__input"
                           onChange={geoReferencedSitemapImageFileHandle}
                         />
                       <label class="file-input__label" for="file-input1">
                         <img
                           src={ICONS?.imageUplodIco}
                           style={{ margin: "auto" }}
                         />
                       </label>
                     </div>
                   </div>
                    }
                        {/* </div> */}
                        <div className="col-md-4   col-sm-12">
                          <div className="sub-fieldcheck">
                            <p>Sub fields boundaries present</p>
                            <div className="d-flex justify-content-between">
                              {formik?.values?.subfieldsBoundariesPresent === "true" || formik?.values?.subfieldsBoundariesPresent === true ?
                              (<>
                                <label>
                                <Field
                                  type="radio"
                                  name="subfieldsBoundariesPresent"
                                  value="true"
                                  checked
                                />
                                Available
                              </label>
                              <label>
                                <Field
                                  type="radio"
                                  name="subfieldsBoundariesPresent"
                                  value="false"
                              
                                />
                                Not Available
                              </label>
                              </>):

    (<>
                                <label>
                                <Field
                                  type="radio"
                                  name="subfieldsBoundariesPresent"
                                  value="true"
                                />
                                Available
                              </label>
                              <label>
                                <Field
                                  type="radio"
                                  name="subfieldsBoundariesPresent"
                                  value="false"
                                 checked
                                />
                                Not Available
                              </label>
                              </>)
                              }
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4   col-sm-12">
                          <div className="sub-fieldcheck">
                            <p>Sub field Name/Label present</p>
                            <div className="d-flex justify-content-between">
                            {formik?.values?.subfieldNamePresent === "true" || formik?.values?.subfieldNamePresent === true ?
                              (<>
                                <label>
                                <Field
                                  type="radio"
                                  name="subfieldNamePresent"
                                  value="true"
                                  checked
                                />
                                Available
                              </label>
                              <label>
                                <Field
                                  type="radio"
                                  name="subfieldNamePresent"
                                  value="false"
                              
                                />
                                Not Available
                              </label>
                              </>):

    (<>
                                <label>
                                <Field
                                  type="radio"
                                  name="subfieldNamePresent"
                                  value="true"
                                />
                                Available
                              </label>
                              <label>
                                <Field
                                  type="radio"
                                  name="subfieldNamePresent"
                                  value="false"
                                 checked
                                />
                                Not Available
                              </label>
                              </>)
                              }
                              {/* <label>
                                <Field
                                  type="radio"
                                  name="subfieldNamePresent"
                                  value="true"
                                  checked={formik.values.subfieldNamePresent === true }
                                />
                                Available
                              </label>
                              <label>
                                <Field
                                  type="radio"
                                  name="subfieldNamePresent"
                                  value="false"
                                  checked={formik.values.subfieldNamePresent === false || formik.values.subfieldNamePresent === null || formik.values.subfieldNamePresent === "" ? true:""}
                                />
                                Not Available
                              </label> */}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
      </div>


      <div className="projects-info">
        <h2 className="rowmain-heading">Geo Reference Map</h2>
        <div className="row">
        {/* <div className="col-md-3 col-sm-12">
            <div className="project-label edit-projects">
             
              <EditProjectInput name="geoReferencedSitemapImage" type="text" placeholder="Geo referenced site map image" label={"Geo referenced site map image"}/>
            </div>
          </div> */}
          <div className="col-md-4 col-sm-12">
            <div className="sub-fieldcheck">
              <p> Geo referenced site map present</p>
              { formik.values.geoReferencedSitemapPresent ?(
   <div className="d-flex justify-content-between">
   <label>
     <Field
       type="radio"
       name="geoReferencedSitemapPresent"
       value="true"
       checked
       onClick={() => setchangeGeoRefrenceMap(true)}
     />
     Available
   </label>
   <label>
     <Field
       type="radio"
       name="geoReferencedSitemapPresent"
       value="false"
       onClick={() => setchangeGeoRefrenceMap(false)}
     />
     Not Available
   </label>
 </div>
              ) : (   <div className="d-flex justify-content-between">
              <label>
                <Field
                  type="radio"
                  name="geoReferencedSitemapPresent"
                  value="true"
       onClick={() => setchangeGeoRefrenceMap(true)}
                />
                Available
              </label>
              <label>
                <Field
                  type="radio"
                  name="geoReferencedSitemapPresent"
                  value="false"
                  checked
       onClick={() => setchangeGeoRefrenceMap(false)}
                />
                Not Available
              </label>
            </div>)}
           
            </div>
          </div>
          {changeGeoRefrenceMap  &&
          <div className="col-md-3 col-sm-12">
            <div className="project-shapefile">
              <p>Site Map Image</p>
              
              <div className="d-flex justify-content-around">
              <button onClick={() =>handleSiteMapImg()} className="shape-link">View shape file</button>
              <div className="shape-delico" 
              onClick={() =>{
                setchangeGeoRefrenceMapEdit(true)
              }}
              ><EditIcon htmlColor="blue"/></div>
              </div>
             
              
            </div>
          </div>
        }
         {changeGeoRefrenceMapEdit && 
                  <div className="col-md-3 col-sm-12">
                          
                  <div class="file-input">
                  <label>Site Map Image</label>
                    <input
                      label={"Upload Shape file"}
                      id="file-input"
                      type="file"
                      name="geoReferencedSitemapImageFile"
                      className="file-input__input"
                      onChange={checekHandleSubfield}
                    />
                    <label class="file-input__label" for="file-input">
                      <img
                        src={ICONS.imageUplodIco}
                        style={{ margin: "auto" }}
                      />
                    </label>
                  </div>
                </div>
              }
         
        </div>
      </div>

      <div className="projects-info">
        <h2 className="rowmain-heading">Vegetation Info</h2>
        <div className="row">
        <div className="col-md-3 col-sm-12">
                <div className="edit-projects">
                  <label className="form-label">Vegetation Type</label>
                
                <Field as="select" name="vegetationType" className="form-select">
                {/* {formik.values.vegetationType ? (
                    <option selected value={formik.values.vegetationType}>{formik.values.vegetationType}</option>
                  ):""} */}
                {siteVegetationTypes?.data?.map((val, index) => {
                          return (
                            <option value={val.vegitaiontypeid} key={index}>
                              {val.vegitationtype}
                            </option>
                          );
                        })}
                  {/* {sitetypes?.map((val, index) => {
                    return (
                      <option value={val.sitetypeid} key={index}>
                        {val.sitetype}
                      </option>
                    );
                  })} */}
                </Field>
                <ErrorMessage
                  name="siteType"
                  render={msg => <div className="error">
                    &nbsp;Please select site type </div>}
                />
                <div className="arrowdown-ico">
                <ArrowDropDownIcon />
                </div>
                </div>
              </div>

          <div className="col-md-3 col-sm-12">
            <div className="project-label edit-projects">
              {/* <label>Plant Species</label>
              <input
                type="text"
                name=""
                value="Spotted knapweed"
                placeholder="Congo"
                
              /> */}
              <EditProjectInput type="text" name="plantSpecies" placeholder="Plant Species" label={"Plant Species"}/>
            </div>
          </div>

          <div className="col-md-3 col-sm-12">
                <div className="edit-projects">
                  <label className="form-label">Age of vegetation</label>
                
                <Field as="select" name="ageOfVegetation" className="form-select">
                {/* {formik.values.ageOfVegetation ? (
                    <option selected value={formik.values.ageOfVegetation}>{formik.values.ageOfVegetation}</option>
                  ):""} */}
              {ageOfVegetation?.data?.map((val, index) => {
                          return (
                            <option value={val.id} key={index}>
                              {val.vegetation_age}
                            </option>
                          );
                        })}
                  {/* {sitetypes?.map((val, index) => {
                    return (
                      <option value={val.sitetypeid} key={index}>
                        {val.sitetype}
                      </option>
                    );
                  })} */}
                </Field>
                <ErrorMessage
                  name="siteType"
                  render={msg => <div className="error">
                    &nbsp;Please select site type </div>}
                />
                <div className="arrowdown-ico">
                <ArrowDropDownIcon />
                </div>
                </div>
              </div>
        </div>
      </div>

      <div className="projects-info">
        <h2 className="rowmain-heading">Soil Information</h2>
        <div className="row">
        <div className="col-md-3 col-sm-12">
                <div className="edit-projects">
                  <label className="form-label">Soil Type</label>
                
                <Field as="select" name="soilType" className="form-select">
                   {siteSoilTypes?.data?.map((val, index) => {
                          return (
                            <option value={val.soiltypeid} key={index}>
                              {val.soiltype}
                            </option>
                          );
                        })}
                </Field>
                <ErrorMessage
                  name="siteType"
                  render={msg => <div className="error">
                    &nbsp;Please select site type </div>}
                />
                <div className="arrowdown-ico">
                <ArrowDropDownIcon />
                </div>
                </div>
              </div>

              <div className="col-md-3 col-sm-12">
                <div className="edit-projects">
                  <label className="form-label">pH</label>
                
                <Field as="select" name="soilPh" className="form-select">
                    {sitePhType?.data?.map((val, index) => {
                          return (
                            <option value={val.id} key={index}>
                              {val.soil_ph_type}
                            </option>
                          );
                        })}
                </Field>
                <ErrorMessage
                  name="siteType"
                  render={msg => <div className="error">
                    &nbsp;Please select site type </div>}
                />
                <div className="arrowdown-ico">
                <ArrowDropDownIcon />
                </div>
                </div>
              </div>

          <div className="col-md-3 col-sm-12">
            <div className="project-label edit-projects">
              <EditProjectInput type="text" name="organicCarbon" placeholder="Organic Carbon" label={"Organic Carbon"}/>
            </div>
          </div>

          <div className="col-md-3 col-sm-12">
            <div className="project-label edit-projects">
              <EditProjectInput name="bulkDensity" type="text" placeholder="OrBulk Density" label={"OrBulk Density"}/>
            </div>
          </div>
        </div>
      </div>

      {/* satus section */}
      {/* <div className="projects-info">
        <h2 className="rowmain-heading">Status</h2>
        <div className="row">
        <div className="col-md-2 col-sm-12">
                <div className="edit-projects">
                <div className="radio-border">
                <input type="radio" id="Pre_Analysis" name="status" value="Pre Analysis" />
                <label for="Pre_Analysis">Pre Analysis</label>
                </div>
                </div>
          </div>
          <div className="col-md-2 col-sm-12">
                <div className="edit-projects">
                <div className="radio-border">
                <input type="radio" id="Site_Analysis" name="status" value="Site Analysis" />
                <label for="Site_Analysis">Site Analysis</label>
                </div>
                </div>
          </div>
          <div className="col-md-2 col-sm-12">
                <div className="edit-projects">
                <div className="radio-border">
                <input type="radio" id="html" name="status" value="Carbon Seq" />
                <label for="html">Carbon Seq</label>
                </div>
                </div>
          </div>
          <div className="col-md-2 col-sm-12">
                <div className="edit-projects">
                <div className="radio-border">
                <input type="radio" id="Methodology" name="status" value="Methodology" />
                <label for="Methodology">Methodology</label>
                </div>
                </div>
          </div>
          <div className="col-md-2 col-sm-12">
                <div className="edit-projects">
                <div className="radio-border">
                <input type="radio" id="Monitoring" name="status" value="Monitoring" />
                <label for="Monitoring">Monitoring</label>
                </div>
                </div>
          </div>
          <div className="col-md-2 col-sm-12">
            
                <div className="edit-projects">
                <div className="radio-border">
                <input type="radio" id="Completed" name="status" value="Completed" />
                <label for="Completed">Completed</label>
                </div>
                </div>
          </div>
        </div>
      </div> */}
      </Form>
       )}
       </Formik>
    </div>
  );
}


export default EditProject;