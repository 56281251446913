import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../../axios";
import axiosClient from "../../axios";
import { Button } from "@mui/material";
import * as Yup from "yup";
import ICONS from '../../features/images';
import { Formik, Form, ErrorMessage, Field } from "formik";
import { TextInput } from "../../components/TextField";
import { add_user, view_all_roles, view_organizations_list } from "../../features/Apis/Index";
const CreateUser = () => {
  let nav = useNavigate();
  const [showRole, setShowRole] = useState([]);
  const [showOrganizations, setShowOrganizations] = useState([]);
  useEffect(() => {
    getUserOrganization();
    getUserRole();
  }, []);
  const getUserOrganization = () => {
    axios.get(view_organizations_list).then((res) => {
      setShowOrganizations(res.data.gaitObj);
    });
  };
  const getUserRole = () => {
    axios.get(view_all_roles).then((res) => {
      setShowRole(res.data.gaitObj);
    });
  };
  const validate = Yup.object({
    firstname: Yup.string()
      .max(15, "must be 15")
      .required("First Name is require"),
    lastname: Yup.string()
      .max(15, "must be 15")
      .required("Last Name is require"),
    email: Yup.string().email("Email is invalid").required("Email is required"),
    mobilenum: Yup.number().required("Mobile Number is required"),
    designation: Yup.string().required("Designation is require"),
    username: Yup.string()
      .email("Username is invalid")
      .required("Username is required"),
    organization: Yup.string().required("Please select organization"),
    rolename: Yup.string().required("Please select role"),
    status: Yup.string().required("Please select role"),
    password: Yup.string()
      .required("Password is required.")
      .min(8, "Password is too short - should be 8 chars minimum.")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      ),
  });
  const addUser = async (values) => {
    axiosClient
      .post(add_user, {
        personIdref: {
          orgidref: {
            orgid: values.organization,
          },
          firstname: values.firstname,
          lastname: values.lastname,
          designation: values.designation,
          mobilenum: values.mobilenum,
          email: values.email,
        },
        roleid: values.rolename,
        username: values.username,
        authtoken: "NA",
        password: values.password,
        isactive: values.status,
        createdby: 1965,
        modifiedby: 1965,
      })
      .then((res) => {
        localStorage.setItem('userCreated' , true);
        nav("/users");
      })
      .catch((e) => {
        alert(e, "getting error ");
      });
  };
  return (
    <Formik
      initialValues={{
        firstname: "",
        lastname: "",
        email: "",
        mobilenum: "",
        designation: "",
        password: "",
        username: "",
        organization: "",
        rolename: "",
        status: ""
      }}
      validationSchema={validate}
      onSubmit={(values) => {
        addUser(values);
      }}
    >
      {(formik) => (
        <div className="container">
          
          <Button
            onClick={(e) => {
              nav("/users");
            }}
            style={{
              marginLeft: "auto",
              display: "block",
              width: 140,
              background: "linear-gradient(0deg, #55AA8B, #55AA8B), #FEFEFE",
              borderRadius: 4,
              color: "white",
            }}
          >
            View All
          </Button>
          <Form>
            <div className="row form-top">
              <div className="col-md-3 col-sm-12">
                <div className="d-flex justify-content-between align-item-center">
                  <div>
                  <label className="form-label">Organization</label>
                  </div>
                  <div>
                  </div>
                </div>
                <Field as="select" name="organization" className="form-select">
                  <option >-select-</option>
                  {showOrganizations?.map((val) => {
                    return (
                      <option value={val.orgid} key={val.orgid}>
                        {val.orgname}{" "}
                      </option>
                    );
                  })}
                </Field>
                <ErrorMessage
                  name="organization"
                  render={msg => <div className="error"><img src={ICONS.WarningIcon} />&nbsp;Please select organization </div>}
                />
              </div>
              <div className="col-md-3 col-sm-12">
                <TextInput label="First Name" name="firstname" type="text" />
              </div>
              <div className="col-md-3 col-sm-12">
                <TextInput label="Last Name" name="lastname" type="text" />
              </div>
              <div className="col-md-3 col-sm-12">
                <TextInput label="Designation" name="designation" type="text" />
              </div>
            </div>
            <div className="row">
              <div className="col-md-3 col-sm-12">
                <div className="change-profilepic">
                  <img src={ICONS.ProfileImg} width="100%" />
                </div>
              </div>
              <div className="col-md-3 col-sm-12">
               
                <TextInput label="Mobile Number" name="mobilenum" type="text" />
                <div className="mob-number">
                  <TextInput label="Username" name="username" type="text" />
                </div>
              </div>
              <div className="col-md-3 col-sm-12">
                <TextInput label="Email" name="email" type="email" />
                <div className="mob-number">
                  <TextInput label="Password" name="password" type="text" />
                </div>
              </div>
              <div className="col-md-3 col-sm-12">
                <label className="form-label">Role</label>
                <Field as="select" name="rolename" className="form-select" label="Role">
                  <option >-select-</option>
                  {showRole?.map((val) => {
                    return (
                      <option value={val.roleid} key={val.roleid}>
                        {val.rolename}{" "}
                      </option>
                    );
                  })}
                </Field>
                <ErrorMessage
                  name="rolename"
                  render={msg => <div className="error"><img src={ICONS.WarningIcon} />&nbsp;Please select role </div>}
                />
                <div className="mob-number">
                  <label className="form-label">Status</label>
                  <Field as="select" name="status" className="form-select">
                    <option>-select-</option>
                    <option value={1}>Active</option>
                    <option value={0}>Inactive</option>
                  </Field>
                   <ErrorMessage
                  name="status"
                  render={msg => <div className="error"><img src={ICONS.WarningIcon} />&nbsp;Please select status </div>}
                />
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end bottom-button">
              <Button
                style={{
                  marginLeft: 15,
                  display: "block",
                  width: 140,
                  borderRadius: 4,
                  borderColor: "#FF6E6E",
                  borderWidth: 1,
                  color: "#FF6E6E",
                  border: "1px solid",
                  fontFamily: "Poppins",
                  fontSize: 13,
                }}
                onClick={(e) => {
                  nav("/users");
                }}
              >
                Cancel
              </Button>

              <Button
                style={{
                  marginLeft: 15,
                  display: "block",
                  width: 140,
                  background:
                    "linear-gradient(0deg, #55AA8B, #55AA8B), #FEFEFE",
                  borderRadius: 4,
                  color: "white",
                  fontFamily: "Poppins",
                  fontSize: 13,
                }}
                type="submit"
              >
                Create
              </Button>
            </div>
          </Form>
        </div>
      )}
    </Formik>
  );
};

export default CreateUser;
