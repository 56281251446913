import React, { useState } from 'react';
import * as Yup from "yup";
import { Formik, Form, ErrorMessage, Field } from "formik";
import { useNavigate } from "react-router-dom";
import { TextInput } from '../../components/TextField';
import { Button,Snackbar,Alert } from "@mui/material";
import axiosClient from '../../axios';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { save_sensor_installation_info, viewallusers, view_all_sites, view_inactive_sensors } from '../../features/Apis/Index';
const SiteInstall = () => {
    let nav = useNavigate();
    const [usersList, setUsers] = useState([]);
    const [inactivesensors, setinactsensors] = useState([]);
    const [sites, setSites] = useState([]);
    const [aopen, setAlertOpen] = useState(false);
    const user = useSelector(state => state.loginData.user)
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setAlertOpen(false);
      };
    const getAllSites = (orgid, orgcode) => {
        axiosClient.get(`v1/gait/site/${view_all_sites}${orgid}/${orgcode}`).then((res) => {
          setSites(res.data.gaitObj)
        })
      }

      const getAllInactSensors = () => {
        axiosClient.get(view_inactive_sensors).then((res) => {
          setinactsensors(res.data.gaitObj)
        })
      }

      const getAllUsers = (orgid, orgcode) => {
        axiosClient.get(`${viewallusers}${orgid}/${orgcode}`).then((res) => {
          setUsers(res.data.gaitObj)
        })
      }
   
    const addInstallation = (val) => {
        axiosClient.post(save_sensor_installation_info, {
          "sensoridref": {
            "sensorid": Number(val.sensorid)
          },
          "siteidref": {
            "siteid": Number(val.siteid)
          },
          "coveragearea": Number(val.coveragearea),
          "elevation": Number(val.elevation),
          "sensor_longitude": val.sensor_longitude,
          "sensor_latitude": val.sensor_latitude,
          "commu_frequency": Number(val.commu_frequency),
          "commu_network": val.commu_network,
          "installedby": val.installedby,
          "sensor_status": val.sensor_status,
          "insertedby": val.installedby,
          "modifiedby": val.installedby
        }).then(res => {
            setAlertOpen(true);
    
        })
      }
      useEffect(()=>{
        getAllSites(user?.personidref?.orgidref?.orgid, user?.personidref?.orgidref?.orgcode);
        getAllInactSensors();
        getAllUsers(user?.personidref?.orgidref?.orgid, user?.personidref?.orgidref?.orgcode)
      },[])
    const validate = Yup.object({
        coveragearea: Yup.string()
          .required("Coverage area is require"),
          elevation : Yup.string()
          .required("Elevation is require"),
          sensor_longitude: Yup.string().required("Sensor longitude is required"),
          sensor_latitude: Yup.string().required("Sensor latitude is required"),
          commu_frequency: Yup.string().required("Frequency is require"),
          commu_network: Yup.string().required("Network in required"),
          installedby: Yup.string().required("Installedby is required"),
          sensor_status:Yup.string().required("Sensor status is required"),
          siteid:Yup.string().required("Site is required"),
          sensorid:Yup.string().required("Sensor is required")
      });
  return (
    <Formik
    initialValues={{
        coveragearea: "",
        elevation: "",
        sensor_longitude: "",
        sensor_latitude: "",
        commu_frequency: "",
        commu_network: "",
        installedby: "",
        sensor_status: "",
        sensor_status: "",
        siteid:'',
        sensorid:'',
    }}
    validationSchema={validate}
    onSubmit={(values) => {
        addInstallation(values);
    }}
  >
    {(formik) => (
        <Form>
            <Button
            onClick={(e) => {
              nav("/sites");
            }}
            style={{
              marginLeft: "auto",
              display: "block",
              width: 140,
              background: "linear-gradient(0deg, #55AA8B, #55AA8B), #FEFEFE",
              borderRadius: 4,
              color: "white",
            }}
          >
            View All
          </Button>
          <Snackbar open={aopen} autoHideDuration={2000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Site installed successfully.
        </Alert>
      </Snackbar>
            <div className="row form-top sites-content">
              
              <div className="col-md-3 col-sm-12">
                <div className="d-flex justify-content-between align-item-center">
                  <div>
                  <label className="form-label">Site</label>
                  </div>
                  <div>
                  </div>
                </div>
                <Field as="select" name="siteid" className="form-select">
                  <option>-select-</option>
                  {sites?.map((val, index) => {
                  return (<option value={val.siteid} key={index}>{val.sitename}12</option>)
                })}
                </Field>
                <ErrorMessage
                  name="siteid"
                  render={msg => <div className="error">
                    &nbsp;Please select site type </div>}
                />
          
              </div>

              <div className="col-md-3 col-sm-12">
                <div className="d-flex justify-content-between align-item-center">
                  <div>
                  <label className="form-label">Sensor</label>
                  </div>
                  <div>
                  </div>
                </div>
                <Field as="select" name="sensorid" className="form-select">
                  <option>-select-</option>
                  {inactivesensors?.map((val, index) => {
                  return (<option value={val.sensorid} key={index}>{val.sensormodel}</option>)
                })}
                </Field>
                <ErrorMessage
                  name="sensorid"
                  render={msg => <div className="error">
                    &nbsp;Please select sensor</div>}
                />
          
              </div> 
              <div className="col-md-3 col-sm-12">
                <TextInput label="Coverage Area" name="coveragearea" type="text" placeholder="coverage area"/>
              </div>
              <div className="col-md-3 col-sm-12">
                <TextInput label="Elevation" name="elevation" type="text" placeholder="Elevation"/>
              </div>
              <div className='row addsite-body'>
                <div className='col-md-3 col-sm-12'>
                <TextInput label="Longitude" name="sensor_longitude" type="text" placeholder="Longitude"/>
                </div>
                <div className='col-md-3 col-sm-12'>
                <TextInput label="Latitude" name="sensor_latitude" type="text" placeholder="latitude"/>
                </div>
                <div className='col-md-3 col-sm-12'>
                <TextInput label="Comm. Frequency" name="commu_frequency" type="text" placeholder="Frequency"/>
                </div>
                <div className="col-md-3 col-sm-12">
                <div className="d-flex justify-content-between align-item-center">
                  <div>
                  <label className="form-label">Comm Network</label>
                  </div>
                  <div>
                  </div>
                </div>
                <Field as="select" name="commu_network" className="form-select">
                  <option>-select-</option>
                  <option value="GSM">GSM</option>
                <option value="Satellite">Satellite</option>
                 
                </Field>
                <ErrorMessage
                  name="siteType"
                  render={msg => <div className="error">
                    &nbsp;Please select Comm Network </div>}
                />
          
              </div> 
              </div>

              <div className='row addsite-body'>
              <div className="col-md-3 col-sm-12">
                <div className="d-flex justify-content-between align-item-center">
                  <div>
                  <label className="form-label">Installed by</label>
                  </div>
                  <div>
                  </div>
                </div>
                <Field as="select" name="installedby" className="form-select">
                  <option >-select-</option>
                  {usersList?.map((val, index) => {
                  return (<option value={val.orguserid} key={index}>{val.personIdref.firstname} {val.personIdref.lastname}</option>)
                })}
                </Field>
                <ErrorMessage
                  name="installedby"
                  render={msg => <div className="error">
                    &nbsp;Please select Installed by </div>}
                />
          
              </div>

              <div className='col-md-3 col-sm-12'>
                <TextInput label="Install Date" name="installeddate" type="date" placeholder="Install Date"/>
                </div>
                <div className='col-md-3 col-sm-12'>
                <TextInput label="Maintenance Due Date" name="maintenancedate" type="date" placeholder="email"/>
                </div>
                <div className="col-md-3 col-sm-12">
                <div className="d-flex justify-content-between align-item-center">
                  <div>
                  <label className="form-label">Status</label>
                  </div>
                  <div>
                  </div>
                </div>
                <Field as="select" name="sensor_status" className="form-select">
                  <option>-select-</option>
                  <option value={1}>Active</option>
                  <option value={0}>InActive</option>
                 
                </Field>
                <ErrorMessage
                  name="sensor_status"
                  render={msg => <div className="error">
                    &nbsp;Please select status </div>}
                />
          
              </div>
              </div>
              <div className="d-flex justify-content-end bottom-button add-sitebtn">
              <Button
                style={{
                  marginLeft: 15,
                  display: "block",
                  width: 140,
                  borderRadius: 4,
                  borderColor: "#FF6E6E",
                  borderWidth: 1,
                  color: "#FF6E6E",
                  border: "1px solid",
                  fontFamily: "Poppins",
                  fontSize: 13,
                }}
                onClick={(e) => {
                  nav("/sites");
                }}
              >
                Cancel
              </Button>

              <Button
                style={{
                  marginLeft: 15,
                  display: "block",
                  width: 140,
                  background:
                    "linear-gradient(0deg, #55AA8B, #55AA8B), #FEFEFE",
                  borderRadius: 4,
                  color: "white",
                  fontFamily: "Poppins",
                  fontSize: 13,
                }}
                type="submit"
              >
                Create
              </Button>
            </div>
            </div>
        </Form>
    )}
    </Formik>
  )
}

export default SiteInstall;