import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { Line } from "react-chartjs-2";
import { format } from "date-fns";
import Typography from "@mui/material/Typography";
import ICONS from '../../features/images';
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  ArcElement,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import {
  GridToolbarExport,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import { getSensorInfo } from "../../features/counter/APIs";
import { connect, useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import L from "leaflet";

ChartJS.register(
  ArcElement,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

const columns = [
  { field: "id", headerName: "ID", width: 90 },
  {
    field: "Amb_Pressure",
    headerName: "Amb_Pressure",
    width: 150,
    editable: true,
  },
  {
    field: "Amb_Temp",
    headerName: "Amb_Temp",
    width: 150,
    editable: true,
  },
  {
    field: "CO2",
    headerName: "CO2",
    type: "number",
    width: 110,
    editable: true,
  },
  {
    field: "DateTime",
    headerName: "DateTime",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
  },
  {
    field: "DeviceId",
    headerName: "DeviceId",
    type: "number",
    width: 110,
    editable: true,
  },
  {
    field: "FD_CO2_Conc_ppm",
    headerName: "FD_CO2_Conc_ppm",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
  },

  {
    field: "FD_CO2_Flux_umol/m2/s",
    headerName: "FD_CO2_Flux_umol/m2/s",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
  },

  {
    field: "FD_Temp_DegC",
    headerName: "FD_Temp_DegC",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
    valueGetter: (params) => `${params.row.FD_Temp_DegC || ""} `,
  },

  {
    field: "Fc_mass_mg",
    headerName: "Fc_mass_mg",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
    valueGetter: (params) => `${params.row.Fc_mass_mg || ""} `,
  },

  {
    field: "H2O",
    headerName: "H2O",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
    valueGetter: (params) => `${params.row.H2O || ""}`,
  },

  {
    field: "Record_No",
    headerName: "Record_No",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
    valueGetter: (params) => `${params.row.Record_No || ""}`,
  },

  {
    field: "Sonic_temp",
    headerName: "Sonic_temp",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
    valueGetter: (params) => `${params.row.Sonic_temp || ""} `,
  },

  {
    field: "Source_Temp",
    headerName: "Source_Temp",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
    valueGetter: (params) => `${params.row.Source_Temp || ""} `,
  },

  {
    field: "Timestamp",
    headerName: "Timestamp",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
    valueGetter: (params) => `${params.row.Timestamp || ""} `,
  },
  {
    field: "UxCO2_Cov",
    headerName: "UxCO2_Cov",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
    valueGetter: (params) => `${params.row.UxCO2_Cov || ""} `,
  },
  {
    field: "UxH2O_Cov",
    headerName: "UxH2O_Cov",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
    valueGetter: (params) => `${params.row.UxH2O_Cov || ""} `,
  },
  {
    field: "UyCO2_Cov",
    headerName: "UyCO2_Cov",
    description: "This column has a value getter and is not sortable.",

    sortable: false,
    width: 160,
    valueGetter: (params) => `${params.row.UyCO2_Cov || ""} `,
  },
  {
    field: "UyH2O_Cov",
    headerName: "UyH2O_Cov",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
    valueGetter: (params) => `${params.row.UyH2O_Cov || ""} `,
  },
  {
    field: "UzCO2_Cov",
    headerName: "UzCO2_Cov",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
    valueGetter: (params) => `${params.row.UzCO2_Cov || ""} `,
  },
  {
    field: "UzH2O_Cov",
    headerName: "UzH2O_Cov",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
    valueGetter: (params) => `${params.row.UzH2O_Cov || ""}`,
  },
  {
    field: "Wind_Ux",
    headerName: "Wind_Ux",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
    valueGetter: (params) => `${params.row.Wind_Ux || ""}`,
  },
  {
    field: "Wind_Uy",
    headerName: "Wind_Uy",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
    valueGetter: (params) => `${params.row.Wind_Uy || ""} `,
  },
  {
    field: "Wind_Uz",
    headerName: "Wind_Uz",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
    valueGetter: (params) => `${params.row.Wind_Uz || ""} `,
  },
];

function CarbonFluxReport(props) {
  const location = useLocation();
  const [urlParam] = useSearchParams();
  const sensorid = location.state.sensorId;
  const [value, setValue] = React.useState(0);
  const [graphDataObj, setgraphDataObj] = React.useState(null);
  const [dateValue, setDateValue] = React.useState(
    format(new Date(), "yyyy-MM-dd")
  );

  // const [sensorRecord, setSensorRecord] = React.useState([]);
  const [graphDataArray, setGraphDataArray] = React.useState([]);
  const [windXYZ, setWindXYZ] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [permission, setPermission] = React.useState(false);
  const [windVal_Y, setWindVal_Y] = useState({});
  const [windVal_Z, setWindVal_Z] = useState({});
  const [coTwoData, setCoTwoData] = useState({});
  const [h2oTwoData, setHTwoData] = useState({});
  // const sensorgrey = L.icon({ iconUrl: sgrey, iconSize: 25 });
  // const sensorgreen = L.icon({ iconUrl: sgreen, iconSize: 25 });
  // const sensorred = L.icon({ iconUrl: sred, iconSize: 25 });

  const sensor = useSelector((state) => state.sensorInfo?.gaitObj);
  const user = useSelector((state) => state.loginData.user);
  const [tabValue, setTabValue] = React.useState(0);
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  const handleChangetab = (event, newValue) => {
    setTabValue(newValue);
  };
  let acount = [];
  let icount = [];

  React.useEffect(() => {
    setTimeout(() => {
      setPermission(true);
    }, 3000);
  }, []);
  var options = {
    elements: {
      rectangle: {
        borderWidth: 2,
      },
    },
    layout: {
      padding: 0,
    },
    legend: {
      display: true,
      labels: {
        boxWidth: 16,
      },
    },
    maintainAspectRatio: false,
    responsive: true,

    title: {
      display: false,
    },
    tooltips: {
      intersect: false,
      mode: "index",
      position: "nearest",
      callbacks: {},
    },
    scales: {
      y: {
        display: false,
      },
      x: {
        display: false,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };
  var windOptions = {
    elements: {
      rectangle: {
        borderWidth: 2,
      },
    },
    layout: {
      padding: 0,
    },
    legend: {
      display: false,
      labels: {
        boxWidth: 16,
      },
    },
    maintainAspectRatio: false,
    responsive: true,

    title: {
      display: false,
    },
    tooltips: {
      intersect: false,
      mode: "index",
      position: "nearest",
      callbacks: {},
    },
    scales: {
      y: {
        display: false,
      },
      x: {
        display: false,
      },
    },
    plugins: {
      legend: {
        display: true,
        labels: {
          color: 'rgb(255, 99, 132)'
      }
      },
    },
  };
  var winLegendOption = {
    elements: {
      rectangle: {
        borderWidth: 1,
      },
    },
    layout: {
      padding: 0,
      right: 1000,
    },
    legend: {
      display: false,
      labels: {
        boxWidth: 16,
      },
    },
    maintainAspectRatio: false,
    responsive: true,

    title: {
      display: false,
    },
    tooltips: {
      intersect: false,
      mode: "index",
      position: "nearest",
      callbacks: {},
    },
    scales: {
      y: {
        display: true,
      },
      x: {
        display: true,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  var winLegendOption2 = {
    elements: {
      rectangle: {
        borderWidth: 2,
      },
    },
    layout: {
      padding: 0,
    },
    legend: {
      display: false,
      labels: {
        boxWidth: 16,
      },
    },
    maintainAspectRatio: false,
    responsive: true,

    title: {
      display: false,
    },
    tooltips: {
      intersect: false,
      mode: "index",
      position: "nearest",
      callbacks: {},
    },
    scales: {
      y: {
        display: false,
        grid: {
          color: "red",
          borderColor: "red",
          tickColor: "red",
        },
      },
      x: {
        display: false,
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    xAxes: [
      {
        display: true,
        position: "bottom",
        ticks: {
          autoSkip: false,
          maxRotation: 90,
          minRotation: 90,
        },
      },
    ],
  };

  const getMapData = () =>{
    setLoading(true);
    // let fetchRes = fetch(`https://uchohlcrkovc4bdlgmqhvt2h740utiwz.lambda-url.ap-southeast-2.on.aws/?sensor_id=${sensorid}&for_date=${dateValue}`)
    fetch(
      `https://uchohlcrkovc4bdlgmqhvt2h740utiwz.lambda-url.ap-southeast-2.on.aws/?sensor_id=${sensorid}&for_date=${dateValue}`
    )
      .then((res) => res.json())
      .then((resp) => {
        
        let graphData = resp?.data;
        graphData?.map((val, index) => {
          val.id = index;
        });
        let humidArry = graphData?.map((val, index) => {
          // return val.Fc_mass_mg || 0;
          return graphData[index].Fc_mass_mg || 0;
        });

        let windx = graphData?.map((val, index) => {
          return val.Wind_Ux || 0;
          // return graphData[index].Wind_Ux || 0;
        });
        let windy = graphData?.map((val, index) => {
          return val.Wind_Uy || 0;
          // return graphData[index].Wind_Uy || 0;
        });
        let windz = graphData?.map((val, index) => {
          return val.Wind_Uz || 0;
          // return graphData[index].Wind_Uz || 0;
        });
        let co2Value = graphData?.map((val, index) => {
          return val.CO2 || 0;
          // return graphData[index].CO2 || 0;
        });
        let ho2Value = graphData?.map((val, index) => {
          return val.H2O || 0;
          // return graphData[index].H2O || 0;
        });
        let DateTime = graphData?.map((val, index) => {
          // return new Date(graphData[index].DateTime).toLocaleTimeString();
          return new Date(val.DateTime).toLocaleTimeString();
        });

        setGraphDataArray(graphData);
        var data = {
          datasets: [
            {
              backgroundColor: "#96A3FD",
              borderColor: "#96A3FD",
              fill: false,
              data: humidArry,
              id: "carbon",
              pointRadius: 0,
              label: "Carbon Flux",
              yAxisID: "left",
            },
          ],
          labels: DateTime,
        };
        setgraphDataObj(data);
        var wind_x = {
          datasets: [
            {
              backgroundColor: "#55aa8b",
              borderColor: "#55aa8b",
              fill: false,
              data: windx,
              id: "carbon",
              label: "Wind_Ux",
              yAxisID: "left",
            },
            {
              backgroundColor: "#6F3895",
              borderColor: "#6F3895",
              fill: false,
              data: windy,
              id: "ambp",
              label: "Wind_Uy",
              yAxisID: "right",
            },
            {
              backgroundColor: "#FFD600",
              borderColor: "#FFD600",
              fill: false,
              data: windz,
              id: "ambt",
              label: "Wind_Uz",
              yAxisID: "left",
            },
          ],
          labels: DateTime,
        };
        setWindXYZ(wind_x);
        var wind_y = {
          datasets: [
            {
              backgroundColor: "#6F3895",
              borderColor: "#6F3895",
              fill: false,
              data: windy,
              id: "ambp",
              label: "Wind_Uy",
              yAxisID: "right",
            },
          ],
          labels: DateTime,
        };
        setWindVal_Y(wind_y);
        var wind_z = {
          datasets: [
            {
              backgroundColor: "#FFD600",
              borderColor: "#FFD600",
              fill: false,
              data: windz,
              id: "ambt",
              label: "Wind_Uz",
              yAxisID: "left",
            },
          ],
          labels: DateTime,
        };
        setWindVal_Z(wind_z);
        var co2Dataset = {
          datasets: [
            {
              backgroundColor: "#0085FF",
              borderColor: "#0085FF",
              fill: false,
              data: co2Value,
              id: "ambt",
              label: "CO2",
              yAxisID: "left",
              pointRadius: 0,
            },
          ],
          labels: DateTime,
        };
        setCoTwoData(co2Dataset);

        var h2oDataset = {
          datasets: [
            {
              backgroundColor: "#6BBE45",
              borderColor: "#6BBE45",
              fill: false,
              data: ho2Value,
              id: "ambt",
              label: "H2O",
              pointRadius: 0,
            },
          ],
          labels: DateTime,
        };
        setHTwoData(h2oDataset);
        setLoading(false);
      });
  }

  useEffect(() => {
    getMapData();
    props.getSensor(sensorid);
  }, [dateValue]);
  function toolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport
          csvOptions={{ fileName: "GAIT_" + format(new Date(), "dd-MM-yyyy") }}
          printOptions={{ disableToolbarButton: true }}
        />
      </GridToolbarContainer>
    );
  }
  return (
    <>
    
      {loading ? (
        <div>
          <img
            src={ICONS.SignalGreen}
            style={{
              width: "5%",
              position: "absolute",
              top: "50%",
              left: "50%",
            }}
          />
        </div>
      ) : (
        <>
          <Grid
            container
            spacing={2}
            style={{ marginTop: 0, alignItems: "stretch" }}
            alignItems="start"
          >
            <Grid item lg={12}>
              <div hidden={value !== 0} role="tabpanel" value={value} index={0}>
                <Box sx={{ height: 150, width: "100%" }}>
                  {graphDataObj && (
                    <>
                      <div className="d-flex justify-content-center ">
                        <div className="graph-title w5">
                          <p>CARBON FLUX</p>
                        </div>
                        <div className="w95">
                          <Line options={options} data={graphDataObj} />
                        </div>
                      </div>
                    </>
                  )}
                </Box>
              </div>

              {/* wind x */}
              <div hidden={value !== 0} role="tabpanel" value={value} index={0}>
                <Box sx={{ height: 150, width: "100%", marginLeft: -2 }}>
                  {permission === true ? (
                    <>
                      <div className="d-flex justify-content-center">
                        <div className="graph-title w5">
                          <p>WIND</p>
                        </div>
                        <div className="w95">
                          {windXYZ != {} ?(<Chart options={windOptions} type="bar" data={windXYZ}/>):null}
                        </div>
                      </div>
                    </>
                  ) : null}
                </Box>
              </div>

              {/* co2 */}
              <div hidden={value !== 0} role="tabpanel" value={value} index={0}>
                <Box sx={{ height: 150, width: "100%" }}>
                  {permission === true ? (
                    <>
                      <div className="d-flex justify-content-center">
                        <div className="graph-title w5">
                          <p>CO2</p>
                        </div>
                        <div className="w95">
                          {coTwoData !={} ? (<Line options={winLegendOption2} data={coTwoData} />):null}
                        </div>
                      </div>
                    </>
                  ) : null}
                </Box>
              </div>
              {/* co2 */}
              <div hidden={value !== 0} role="tabpanel" value={value} index={0}>
                <Box sx={{ height: 150, width: "100%", marginLeft: -1.8 }}>
                  {permission === true ? (
                    <>
                      <div className="d-flex justify-content-center">
                        <div className="graph-title w5">
                          <p>H2O</p>
                        </div>
                        <div className="w95">
                          {h2oTwoData !={} ?(<Line options={winLegendOption} data={h2oTwoData} />):null}
                        </div>
                      </div>
                    </>
                  ) : null}
                </Box>
              </div>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}

const mapDispatchToProps = {
  getSensor: getSensorInfo,
};
export default connect(null, mapDispatchToProps)(CarbonFluxReport);
