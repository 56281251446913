import { Card, CardContent, Grid } from "@mui/material";
import React, { useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Alert, Snackbar } from "@mui/material";
import { Chart as ChartJS, registerables } from "chart.js";
import { Chart, Doughnut } from "react-chartjs-2";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ICONS from "../features/images";
import {
  getAllSites,
  getSite,
  getSensorTable,
  getSiteCarbonByYear,
  getSiteCarbonBySource,
} from "../features/counter/APIs";
import { connect, useSelector } from "react-redux";
import Slider from "react-slick";
import { useNavigate, useLocation } from "react-router-dom";
import { MapContainer, Marker, GeoJSON, TileLayer, Popup } from "react-leaflet";
import axios from "axios";
import L from "leaflet";
import { useState } from "react";
import axiosClient from "../axios";

ChartJS.register(...registerables);

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

const labels = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];
let newSensorAboveArr = [];
let newSensorBelowArr = [];
let newSatlliteAboveArr = [];
let newSatlliteBelowArr = [];
function Homepage(props) {
  const [value, setValue] = React.useState(0);
  const [bgColor, setBgColor] = React.useState("#55AA8B");
  const sensorgrey = L.icon({ iconUrl: ICONS.sgrey, iconSize: 25 });
  const sensorgreen = L.icon({ iconUrl: ICONS.sgreen, iconSize: 25 });
  const sensorred = L.icon({ iconUrl: ICONS.sred, iconSize: 25 });

  const [geoJSONObj, setgeoJSONObj] = React.useState(null);
  const [barChartData, setChartData] = React.useState({
    labels: labels,
    datasets: [
      {
        type: "line",
        label: "Dataset 1",
        borderColor: "#259A6C",
        borderWidth: 2,
        fill: false,
        data: [123, 234, 434, 345, 45, 34, 232],
        borderWidth: 1,
      },
      {
        type: "bar",
        label: "",
        borderWidth: 1,
        data: [123, 234, 244, 345, 45, 34, 232],
      },
    ],
  });
  const [open, setOpen] = React.useState(false);
  let selectElem = React.createRef();
  let navigate = useNavigate();
  let location = useLocation();
  const [chartDataSourceSensor, setChartDataSourceSensor] = React.useState({
    labels: ["Air Carbon", "Soil Carbon", "Area Covered"],
    datasets: [
      {
        label: "# of Votes",
        data: [12, 19, 54],
        backgroundColor: [
          "rgba(45, 112, 232, 0.8)",
          "rgba(75, 192, 192, 0.8)",
          "rgba(13, 123, 144, 0.8)",
        ],
        borderColor: [
          "rgba(45, 112, 232, 0.8)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(23, 123, 144, 0.2)",
        ],
        borderWidth: 1,
      },
    ],
  });
  const [chartDataSource, setChartDataSource] = React.useState({
    labels: ["Air Carbon", "Soil Carbon", "Area Covered"],
    datasets: [
      {
        label: "# of Votes",
        data: [12, 19, 54],
        backgroundColor: [
          "rgba(45, 112, 232, 0.8)",
          "rgba(75, 192, 192, 0.8)",
          "rgba(13, 123, 144, 0.8)",
        ],
        borderColor: [
          "rgba(45, 112, 232, 0.8)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(23, 123, 144, 0.2)",
        ],
        borderWidth: 1,
      },
    ],
  });
  const [convertTon, setconvertTon] = useState("");
  const sites = useSelector((state) => state.sites?.gaitObj);
  const currentSite = useSelector((state) => state.currentSite?.gaitObj);
  const sensorTable = useSelector((state) => state.sensorTable?.gaitObj);
  const siteCarbon = useSelector((state) => state.siteCarbon?.gaitObj);
  const siteCarbonBySource = useSelector(
    (state) => state?.siteCarbonBySource?.gaitObj
  );
  const handleClick = () => {
    setTimeout(() => {
      let loginStatus = localStorage.getItem("status");
      if (loginStatus === "Shown") {
        setOpen(false);
      } else {
        setOpen(true);
      }
    }, 1000);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const user = useSelector((state) => state.loginData?.user);
  const getBgColor = () => {
    sites?.map((val) => {
      setBgColor(val.carbontype);
    });
  };
  const randomFunction = (siteid, mapcolor) => {
    getSite(siteid);
    setActiveSite(siteid);
    setBgColor(mapcolor);
  };
  const getSite = (siteId) => {
    axiosClient
      .get(`v1/gait/site/view_site_info?siteId=${siteId}`)
      .then((res) => {
        if (res.data != "" && res.data != null) {
          if (res.data.gaitObj.shapefile != "NA") {
            axios.get(res.data.gaitObj.shapefile).then((resp) => {
              setgeoJSONObj(resp.data);
            });
          }
        }
      });
  };
  useEffect(() => {
    props.getAllSites(
      user?.personidref?.orgidref?.orgid,
      user?.personidref?.orgidref?.orgcode
    );
    handleClick();
  }, []);
  useEffect(() => {
    if (sites) {
      getSite(sites[0].siteid);
      props.getCurrentSite(sites[0].siteid);
      props.getSensorTable(sites[0].siteid);
      props.getSiteCarbonByYear(sites[0].siteid, "2022");
      props.getSiteCarbonBySource(sites[0].siteid);
    }
  }, [sites]);

  useEffect(() => {
    if (siteCarbon) {
      let satelliteData = [];

      siteCarbon?.map((val) => {
        if (val.source == "Satellite") {
          satelliteData.push({ x: labels[val.month - 1], y: val.totalcarbon });
        }
      });

      let sensorData = [];
      siteCarbon?.map((val) => {
        if (val.source == "Sensor") {
          sensorData.push({ x: labels[val.month - 1], y: val.totalcarbon });
        }
      });

      let temp = {
        labels: labels,
        datasets: [
          {
            type: "line",
            label: "Soil Carbon",
            borderColor: "#259A6C",
            backgroundColor: "transparent",
            borderWidth: 2,
            fill: false,
            data: sensorData,
          },
          {
            type: "bar",
            label: "",
            borderColor: "#259A6C",
            backgroundColor: "#259A6C",
            data: sensorData,
          },
          {
            type: "bar",
            label: "",
            borderColor: "#75f0c0",
            backgroundColor: "#75f0c0",
            data: satelliteData,
          },
        ],
      };
      setChartData(temp);
    }
  }, [siteCarbon]);
  const settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    spacing: 2,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 1366,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  useEffect(() => {
    if (siteCarbonBySource) {
      const data = {
        labels: ["Air Carbon", "Soil Carbon"],
        datasets: [
          {
            label: "# of Votes",
            data: [
              Number(siteCarbonBySource?.[0]?.abovecarbon),
              Number(siteCarbonBySource?.[0]?.belowcarbon),
            ],
            backgroundColor: [
              "rgba(45, 112, 232, 0.8)",
              "rgba(75, 192, 192, 0.8)",
            ],
            borderColor: ["rgba(45, 112, 232, 0.8)", "rgba(75, 192, 192, 0.2)"],
            borderWidth: 1,
          },
        ],
      };

      setChartDataSource(data);

      const dataSensor = {
        labels: ["Air Carbon", "Soil Carbon"],
        datasets: [
          {
            label: "# of Votes",
            data: [
              siteCarbonBySource?.[1]?.abovecarbon,
              siteCarbonBySource?.[1]?.belowcarbon,
            ],
            backgroundColor: [
              "rgba(45, 112, 232, 0.8)",
              "rgba(75, 192, 192, 0.8)",
            ],
            borderColor: ["rgba(45, 112, 232, 0.8)", "rgba(75, 192, 192, 0.2)"],
            borderWidth: 1,
          },
        ],
      };

      setChartDataSourceSensor(dataSensor);
    }
  }, [siteCarbonBySource]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const setActiveSite = (siteid) => {
    props.getCurrentSite(siteid);
    props.getSensorTable(siteid);
    props.getSiteCarbonByYear(siteid, "2022");
    props.getSiteCarbonBySource(siteid);
    selectElem.current.value = "2022";
  };

  const handleBarFilter = (year) => {
    props.getSiteCarbonByYear(currentSite.siteid, year);
  };
  let satelliteArr = [];
  let satelliteBelowArr = [];
  let satelliteDoughRes = siteCarbonBySource?.map((val, index) => {
    if (val.source === "Satellite") {
      satelliteArr.push(siteCarbonBySource?.[index]?.abovecarbon);
      satelliteBelowArr.push(siteCarbonBySource?.[index]?.belowcarbon);
    }
  });
  const spatialData = {
    labels: [],
    datasets: [
      {
        label: "Attendance for Week 1",
        data: [
          satelliteArr?.[0] / 1000 ? satelliteArr?.[0] / 1000 : 1,
          satelliteBelowArr?.[0] / 1000,
        ],
        borderColor: ["rgba(255,206,86,0.2)"],
        backgroundColor: ["#E3EECA", "#6BBE45", "#E3EECA"],
        pointBackgroundColor: "#E3EECA",
        strokewidth: 6,
        borderWidth: 1,
        borderRadius: 1,
        cutout: "90%",
      },
    ],
    text: 1211,
  };

  let sensorArr = [];
  let sensorBelowArr = [];
  let sensorDoughRes = siteCarbonBySource?.map((val, index) => {
    if (val.source === "Sensor") {
      sensorArr.push(siteCarbonBySource?.[index]?.abovecarbon / 1000);
      sensorBelowArr.push(siteCarbonBySource?.[index]?.belowcarbon / 1000);
    }
  });
  const data = {
    labels: [],
    datasets: [
      {
        label: "Attendance for Week 1",
        data: [sensorArr[0], sensorBelowArr[0]],
        borderColor: ["rgba(255,206,86,0.2)"],
        backgroundColor: ["#A8D279", "#2C8943", "#A8D279"],
        pointBackgroundColor: "#A8D279",
        strokewidth: 6,
        borderWidth: 1,
        borderRadius: 1,
        cutout: "90%",
      },
    ],
  };
  const spatialOptions = {
    type: "doughnut",
    data: spatialData,
    percentageInnerCutout: 90,
    aspectRatio: 2.5,
    radius: 50,
    options: {
      tooltips: {
        enabled: false,
      },
      plugins: {
        legend: {
          position: "top",
        },
        animation: {
          duration: 5000,
        },
      },
    },
  };
  const options1 = {
    type: "doughnut",
    data: data,
    strokewidth: 6,
    percentageInnerCutout: 90,
    aspectRatio: 2.5,
    radius: 50,
    innerRadius: 75,
    cornerRadius: 8,
    getDefaultProps() {
      return {
        value: 0,
        valuelabel: "Completed",
        size: 116,
        strokewidth: 6,
      };
    },
    elements: {
      arc: {
        innerRadius: "1px",
        borderWidth: [0, 1, 1, 0],
        strokewidth: 6,
        cutoutPercentage: 40,
      },
    },
    options: {
      strokewidth: 6,
      responsive: true,
      cutoutPercentage: 60,
      tooltips: {
        enabled: false,
      },
      plugins: {
        legend: {
          position: "top",
        },
        animation: {
          duration: 5000,
        },
        title: {
          display: true,
          text: "Chart.js Doughnut Chart",
        },
        getDefaultProps() {
          return {
            value: 0,
            valuelabel: "Completed",
            size: 116,
            strokewidth: 6,
          };
        },
      },
    },
  };
  return (
    <div style={{ width: "100%" }}>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          sx={{ width: "100%" }}
          style={{ fontSize: 16, fontFamily: "Poppins" }}
        >
          You are successfully logged in
        </Alert>
      </Snackbar>
      <div className="site-slider">
        <Slider {...settings}>
          {sites?.map((val, index) => {
            const dataDough = {
              labels: ["Total Carbon"],
              datasets: [
                {
                  label: "# of Votes",
                  data: [val.totalcarbon],
                  backgroundColor: [
                    "rgba(75, 192, 192, 0.8)",
                    "rgba(13, 123, 144, 0.8)",
                  ],
                  borderColor: [
                    "rgba(75, 192, 192, 0.8)",
                    "rgba(13, 123, 144, 0.8)",
                  ],
                  borderWidth: 1,
                },
              ],
            };
            return (
              <div
                onClick={() =>
                  randomFunction(val.siteid, currentSite.carbontype)
                }
                key={index}
              >
                <Card
                  className={`card-site ${
                    val.siteid == currentSite?.siteid
                      ? "card-primary active-location-val"
                      : ""
                  }`}
                >
                  <CardContent
                    style={{
                      maxWidth: "177px",
                      width: "100%",
                      padding: 10,
                      paddingBottom: 5,
                    }}
                  >
                    <div
                      className={`bar ${
                        val.siteid == currentSite?.siteid ? "active-bar" : ""
                      }`}
                    ></div>

                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex align-items-center">
                        <img
                          src={ICONS.locationMap}
                          height={15}
                          style={{ marginRight: 10 }}
                        />{" "}
                        <span className="location-name"> {val.sitename}</span>
                      </div>
                      {val.siteid == currentSite?.siteid ? (
                        <img src={ICONS.Single_Green} width={"13%"} />
                      ) : (
                        ""
                      )}
                    </div>
                    <p
                      className={`location-val ${
                        val.siteid == currentSite?.siteid
                          ? "active-location-val"
                          : ""
                      }`}
                    >
                      {(val.totalcarbon / 1000).toFixed(1)}T
                    </p>
                    <Grid
                      container
                      justifyContent={"space-between"}
                      alignItems="flex-end"
                    >
                      <Grid item justifyContent={"space-between"}>
                        <div className="d-flex justify-content-between align-items-center">
                          <img src={ICONS.SiteCardTower} height={15} />
                          <span className="sensor-count">
                            {val.sensorcount}
                          </span>
                        </div>
                      </Grid>
                      <Grid item>
                        <div className="d-flex justify-content-between align-items-center">
                          <img src={ICONS.SiteCardTower} height={15} />

                          <span className="sensor-count">
                            {val.totalarea}Ha
                          </span>
                        </div>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </div>
            );
          })}
        </Slider>
      </div>
      {currentSite && (
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <Card className="card" sx={{ minWidth: 275 }}>
                <CardContent style={{ paddingTop: 0, paddingBottom: 0 }}>
                  <div className="pill-container">
                    <div className="pill pill-green">
                      <img src={ICONS.finger} />
                      <span className="number-container">
                        {" "}
                        {currentSite.siteid}
                      </span>
                    </div>
                    <div className="pill pill-green">
                      <img src={ICONS.locationMap} />
                      <span className="number-container">
                        {" "}
                        {currentSite.totalarea}Ha
                      </span>
                    </div>
                    <div className="pill pill-yellow">
                      <img src={ICONS.target} />
                      <span className="number-container">
                        {" "}
                        {currentSite.country}
                      </span>
                    </div>
                    <div className="pill pill-green">
                      <img src={ICONS.latlngGlobe} />

                      <span className="number-container">
                        {" "}
                        {currentSite.sitelatitude}, {currentSite.sitelogitude}
                      </span>
                    </div>
                  </div>

                  <Card
                    className="bg-dark"
                    style={{ height: 286, marginTop: 0 }}
                  >
                    {geoJSONObj && (
                      <MapContainer
                        key={Math.random()}
                        center={[
                          currentSite.sitelatitude,
                          currentSite.sitelogitude,
                        ]}
                        zoom={13}
                        trackResize={true}
                        scrollWheelZoom={false}
                        style={{
                          height: "100%",
                        }}
                      >
                        <>
                          <TileLayer
                            key={Math.random()}
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
                          />

                          <GeoJSON
                            key={Math.random()}
                            data={geoJSONObj}
                            color={bgColor ? bgColor : "green"}
                          >
                            <Popup>
                              <ul style={{ listStyle: "none" }}>
                                <li>
                                  <b>Site info</b>
                                </li>
                                <li>
                                  <a>
                                    <b>Site ID :</b> {currentSite.siteid}
                                  </a>
                                </li>
                                <li>
                                  <b>Site name :</b>
                                  {currentSite.sitename}
                                </li>
                                <li>
                                  <b>Total Area :</b>
                                  {currentSite.totalarea}
                                  {"Ha "}
                                </li>
                                <li>
                                  <b>Elevation :</b>
                                  {currentSite.elevation}
                                </li>
                                <li>
                                  <b>Location:</b> {currentSite.location}
                                </li>
                                <li>
                                  <b>Country:</b> {currentSite.country}
                                </li>
                                <li>
                                  <b>Activation date :</b>{" "}
                                  {new Date(
                                    currentSite.activationdate
                                  ).toLocaleDateString()}
                                </li>
                                <li>
                                  <b>No. Of installations :</b>{" "}
                                  {currentSite.sensorcount}
                                </li>
                                <li>
                                  <b>Site Type:</b> {currentSite.sitetype}
                                </li>
                                <li>
                                  <b>Carbon Type:</b> {currentSite.carbontype}
                                </li>
                                <li>
                                  <b>Site owner :</b> {currentSite.owner}
                                </li>
                                <li>
                                  <b>Contact no :</b> {currentSite.ownercontact}
                                </li>
                              </ul>
                            </Popup>
                          </GeoJSON>
                          {sensorTable && (
                            <Marker
                              position={[
                                Number(sensorTable[0]?.latitude),
                                Number(sensorTable[0]?.longitude),
                              ]}
                              icon={
                                sensorTable[0]?.isactive == 1 &&
                                sensorTable[0]?.livestatus == 1
                                  ? sensorgreen
                                  : sensorTable[0]?.isactive == 1 &&
                                    sensorTable[0]?.livestatus == 0
                                  ? sensorred
                                  : sensorgrey
                              }
                            >
                              <Popup>
                                <ul>
                                  <li>
                                    {" "}
                                    Sensor Id : {sensorTable[0]?.sensorid}
                                  </li>
                                  <li>
                                    {" "}
                                    Sensor Status:{" "}
                                    {sensorTable[0]?.livestatus == 1
                                      ? "Active"
                                      : "Inactive"}
                                  </li>
                                  <li>
                                    {" "}
                                    Sensor Type: {sensorTable[0]?.sensortype}
                                  </li>
                                  <li> Location: {sensorTable[0]?.location}</li>
                                  <li> Country: {sensorTable[0]?.country}</li>
                                  <li>
                                    {" "}
                                    Last status :{" "}
                                    {new Date(
                                      sensorTable[0]?.lastcommunidate
                                    ).toLocaleDateString()}
                                  </li>
                                </ul>
                              </Popup>
                            </Marker>
                          )}
                        </>
                      </MapContainer>
                    )}
                  </Card>

                  <div className="pill-container" style={{ marginTop: 0 }}>
                    <div className="pill pill-green">
                      <img src={ICONS.userIcon} />
                      <span> {currentSite.owner}</span>
                    </div>
                    <div className="pill pill-green">
                      <img src={ICONS.SiteCardTower} height={15} />
                      <span> {currentSite.sensorcount}</span>
                    </div>
                    <div className="pill pill-yellow">
                      <img src={ICONS.leased} />
                      <span>{currentSite.sitetype}</span>
                    </div>
                    <div className="pill pill-green">
                      <img src={ICONS.installation} />
                      <span>
                        {" "}
                        {new Date(
                          currentSite.activationdate
                        ).toLocaleDateString()}
                      </span>
                    </div>
                  </div>
                </CardContent>
              </Card>
              <Card className="card" sx={{ minWidth: 275 }}>
                <CardContent>
                  <Box sx={{ width: "100%" }}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                      >
                        <Tab
                          label="Sensors Info"
                          style={{ textTransform: "unset" }}
                        ></Tab>
                        <Tab
                          label="Spatial Info"
                          disabled
                          style={{
                            textTransform: "unset",
                            fontFamily: "poppins",
                          }}
                        />
                      </Tabs>
                    </Box>
                    <div
                      hidden={value !== 0}
                      role="tabpanel"
                      value={value}
                      index={0}
                    >
                      <TableContainer
                        component={Paper}
                        style={{ boxShadow: "none" }}
                      >
                        <Table aria-label="simple table">
                          <TableHead className="grid_tblhead">
                            <TableRow>
                              <TableCell className="grid_tbl">Sensor</TableCell>
                              <TableCell className="grid_tbl">Status</TableCell>
                              <TableCell className="grid_tbl">
                                Latitude
                              </TableCell>
                              <TableCell className="grid_tbl">
                                Longitude
                              </TableCell>
                              <TableCell className="grid_tbl">
                                Installation
                              </TableCell>
                              <TableCell className="grid_tbl">
                                Maintenance
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {sensorTable &&
                              sensorTable?.map((row, index) => (
                                <TableRow
                                  key={index}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                  className="tbl-sell"
                                >
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    onClick={(e) => {
                                      navigate(
                                        `/sensor?sensorId=${row.sensorid}`
                                      );
                                    }}
                                  >
                                    <a
                                      className="text-primary"
                                      style={{ cursor: "pointer" }}
                                    >
                                      {row.sensorid}
                                    </a>
                                  </TableCell>

                                  <TableCell
                                    className={
                                      row.livestatus == 0
                                        ? "inactive"
                                        : "active"
                                    }
                                  >
                                    {row.livestatus == 0
                                      ? "Inactive"
                                      : "Active"}
                                  </TableCell>
                                  <TableCell>{row.latitude}</TableCell>
                                  <TableCell>{row.longitude}</TableCell>
                                  <TableCell>
                                    {new Date(
                                      row.installeddate
                                    ).toLocaleDateString()}
                                  </TableCell>
                                  <TableCell>
                                    {new Date(
                                      row.maintenancedate
                                    ).toLocaleDateString()}
                                  </TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                    <div
                      hidden={value !== 1}
                      role="tabpanel"
                      value={value}
                      index={1}
                    >
                      Item Two
                    </div>
                  </Box>
                </CardContent>
              </Card>
            </div>

            <div className="col-md-6">
              <div className="sensor-cards">
                <div className="">
                  <div className="row">
                    <div className="col-lg-6 col-md-12 col-sm-12">
                      <Card className="card">
                        <CardContent style={{ paddingBottom: 0 }}>
                          <Grid container alignItems={"top"}>
                            <Grid item xs={7}>
                              <span className="cardsensor-metrics">
                                Sensor Metrics
                              </span>
                            </Grid>
                            <Grid item xs={5} style={{ position: "relative" }}>
                              <img
                                src={ICONS.signalGreen}
                                width={"50px"}
                                style={{
                                  display: "block",
                                  marginLeft: "auto",
                                  marginTop: -10,
                                }}
                              />
                            </Grid>
                          </Grid>
                        </CardContent>
                        <div className="chart-wrapper">
                          <div className="chart-info">
                            <div className="sensorcard-info1">
                              <div className="bordertxt-center">
                                <Doughnut data={data} options={options1} />
                                <div className="doughnut-txt">
                                  <p className="tonsin-number">
                                    {siteCarbonBySource?.map((val, index) => {
                                      newSensorAboveArr = [];
                                      newSatlliteAboveArr = [];
                                      if (
                                        siteCarbonBySource?.[index]?.source ===
                                        "Sensor"
                                      ) {
                                        newSensorAboveArr.push(
                                          siteCarbonBySource?.[index]
                                            ?.totalcarbon
                                        );
                                        newSensorBelowArr.push(
                                          siteCarbonBySource?.[index]
                                            ?.belowcarbon
                                        );
                                      }
                                      if (
                                        siteCarbonBySource?.[index]?.source ===
                                        "Satellite"
                                      ) {
                                        newSatlliteAboveArr.push(
                                          siteCarbonBySource?.[index]
                                            ?.totalcarbon
                                        );
                                      }
                                    })}
                                    {siteCarbonBySource?.length === 1 ?
                                    Number(
                                      newSensorAboveArr?.[0]
                                        ? (
                                            newSensorAboveArr?.[0] / 1000
                                          ).toFixed(1)
                                        : Number(0)
                                    ) >= 1000
                                      ? Math.abs(
                                          Number(
                                            newSensorAboveArr?.[0]
                                              ? newSensorAboveArr?.[0] / 1000
                                              : Number(0)
                                          ) / 1000
                                        ).toFixed(2) + "K"
                                      : Number(
                                          newSensorAboveArr?.[0]
                                            ? (
                                                newSensorAboveArr?.[0] / 1000
                                              ).toFixed(1)
                                            : Number(0)
                                        ) :"0"}
                                    {siteCarbonBySource &&
                                    Number(
                                      newSensorAboveArr?.[1]?.totalcarbon
                                        ? (
                                            newSensorAboveArr?.[0]
                                              ?.totalcarbon / 1000
                                          ).toFixed(1)
                                        : Number(0)
                                    ) >= 1000000
                                      ? (
                                          Number(
                                            newSensorAboveArr?.[0]?.totalcarbon
                                              ? newSensorAboveArr?.[0]
                                                  ?.totalcarbon / 1.0e6
                                              : Number(0)
                                          ) / 1000
                                        ).toFixed(2) + "M"
                                      : ""}
                                  </p>
                                  <p className="tons-txt">Tons</p>
                                </div>
                              </div>
                            </div>
                            <div className="sm-doughnut">
                              <div className="data-1">
                                <div className="dashed_img">
                                  <img src={ICONS.Dash_1} />
                                </div>
                                <span className="carbon-categories">
                                  Air Carbon
                                </span>
                                <span className="carbon-values">
                                  {siteCarbonBySource &&
                                  siteCarbonBySource?.[0]?.source === "Sensor"
                                    ? (
                                        siteCarbonBySource?.[0]?.abovecarbon /
                                        1000
                                      ).toFixed(1) || 0
                                    : ""}
                                  T
                                </span>
                              </div>
                              <div className="data-1">
                                <div className="dashed_img">
                                  <img src={ICONS.Dash_2} />
                                </div>
                                <span className="carbon-categories">
                                  Soil Carbon
                                </span>
                                <span className="carbon-values">
                                  {(siteCarbonBySource &&
                                    (
                                      siteCarbonBySource?.[0]?.belowcarbon /
                                      1000
                                    ).toFixed(1)) ||
                                    0}
                                  T
                                </span>
                              </div>
                              <div className="data-1">
                                <div className="dashed_img">
                                  <img src={ICONS.Dash_3} />
                                </div>
                                <span className="carbon-categories">Area</span>
                                <span className="carbon-values">
                                  {(currentSite && currentSite.totalarea) || 0}
                                  Ha
                                </span>
                              </div>
                            </div>
                          </div>

                          <Grid container>
                            <Grid item xs={7}>
                              <Grid
                                container
                                style={{ padding: "5px 20px" }}
                                alignItems={"center"}
                                justifyContent={"center"}
                              ></Grid>
                            </Grid>
                          </Grid>
                        </div>
                      </Card>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12">
                      <Card className="card">
                        <CardContent style={{ paddingBottom: 0 }}>
                          <Grid container alignItems={"top"}>
                            <Grid item xs={7}>
                              <span className="cardsensor-metrics">
                                Spatial Metrics
                              </span>
                            </Grid>
                            <Grid item xs={5}>
                              <img
                                src={ICONS.satelliteBlack}
                                width={"50px"}
                                style={{
                                  display: "block",
                                  marginLeft: "auto",
                                  marginTop: -10,
                                }}
                              />
                            </Grid>
                          </Grid>
                        </CardContent>
                        <div className="chart-wrapper">
                          <div className="chart-info">
                            <div className="sensorcard-info1">
                              <div className="bordertxt-center">
                                <Doughnut
                                  data={spatialData}
                                  options={spatialOptions}
                                />
                                <div className="doughnut-txt">
                                  <p className="tonsin-number">
                                    {siteCarbonBySource?.[0]?.source ===
                                    "Satellite"
                                      ? siteCarbonBySource &&
                                        siteCarbonBySource?.[0]?.totalcarbon
                                        ? (
                                            siteCarbonBySource?.[0]
                                              ?.totalcarbon / 1000
                                          ).toFixed(1)
                                        : Number(0)
                                      : 0}
                                  </p>
                                  <p className="tons-txt">Tons</p>
                                </div>
                              </div>
                            </div>
                            <div className="sm-doughnut">
                              <div className="data-1">
                                <div className="dashed_img">
                                  <img src={ICONS.Dash_1} />
                                </div>
                                <span className="carbon-categories">
                                  Air Carbon
                                </span>
                                <span className="carbon-values">
                                  {siteCarbonBySource?.[0]?.source ===
                                  "Satellite"
                                    ? (siteCarbonBySource &&
                                        (
                                          siteCarbonBySource?.[0]?.abovecarbon /
                                          1000
                                        ).toFixed(1)) ||
                                      0
                                    : "0"}
                                  T
                                </span>
                              </div>

                              <div className="data-1">
                                <div className="dashed_img">
                                  <img src={ICONS.Dash_2} />
                                </div>
                                <span className="carbon-categories">
                                  Soil Carbon
                                </span>
                                <span className="carbon-values">
                                  {siteCarbonBySource?.[0]?.source ===
                                  "Satellite"
                                    ? (siteCarbonBySource &&
                                        (
                                          siteCarbonBySource?.[0]?.belowcarbon /
                                          1000
                                        ).toFixed(1)) ||
                                      0
                                    : "0"}
                                  T
                                </span>
                              </div>

                              <div className="data-1">
                                <div className="dashed_img">
                                  <img src={ICONS.Dash_3} />
                                </div>
                                <span className="carbon-categories">Area</span>
                                <span className="carbon-values">
                                  {" "}
                                  {(currentSite && currentSite.totalarea) || 0}
                                  Ha
                                </span>
                              </div>
                            </div>
                          </div>

                          <Grid container>
                            <Grid item xs={7}>
                              <Grid
                                container
                                style={{ padding: "5px 20px" }}
                                alignItems={"center"}
                                justifyContent={"center"}
                              ></Grid>
                            </Grid>
                          </Grid>
                        </div>
                      </Card>
                    </div>
                  </div>
                </div>
                <Card className="card" sx={{ minWidth: 275 }}>
                  <div className="annual-report">
                    <Grid
                      container
                      alignItems={"center"}
                      justifyContent={"space-between"}
                    >
                      <Grid item>
                        <h4 className="annual-rep">Annual Carbon Report</h4>
                      </Grid>
                      <Grid item alignItems={"center"}>
                        <img
                          src={ICONS.greenCircle}
                          height={13}
                          style={{ marginRight: 10 }}
                        />
                        <span className="carbon-title">Green Carbon</span>
                      </Grid>
                      <Grid item>
                        <select
                          ref={selectElem}
                          onChange={(e) => handleBarFilter(e.target.value)}
                          defaultValue="2022"
                          className="carbon-title"
                        >
                          <option value="2016">2016</option>
                          <option value="2017">2017</option>
                          <option value="2018">2018</option>
                          <option value="2019">2019</option>
                          <option value="2020">2020</option>
                          <option value="2021">2021</option>
                          <option value="2022">2022</option>
                          <option value="2023">2023</option>
                        </select>
                      </Grid>
                    </Grid>
                  </div>
                  <Chart
                    type="bar"
                    options={{
                      scales: {
                        x: {
                          stacked: true,
                        },
                        y: {
                          stacked: true,
                        },
                        y: { title: { display: true, text: "CARBON IN TONS" } },
                      },

                      plugins: {
                        tooltip: {
                          enabled: true,
                          callbacks: {
                            label: function (tooltipItems, data) {
                              return tooltipItems.formattedValue + " T";
                            },
                          },
                        },
                        legend: { display: false },
                      },
                    }}
                    data={barChartData}
                  />
                </Card>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const mapDispatchToProps = {
  getAllSites: getAllSites,
  getCurrentSite: getSite,
  getSensorTable: getSensorTable,
  getSiteCarbonByYear: getSiteCarbonByYear,
  getSiteCarbonBySource: getSiteCarbonBySource,
};

export default connect(null, mapDispatchToProps)(Homepage);
