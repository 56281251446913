import sgrey from '../../assets/tower-grey.png';
import Location_G1 from '../../assets/icons/location_G1.svg';
import Lan_lat_G3 from "../../assets/icons/Lan_lat_G3.svg";
import Increase from "../../assets/icons/Up_G6.svg";
import Decrease from "../../assets/icons/Dwon_G6.svg";
import sgreen from "../../assets/tower-green.png";
import MyLogo from "../../assets/logo-full.png";
import SignalGreen from "../../assets/gifs/signal-green.gif";
import Sensor_G4 from "../../assets/icons/Sensor_G4.svg";
import calender_G2 from "../../assets/icons/calender_G2.svg";
import Area_G5 from "../../assets/icons/Area_G5.svg";
import sred from "../../assets/tower-red.png";
import WarningIcon from '../../assets/icons/warning_24px.svg';
import WrongIcon from '../../assets/icons/wrong.svg';
import Map_Location from '../../assets/icons/map-location.svg';
import reportIcon from "../../assets/id-badge-line.png";
import bxArea from "../../assets/bx-area.png";
import Single_Green from "../../assets/gifs/Rectangle_unscreen2.gif";
import Not_Active from "../../assets/gifs/Rectangle_unscreen1.gif";
import ClockSettings from "../../assets/reshot-icon-clock-settings.png";
import ReshotTime from "../../assets/reshot-icon-time.png";
import Biggreen from "../../assets/big-green.png";
import Biggrey from "../../assets/big-grey.png";
import Bigred from "../../assets/big-red.png";
import BigArea from "../../assets/sensor_location.png";
import UserAvatar from "../../assets/user1.svg";
import ReportIcon from "../../assets/sensor_make.png";
import CommunicationTower from "../../assets/communication_tower.png";
import gradient from "../../assets/sign-in.png";
import Gait_complete_logo from '../../assets/Gait_complete_logo.svg';
import splash from "../../assets/loader.png";
import SiteCardTower from "../../assets/bigtower.png";
import finger from "../../assets/finger.png";
import target from "../../assets/target.png";
import latlngGlobe from "../../assets/latlng-globe.png";
import greenCircle from "../../assets/green-circle.png";
import locationMap from "../../assets/icons/map-svgrepo.svg";
import userIcon from "../../assets/person_24px.svg";
import installation from "../../assets/installation.svg";
import leased from "../../assets/leased.svg";
import satelliteBlack from "../../assets/gifs/Frame1.gif";
import signalGreen from "../../assets/gifs/Frame2.gif";
import Dash_1 from "../../assets/dash_1.png";
import Dash_2 from "../../assets/dash_2.png";
import Dash_3 from "../../assets/dash_3.png";
import ProfileImg from "../../assets/profiles/change_profile_photo.svg";
import DpBackground from "../../assets/profiles/dp_bg.svg";
import PikeCreek from '../../assets/svgs/Pik- Cree- Sit- Analysis--1.svg';
import PikeCreekMap from '../../assets/svgs/Pike-Creek-Site-Analysis-7.svg';  
import logo from "../../assets/gait_main_logo.svg";
import ImageUplodIco from '../../assets/svgs/cloud_upload_24px.svg';
export default {
    "logo":logo,
    "PikeCreekMap":PikeCreekMap,
    "PikeCreek":PikeCreek,
    "DpBackground":DpBackground,
    "ProfileImg":ProfileImg,
    "Dash_3":Dash_3,
    "Dash_2":Dash_2,
    "Dash_1":Dash_1,
    "signalGreen":signalGreen,
    'sgrey':sgrey,
    'Location_G1':Location_G1,
    "Lan_lat_G3":Lan_lat_G3,
    "Increase":Increase,
    "Decrease":Decrease,
    "sgreen":sgreen,
    "MyLogo":MyLogo,
    "SignalGreen":SignalGreen,
    "Sensor_G4":Sensor_G4,
    "calender_G2":calender_G2,
    "Area_G5":Area_G5,
    "sred":sred,
    "WarningIcon":WarningIcon,
    "WrongIcon":WrongIcon,
    "Map_Location":Map_Location,
    "reportIcon":reportIcon,
    "bxArea":bxArea,
    "Single_Green":Single_Green,
    "Not_Active":Not_Active,
    "ClockSettings":ClockSettings,
    "ReshotTime":ReshotTime,
    "Biggreen":Biggreen,
    "Biggrey":Biggrey,
    "Bigred":Bigred,
    "BigArea":BigArea,
    "UserAvatar":UserAvatar,
    "ReportIcon":ReportIcon,
    "CommunicationTower":CommunicationTower,
    "gradient":gradient,
    "Gait_complete_logo":Gait_complete_logo,
    "splash":splash,
    'SiteCardTower':SiteCardTower,
    "finger":finger,
    "target":target,
    "latlngGlobe":latlngGlobe,
    "greenCircle":greenCircle,
    "locationMap":locationMap,
    "userIcon":userIcon,
    "installation":installation,
    "leased":leased,
    "satelliteBlack":satelliteBlack,
    "imageUplodIco":ImageUplodIco
}
