// // /* eslint-disable object-shorthand */
// // /* eslint-disable camelcase */
// // /* eslint-disable no-unused-expressions */
// // /* eslint-disable react/no-array-index-key */
// // /* eslint-disable array-callback-return */
// // /* eslint-disable no-else-return */
// // /* eslint-disable no-underscore-dangle */
// // /* eslint-disable no-dupe-keys */
// // /* eslint-disable no-unused-vars */
// // /* eslint-disable react/no-array-index-key */
// // /* eslint-disable react/no-this-in-sfc */
// // /* eslint-disable consistent-return */
// // /* eslint-disable  no-nested-ternary */
// // /* eslint-disable no-restricted-globals */
// // /* eslint-disable radix */
// // /* eslint-disable prefer-destructuring */
// // /* eslint-disable no-useless-escape */
// // /* eslint-disable no-restricted-syntax */
// // /* eslint-disable jsx-a11y/no-onchange */
// // /* eslint-disable react/jsx-boolean-value */
// // /* eslint-disable no-plusplus */
// // /* eslint-disable no-await-in-loop */
// // /* eslint-disable jsx-a11y/label-has-associated-control */
// // /* eslint-disable jsx-a11y/label-has-for */
// // /* eslint-disable no-loop-func */

// // import React, { useEffect, useState } from 'react';
// // import DatePicker from 'react-datepicker';
// // import Select from 'react-select';
// // import { Line, Chart } from 'react-chartjs-2';
// // import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
// // import Multiselect from 'multiselect-react-dropdown';
// // import { useWindowHeight } from '@react-hook/window-size/throttled';
// // import 'react-datepicker/dist/react-datepicker.css';
// // import 'rc-slider/assets/index.css';
// // import zoomPlugin from 'chartjs-plugin-zoom';

// // import { connect } from 'react-redux';
// // import { Row, Col, Container, Button } from 'reactstrap';
// // // import {
// // //   getWeatherData,
// // //   getCarbonData,
// // //   getWeatherChartData,
// // //   getCarbonChartData,
// // // } from 'redux/actions';
// // import {
// //   getAllSites,
// //   getSite,
// //   getSensorTable,
// //   getSiteCarbonByYear,
// //   getSiteCarbonBySource,
// //   getWeatherData,
// //   getCarbonData,
// //   getCarbonChartData,
// //   getWeatherChartData
// // } from "../../features/counter/APIs";
// // import moment from 'moment';
// // import 'chartjs-adapter-luxon';
// // import { Settings } from 'luxon';
// // import _default from 'rc-slider/lib/Slider';
// // import sun from '../../assets/sun.png';

// // // Chart.register(zoomPlugin);
// // Settings.defaultZoneName = 'gmt+0';

// // const TestMapImpliment = ({
// //   // loading,
// //   // carbonLoading,
// //   // weatherLoading,
// //   getWeatherDataAction,
// //   getCarbonDataAction,
// //   weatherDataSet,
// //   carbonDataSet,
// //   getWeatherChartDataAction,
// //   getCarbonChartDataAction,
// //   dataSet1,
// //   dataSet2,
// //   dataSet3,
// //   dataSet4,
// //   dataSet5,
// //   dataSet6,
// //   dataSet7,
// //   dataSet8,
// //   dataSet9,
// //   dataSet11,
// //   dataSet12,
// //   // dataSet13,
// //   dataSet14,
// //   dataSet15,
// //   dataSetSoil,
// //   dataSetAir,
// //   dataSetNet,
// //   dataSet_emission_air,
// // }) => {

// //   const today = new Date();
// //   const [range, setRange] = useState({
// //     min:
// //       new Date(
// //         today.getFullYear(),
// //         today.getMonth(),
// //         today.getDate(),
// //         0,
// //         0,
// //         0
// //       ) -
// //       24 * 60 * 60 * 1000 * 7,
// //     max: new Date(
// //       today.getFullYear(),
// //       today.getMonth(),
// //       today.getDate(),
// //       0,
// //       0,
// //       0
// //     ),
// //   });
// //   const [legendHeight, setLegendHeight] = useState();
// //   const [fromDate, setFromDate] = useState(
// //     new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0) -
// //       24 * 60 * 60 * 1000 * 8
// //   );
// //   const [selCarbon, setSelCarbon] = useState([
// //     { label: 'Air_Carbon' },
// //     { label: 'Soil_Carbon' },
// //     { label: 'Net_Carbon' },
// //   ]);
// //   const [selWeather, setSelWeather] = useState([]);

// //   const [legends, setLegends] = useState({
// //     Air_Carbon: false,
// //     Soil_Carbon: false,
// //     Net_Carbon: false,
// //     Air_Emission: true,
// //   });

// //   const legendsinit = {
// //     Air_Carbon: false,
// //     Soil_Carbon: false,
// //     Net_Carbon: false,
// //     Air_Emission: true,
// //   };

// //   const [weaLegends, setweaLegends] = useState({
// //     Wind_Speed: true,
// //     Pressure: true,
// //     Total_Precipitation: true,
// //     Humidity: true,
// //     Temperature: true,
// //     Heat_Index: true,
// //     Dew_Point: true,
// //     Visibility: true,
// //     Wind_Gust: true,
// //     Cloud_Cover: true,
// //     Windchill_Temperature: true,
// //     Will_Rain: true,
// //     Will_Snow: true,
// //   });

// //   const weatherInit = {
// //     Wind_Speed: true,
// //     Pressure: true,
// //     Total_Precipitation: true,
// //     Humidity: true,
// //     Temperature: true,
// //     Heat_Index: true,
// //     Dew_Point: true,
// //     Visibility: true,
// //     Wind_Gust: true,
// //     Cloud_Cover: true,
// //     Windchill_Temperature: true,
// //     Will_Rain: true,
// //     Will_Snow: true,
// //   };

// //   const [selectedLabels, setSelectedLabels] = useState({
// //     Air_Carbon_Lab: false,
// //     Soil_Carbon_Lab: false,
// //     Net_Carbon_Lab: false,
// //     Air_Emission_Lab: true,
// //     Wind_Speed_Lab: true,
// //     Pressure_Lab: true,
// //     Total_Precipitation_Lab: true,
// //     Humidity_Lab: true,
// //     Temperature_Lab: true,
// //     Heat_Index_Lab: true,
// //     Dew_Point_Lab: true,
// //     Visibility_Lab: true,
// //     Wind_Gust_Lab: true,
// //     Cloud_Cover_Lab: true,
// //     Windchill_Temperature_Lab: true,
// //     Will_Rain_Lab: true,
// //     Will_Snow_Lab: true,
// //   });

// //   useEffect(() => {
// //     getWeatherDataAction();
// //     getCarbonDataAction();
// //     getWeatherChartDataAction();
// //     getCarbonChartDataAction();
// //   }, []);

// //   useEffect(() => {
// //     const divHeight = document.getElementById('filter-grid');
// //     setLegendHeight(divHeight && divHeight.offsetHeight);
// //   }, []);
// //   useEffect(() => {
// //     if (dataSetSoil && dataSetSoil.length > 0) {
// //       const startDate = dataSetSoil[0].x;
// //       setRange({
// //         min: startDate - 6 * 1000 * 60 * 60 * 24,
// //         max: startDate + 1000 * 60 * 60 * 24,
// //       });
// //     }
// //   }, [dataSetSoil]);

// //   const changeHandler = (event) => {
// //     const legCount = event.length;
// //     const weaLegendsCount = selWeather.length;
// //     const totalCount = legCount + weaLegendsCount;
// //     let Obj = legendsinit;

// //     event.length > 0 &&
// //       event.map((item) => {
// //         Obj = {
// //           ...Obj,
// //           [item.value]: false,
// //         };
// //       });
// //     setSelCarbon(event);
// //     totalCount > 0 && setLegends(Obj);
// //   };

// //   const changeHandlerWeather = (e) => {
// //     const legCount = e.length;
// //     const carbonLegendsCount = selCarbon.length;
// //     const totalCount = legCount + carbonLegendsCount;

// //     let WeatherObj = weatherInit;
// //     e.length > 0 &&
// //       e.map((item) => {
// //         WeatherObj = {
// //           ...WeatherObj,
// //           [item.value]: false,
// //         };
// //       });
// //     setSelWeather(e);
// //     totalCount > 0 && setweaLegends(WeatherObj);
// //   };

// //   const getData = () => {
// //     setRange({
// //       min: fromDate,
// //       max: new Date(new Date(fromDate).getTime() + 7 * 24 * 60 * 60 * 1000),
// //     });
// //     setSelectedLabels({
// //       Air_Carbon_Lab: legends.Air_Carbon,
// //       Soil_Carbon_Lab: legends.Soil_Carbon,
// //       Net_Carbon_Lab: legends.Net_Carbon,
// //       Air_Emission_Lab: legends.Air_Emission,
// //       Wind_Speed_Lab: weaLegends.Wind_Speed,
// //       Pressure_Lab: weaLegends.Pressure,
// //       Total_Precipitation_Lab: weaLegends.Total_Precipitation,
// //       Humidity_Lab: weaLegends.Humidity,
// //       Temperature_Lab: weaLegends.Temperature,
// //       Heat_Index_Lab: weaLegends.Heat_Index,
// //       Dew_Point_Lab: weaLegends.Dew_Point,
// //       Visibility_Lab: weaLegends.Visibility,
// //       Wind_Gust_Lab: weaLegends.Wind_Gust,
// //       Cloud_Cover_Lab: weaLegends.Cloud_Cover,
// //       Windchill_Temperature_Lab: weaLegends.Windchill_Temperature,
// //       Will_Rain_Lab: weaLegends.Will_Rain,
// //       Will_Snow_Lab: weaLegends.Will_Snow,
// //     });
// //   };

// //   const tooltipLine = {
// //     id: 'toolTipLine',
// //     beforeDraw: (chart) => {
// //       if (
// //         chart &&
// //         chart.tooltip &&
// //         chart.tooltip._active &&
// //         chart.tooltip._active.length
// //       ) {
// //         const ctx = chart.ctx;
// //         ctx.save();
// //         const activePoint = chart.tooltip._active[0];
// //         ctx.beginPath();
// //         ctx.setLineDash([5, 7]);
// //         ctx.moveTo(activePoint.element.x, 0);
// //         ctx.lineTo(activePoint.element.x, 1000);
// //         ctx.lineWidth = 2;
// //         ctx.strokeStyle = '#20c997';
// //         ctx.stroke();
// //         ctx.restore();
// //       }
// //     },
// //   };

// //   const data = {
// //     datasets: [
// //       {
// //         label: 'Soil Carbon (Kg)',
// //         fill: false,
// //         data: [1,2,3,4],
// //         // data: dataSetSoil,
// //         backgroundColor: '#aed97d',
// //         color: '#aed97d',
// //         borderColor: '#aed97d',
// //         pointRadius: 0,
// //         hidden: selectedLabels.Soil_Carbon_Lab,
// //         yAxisID: 'id1',
// //       },
// //       {
// //         label: 'Air Carbon (Kg)',
// //         fill: false,
// //         data: [13,15,17,22],
// //         // data: dataSetAir,
// //         backgroundColor: '#8deefb',
// //         color: '#8deefb',
// //         borderColor: '#8deefb',
// //         pointRadius: 1,
// //         hidden: selectedLabels.Air_Carbon_Lab,
// //         yAxisID: 'id1',
// //       },
// //       {
// //         label: 'Net Carbon (Kg)',
// //         fill: false,
// //         // data: dataSetNet,
// //         data: [6,8,9,11],
// //         backgroundColor: '#20c997',
// //         color: '#20c997',
// //         borderColor: '#20c997',
// //         pointRadius: 1,
// //         borderJoinStyle: 'round',
// //         tension: 0.1,
// //         hidden: selectedLabels.Net_Carbon_Lab,
// //         yAxisID: 'id1',
// //       },
// //       // {
// //       //   label: 'Air Emission (Kg)',
// //       //   fill: false,
// //       //   data: dataSet_emission_air,
// //       //   backgroundColor: '#34a853',
// //       //   color: '#34a853',
// //       //   borderColor: '#34a853',
// //       //   pointRadius: 0,
// //       //   borderJoinStyle: 'round',
// //       //   tension: 0.1,
// //       //   hidden: selectedLabels.Air_Emission_Lab,
// //       //   yAxisID: 'id1',
// //       // },
// //       // {
// //       //   label: 'Wind Speed (km/hr)',
// //       //   fill: false,
// //       //   data: dataSet1,
// //       //   backgroundColor: '#dc493a',
// //       //   color: '#dc493a',
// //       //   borderColor: '#dc493a',
// //       //   hidden: selectedLabels.Wind_Speed_Lab,
// //       //   pointRadius: 0,
// //       //   yAxisID: 'id2',
// //       // },
// //       // {
// //       //   label: 'Pressure (mb)',
// //       //   fill: false,
// //       //   data: dataSet2,
// //       //   backgroundColor: '#fff49b',
// //       //   color: '#fff49b',
// //       //   borderColor: '#fff49b',
// //       //   hidden: selectedLabels.Pressure_Lab,
// //       //   pointRadius: 0,
// //       //   yAxisID: 'id1',
// //       // },
// //       // {
// //       //   label: `Temperature (${'\u00b0'}C)`,
// //       //   fill: false,
// //       //   data: dataSet3,
// //       //   backgroundColor: '#d7b2ff',
// //       //   color: '#d7b2ff',
// //       //   borderColor: '#d7b2ff',
// //       //   hidden: selectedLabels.Temperature_Lab,
// //       //   pointRadius: 0,
// //       //   yAxisID: 'id2',
// //       // },
// //       // {
// //       //   label: 'Total Precipitation (in)',
// //       //   fill: false,
// //       //   data: dataSet4,
// //       //   backgroundColor: '#b7ffbe',
// //       //   color: '#b7ffbe',
// //       //   borderColor: '#b7ffbe',
// //       //   hidden: selectedLabels.Total_Precipitation_Lab,
// //       //   yAxisID: 'id2',
// //       //   pointRadius: 0,
// //       // },
// //       // {
// //       //   label: 'Humidity (%)',
// //       //   fill: false,
// //       //   data: dataSet5,
// //       //   backgroundColor: '#f0bc8c',
// //       //   color: '#f0bc8c',
// //       //   borderColor: '#f0bc8c',
// //       //   hidden: selectedLabels.Humidity_Lab,
// //       //   yAxisID: 'id2',
// //       //   pointRadius: 0,
// //       // },
// //       // {
// //       //   label: `Heat Index (${'\u00b0'}C)`,
// //       //   fill: false,
// //       //   data: dataSet6,
// //       //   backgroundColor: '#4392f1',
// //       //   color: '#4392f1',
// //       //   borderColor: '#4392f1',
// //       //   hidden: selectedLabels.Heat_Index_Lab,
// //       //   yAxisID: 'id2',
// //       //   pointRadius: 0,
// //       // },
// //       // {
// //       //   label: `Dew Point (${'\u00b0'}C)`,
// //       //   fill: false,
// //       //   data: dataSet7,
// //       //   backgroundColor: '#f5680a',
// //       //   color: '#f5680a',
// //       //   borderColor: '#f5680a',
// //       //   hidden: selectedLabels.Dew_Point_Lab,
// //       //   yAxisID: 'id2',
// //       //   pointRadius: 0,
// //       // },
// //       // {
// //       //   label: 'Visibility (km)',
// //       //   fill: false,
// //       //   data: dataSet8,
// //       //   backgroundColor: '#222d81',
// //       //   color: '#222d81',
// //       //   borderColor: '#222d81',
// //       //   hidden: selectedLabels.Visibility_Lab,
// //       //   yAxisID: 'id2',
// //       //   pointRadius: 0,
// //       // },
// //       // {
// //       //   label: 'Wind Gust (km/hr)',
// //       //   fill: false,
// //       //   data: dataSet9,
// //       //   backgroundColor: '#f7ff23',
// //       //   color: '#f7ff23',
// //       //   borderColor: '#f7ff23',
// //       //   hidden: selectedLabels.Wind_Gust_Lab,
// //       //   yAxisID: 'id2',
// //       //   pointRadius: 0,
// //       // },
// //       // {
// //       //   label: 'Cloud Cover (%)',
// //       //   fill: false,
// //       //   data: dataSet11,
// //       //   backgroundColor: '#8f1666',
// //       //   color: '#8f1666',
// //       //   borderColor: '#8f1666',
// //       //   hidden: selectedLabels.Cloud_Cover_Lab,
// //       //   yAxisID: 'id2',
// //       //   pointRadius: 0,
// //       // },
// //       // {
// //       //   label: `Windchill Temperature (${'\u00b0'}C)`,
// //       //   fill: false,
// //       //   data: dataSet12,
// //       //   backgroundColor: '#465be7',
// //       //   color: '#465be7',
// //       //   borderColor: '#465be7',
// //       //   hidden: selectedLabels.Windchill_Temperature_Lab,
// //       //   yAxisID: 'id2',
// //       //   pointRadius: 0,
// //       // },
// //       // {
// //       //   label: 'Will It Rain',
// //       //   fill: false,
// //       //   data: dataSet14,
// //       //   backgroundColor: '#57c42c',
// //       //   color: '#57c42c',
// //       //   borderColor: '#57c42c',
// //       //   hidden: selectedLabels.Will_Rain_Lab,
// //       //   yAxisID: 'id2',
// //       //   pointRadius: 0,
// //       // },
// //       // {
// //       //   label: 'Will It Snow',
// //       //   fill: false,
// //       //   data: dataSet15,
// //       //   backgroundColor: '#6a5d9d',
// //       //   color: '#6a5d9d',
// //       //   borderColor: '#6a5d9d',
// //       //   hidden: selectedLabels.Will_Snow_Lab,
// //       //   yAxisID: 'id2',
// //       //   pointRadius: 0,
// //       // },
// //     ],
// //   };

// //   const zoomOptions = {
// //     zoom: {
// //       enabled: false,
// //     },
// //     pan: {
// //       enabled: true,
// //       mode: 'x',
// //       drag: false,
// //       onPan: async ({ chart, event }) => {
// //         if (
// //           chart._sortedMetasets[0].xScale.ticks[0].value <
// //           dataSet1[dataSet1.length - 1].x
// //         ) {
// //           setRange({
// //             min: dataSet1[dataSet1.length - 1].x,
// //             max: dataSet1[dataSet1.length - 169].x,
// //           });
// //         } else if (
// //           chart._sortedMetasets[0].xScale.ticks[
// //             chart._sortedMetasets[0].xScale.ticks.length - 1
// //           ].value > dataSet1[0].x
// //         ) {
// //           const startDate1 = dataSetSoil[0].x;
// //           setRange({
// //             min: startDate1 - 6 * 1000 * 60 * 60 * 24,
// //             max: startDate1 + 1000 * 60 * 60 * 24,
// //           });
// //         } else {
// //           const xaxisVisibleTickDate = new Date(
// //             chart._sortedMetasets[0].xScale.ticks[
// //               chart._sortedMetasets[0].xScale.ticks.length - 1
// //             ].value
// //           ).setHours(0, 0, 0);
// //           setRange({
// //             min: xaxisVisibleTickDate - 24 * 60 * 60 * 1000 * 7,
// //             max: xaxisVisibleTickDate,
// //           });
// //         }
// //       },
// //     },
// //   };

// //   const options = {
// //     maintainAspectRatio: false,
// //     elements: {
// //       point: {
// //         radius: 1,
// //       },
// //     },
// //     interaction: {
// //       intersect: false,
// //       mode: 'x',
// //     },
// //     plugins: {
// //       zoom: zoomOptions,
// //       htmlLegend: {
// //         // ID of the container to put the legend in
// //         containerID: 'legend-container',
// //       },
// //       legend: {
// //         display: false,
// //         position: 'bottom',
// //       },
// //       tooltip: {
// //         padding: 24,
// //         titleMarginBottom: 10,
// //         bodySpacing: 10,
// //       },
// //     },

// //     scales: {
// //       xAxes: {
// //         min: range.min,
// //         max: range.max,
// //         type: 'time',
// //         time: {
// //           parser: 'h:m',
// //           unit: 'hour',
// //         },
// //         display: true,
// //         ticks: {
// //           maxTicksLimit: 29,
// //           beginAtZero: false,
// //           autoSkip: false,
// //           maxRotation: 0,
// //           major: {
// //             enabled: true,
// //           },
// //           font: function (context) {
// //             if (context.tick && context.tick.major) {
// //               return {
// //                 weight: 'bold',
// //               };
// //             }
// //           },
// //         },
// //         grid: {
// //           color: ['#b4acac', '#5655566b', '#5655566b', '#5655566b'],
// //           lineWidth: [2, 1, 1, 1],
// //         },
// //       },

// //       id2: {
// //         type: 'linear',
// //         display: true,
// //         position: 'right',
// //         suggestedMin: 0,
// //         suggestedMax: 25,
// //         grid: {
// //           color: '#5655566b',
// //         },
// //         ticks: {
// //           callback: (value) => {
// //             return String(value).replace(/,(?=.*\.\d+)/g, '');
// //           },
// //         },
// //       },
// //       id1: {
// //         type: 'linear',
// //         display: true,
// //         position: 'left',
// //         suggestedMin: '0',
// //         suggestedMax: '1000',

// //         ticks: {
// //           callback: (value) => {
// //             return String(value).replace(/,(?=.*\.\d+)/g, '');
// //           },
// //         },
// //         // grid line settings
// //         grid: {
// //           drawOnChartArea: false, // only want the grid lines for one axis to show up
// //           color: '#5655566b',
// //         },
// //       },
// //     },
// //   };

// //   const weekDataSet = (() => {
// //     return (
// //       carbonDataSet &&
// //       carbonDataSet.eod &&
// //       carbonDataSet.eod
// //         .filter((item, index) =>
// //           range.max % 86400000 === parseInt(range.max % 86400000, 10)
// //             ? moment(item.day).valueOf() < range.max - 1
// //             : moment(item.day).valueOf() <= range.max
// //         )
// //         .slice(-7)
// //     );
// //   })();

// //   const weekWeatherDataSet = (() => {
// //     return (
// //       weatherDataSet &&
// //       weatherDataSet.forecast &&
// //       weatherDataSet.forecast.forecastday &&
// //       weatherDataSet.forecast.forecastday
// //         .filter((item, index) =>
// //           range.max % 86400000 === parseInt(range.max % 86400000, 10)
// //             ? moment(item.date).valueOf() < range.max - 1
// //             : moment(item.date).valueOf() <= range.max
// //         )
// //         .sort((a, b) => b.date < a.date)
// //         .slice(-7)
// //     );
// //   })();

// //   function dateFomat(day) {
// //     return moment(new Date(day)).format('DD MMM');
// //   }

// //   function dayFomat(day) {
// //     return moment(new Date(day)).format('dddd');
// //   }

// //   const getBackgroundColor = (value, type) => {
// //     let className;
// //     if (value >= 0 && value < 100) {
// //       className = `${type}_1`;
// //     } else if (value >= 101 && value < 200) {
// //       className = `${type}_2`;
// //     } else if (value >= 201 && value < 300) {
// //       className = `${type}_3`;
// //     } else if (value >= 301 && value < 400) {
// //       className = `${type}_4`;
// //     } else if (value >= 401 && value < 500) {
// //       className = `${type}_5`;
// //     } else if (value >= 501 && value < 600) {
// //       className = `${type}_6`;
// //     } else if (value >= 601) {
// //       className = `${type}_7`;
// //     }
// //     return className;
// //   };

// //   // css Height
// //   const height = useWindowHeight({ fps: 30 });
// //   const wheight = height - (306 + legendHeight);

// //   const optionsCarbon = [
// //     {
// //       value: 'Soil_Carbon',
// //       label: 'Soil Carbon (Kg)',
// //       name: 'soil',
// //     },
// //     { value: 'Air_Carbon', label: 'Air Carbon (Kg)' },
// //     { value: 'Net_Carbon', label: 'Net Carbon (Kg)' },
// //     { value: 'Air_Emission', label: 'Air Emission (Kg)' },
// //   ];

// //   const optionsWeather = [
// //     { value: 'Wind_Speed', label: 'Wind Speed (km/hr)' },
// //     { value: 'Pressure', label: 'Pressure (mb)' },
// //     { value: 'Temperature', label: `Temperature (${'\u00b0'}C)` },
// //     { value: 'Total_Precipitation', label: 'Total Precipitation' },
// //     { value: 'Humidity', label: 'Humidity' },
// //     { value: 'Heat_Index', label: `Heat Index (${'\u00b0'}C)` },
// //     { value: 'Dew_Point', label: `Dew Point (${'\u00b0'}C)` },
// //     { value: 'Visibility', label: 'Visibility (km)' },
// //     { value: 'Wind_Gust ', label: 'Wind Gust (km/hr)' },
// //     { value: 'Cloud_Cover', label: 'Cloud Cover (%)' },
// //     {
// //       value: 'Windchill_Temperature',
// //       label: `Windchill Temperature (${'\u00b0'}C)`,
// //     },
// //     { value: 'Will_Rain', label: 'Will It Rain' },
// //     { value: 'Will_Snow', label: 'Will It Snow' },
// //   ];

// //   return (
// //     <>
// //       <Row className="filer_wrapper" id="filter-grid">
// //         <h2>Filters:</h2>
// //         <div className="col-sm-2 filter">
// //           <DatePicker
// //             name="start"
// //             minDate={new Date(fromDate).getTime() - 23 * 24 * 60 * 60 * 1000}
// //             maxDate={fromDate}
// //             placeholderText="Date"
// //             selected={fromDate}
// //             onChange={setFromDate}
// //             className="filter"
// //           />
// //           <i className="simple-icon-calendar" />
// //         </div>
// //         <div className="carbon_options filter col-sm-2">
        
// //           <ReactMultiSelectCheckboxes
// //             options={optionsCarbon}
// //             onChange={changeHandler}
// //             defaultValue={[
// //               optionsCarbon[0],
// //               optionsCarbon[1],
// //               optionsCarbon[2],
// //             ]}
// //             placeholderButtonLabel="3 selected"
// //             // styles={colourStyles}
// //           />
// //         </div>
// //         <div className="weather_options filter col-sm-2">
// //           {/* <Select
// //             options={optionsWeather}
// //             isMulti
// //             onChange={changeHandlerWeather}
// //           /> */}
// //           <ReactMultiSelectCheckboxes
// //             options={optionsWeather}
// //             onChange={changeHandlerWeather}
// //           />
// //         </div>
// //         <div className="submit_filter  col-sm-3">
// //           <Button type="submit" name="end" className="data" onClick={getData}>
// //             Get Data
// //           </Button>
// //         </div>
// //       </Row>
// //       {/* { !weatherLoading && ( */}

// //       {/* // {!carbonLoading && !weatherLoading && ( */}
// //         <div className="chart-info">
// //           <Container>
// //             <Row className="row-blok">
// //               {weekDataSet &&
// //                 weekWeatherDataSet &&
// //                 weekDataSet.map((item, index) => {
// //                   return (
// //                     <Col key={index}>
// //                       <p key={index + 1} className="date">
// //                         <img
// //                           alt=""
// //                           src={
// //                             weekWeatherDataSet[index].day &&
// //                             weekWeatherDataSet[index].day.condition &&
// //                             weekWeatherDataSet[index].day.condition.icon
// //                           }
// //                         />
// //                         <span>{dateFomat(item.day)}</span>
// //                       </p>

// //                       <p className="csday">
// //                         {dayFomat(item.day)}
// //                         <span>
// //                           {weekWeatherDataSet[index] &&
// //                             weekWeatherDataSet[index].day &&
// //                             weekWeatherDataSet[index].day.condition &&
// //                             weekWeatherDataSet[index].day.condition.text}
// //                         </span>
// //                       </p>

// //                       <p
// //                         className={`air-block ${getBackgroundColor(
// //                           item.absorption_air,
// //                           'air'
// //                         )}`}
// //                       >
// //                         {' '}
// //                         {item.absorption_air} Kg
// //                       </p>
// //                       <p
// //                         className={`soil-block ${getBackgroundColor(
// //                           item.absorption_soil,
// //                           'soil'
// //                         )}`}
// //                       >
// //                         {' '}
// //                         {item.absorption_soil} Kg
// //                       </p>
// //                       <p
// //                         className={`net-block ${getBackgroundColor(
// //                           item.absorption_total,
// //                           'net'
// //                         )}`}
// //                       >
// //                         {item.absorption_total} Kg
// //                       </p>

// //                       <p className="moon-set">
// //                         <span className="rise">
// //                           {weekWeatherDataSet[index] &&
// //                             weekWeatherDataSet[index].day &&
// //                             weekWeatherDataSet[index].astro.sunrise}
// //                         </span>
// //                         <img src={sun} alt="" />

// //                         <span className="set">
// //                           {weekWeatherDataSet[index] &&
// //                             weekWeatherDataSet[index].day &&
// //                             weekWeatherDataSet[index].astro.sunset}
// //                         </span>
// //                       </p>
// //                     </Col>
// //                   );
// //                 })}
// //             </Row>
// //           </Container>

// //           <Row>
// //             {legends && (
// //               <Line
// //                 height={wheight}
// //                 width="100%"
// //                 style={{ height: wheight }}
// //                 data={data}
// //                 options={options}
// //                 plugins={[tooltipLine]}
// //                 // htmlLegendPlugin
// //                 className="Chart-view"
// //               />
// //             )}
// //           </Row>

// //           {/* <div id="legend-container" /> */}
// //         </div>
// //       {/* )} */}
// //     </>
// //   );
// // };

// // // const mapStateToProps = ({ sensorData }) => {
// // //   const {
// // //     // loading,
// // //     // carbonLoading,
// // //     // weatherLoading,
// // //     error,
// // //     weatherDataSet,
// // //     carbonDataSet,
// // //     dataSet1,
// // //     dataSet2,
// // //     dataSet3,
// // //     dataSet4,
// // //     dataSet5,
// // //     dataSet6,
// // //     dataSet7,
// // //     dataSet8,
// // //     dataSet9,
// // //     dataSet11,
// // //     dataSet12,
// // //     // dataSet13,
// // //     dataSet14,
// // //     dataSet15,
// // //     dataSetSoil,
// // //     dataSetAir,
// // //     dataSetNet,
// // //     dataSet_emission_air,
// // //   } = sensorData;
// // //   return {
// // //     // loading,
// // //     // carbonLoading,
// // //     // weatherLoading,
// // //     error,
// // //     weatherDataSet,
// // //     carbonDataSet,
// // //     dataSet1,
// // //     dataSet2,
// // //     dataSet3,
// // //     dataSet4,
// // //     dataSet5,
// // //     dataSet6,
// // //     dataSet7,
// // //     dataSet8,
// // //     dataSet9,
// // //     dataSet11,
// // //     dataSet12,
// // //     // dataSet13,
// // //     dataSet14,
// // //     dataSet15,
// // //     dataSetSoil,
// // //     dataSetAir,
// // //     dataSetNet,
// // //     dataSet_emission_air,
// // //   };
// // // };

// // export default connect(null, {
// //   getWeatherDataAction: getWeatherData,
// //   getCarbonDataAction: getCarbonData,
// //   getWeatherChartDataAction: getWeatherChartData,
// //   getCarbonChartDataAction: getCarbonChartData,
// // })(TestMapImpliment);

// import * as React from 'react';
// import PropTypes from 'prop-types';
// import { alpha } from '@mui/material/styles';
// import Box from '@mui/material/Box';
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TablePagination from '@mui/material/TablePagination';
// import TableRow from '@mui/material/TableRow';
// import TableSortLabel from '@mui/material/TableSortLabel';
// import Toolbar from '@mui/material/Toolbar';
// import Typography from '@mui/material/Typography';
// import Paper from '@mui/material/Paper';
// import Checkbox from '@mui/material/Checkbox';
// import IconButton from '@mui/material/IconButton';
// import Tooltip from '@mui/material/Tooltip';
// import DeleteIcon from '@mui/icons-material/Delete';
// import FilterListIcon from '@mui/icons-material/FilterList';
// import { visuallyHidden } from '@mui/utils';
// import { useEffect, useState } from 'react';
// import { DataGrid } from '@mui/x-data-grid';
// import { connect, useSelector } from 'react-redux';
// import { Alert, Button, FormControlLabel, Grid, Menu, MenuItem, Snackbar, Switch } from '@mui/material';
// // import { getAllSites } from '../features/counter/APIs';
// import { getAllSites } from '../../features/counter/APIs';
// import EditIcon from '@mui/icons-material/Edit';
// import SensorsIcon from '@mui/icons-material/Sensors';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
// import axios from '../../axios';
// // import axios from '../axios';
// import axiosClient from '../../axios';
// import { useNavigate } from "react-router-dom";
// import SignalGreen from '../../assets/gifs/signal-green.gif';
// function createData(name, calories, fat, carbs, protein) {
//   return {
//     name,
//     calories,
//     fat,
//     carbs,
//     protein,
//   };
// }

// const rows = [
//   createData('Cupcake', 305, 3.7, 67, 4.3),
//   createData('Donut', 452, 25.0, 51, 4.9),
//   createData('Eclair', 262, 16.0, 24, 6.0),
//   createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
//   createData('Gingerbread', 356, 16.0, 49, 3.9),
//   createData('Honeycomb', 408, 3.2, 87, 6.5),
//   createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
//   createData('Jelly Bean', 375, 0.0, 94, 0.0),
//   createData('KitKat', 518, 26.0, 65, 7.0),
//   createData('Lollipop', 392, 0.2, 98, 0.0),
//   createData('Marshmallow', 318, 0, 81, 2.0),
//   createData('Nougat', 360, 19.0, 9, 37.0),
//   createData('Oreo', 437, 18.0, 63, 4.0),
// ];

// function descendingComparator(a, b, orderBy) {
//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// }

// function getComparator(order, orderBy) {
//   return order === 'desc'
//     ? (a, b) => descendingComparator(a, b, orderBy)
//     : (a, b) => -descendingComparator(a, b, orderBy);
// }

// // This method is created for cross-browser compatibility, if you don't
// // need to support IE11, you can use Array.prototype.sort() directly
// function stableSort(array, comparator) {
//   const stabilizedThis = array.map((el, index) => [el, index]);
//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) {
//       return order;
//     }
//     return a[1] - b[1];
//   });
//   return stabilizedThis.map((el) => el[0]);
// }

// const headCells = [
//   {
//     id: 'name',
//     numeric: false,
//     disablePadding: true,
//     label: 'Dessert (100g serving)',
//   },
//   {
//     id: 'calories',
//     numeric: true,
//     disablePadding: false,
//     label: 'Calories',
//   },
//   {
//     id: 'fat',
//     numeric: true,
//     disablePadding: false,
//     label: 'Fat (g)',
//   },
//   {
//     id: 'carbs',
//     numeric: true,
//     disablePadding: false,
//     label: 'Carbs (g)',
//   },
//   {
//     id: 'protein',
//     numeric: true,
//     disablePadding: false,
//     label: 'Protein (g)',
//   },
// ];

// // function EnhancedTableHead(props) {
// //   const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
// //     props;
// //   const createSortHandler = (property) => (event) => {
// //     onRequestSort(event, property);
// //   };

// //   return (
// //     <TableHead>
// //       <TableRow>
// //         {headCells.map((headCell) => (
// //           <TableCell
// //             key={headCell.id}
// //             align={headCell.numeric ? 'right' : 'left'}
// //             padding={headCell.disablePadding ? 'none' : 'normal'}
// //             sortDirection={orderBy === headCell.id ? order : false}
// //           >
// //             <TableSortLabel
// //               active={orderBy === headCell.id}
// //               direction={orderBy === headCell.id ? order : 'asc'}
// //               onClick={createSortHandler(headCell.id)}
// //             >
// //               {headCell.label}
// //               {orderBy === headCell.id ? (
// //                 <Box component="span" sx={visuallyHidden}>
// //                   {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
// //                 </Box>
// //               ) : null}
// //             </TableSortLabel>
// //           </TableCell>
// //         ))}
// //       </TableRow>
// //     </TableHead>
// //   );
// // }

// // EnhancedTableHead.propTypes = {
// //   numSelected: PropTypes.number.isRequired,
// //   onRequestSort: PropTypes.func.isRequired,
// //   onSelectAllClick: PropTypes.func.isRequired,
// //   order: PropTypes.oneOf(['asc', 'desc']).isRequired,
// //   orderBy: PropTypes.string.isRequired,
// //   rowCount: PropTypes.number.isRequired,
// // };

// const EnhancedTableToolbar = (props) => {
//   const { numSelected } = props;

//   return (
//     <Toolbar
//       sx={{
//         pl: { sm: 2 },
//         pr: { xs: 1, sm: 1 },
//         ...(numSelected > 0 && {
//           bgcolor: (theme) =>
//             alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
//         }),
//       }}
//     >
//       {numSelected > 0 ? (
//         <Typography
//           sx={{ flex: '1 1 100%' }}
//           color="inherit"
//           variant="subtitle1"
//           component="div"
//         >
//           {numSelected} selected
//         </Typography>
//       ) : (
//         <Typography
//           sx={{ flex: '1 1 100%' }}
//           variant="h6"
//           id="tableTitle"
//           component="div"
//         >
//           Nutrition
//         </Typography>
//       )}

//       {numSelected > 0 ? (
//         <Tooltip title="Delete">
//           <IconButton>
//             <DeleteIcon />
//           </IconButton>
//         </Tooltip>
//       ) : (
//         <Tooltip title="Filter list">
//           <IconButton>
//             <FilterListIcon />
//           </IconButton>
//         </Tooltip>
//       )}
//     </Toolbar>
//   );
// };

// // EnhancedTableToolbar.propTypes = {
// //   numSelected: PropTypes.number.isRequired,
// // };

// const  EnhancedTable = (props) => {
//   const [order, setOrder] = React.useState('asc');
//   const [orderBy, setOrderBy] = React.useState('calories');
//   const [selected, setSelected] = React.useState([]);
//   const [page, setPage] = React.useState(0);
//   const [dense, setDense] = React.useState(false);
//   const [rowsPerPage, setRowsPerPage] = React.useState(5);

//   const handleRequestSort = (event, property) => {
//     const isAsc = orderBy === property && order === 'asc';
//     setOrder(isAsc ? 'desc' : 'asc');
//     setOrderBy(property);
//   };
//   let nav = useNavigate();
//   const [rows, setRows] = useState([])
//   const [open, setOpen] = useState(-1)
//   const [anchorEl, setAnchorEl] = useState(null);
//   const [aopen, setAlertOpen] = useState(false);
//   const [siteOpen, setSiteOpen] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const user = useSelector(state => state.loginData.user)

//   const handleClose = (event, reason) => {
//     if (reason === 'clickaway') {
//       return;
//     }
//     setAlertOpen(false);
//   };

//   const handleCloseSite = (event, reason) =>{
//     if (reason === 'clickaway') {
//       return;
//     }
//     setSiteOpen(false);
//   }

//   const columns = [
//     { field: 'siteid', headerName: 'ID', width: 60 },
//     {
//       field: "sitename", headerName: 'Site Name', flex: 1,
//     },
//     {
//       field: 'sitetype', headerName: 'Site Type',
//       flex: 1,
//       renderCell: (val) => (
//         <span className='text'>
//           {val.value}
//         </span>
//       ),
//     },
//     {
//       field: 'sitelogitude',
//       headerName: 'Longitude',
//       description: 'This column has a value getter and is not sortable.',
//       sortable: false,
//       flex: 1,
//     },
//     {
//       field: 'sitelatitude',
//       headerName: 'Latitude',
//       flex: 1,
//     },
//     {
//       field: 'carbontype',
//       headerName: 'Carbon Type',
//       flex: 1,
//     },
//     {
//       field: 'soiltype',
//       headerName: 'Soil Type',
//       description: 'This column has a value getter and is not sortable.',
//       sortable: false,
//       flex: 1,
//     },
//     {
//       field: 'vegitationtype',
//       headerName: 'Vegetation',
//       description: 'This column has a value getter and is not sortable.',
//       sortable: false,
//       flex: 1,
//     },
    

//   ];
//   if (user?.personIdref?.orgidref?.orgcode == "GAIT") {
//     columns.push(
//       {
//         field: 'isactive',
//         headerName: 'Status',
//         description: 'This column has a value getter and is not sortable.',
//         sortable: false,
//         flex: 1,
//         renderCell: (val) => (
//           <>
//             <span>
//               <FormControlLabel className={val.value == 1 ? 'text-green' : ""} control={<Switch onChange={(e) => deactSensor(val.row)} color='success' checked={val.value === 0 ? false : true}
//               />} label={val.value == 0 ? "Inactive" : "Active"} />
//             </span>
//           </>
//         ),
//         flex: 1,
//       }
//     )
//     columns.push(
//       {
//         field: 'owner',
//         headerName: 'Owner',
//         sortable: false,
//         flex: 1,
//         renderCell: (params) => {
//           return (
//             <>
//               {params.value}
//             </>
//           );
//         }
//       }
//     )
//     columns.push(
//       {
//         field: 'action',
//         headerName: 'Actions',
//         flex: 1,
//         renderCell: (val) => (
//           <>
//             <Button
//               color='inherit'
//               id="basic-button"
//               aria-haspopup="true"
//               onClick={e => {
//                 setAnchorEl(e.currentTarget);
//                 setOpen(val.value)
//               }}
//             >
//               <MoreVertIcon />
//             </Button>
//             <Menu
//               onClose={e => {
//                 setAnchorEl(null);
//                 setOpen(-1)
//               }}
//               anchorEl={anchorEl}
//               id="basic-menu"
//               anchorOrigin={{
//                 vertical: 'bottom',
//                 horizontal: 'middle',
//               }}
//               open={(open == val.value) ? true : false}
//               MenuListProps={{
//                 'aria-labelledby': 'basic-button',
//               }}
//             >
//               <MenuItem style={{ backgroundColor: "#000", opacity: 1, color: "#fff" }} disabled onClick={e => setOpen(-1)}>Actions</MenuItem>
//               <MenuItem onClick={e => {
//                 nav('/edit-site', {state:{
//                   siteId: val.row} 
//                 })
//               }}><EditIcon htmlColor='#4EB9E9'/> <span style={{marginLeft: 5}}>Edit</span></MenuItem>
//               <MenuItem 
//               onClick={e => {
//                 nav('/site-install')
//               }}
//               ><SensorsIcon htmlColor='#2C4EA6'/> <span style={{marginLeft: 5}}>Install </span> </MenuItem>
//             </Menu>
//           </>
//         ),
//       })
//   }
//   const handleSelectAllClick = (event) => {
//     if (event.target.checked) {
//       const newSelected = rows.map((n) => n.name);
//       setSelected(newSelected);
//       return;
//     }
//     setSelected([]);
//   };

//   const handleClick = (event, name) => {
//     const selectedIndex = selected.indexOf(name);
//     let newSelected = [];

//     if (selectedIndex === -1) {
//       newSelected = newSelected.concat(selected, name);
//     } else if (selectedIndex === 0) {
//       newSelected = newSelected.concat(selected.slice(1));
//     } else if (selectedIndex === selected.length - 1) {
//       newSelected = newSelected.concat(selected.slice(0, -1));
//     } else if (selectedIndex > 0) {
//       newSelected = newSelected.concat(
//         selected.slice(0, selectedIndex),
//         selected.slice(selectedIndex + 1),
//       );
//     }

//     setSelected(newSelected);
//   };

//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };

//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(parseInt(event.target.value, 10));
//     setPage(0);
//   };

//   const handleChangeDense = (event) => {
//     setDense(event.target.checked);
//   };

//   const isSelected = (name) => selected.indexOf(name) !== -1;

//   // Avoid a layout jump when reaching the last page with empty rows.
//   const emptyRows =
//     page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
//     const getAllSites = (orgid, orgcode) => {
//       setLoading(true);
//       axiosClient.get(`v1/gait/site/view_all_sites/${orgid}/${orgcode}`).then((res) => {
//         setLoading(false);
//       })
//     }
//     const deactSensor = (site) => {
//       if (site.isactive == 0) {
//         axios.put(`v1/gait/site/change_site_status/${site.siteid}/1`).then((res) => {
//           getAllSitesandUpdate()
//           setAlertOpen(true);
//           getAllSites(user?.personidref?.orgidref?.orgid, user?.personidref?.orgidref?.orgcode)
//         })
//       } else {
//         axios.put(`v1/gait/site/change_site_status/${site.siteid}/0`).then((res) => {
//           getAllSitesandUpdate()
//           setAlertOpen(true);
//           getAllSites(user?.personidref?.orgidref?.orgid, user?.personidref?.orgidref?.orgcode)
//         })
//       }
//     }
//     const getAllSitesandUpdate = () => {
//       props.getAllSites(user?.personidref?.orgidref?.orgid, user?.personidref?.orgidref?.orgcode).then((res) => {
//         const tempRows = sitesList?.map((val, index) => {
//           val.id = index
//           val.action = index
//           return (val)
//         })
//         setRows(tempRows)
//       })
//     }
//     const sitesList = useSelector(state => state.sites.gaitObj)
//     useEffect(() => {
//       getAllSitesandUpdate()
//       getAllSites(user?.personidref?.orgidref?.orgid, user?.personidref?.orgidref?.orgcode)
//       showSiteAddedMsg();
//     }, []);
  
//     const showSiteAddedMsg = () =>{
//       const siteAdded = localStorage.getItem('addedSite');
//       if(siteAdded == 'true'){
//         setSiteOpen(true);
//        localStorage.removeItem('addedSite');
//       }else{
//         setSiteOpen(false);
//       }
//     }
//   return (
//     <Box sx={{ width: '100%' }}>
//       <Paper sx={{ width: '100%', mb: 2 }}>
//         <EnhancedTableToolbar numSelected={selected.length} />
//         <TableContainer>

//           <Table
//             sx={{ minWidth: 750 }}
//             aria-labelledby="tableTitle"
//             size={dense ? 'small' : 'medium'}
//           >

//             {/* <EnhancedTableHead
//               numSelected={selected.length}
//               order={order}
//               orderBy={orderBy}
//               onSelectAllClick={handleSelectAllClick}
//               onRequestSort={handleRequestSort}
//               rowCount={rows.length}
//             /> */}
//               <TableHead>
//       <TableRow>
//         {columns.map((columns , index) => (
//           <TableCell
//             key={index}
//             align={columns.numeric ? 'right' : 'left'}
//             padding={columns.disablePadding ? 'none' : 'normal'}
//             sortDirection={orderBy === columns.field ? order : false}
//           >
//             <TableSortLabel
//               active={orderBy === columns.field}
//               direction={orderBy === columns.field ? order : 'asc'}
              
//             >
//               {columns.label}
//               {orderBy === columns.field ? (
//                 <Box component="span" sx={visuallyHidden}>
//                   {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
//                 </Box>
//               ) : null}
//             </TableSortLabel>
//           </TableCell>
//         ))}
//       </TableRow>
//     </TableHead>
//             <TableBody>
//               {/* if you don't need to support IE11, you can replace the `stableSort` call with:
//                  rows.slice().sort(getComparator(order, orderBy)) */}
//               {stableSort(rows, getComparator(order, orderBy))
//                 .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
//                 .map((row, index) => {
//                   const isItemSelected = isSelected(row.name);
//                   const labelId = `enhanced-table-checkbox-${index}`;

//                   return (
//                     <TableRow
//                       hover
//                       onClick={(event) => handleClick(event, row.name)}
//                       role="checkbox"
//                       aria-checked={isItemSelected}
//                       tabIndex={-1}
//                       key={row.name}
//                       selected={isItemSelected}
//                     >
//                       <TableCell padding="checkbox">
//                         <Checkbox
//                           color="primary"
//                           checked={isItemSelected}
//                           inputProps={{
//                             'aria-labelledby': labelId,
//                           }}
//                         />
//                       </TableCell>
//                       <TableCell
//                         component="th"
//                         id={labelId}
//                         scope="row"
//                         padding="none"
//                       >
//                         {row.name}
//                       </TableCell>
//                       <TableCell align="right">{row.calories}</TableCell>
//                       <TableCell align="right">{row.fat}</TableCell>
//                       <TableCell align="right">{row.carbs}</TableCell>
//                       <TableCell align="right">{row.protein}</TableCell>
//                     </TableRow>
//                   );
//                 })}
//               {emptyRows > 0 && (
//                 <TableRow
//                   style={{
//                     height: (dense ? 33 : 53) * emptyRows,
//                   }}
//                 >
//                   <TableCell colSpan={6} />
//                 </TableRow>
//               )}
//             </TableBody>
//           </Table>
//         </TableContainer>
//         <TablePagination
//           rowsPerPageOptions={[5, 10, 25]}
//           component="div"
//           count={rows.length}
//           rowsPerPage={rowsPerPage}
//           page={page}
//           onPageChange={handleChangePage}
//           onRowsPerPageChange={handleChangeRowsPerPage}
//         />
//       </Paper>
//       <FormControlLabel
//         control={<Switch checked={dense} onChange={handleChangeDense} />}
//         label="Dense padding"
//       />
//     </Box>
//   );
// }
// const mapDispatchToProps = {
//   getAllSites: getAllSites,
// }
// export default connect(null, mapDispatchToProps)(EnhancedTable);
import * as React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';

const drawerWidth = 240;

interface Props {
  window?: () => Window;
}

export default function ResponsiveDrawer(drawerprops: Props) {
  const { window } = drawerprops;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const containerdrawer = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {/* <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      > */}
        <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
      {/* </AppBar> */}
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={containerdrawer}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, 
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {/* {drawer} */}
          <p>afuwqfry</p>
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {/* {drawer} */}
          <p>erwrwrwerw</p>
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        <Toolbar />
      
       
      </Box>
    </Box>
  );
}
