import React from "react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Vector from "../../assets/map-svgrepo-com.svg";
import photo_24px from "../../assets/photo_24px.svg";
import aspect_ratio_24px from "../../assets/aspect_ratio_24px.svg";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ICONS from "../../features/images/index";
import VH4EQ5KTAC from "../../assets/reshot-icon-factory-company-VH4EQ5KTAC.svg";
import person_outline_24px from "../../assets/person_outline_24px.svg";
import check24pxImg from "../../assets/24px.svg";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import {
  Alert,
  Button,
  FormControlLabel,
  Menu,
  MenuItem,
  Snackbar,
  Switch,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import axiosClient from "../../axios";
import ColorPink from "../../assets/Color-Pink.svg";
function ProjectCardView(props) {
  const nav = useNavigate();
  const [projectsLists, setProjectsLists] = useState([]);
  const [open, setOpen] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [projectId, setProjectId] = useState();
  useEffect(() => {
    viewAllProjects();
  }, []);
  const handleClickOpenAlert = (pId) => {
    setProjectId(pId);
    setOpenAlert(true);
  };
  const handleCloseAlert = () => {
    deleteProjects(projectId);
    setOpenAlert(false);
  };
  const handleCloseAlertNo = () => {
    setOpenAlert(false);
  };
  const viewAllProjects = () => {
    setLoading(true);
    axiosClient
      .get("v1/gait/project/viewAll")
      .then((res) => {
        if (res.status === 200) {
          const tempRows = res?.data?.map((val, index) => {
            val.id = val.id;
            val.action = val.id;
            return val;
          });
          setProjectsLists(tempRows);
          setLoading(false);
        }
      })
      .catch((err) => {});
  };

  const deleteProjects = (pId) => {
    axiosClient
      .delete(`v1/gait/project/byId/${pId}`)
      .then((res) => {
        viewAllProjects();
      })
      .catch((err) => {
        console.log(err, "chcek erroro if have ");
      });
  };

  const filterProjectByStatus = (status_name) =>{
    setLoading(true);
    axiosClient.post('v1/gait/project/filterbystatus',
    {
      status:status_name
    }
    ).then(res =>{
      if(res?.status === 200){
        setProjectsLists(res?.data)
        setLoading(false);
      }
    })
  }

  const searchBySiteProjectCountry =(e) =>{
    console.log(e.target.value === "" || e.target.value === null , "check e target")
    if(e.target.value === "" || e.target.value === null){
      viewAllProjects()
    }else{
    axiosClient.post('v1/gait/project/searchon',
      {
        searchName:e.target.value
    
    }).then(res =>{
      setProjectsLists(res?.data)
    })
  }
  }

  return (
    <>
      {loading ? (
        <div>
          <img
            src={ICONS.SignalGreen}
            style={{
              width: "5%",
              position: "absolute",
              top: "50%",
              left: "50%",
            }}
          />
        </div>
      ) : (
        <>
          <Dialog
            open={openAlert}
            onClose={handleCloseAlertNo}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <span className="sure_to-delete">
                {" "}
                Are you sure want to delete
              </span>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseAlertNo}>
                {" "}
                <span className="sure_to-no"> No</span>
              </Button>
              <Button onClick={handleCloseAlert}>
                <span className="sure_to-yes">Yes</span>
              </Button>
            </DialogActions>
          </Dialog>
          <div className="d-flex flex-row-reverse justify-content-center align-items-baseline">
            <div className="add-projectbtn">
              <button onClick={() => nav("/add-projects")}>Add Project</button>
            </div>
            <div className="search-input">
              <input type="text" name="search" placeholder="serach" onChange={ searchBySiteProjectCountry} />
              <div className="search-ico">
                <SearchIcon />
              </div>
            </div>
            
            <div class="dropdown">
              <button class="dropbtn-status">
                Status <KeyboardArrowDownIcon />
              </button>
              <div class="dropdown-content1">
                <MenuItem
                  style={{
                    backgroundColor: "#000",
                    opacity: 1,
                    color: "#fff",
                    borderRadius: "4px",
                  }}
                  disabled
                >
                  Select
                </MenuItem>
                <MenuItem
                onClick={() => filterProjectByStatus('New')}
                >
                  <div className="card-yellow"></div>
                  <span style={{ marginLeft: 5 }}> New</span>
                </MenuItem>
                <MenuItem
                onClick={() => filterProjectByStatus('Pre Analysis')}
                >
                  <div className="card-pink"></div>
                  <span style={{ marginLeft: 5 }}> Pre Analysis</span>
                </MenuItem>

                <MenuItem
               onClick={() => filterProjectByStatus('Site Analysis')}
                >
                  <div className="card-darkblue"></div>
                  <span style={{ marginLeft: 5 }}>Site Analysis</span>
                </MenuItem>

                {/* <button>Delete</button>
                                      <button>Edit</button> */}
                {/* <button><AddIcon htmlColor="#2C4EA6" /> View More</button> */}
                <MenuItem
                onClick={() => filterProjectByStatus('Carbon Sequestration')}
                >
                  <div className="card-green"></div>
                  <span style={{ marginLeft: 5 }}>
                    Carbon Sequestration
                  </span>{" "}
                </MenuItem>

                <MenuItem
                onClick={() => filterProjectByStatus('Methodology')}
                >
                  <div className="card-tamoto"></div>
                  <span style={{ marginLeft: 5 }}>Methodology</span>{" "}
                </MenuItem>

                <MenuItem
                onClick={() => filterProjectByStatus('Monitoring')}
                >
                  <div className="card-blue"></div>
                  <span style={{ marginLeft: 5 }}>Monitoring</span>{" "}
                </MenuItem>

                <MenuItem
                onClick={() => filterProjectByStatus('Completed')}
                >
                  <div className="card-lightgreen"></div>
                  <span style={{ marginLeft: 5 }}>Completed</span>{" "}
                </MenuItem>
              </div>
            </div>
            <div className="reset-btn">
            <button onClick={() => viewAllProjects()}>
                 <RestartAltIcon />
              </button>
              </div>
          </div>
          <div className="container">
            <div className="row">
      {console.log(projectsLists.length !== 0 , "projectsListsprojectsLists")}
              {/* <div className="d-flex justify-content-between flex-wrap"> */}
              {
                projectsLists.length !== 0 ? (
              projectsLists?.map((val, index) => {
                return (
                  <div className="col-md-3 col-sm-12">
                    <div className="project-cards">
                      <div className="d-flex justify-content-between align-items-baseline">
                        {val?.status == "New" && (
                          <div className="card-status-yellow"></div>
                        )}
                        {val?.status == "Pre Analysis" && (
                          <div className="card-status-pink"></div>
                        )}
                        {val?.status == "Site Analysis" && (
                          <div className="card-status-blue"></div>
                        )}
                        {val?.status == "Carbon Sequestration" && (
                          <div className="card-status-green"></div>
                        )}
                        {val?.status == "Methodology" && (
                          <div className="card-status-lightpink"></div>
                        )}
                        {val?.status == "Monitoring" && (
                          <div className="card-status-lightblue"></div>
                        )}
                        {val?.status == "Completed" && (
                          <div className="card-status"></div>
                        )}

                        <div className="card-option">
                          <div class="dropdown">
                            <button class="dropbtn">
                              <MoreHorizIcon />
                            </button>
                            <div class="dropdown-content1">
                              <MenuItem
                                style={{
                                  backgroundColor: "#000",
                                  opacity: 1,
                                  color: "#fff",
                                  borderRadius: "4px",
                                }}
                                disabled
                              >
                                Actions
                              </MenuItem>
                              <MenuItem
                                onClick={(e) => {
                                  nav("/edit-projects", {
                                    state: {
                                      projectId: val?.id,
                                    },
                                  });
                                }}
                              >
                                <EditIcon htmlColor="#4EB9E9" />{" "}
                                <span style={{ marginLeft: 5 }}>Edit</span>
                              </MenuItem>

                              {/* <MenuItem
                                onClick={(e) => {
                                  handleClickOpenAlert(val?.id);
                                }}
                              >
                                <DeleteIcon htmlColor="#FF7272" />{" "}
                                <span style={{ marginLeft: 5 }}>Delete</span>
                              </MenuItem> */}

                              {/* <button>Delete</button>
                                      <button>Edit</button> */}
                              {/* <button><AddIcon htmlColor="#2C4EA6" /> View More</button> */}
                              <MenuItem
                                onClick={(e) => {
                                  nav("/projects-view", {
                                    state: {
                                      projectId: val?.id,
                                    },
                                  });
                                }}
                              >
                                <AddIcon htmlColor="#2C4EA6" />{" "}
                                <span style={{ marginLeft: 5 }}>
                                  View More{" "}
                                </span>{" "}
                              </MenuItem>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        onClick={(e) => {
                          val?.status != "New" &&
                          nav("/project-analysis", {
                            state: {
                              projectId: val?.id
                            },
                          });
                          window.localStorage.setItem('refresh', true);
                        }
                      }
                      className="redirect-nav"
                      >
                        <div className="project-heading">
                          <h1>
                            {val?.projectName ? val?.projectName : "N/A"}{" "}
                            {/* {val?.id} */}
                          </h1>
                        </div>
                        <div className="cardsection-one">
                          <div className="projectview-content">
                            <div className="d-flex justify-content-between">
                              <div className="d-flex justify-content-between align-items-baseline">
                                <img src={Vector} />
                                <p className="cardp-txt">
                                  {val?.siteName ? val?.siteName : "N/A"}
                                </p>
                              </div>
                              <img src={photo_24px} />
                            </div>
                          </div>
                          <div className="projectview-content">
                            <div className="d-flex justify-content-between align-items-baseline">
                              <div className="d-flex justify-content-between align-items-baseline">
                                {}
                                <img src={aspect_ratio_24px} />
                                <p className="cardp-txt">
                                  {val?.areaInHectres
                                    ? val?.areaInHectres
                                    : "N/A"}{" "}
                                  HT
                                </p>
                              </div>
                              <img src={photo_24px} />
                            </div>
                          </div>
                        </div>

                        <div className="cardsection-one">
                          <div className="projectview-content">
                            <div className="d-flex justify-content-start">
                              <img src={VH4EQ5KTAC} />
                              <p className="cardp-txt">
                                {val?.organizationName
                                  ? val?.organizationName
                                  : "N/A"}
                              </p>
                            </div>
                          </div>
                          {/* <div className="projectview-content">
                    <div className="d-flex justify-content-start">
                      <img src={person_outline_24px} />
                      <p className="cardp-txt">{val?.siteOwnerName}</p>
                    </div>
                  </div> */}
                        </div>
                        <div className="projectview-content">
                          <div className="d-flex justify-content-start">
                            <img src={check24pxImg} />
                            <p className="cardp-txt">
                              {val?.country ? val?.country : "N/A"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }) ) : (
                <div className="not-founded">
                    <p>No Projects Found</p>
                    <button onClick={() => viewAllProjects()}>Go Back</button>
                </div>
              )
              }
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default ProjectCardView;
