import React from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
    Button
} from "@mui/material";
import { Formik, Form, ErrorMessage, Field } from "formik";
import * as Yup from "yup";
import { useState } from "react";
const Report = (props) => {
  let nav = useNavigate();
  const params = useLocation();
  const [site , setSite] = useState();
  const [year , setYear] = useState();

  const sites = useSelector((state) => state.sites?.gaitObj);
  const handleChange = (e) => {
    setSite(e.target.value);
  };
  const handleChangeYear = (e) =>{
    setYear(e.target.value);
  }
  const validate = Yup.object({
    year: Yup.string().required("Year field is require"),
    site: Yup.string().required("Site field is require"),
  });
  return (
    <Formik
    initialValues={{
     year:'',
     site:''
    }}
    validationSchema={validate}
    onSubmit={(values) => {
        nav("/get-report", {state:{
            siteId: values.site, year: values.year} 
          })
    }}
  >
    {(formik) =>(
      <Form>
    <div style={{ width: "50%", margin: "auto" }}>
      <div>
        <label className="form-label">Site</label>
        <Field className="form-select" name="site"  as="select">
        <option >-Select-</option>
          {sites?.map((val, index) => {
            return (
              <option value={val.siteid} key={index}>
                {val.sitename}{" "}
              </option>
            );
          })}
        </Field>
        <ErrorMessage
                  name="site"
                  render={msg => <div className="error">
                    &nbsp;Please select site </div>}
                />
      </div>

      <div style={{ marginTop: 20 }}>
        <label className="form-label">Year</label>
        <Field as="select" className="form-select" name="year" >
          <option >-Select-</option>
          <option value="2016">2016</option>
          <option value="2017">2017</option>
          <option value="2018">2018</option>
          <option value="2019">2019</option>
          <option value="2020">2020</option>
          <option value="2021">2021</option>
          <option value="2022">2022</option>
        </Field>
        <ErrorMessage
                  name="year"
                  render={msg => <div className="error">
                    &nbsp;Please select year </div>}
                />
      </div>

      <div style={{marginTop:20}}>
      <Button
                style={{
                    marginLeft: 15,
                    display: "block",
                    width: 140,
                    background: "linear-gradient(0deg, #55AA8B, #55AA8B), #FEFEFE",
                    borderRadius: 4,
                    color: "white",
                    fontFamily: "Poppins",
                    fontSize: 13,
                    margin:'auto'
                }}
                type="submit"
                >
                Get Report
            </Button>
            </div>
    </div>
    </Form>
    )}
    </Formik>
  );
}
export default Report;
