import thunkMiddleware from 'redux-thunk'
import { configureStore } from "@reduxjs/toolkit";
import sensorReducer from '../features/counter/APIs'
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
    key: 'root',
    storage,
  }
  
  const persistedReducer = persistReducer(persistConfig, sensorReducer)
  
  export const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: [thunkMiddleware]
  })
  
  export const persistor = persistStore(store)