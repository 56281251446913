import { Card, Grid } from "@mui/material";
import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import sgreen from "../assets/circle-green.png";
import sgrey from "../assets/circle-grey.png";
import sred from "../assets/circle-red.png";
import { getAllSensors } from "../features/counter/APIs";
import { MapContainer } from "react-leaflet/MapContainer";
import { TileLayer } from "react-leaflet/TileLayer";
import L from "leaflet";
import ICONS from '../features/images';
import { Marker, Popup } from "react-leaflet";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
const sensorgrey = L.icon({ iconUrl: sgrey, iconSize: 25 });
const sensorgreen = L.icon({ iconUrl: sgreen, iconSize: 25 });
const sensorred = L.icon({ iconUrl: sred, iconSize: 25 });

// some other code
function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

const labels = ["January", "February", "March", "April", "May", "June", "July"];
function SensorStatus() {
  const user = useSelector((state) => state.loginData.user);
  let navigate = useNavigate();
  React.useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://d3js.org/d3.v4.js";
    script.async = true;
    document.body.appendChild(script);
    getAllSensorRecord();
  }, []);

  const [value, setValue] = React.useState(0);
  const [active, setActive] = React.useState(0);
  const [inActive, setInActive] = React.useState(0);
  const [sensorRecord, setSensorRecord] = React.useState([]);
  const [icount, setIcount] = React.useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    document.getElementById("heat-map").innerHTML = null;
    setTimeout(() => {
      initHeatMap(sensorRecord);
    }, 500);
  };

  const initHeatMap = (sensorArr) => {
    const d3 = window.d3;

    // set the dimensions and margins of the graph
    var margin = { top: 100, right: 30, bottom: 30, left: 30 },
      width = 520 - margin.left - margin.right,
      height = 450 - margin.top - margin.bottom;

    // append the svg object to the body of the page
    var svg = d3
      .select("#heat-map")
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    let arr = {};

    let numbers = [];

    sensorArr?.map((sensor) => {
      let currentSensorCountry = sensor.country;

      let length = arr[currentSensorCountry];

      if (length) {
        arr[currentSensorCountry] = length + 1;
      } else {
        arr[currentSensorCountry] = 1;
      }
      numbers.push(arr[currentSensorCountry]);
    });
    let sample = numbers;
    const countries = [...new Set(sensorArr?.map((item) => item.country))];
    const sensors = sample;
    // Labels of row and columns
    var myGroups = countries;
    var myVars = sensors;
    // Build X scales and axis:
    var x = d3.scaleBand().range([0, width]).domain(myGroups).padding(0.01);
    svg
      .append("g")
      .attr("transform", "translate(0," + height + ")")
      .call(d3.axisBottom(x));

    // Build X scales and axis:
    var y = d3.scaleBand().range([height, 0]).domain(myVars).padding(0.01);
    svg.append("g").call(d3.axisLeft(y));

    let tempArr = [];
    sensorArr?.map((val, index) => {
      tempArr.push({ group: val.country, variable: sample[index], value: val });
    });
    //Read the data
    d3.csv(
      "https://raw.githubusercontent.com/holtzy/D3-graph-gallery/master/DATA/heatmap_data.csv",
      function (data) {
        // create a tooltip
        var tooltip = d3
          .select("#heat-map")
          .append("div")
          .style("opacity", 0)
          .attr("class", "tooltip")
          .style("background-color", "white")
          .style("border", "solid")
          .style("border-width", "2px")
          .style("border-radius", "5px")
          .style("padding", "5px");

        // Three function that change the tooltip when user hover / move / leave a cell
        var mouseover = function (d) {
          tooltip.style("opacity", 1);
        };
        var mousemove = function (d) {
          tooltip
            .html(
              `<ul>
          <li> Sensor Id : ${d.value.sensorid}</li> 
          <li> Sensor Status: ${
            d.value.isactive == 1 ? "Active" : "Inactive"
          }</li>
          <li> Sensor Type: ${d.value.sensortype}</li>
          <li> Location: ${d.value.location}</li>
          <li> Country: ${d.value.country}</li>
          <li> Last status : ${new Date(
            d.value.lastcommunidate
          ).toLocaleDateString()}</li>
        </ul>`
            )
            .style("left", d3.mouse(this)[0] + 70 + "px")
            .style("top", d3.mouse(this)[1] + "px");
        };
        var mouseleave = function (d) {
          tooltip.style("opacity", 0);
        };
        var mouseclick = function (d) {
          navigate("/sensor?sensorId=" + d.value.sensorid);
        };

        // add the squares
        svg
          .selectAll()
          .data(tempArr, function (d) {
            return d.group + ":" + d.variable;
          })
          .enter()
          .append("rect")
          .attr("x", function (d) {
            return x(d.group);
          })
          .attr("y", function (d) {
            return y(d.variable);
          })
          .attr("width", x.bandwidth())
          .attr("height", y.bandwidth())
          .style("fill", function (d) {
            return d.value.isactive == 1 && d.value.livestatus == 1
              ? "#55aa8b"
              : d.value.isactive == 1 && d.value.livestatus == 0
              ? "red"
              : "#ccc";
          })
          .on("mouseover", mouseover)
          .on("mousemove", mousemove)
          .on("mouseleave", mouseleave)
          .on("click", mouseclick);
      }
    );
  };
  const getAllSensorRecord = async () => {
    let acount = [];

    await getAllSensors(
      user?.personidref?.orgidref?.orgid,
      user?.personidref?.orgidref?.orgcode
    )
      .then((res) => {
        setSensorRecord(res.data.gaitObj);

        let temp = [];
        res.data.gaitObj?.map((val) => {
          val.isactive === 0 ? acount.push(val) : temp.push(val);
        });

        setIcount(temp);
        setActive(icount.length);
        setInActive(acount.length);
      })
      .catch((e) => {});
  };
  return (
    <>
      <Grid
        container
        spacing={2}
        style={{ marginTop: 0, alignItems: "stretch" }}
        alignItems="start"
      >
        <Grid item lg={12} >
          <Box sx={{ width: "100%" }}>
            <Card className="card card-grey">
              <Grid
                container
                spacing={2}
                style={{ alignItems: "stretch" }}
                alignItems="start"
              >
                <Grid item lg={9} className="map-grid">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab label="Map"></Tab>
                    <Tab label="Graph" />
                  </Tabs>
                  <div
                    style={{ padding: 15 }}
                    hidden={value !== 0}
                    role="tabpanel"
                    value={value}
                    index={0}
                  >
                    <div className="map-container" style={{ height: "100%" }}>
                      <MapContainer
                        center={[-44.573889, 169.320556]}
                        zoom={2}
                        scrollWheelZoom={false}
                        style={{
                          height: "450px",
                        }}
                      >
                        <TileLayer
                          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                          url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
                        />

                        {sensorRecord?.map((item, index) => {
                          return (
                            <>
                              <Marker
                                position={[
                                  Number(item.latitude),
                                  Number(item.longitude),
                                ]}
                                icon={
                                  item.isactive == 1 && item.livestatus == 1
                                    ? sensorgreen
                                    : item.isactive == 1 && item.livestatus == 0
                                    ? sensorred
                                    : sensorgrey
                                }
                              >
                                <Popup>
                                  <ul>
                                    <li> Sensor Id : {item.sensorid}</li>
                                    <li>
                                      {" "}
                                      Sensor Status:{" "}
                                      {item.isactive == 1
                                        ? "Active"
                                        : "Inactive"}
                                    </li>
                                    <li> Sensor Type: {item.sensortype}</li>
                                    <li> Location: {item.location}</li>
                                    <li> Country: {item.country}</li>
                                    <li>
                                      {" "}
                                      Last status :{" "}
                                      {new Date(
                                        item.lastcommunidate
                                      ).toLocaleDateString()}
                                    </li>
                                  </ul>
                                </Popup>
                              </Marker>
                            </>
                          );
                        })}
                      </MapContainer>
                    </div>
                  </div>
                  <div
                    hidden={value !== 1}
                    role="tabpanel"
                    value={value}
                    index={1}
                  >
                    <div id="heat-map"></div>
                  </div>
                </Grid>
                <Grid item lg={3} className="map-grid">
                  <div className="sensor-box">
                    <img src={ICONS.Single_Green} width={"15%"} />
                    <p>{icount.length}</p>
                    <span>Active Sensors</span>
                  </div>
                  <div className="sensor-box">
                    <img src={ICONS.Not_Active} width={"15%"} />
                    <p>{inActive}</p>
                    <span>Inactive Sensors</span>
                  </div>
                </Grid>
              </Grid>
            </Card>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
export default SensorStatus;
