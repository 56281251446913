import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ICONS from '../../features/images';
import axios from "../../axios";
import axiosClient from "../../axios";
import {
    Button
} from "@mui/material";
import Box from '@mui/material/Box';
import { updateprofile, view_all_roles, view_organizations_list, view_profile } from "../../features/Apis/Index";
const UpdateUser = () => {
    let nav = useNavigate();
    const params = useLocation();
    const [organization, setorganization] = useState("");
    const [firstname, setfirstname] = useState("");
    const [rolename, setrolename] = useState("");
    const [status, setstatus] = useState("");
    const [lastname, setlastname] = useState("");
    const [designation, setdesignation] = useState("");
    const [mobilenum, setmobilenum] = useState("");
    const [email, setemail] = useState("");
    const [username, setusername] = useState("");
    const [password, setpassword] = useState("");
    const [showRole, setShowRole] = useState([]);
    const [errfname, seterrfname] = useState();
    const [personId, setpersonId] = useState();
    const [orgId, setorgId] = useState();
    const [roleId, setroleId] = useState();
    const [isActive, setisActive] = useState();
    const [isEnable, setisEnable] = useState();
    const [createdby, setcreatedby] = useState();
    const [modifiedby, setmodifiedby] = useState();
    useEffect(() => {
        getUserOrganization();
        getUserRole();
        viewProfile();
    }, []);

    const viewProfile = () => {
        axios.get(view_profile + params.state.userId).then((res) => {
            let userInfo = res.data.gaitObj.personIdref;
            setfirstname(userInfo.firstname);
            setlastname(userInfo.lastname);
            setdesignation(userInfo.designation);
            setmobilenum(userInfo.mobilenum);
            setemail(userInfo.email);
            setorganization(userInfo.orgidref.orgname);
            setstatus(userInfo.orgidref.isactive);
            setusername(res.data.gaitObj.username);
            setrolename(res.data.gaitObj.roleidref.rolename);
            setpersonId(userInfo.personid);
            setorgId(userInfo.orgidref.orgid);
            setroleId(res.data.gaitObj.roleidref.roleid);
            setisActive(res.data.gaitObj.isactive);
            setisEnable(res.data.gaitObj.enabled);
            setcreatedby(res.data.gaitObj.createdby);
            setmodifiedby(res.data.gaitObj.modifiedby);
            setpassword(res.data.gaitObj.password);
        });
    };
    const updateProfile = () => {
        axiosClient
            .put(updateprofile,
                {
                        "orguserid":params.state.userId,
                        "personIdref":{
                        "personid":personId,
                        "orgidref":{
                            "orgid":orgId
                        },
                        "firstname":firstname,
                        "lastname":lastname,
                        "designation":designation,
                        "mobilenum":mobilenum,
                        "email":email
                        },
                        "roleidref":{
                        "roleid":roleId
                        }
                }
            )
            .then((res) => {
                nav("/users");
            })
            .catch((e) => {
                alert(e, "getting error ");
            });
    };
const getUserOrganization = () => {
    axios.get(view_organizations_list).then(res => {
    })
}
const getUserRole = () => {
    axios.get(view_all_roles).then(res => {
        setShowRole(res.data.gaitObj);
    })
}
const d = new Date();
let time = d.getTime();
return (
    <div className="container">
        <Button
            onClick={e => {
                nav('/users')
            }} style={{
                marginLeft: "auto",
                display: "block",
                width: 140,
                background: "linear-gradient(0deg, #55AA8B, #55AA8B), #FEFEFE",
                borderRadius: 4, color: "white",
            }}
        >View All</Button>
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
        ></Box>
        <div className="row form-top">
        <div className="col-md-3 col-sm-12">
                <div className="change-profilepic">
                    <img src={ICONS.ProfileImg} width="100%" />
                </div>
            </div>
            <div className="col-md-9">
                <div className="row">
            <div className="col-md-4 col-sm-12">
                <label className="form-label">First Name</label>
                <input className="form-control from-input" type="text" value={firstname} placeholder="First Name" onChange={(e) => setfirstname(e.target.value)} disabled/>
                <p style={{ color: 'red' }}>{errfname}</p>
            </div>
            <div className="col-md-4 col-sm-12">
                <label className="form-label">Last Name</label>
                <input className="form-control from-input" type="text" value={lastname} placeholder="First Name" onChange={(e) => setlastname(e.target.value)} disabled/>
            </div>
            <div className="col-md-4 col-sm-12">
                <label className="form-label">Designation</label>
                <input className="form-control from-input" type="text" value={designation} placeholder="Designation" onChange={(e) => setdesignation(e.target.value)} />
            </div>
            <div className="col-md-4 col-sm-12">
                <label className="form-label">Mobile Number</label>
                <input className="form-control from-input" type="text" value={mobilenum} placeholder="Mobile Number" onChange={(e) => setmobilenum(e.target.value)} />
            </div>
            <div className="col-md-4 col-sm-12">
                <label className="form-label">Email</label>
                <input className="form-control from-input" type="text" value={email} placeholder="Email" onChange={(e) => setemail(e.target.value)} />
            </div>
            <div className="col-md-4 col-sm-12">
                <label className="form-label">Role</label>
                <select className="form-select"
                    onChange={(e) => setrolename(e.target.value)}
                    defaultValue=""
                >
                    {rolename != "" ? (<option>{rolename}</option>) : (
                        <option>-select-</option>
                    )}
                    {showRole?.map(val => {
                        return (<option value={val.roleid} key={val.roleid}>{val.rolename} </option>)
                    })}
                </select>
            </div>
            </div>
            </div>
            </div>
        <div className="d-flex justify-content-end bottom-button">
            <Button
                style={{
                    marginLeft: 15,
                    display: "block",
                    width: 140,
                    borderRadius: 4,
                    borderColor: "#FF6E6E",
                    borderWidth: 1,
                    color: "#FF6E6E",
                    border: "1px solid",
                    fontFamily: "Poppins",
                    fontSize: 13,
                }}
                onClick={e => {
                    nav("/users")
                }}
            >
                Cancel
            </Button>

            <Button
                onClick={(e) => updateProfile()}
                style={{
                    marginLeft: 15,
                    display: "block",
                    width: 140,
                    background: "linear-gradient(0deg, #55AA8B, #55AA8B), #FEFEFE",
                    borderRadius: 4,
                    color: "white",
                    fontFamily: "Poppins",
                    fontSize: 13,
                }}
            >
                Update
            </Button>
        </div>
    </div>
);
}

export default UpdateUser;