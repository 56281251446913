import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Formik, Form, ErrorMessage, Field } from "formik";
import * as Yup from "yup";
import { TextInput } from "../../components/TextField";
import axiosClient from "../../axios";
import { useNavigate } from "react-router-dom";
import { site_owner_types } from "../../DefaultApi";
import ICONS from "../../features/images/index";
import axios from "axios";
// import { getAllSiteTypes } from "../../features/counter/APIs";
import { connect, useSelector } from 'react-redux';
// const =
const AddProjects = (props) => {
  const nav = useNavigate();
  const token = useSelector((state) => state.loginData?.token);
  const [expanded, setExpanded] = useState(false);
  const [siteTypes, setSiteTypes] = useState([]);
  const [siteProjectCategories, setSiteProjectCategories] = useState([]);
  const [siteOwnerTypes, setSiteOwnerTypes] = useState([]);
  const [siteSoilTypes, setSiteSoilTypes] = useState([]);
  const [siteVegetationTypes, setSiteVegetationTypes] = useState([]);
  const [sitePhType, setSitePhType] = useState([]);
  const [ageOfVegetation, setAgeOfVegetation] = useState([]);
  const [checked, setChecked] = useState(false);
  const [checkedGeoPersent, setcheckedGeoPersent1] = useState(false);
  const [checkshapeFile, setCheckshapeFile] = useState(false);
  const [checksubfieldNameFile,setsubfieldNameFile] = useState();
  const [geoReferencedSitemapImg,setgeoReferencedSitemapImage] = useState();
  const [allCountryLists, setAllCountryLists] = useState();
  useEffect(() => {
    // const removeComment = () => props.getAllSiteTypes();
    // props.getAllSiteTypes()
    // .then(res =>{
    //   console.log(res , "checek prntssssssssssss")
    // })
    getSiteTypes();
    getSiteProjectCategories();
    getSiteOwnerTypes();
    getSiteVegetationTypes();
    getSiteSoilTypes();
    getSitePHTypw();
    getSiteAgeOfVegetation();
    getAllCountry();
  }, []);
  const handleChangeCheckbox = () => {
    setChecked(!checked);
  };

  const getSiteTypes = () => {
    axiosClient
      .get("v1/gait/lukup/sitetypes")
      .then((res) => {
        if (res.status === 200) {
          setSiteTypes(res);
        }
      })
      .catch((err) => {});
  };

  const getSiteOwnerTypes = () => {
    axiosClient
      .get("v1/gait/lukup/siteownertypes")
      .then((res) => {
        if (res.status === 200) {
          setSiteOwnerTypes(res);
        }
      })
      .catch((err) => {});
  };

  const getSiteProjectCategories = () => {
    axiosClient
      .get("v1/gait/lukup/prjctcategories")
      .then((res) => {
        if (res.status === 200) {
          setSiteProjectCategories(res);
        }
      })
      .catch((err) => {});
  };

  const getSiteVegetationTypes = () => {
    axiosClient
      .get("v1/gait/lukup/vegetationtypes")
      .then((res) => {
        if (res.status === 200) {
          setSiteVegetationTypes(res);
        }
      })
      .catch((err) => {});
  };

  const getSiteSoilTypes = () => {
    axiosClient
      .get("v1/gait/lukup/soiltypes")
      .then((res) => {
        if (res.status === 200) {
          setSiteSoilTypes(res);
        }
      })
      .catch((err) => {});
  };

  const getSiteAgeOfVegetation = () => {
    axiosClient
      .get("v1/gait/lukup/ageofvegetation")
      .then((res) => {
        if (res.status === 200) {
          setAgeOfVegetation(res);
        }
      })
      .catch((err) => {});
  };

  const getSitePHTypw = () => {
    axiosClient
      .get(
        "v1/gait/lukup/phtypes"
      )
      .then((res) => {
        if (res.status === 200) {
          setSitePhType(res);
        }
      })
      .catch((err) => {});
  };

  const getAllCountry = () =>{
    axiosClient.get('https://countriesnow.space/api/v0.1/countries')
    .then(res =>{
      setAllCountryLists(res);
    })
  }

  const addProjects = (val) => {
    var bodyFormData = new FormData();
    bodyFormData.append("ageOfVegetation", val.ageOfVegetation);
    bodyFormData.append("annualDeforestationRate", val.annualDeforestationRate);
    bodyFormData.append("annualNewPlantationRate", val.annualNewPlantationRate);
    bodyFormData.append("areaInHectres", Number(val.areaInHectres));
    bodyFormData.append("bulkDensity", val.bulkDensity);
    bodyFormData.append("contactPerson", val.contactPerson);
    bodyFormData.append("contactPersonAddress", val.contactPersonAddress);
    bodyFormData.append("contactPersonEmail", val.contactPersonEmail);
    bodyFormData.append("contactPersonNo", val.contactPersonNo);
    bodyFormData.append("contactPersonTitle", val.contactPersonTitle);
    bodyFormData.append("geoReferencedSitemapImage",val.geoReferencedSitemapImage);
    bodyFormData.append("geoReferencedSitemapPresent",val.geoReferencedSitemapPresent);
    bodyFormData.append("organicCarbon", val.organicCarbon);
    bodyFormData.append("organizationName", val.organizationName);
    bodyFormData.append("plantSpecies", val.plantSpecies);
    bodyFormData.append("projectCategory", val.projectCategory);
    bodyFormData.append("projectName", val.projectName);
    bodyFormData.append("shapeFileAvailable", val.shapeFileAvailable ?val.shapeFileAvailable : "" );
    bodyFormData.append("shapefile", val.shapefile);
    bodyFormData.append("siteAddress", val.siteAddress);
    bodyFormData.append("siteArea", val.siteArea);
    bodyFormData.append("siteName", val.siteName);
    bodyFormData.append("siteOwnerContactNo", val.siteOwnerContactNo);
    bodyFormData.append("siteOwnerEmail", val.siteOwnerEmail);
    bodyFormData.append("siteOwnerName", val.siteOwnerName);
    bodyFormData.append("siteOwnerType", val.siteOwnerType);
    bodyFormData.append("siteType", val.siteType);
    bodyFormData.append("soilPh", val.soilPh);
    bodyFormData.append("soilType", val.soilType);
    bodyFormData.append("subfieldNamePresent", val.subfieldNamePresent);
    bodyFormData.append("subfieldsBoundariesPresent",val.subfieldsBoundariesPresent);
    bodyFormData.append("vegetationType", val.vegetationType);
    bodyFormData.append("country", val.country);
    bodyFormData.append("status", "Pre Analysis");
    checksubfieldNameFile &&
    bodyFormData.append("subfieldNameFile", checksubfieldNameFile ? checksubfieldNameFile :"" );
    geoReferencedSitemapImg &&
    bodyFormData.append("geoReferencedSitemapImageFile",geoReferencedSitemapImg ?geoReferencedSitemapImg : "");
    // axios({
    //   method: "post",
    //   url: "http://54.206.48.250:8082/v1/gait/project/save",
    //   data: bodyFormData,
    //   headers: {
    //     "Content-Type": "multipart/form-data",
    //     "Authorization":`Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJzYW50b3NoQGdhaXRnbG9iYWwuY29tIiwiZXhwIjoxNjcxNTU4Njg3LCJpYXQiOjE2NzE1NTUwODd9.r123H6-t9HVJT6Xl6PUUHmQfCHG3Om-59dy0uEWOsqSTvuSbhxzz_fFlivkTn1VkdjZs-NhAhC0l1yqow3ssOw`
    //   },
    // })
    //   .then(function (response) {
    //     console.log(response);
    //   })
    //   .catch(function (response) {
    //     console.log(response);
    //   });
    // const formData = new FormData(form);

    axiosClient
      .post(
        "v1/gait/project/save",
        bodyFormData,
        {
          headers: {
            "Content-type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        // nav('/projects');
        if (res.status === 200) {
          setSitePhType(res);
          nav('/projects');
        }
      })
      .catch((err) => {});
  };
  const HandleRadioButton2 = (val) => {
    setCheckshapeFile(val);
  };
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const checekHandleSubfield = (e) =>{
    setsubfieldNameFile(e.target.files[0]);
  }
  const geoReferencedSitemapImageFileHandle = (e) =>{
    setgeoReferencedSitemapImage(e.target.files[0]);
  }

  const validate = Yup.object({
    // siteArea: Yup
    // .number()
    // .positive()
    // .label('site area')
    // .min(1),
    projectName : Yup.string()
      .required("Project name is require"),
      siteName:Yup.string()
      .required("Site name is required")
  });
  return (
    <>
      <Formik
        initialValues={{
          projectName:"",
          siteName:"",
          siteArea:0.0,
          siteType:0,
          projectCategory: "",
          annualDeforestationRate: "",
          annualNewPlantationRate: "",
          siteOwnerType:0 ,
          siteOwnerName:"" ,
          siteAddress: "",
          siteOwnerContactNo: "",
          siteOwnerEmail:"" ,
          organizationName: "",
          contactPerson: "",
          contactPersonTitle: "",
          contactPersonAddress:"" ,
          contactPersonNo: "",
          contactPersonEmail:"" ,
          shapefileAvailable:false ,
          shapefile:"" ,
          subfieldsBoundariesPresent:false ,
          subfieldNamePresent:false ,
          areaInHectres:0.0 ,
          geoReferencedSitemapPresent:false ,
          geoReferencedSitemapImage:"" ,
          vegetationType: 0,
          plantSpecies: "",
          ageOfVegetation: "",
          soilType: 0,
          soilPh: "",
          organicCarbon: "",
          bulkDensity: "",
          // createdAt: ,
          // createdBy: ,
          // modifiedAt: ,
          // modifiedBy: ,
          subfieldNameFile:null ,
          geoReferencedSitemapImageFile: null,
          country:"",
          status:"",
        }}
        validationSchema={validate}
        onSubmit={(values) => {
          addProjects(values);
        }}
      >
        {(formik) => (
          <Form>
            <div className="add-morebtn">
              <div className="d-flex justify-content-center">
                <div className="btnview-all">
                  <button onClick={() => nav("/projects")}>View All</button>
                </div>
                <div className="btnadd">
                  <button type="submit">Add</button>
                </div>
              </div>
            </div>
            <div className="projects-heading">
              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography sx={{ width: "33%", flexShrink: 0 }}>
                    <p className="accordion-heading">Project Info</p>
                  </Typography>
                  {/* <Typography sx={{ color: 'text.secondary' }}>I am an accordion</Typography> */}
                </AccordionSummary>
                <AccordionDetails>
                  <div className="row">
                    <div className="col-md-3 col-sm-12">
                      <TextInput
                        label="Project Name"
                        name="projectName"
                        type="text"
                        placeholder="Name"
                      />
                    </div>
                    <div className="col-md-3 col-sm-12">
                      <TextInput
                        label="Site Name"
                        name="siteName"
                        type="text"
                        placeholder="site name"
                      />
                    </div>
                    <div className="col-md-3 col-sm-12">
                      <TextInput
                        label="Site Area"
                        name="siteArea"
                        type="text"
                        placeholder="area"
                      />
                    </div>
                    <div className="col-md-3 col-sm-12">
                      <div className="d-flex justify-content-between align-item-center">
                        <div>
                          <label className="form-label">Site Type</label>
                        </div>
                        <div></div>
                      </div>
                      <Field
                        as="select"
                        name="siteType"
                        className="form-select"
                      >
                        <option>-Select-</option>
                        {siteTypes?.data?.map((val, index) => {
                          return (
                            <option value={val.sitetypeid} key={index}>
                              {val.sitetype}
                            </option>
                          );
                        })}
                      </Field>
                      <ErrorMessage
                        name="siteType"
                        render={(msg) => (
                          <div className="error">
                            &nbsp;Please select site type{" "}
                          </div>
                        )}
                      />
                    </div>

                    <div className="col-md-3 col-sm-12">
                      <div className="d-flex justify-content-between align-item-center">
                        <div>
                          <label className="form-label">Project Category</label>
                        </div>
                        <div></div>
                      </div>
                      <Field
                        as="select"
                        name="projectCategory"
                        className="form-select"
                      >
                        <option>-Select-</option>
                        {siteProjectCategories.data?.map((val, index) => {
                          return (
                            <option value={val.project_type} key={index}>
                              {val.project_type}
                            </option>
                          );
                        })}
                      </Field>
                      <ErrorMessage
                        name="siteType"
                        render={(msg) => (
                          <div className="error">
                            &nbsp;Please select site type{" "}
                          </div>
                        )}
                      />
                    </div>
                    <div className="col-md-3 col-sm-12">
                      <TextInput
                        label="Annual Deforestation Rate "
                        name="annualDeforestationRate"
                        type="text"
                        placeholder="annual deforestation rate"
                      />
                      <p className="if-applicable">(if applicable)</p>
                    </div>

                    <div className="col-md-3 col-sm-12">
                      <TextInput
                        label="Annual New Plantation Rate"
                        name="annualNewPlantationRate"
                        type="text"
                        placeholder="comm. frequency"
                      />
                      <p className="if-applicable">(if applicable)</p>
                    </div>
                    <div className="col-md-3 col-sm-12">
                      <div className="d-flex justify-content-between align-item-center">
                        <div>
                          <label className="form-label">Country</label>
                        </div>
                        <div></div>
                      </div>
                      <Field
                        as="select"
                        name="country"
                        className="form-select"
                      >
                      
                        <option>-Select-</option>
                        {allCountryLists?.data?.data?.map((val, index) => {
                          return (
                            <option value={val.country} key={index}>
                              {val.country}
                            </option>
                          );
                        })}
                      </Field>
                      <ErrorMessage
                        name="siteType"
                        render={(msg) => (
                          <div className="error">
                            &nbsp;Please select site type{" "}
                          </div>
                        )}
                      />
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel2"}
                onChange={handleChange("panel2")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2bh-content"
                  id="panel2bh-header"
                >
                  <Typography sx={{ width: "33%", flexShrink: 0 }}>
                    <p className="accordion-heading">Site Owner</p>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="row">
                    <div className="col-md-3 col-sm-12">
                      <div className="d-flex justify-content-between align-item-center">
                        <div>
                          <label className="form-label">Site Owner Type</label>
                        </div>
                        <div></div>
                      </div>
                      <Field
                        as="select"
                        name="siteOwnerType"
                        className="form-select"
                      >
                        <option>-Select-</option>
                        {siteOwnerTypes?.data?.map((val, index) => {
                          return (
                            <option value={val.id} key={index}>
                              {val.site_owner_type}
                            </option>
                          );
                        })}
                      </Field>
                      <ErrorMessage
                        name="siteType"
                        render={(msg) => (
                          <div className="error">
                            &nbsp;Please select site type{" "}
                          </div>
                        )}
                      />
                    </div>
                    <div className="col-md-3 col-sm-12">
                      <TextInput
                        label="Site Owner Name"
                        name="siteOwnerName"
                        type="text"
                        placeholder="name"
                      />
                    </div>
                    <div className="col-md-3 col-sm-12">
                      <TextInput
                        label="Site Owner Contact No"
                        name="siteOwnerContactNo"
                        type="text"
                        placeholder="Site Owner Contact No"
                      />
                    </div>

                    <div className="col-md-3 col-sm-12">
                      <TextInput
                        label="Site Owner Email"
                        name="siteOwnerEmail"
                        type="text"
                        placeholder="email"
                      />
                    </div>

                    <div className="col-md-9 col-sm-12">
                      <TextInput
                        label="Address"
                        name="siteAddress"
                        type="text"
                        placeholder="Address"
                      />
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel3"}
                onChange={handleChange("panel3")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3bh-content"
                  id="panel3bh-header"
                >
                  <Typography sx={{ width: "33%", flexShrink: 0 }}>
                    <p className="accordion-heading">Project Proponent</p>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="row">
                    <div className="col-md-3 col-sm-12">
                      <TextInput
                        label="Organization name"
                        name="organizationName"
                        type="text"
                        placeholder="Organization name"
                      />
                    </div>
                    <div className="col-md-3 col-sm-12">
                      <TextInput
                        label="Contact person"
                        name="contactPerson"
                        type="text"
                        placeholder="Contact person"
                      />
                    </div>
                    <div className="col-md-3 col-sm-12">
                      <TextInput
                        label="Title"
                        name="contactPersonTitle"
                        type="text"
                        placeholder="title"
                      />
                    </div>
                    <div className="col-md-3 col-sm-12">
                      <TextInput
                        label="Contact No"
                        name="contactPersonNo"
                        type="text"
                        placeholder="ph number"
                      />
                    </div>

                    <div className="col-md-3 col-sm-12">
                      <TextInput
                        label="Email"
                        name="contactPersonEmail"
                        type="text"
                        placeholder="email"
                      />
                    </div>

                    <div className="col-md-9 col-sm-12">
                      <TextInput
                        label="Address"
                        name="contactPersonAddress"
                        type="text"
                        placeholder="Address"
                      />
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel4"}
                onChange={handleChange("panel4")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel4bh-content"
                  id="panel4bh-header"
                >
                  <Typography sx={{ width: "33%", flexShrink: 0 }}>
                    <p className="accordion-heading">Shape File</p>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="row">
                    <div className="col-md-3 col-sm-12">
                      <TextInput
                        label="Area (in hectares)*"
                        name="areaInHectres"
                        type="text"
                        placeholder="Area (in hectares)"
                      />
                    </div>
                    <div className="col-md-4   col-sm-12">
                      <div className="sub-fieldcheck">
                        <p>Is shape is available</p>
                        <div className="d-flex justify-content-between">
                          <div>
                            <label>
                              <Field
                                type="radio"
                                name="shapeFileAvailable"
                                value="true"
                                onClick={() => HandleRadioButton2(true)}
                              />
                              Available
                            </label>
                          </div>
                          <div>
                            <label>
                              <Field
                                type="radio"
                                name="shapeFileAvailable"
                                value="false"
                                onClick={() => HandleRadioButton2(false)}
                              />
                              Not Available
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    {checkshapeFile && (
                      <>
                        <div className="col-md-3 col-sm-12">
                          
                          <div class="file-input">
                          <label>Upload Shape file</label>
                            <input
                              label={"Upload Shape file"}
                              id="file-input"
                              type="file"
                              name="subfieldNameFile"
                              className="file-input__input"
                              onChange={checekHandleSubfield}
                            />
                            <label class="file-input__label" for="file-input">
                              <img
                                src={ICONS.imageUplodIco}
                                style={{ margin: "auto" }}
                              />
                            </label>
                          </div>
                        </div>
                        <div className="col-md-4   col-sm-12">
                          <div className="sub-fieldcheck">
                            <p>Sub fields boundaries present</p>
                            <div className="d-flex justify-content-between">
                              <label>
                                <Field
                                  type="radio"
                                  name="subfieldsBoundariesPresent"
                                  value="true"
                                />
                                Available
                              </label>
                              <label>
                                <Field
                                  type="radio"
                                  name="subfieldsBoundariesPresent"
                                  value="false"
                                />
                                Not Available
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4   col-sm-12">
                          <div className="sub-fieldcheck">
                            <p>Sub field Name/Label present</p>
                            <div className="d-flex justify-content-between">
                              <label>
                                <Field
                                  type="radio"
                                  name="subfieldNamePresent"
                                  value="true"
                                />
                                Available
                              </label>
                              <label>
                                <Field
                                  type="radio"
                                  name="subfieldNamePresent"
                                  value="false"
                                />
                                Not Available
                              </label>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === "panel5"}
                onChange={handleChange("panel5")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel5bh-content"
                  id="panel5bh-header"
                >
                  <Typography sx={{ width: "33%", flexShrink: 0 }}>
                    <p className="accordion-heading">Geo Reference Map</p>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="row">
                    {/* <div className="col-md-3 col-sm-12">
                      <TextInput
                        label="Geo referenced site map image"
                        name="geoReferencedSitemapImage"
                        type="text"
                        placeholder="Geo referenced site map image"
                      />
                    </div> */}
                    <div className="col-md-4 col-sm-12">
                      <div className="sub-fieldcheck">
                        <p>Geo referenced site map present</p>
                        <div className="d-flex justify-content-between">
                          <div>
                            <label>
                              <Field
                                type="radio"
                                name="geoReferencedSitemapPresent"
                                value="true"                               
                                onClick={() => setcheckedGeoPersent1(true)}
                              />
                              Available
                            </label>
                          </div>
                          <div>
                            <label>
                              <Field
                                type="radio"
                                name="geoReferencedSitemapPresent"
                                value="false"
                                onClick={() => setcheckedGeoPersent1(false)}
                              />
                              Not Available
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    {checkedGeoPersent && (
                      <div className="col-md-3 col-sm-12">
                        <div class="file-input">
                          {/* <TextInput
                            label={"Upload Site file"}
                            id="file-input"
                            type="file"
                            name="geoReferencedSitemapImageFile"
                            className="file-input__input"
                          /> */}
                          <label>Upload Shape file</label>
                           <input
                              label={"Upload Site file"}
                              id="file-input1"
                              type="file"
                              name="geoReferencedSitemapImageFile"
                              className="file-input__input"
                              onChange={geoReferencedSitemapImageFileHandle}
                            />
                          <label class="file-input__label" for="file-input1">
                            <img
                              src={ICONS?.imageUplodIco}
                              style={{ margin: "auto" }}
                            />
                          </label>
                        </div>
                      </div>
                    )}
                  </div>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === "panel6"}
                onChange={handleChange("panel6")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel6bh-content"
                  id="panel6bh-header"
                >
                  <Typography sx={{ width: "33%", flexShrink: 0 }}>
                    <p className="accordion-heading">Vegetation Info</p>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="row">
                    <div className="col-md-3 col-sm-12">
                      <div className="d-flex justify-content-between align-item-center">
                        <div>
                          <label className="form-label">Vegetation Type</label>
                        </div>
                        <div></div>
                      </div>
                      <Field
                        as="select"
                        name="vegetationType"
                        className="form-select"
                      >
                        <option>-Select-</option>
                        {siteVegetationTypes?.data?.map((val, index) => {
                          return (
                            <option value={val.vegitaiontypeid} key={index}>
                              {val.vegitationtype}
                            </option>
                          );
                        })}
                      </Field>
                      <ErrorMessage
                        name="siteType"
                        render={(msg) => (
                          <div className="error">
                            &nbsp;Please select site type{" "}
                          </div>
                        )}
                      />
                    </div>
                    <div className="col-md-3 col-sm-12">
                      <TextInput
                        label="Plant Species"
                        name="plantSpecies"
                        type="text"
                        placeholder="Plant Species"
                      />
                    </div>
                    <div className="col-md-3 col-sm-12">
                      <div className="d-flex justify-content-between align-item-center">
                        <div>
                          <label className="form-label">
                            Age of vegetation
                          </label>
                        </div>
                        <div></div>
                      </div>
                      <Field
                        as="select"
                        name="ageOfVegetation"
                        className="form-select"
                      >
                        <option>-Select-</option>
                        {ageOfVegetation?.data?.map((val, index) => {
                          return (
                            <option value={val.id} key={index}>
                              {val.vegetation_age}
                            </option>
                          );
                        })}
                      </Field>
                      <ErrorMessage
                        name="siteType"
                        render={(msg) => (
                          <div className="error">
                            &nbsp;Please select site type{" "}
                          </div>
                        )}
                      />
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === "panel7"}
                onChange={handleChange("panel7")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel7bh-content"
                  id="panel7bh-header"
                >
                  <Typography sx={{ width: "33%", flexShrink: 0 }}>
                    <p className="accordion-heading">Soil Information</p>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="row">
                    <div className="col-md-3 col-sm-12">
                      <div className="d-flex justify-content-between align-item-center">
                        <div>
                          <label className="form-label">Soil Type</label>
                        </div>
                        <div></div>
                      </div>
                      <Field
                        as="select"
                        name="soilType"
                        className="form-select"
                      >
                        <option>-Select-</option>
                        {siteSoilTypes?.data?.map((val, index) => {
                          return (
                            <option value={val.soiltypeid} key={index}>
                              {val.soiltype}
                            </option>
                          );
                        })}
                      </Field>
                      <ErrorMessage
                        name="siteType"
                        render={(msg) => (
                          <div className="error">
                            &nbsp;Please select site type{" "}
                          </div>
                        )}
                      />
                    </div>
                    <div className="col-md-3 col-sm-12">
                      <div className="d-flex justify-content-between align-item-center">
                        <div>
                          <label className="form-label">PH Type</label>
                        </div>
                        <div></div>
                      </div>
                      <Field as="select" name="soilPh" className="form-select">
                        <option>-Select-</option>
                        {sitePhType?.data?.map((val, index) => {
                          return (
                            <option value={val.id} key={index}>
                              {val.soil_ph_type}
                            </option>
                          );
                        })}
                      </Field>
                      <ErrorMessage
                        name="siteType"
                        render={(msg) => (
                          <div className="error">
                            &nbsp;Please select site type{" "}
                          </div>
                        )}
                      />
                    </div>
                    <div className="col-md-3 col-sm-12">
                      <TextInput
                        label="Organic Carbon"
                        name="organicCarbon"
                        type="text"
                        placeholder="Organic Carbon"
                      />
                    </div>
                    <div className="col-md-3 col-sm-12">
                      <TextInput
                        label="Bulk Density"
                        name="bulkDensity"
                        type="text"
                        placeholder="Bulk Density"
                      />
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};
export default AddProjects;
// const mapDispatchToProps = {
//   getAllSiteTypes: getAllSiteTypes,
// }

// export default connect(null, mapDispatchToProps)(AddProjects);
