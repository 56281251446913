import { Button } from "@mui/material";
import React, { useEffect } from "react";
import { Chart as ChartJS } from "chart.js";
import {
  getAllSites,
  getSite,
  getSiteCarbonByYear,
  getSiteCarbonBySource,
} from "../../features/counter/APIs";
import { Line } from "react-chartjs-2";
import { connect, useSelector } from "react-redux";
import ICONS from '../../features/images'
import { useLocation } from "react-router-dom";
import { MapContainer, Marker, GeoJSON, Popup, TileLayer } from "react-leaflet";
import axios from "axios";
import axiosClient from "../../axios";
import L from "leaflet";
import { useState } from "react";
import Moment from "react-moment";
import {
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  ArcElement,
} from "chart.js";
import Box from "@mui/material/Box";
import dayjs from "dayjs";
import { PDFExport } from '@progress/kendo-react-pdf';
ChartJS.register(
  ArcElement,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}
const ress = [{ name: "1" }, { name: "2" }, { name: "3" }];
const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];
let d = new Date();
const labels = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

const ref = React.createRef();
let currentApiList = "";
const SequestrationdailyCustom = (props) => {
  const pdfoptions = {
    orientation: "portrait",
    unit: "in",
    format: [20, 13.5],
  };

  const [value, setValue] = React.useState(0);
  const [bgColor, setBgColor] = React.useState("#55AA8B");
  const sensorgrey = L.icon({ iconUrl: ICONS.sgrey, iconSize: 25 });
  const sensorgreen = L.icon({ iconUrl: ICONS.sgreen, iconSize: 25 });
  const sensorred = L.icon({ iconUrl: ICONS.sred, iconSize: 25 });
  const [loading, setLoading] = React.useState(false);
  const [geoJSONObj, setgeoJSONObj] = React.useState(null);
  const [permission, setPermission] = React.useState(false);
  const [barChartData, setChartData] = React.useState({
    labels: labels,
    datasets: [
      {
        type: "line",
        label: "Dataset 1",
        borderColor: "#259A6C",
        borderWidth: 2,
        fill: false,
        data: [123, 234, 434, 345, 45, 34, 232],
        borderWidth: 1,
      },
      {
        type: "bar",
        label: "",
        borderWidth: 1,
        data: [123, 234, 244, 345, 45, 34, 232],
      },
    ],
  });
  const [open, setOpen] = React.useState(false);
  const [siteName, setSiteName] = useState("");
  const [siteLet, setSiteLat] = useState("");
  const [siteLang, setSiteLang] = useState("");
  const [siteSensorCount, setSiteSensorCount] = useState("");
  const [siteAreaCovered, setSiteAreaCovered] = useState("");
  const [siteCountry, setSiteCountry] = useState("");
  const [installedDate, setInstalledDate] = useState("");
  const [getSiteRecord, setGetSiteRecord] = useState("");
  const [totalCatbon, setTotalCarbon] = useState("");
  const [dailyNetCarbon, setDailyNetCarbon] = useState([]);
  const [dailyNetCarbonVal, setDailyNetCarbonVal] = useState([]);
  const [maxNetcarbon,setMaxNetcarbon] = useState("");
  const [minNetcarbon,setMinNetcarbon] = useState("");
  const [totalEmission,setTotalEmission] = useState("");
  const [maxValueDate,setMaxValueDate]  = useState("");
  const [minValueDate,setMinValueDate] = useState("");
  let location = useLocation();

  const sites = useSelector((state) => state.sites?.gaitObj);
  const currentSite = useSelector((state) => state.currentSite?.gaitObj);
  const sensorTable = useSelector((state) => state.sensorTable?.gaitObj);
  const siteCarbon = useSelector((state) => state.siteCarbon?.gaitObj);

  const handleClick = () => {
    setTimeout(() => {
      let loginStatus = localStorage.getItem("status");
      if (loginStatus === "Shown") {
        setOpen(false);
      } else {
        setOpen(true);
      }
    }, 1000);
  };

  const user = useSelector((state) => state.loginData?.user);
  const getSensorTable = () => {
    axiosClient
      .get(
        `v1/gait/site/view_site_sensors_list?siteId=${location.state.siteId}`
      )
      .then((res) => {
        let siteInfo = res.data.gaitObj;
        if (res) {
          setSiteName(siteInfo?.[0]?.sitename);
          setSiteLat(siteInfo?.[0]?.latitude);
          setSiteLang(siteInfo?.[0]?.longitude);
          setSiteCountry(siteInfo?.[0]?.country);
          setSiteAreaCovered(siteInfo?.[0]?.area);
          setInstalledDate(siteInfo?.[0]?.installeddate);
        }
      });
  };
  const getSite = (siteId) => {
    axiosClient
      .get(`v1/gait/site/view_site_info?siteId=${siteId}`)
      .then((res) => {
        if (res.data != "" && res.data != null) {
          if (res.data.gaitObj.shapefile != "NA") {
            axios.get(res.data.gaitObj.shapefile).then((resp) => {
              setgeoJSONObj(resp.data);
            });
          }
            setGetSiteRecord(res.data.gaitObj);
            setSiteSensorCount(res?.data?.gaitObj?.sensorcount);
      
        }
      });
  };
  useEffect(() => {
    getSensorTable();
    if (siteCarbon) {
      let satelliteData = [];
      siteCarbon?.map((val) => {
        if (val.source == "Satellite") {
          satelliteData.push({ x: labels[val.month - 1], y: val.totalcarbon });
        }
      });

      let sensorData = [];
      siteCarbon?.map((val) => {
        if (val.source == "Sensor") {
          sensorData.push({ x: labels[val.month - 1], y: val.totalcarbon });
        }
      });
      let temp = {
        labels: labels,
        datasets: [
          {
            type: "line",
            label: "Soil Carbon",
            borderColor: "#259A6C",
            backgroundColor: "transparent",
            borderWidth: 2,
            fill: false,
            data: sensorData,
          },
          {
            type: "bar",
            label: "",
            borderColor: "#259A6C",
            backgroundColor: "#259A6C",
            data: sensorData,
          },
          {
            type: "bar",
            label: "",
            borderColor: "#75f0c0",
            backgroundColor: "#75f0c0",
            data: satelliteData,
          },
        ],
      };
      setChartData(temp);
    }

    if (siteCarbon) {
      let satelliteData = [];

      siteCarbon.map((val) => {
        if (val.source == "Satellite") {
          satelliteData.push({ x: labels[val.month - 1], y: val.totalcarbon });
        }
      });

      let sensorData = [];
      siteCarbon.map((val) => {
        if (val.source == "Sensor") {
          sensorData.push({ x: labels[val.month - 1], y: val.totalcarbon });
        }
      });
    }
    props.getAllSites(
      user?.personidref?.orgidref?.orgid,
      user?.personidref?.orgidref?.orgcode
    );
    handleClick();
      props.getCurrentSite(location.state.siteId);
    getSite(location.state.siteId);
  }, []);
  useEffect(()=>{
    getDailyNetCarbonval();
  },[])
  const getDailyNetCarbonval = () => {
    setLoading(true);
    currentApiList = `v1/gait/site/view_site_carbon_info_by_date_range?siteId=${location.state.siteId}&fromDate=${location.state.fromDate}&toDate=${location.state.toDate}`;
    axiosClient.get(currentApiList).then((res) => {
      setDailyNetCarbonVal(res?.data?.gaitObj);
      const graphData = res?.data?.gaitObj;
      let newGraphArr = [];
      let graphSensor = graphData.map((val , index) =>{
          if(val.source === "Sensor"){
            newGraphArr.push(val);
          }
      })
      let netCarbon = newGraphArr?.map((val, index) => {
        return newGraphArr[index].netcarbon;
      });
      let carbonCount = 0;
      newGraphArr?.map((val, index) => {
        carbonCount += val.netcarbon;
      });
      setTotalCarbon(carbonCount);
      const max = Math.max(...netCarbon);
      const min = Math.min(...netCarbon);
      setMaxNetcarbon(max);
      setMinNetcarbon(min);
       
        newGraphArr?.map((val,index)=>{
          if(Number(graphData[index].netcarbon) === Number(max)){
            setMaxValueDate(val);
          }
      });
        newGraphArr?.map((val,index)=>{
          if(Number(graphData[index].netcarbon) === Number(min)){
            setMinValueDate(val);
          }
      })
      let totalEmission = 0;
      newGraphArr?.map((val, index) => {
        carbonCount += val.emission;
      });
      setTotalEmission(totalEmission);
       let DateTime = newGraphArr?.map((val, index) => {
          return new dayjs(newGraphArr[index].datetime).format("DD/MM/YYYY");
        });
      var wind_x = {
        datasets: [
          {
            backgroundColor: "#55aa8b",
            borderColor: "#55aa8b",
            fill: false,
            data: netCarbon,
            id: "carbon",
            label: "Daily Carbon",
            yAxisID: "left",
          },
        ],
        labels: DateTime,
      };
      setDailyNetCarbon(wind_x);
      setLoading(false);
    });
  };
  React.useEffect(() => {
    setTimeout(() => {
      setPermission(true);
    }, 3000);
  }, []);
  var windOptions = {
    elements: {
      rectangle: {
        borderWidth: 2,
      },
    },
    layout: {
      padding: 0,
    },
    legend: {
      display: false,
      labels: {
        boxWidth: 16,
      },
    },
    maintainAspectRatio: false,
    responsive: true,

    title: {
      display: false,
    },
    tooltips: {
      intersect: false,
      mode: "index",
      position: "nearest",
      callbacks: {},
    },
    scales: {
      y: {
        display: false,
      },
      x: {
        display: true,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    xAxes: [
      {
        display: false,
        position: "bottom",
        ticks: {
          autoSkip: false,
          maxRotation: 90,
          minRotation: 90,
        },
      },
    ],
  };
  let listRes = [];
  const pdfExportComponent = React.useRef(null);
  return (
    <>
      {loading ? (
        <div>
          <img
            src={ICONS.SignalGreen}
            style={{
              width: "5%",
              position: "absolute",
              top: "50%",
              left: "50%",
            }}
          />
        </div>
      ) : (
        <PDFExport paperSize="A2" ref={pdfExportComponent} fileName={`${siteName}_${siteCountry}${Math.floor(Math.random() * 1000)}_Custom.pdf`}>
        <div className="App">
          <div ref={ref} style={{ width: "98%", padding: 20 }}>
            <div className="d-flex justify-content-between flex-wrap">
              <div className="main-logo">
                <div className="mylogo">
                  <img src={ICONS.MyLogo} />
                </div>
              </div>
              
              <div className="mylogo">
                <h2>Daily Carbon Sequestration Report</h2>
              </div>
              <div className="pdf-btn">
                <div className="example-config">
        <Button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={() => {
        if (pdfExportComponent.current) {
          pdfExportComponent.current.save();
        }
      }}  style={{
        marginLeft: 15,
        display: "block",
        width: 140,
        background:
          "linear-gradient(0deg, #55AA8B, #55AA8B), #FEFEFE",
        borderRadius: 4,
        color: "white",
        fontFamily: "Poppins",
        fontSize: 13,
        margin: "auto",
      }}>
          Download PDF
        </Button>
      </div>
      </div>
            </div>

            <div className="d-flex justify-content-between flex-wrap top-barinfo">
              <div>
                <div className="d-flex justify-content-between align-items-center">
                  <img src={ICONS.Location_G1} />
                  <div className="top-headerbar">
                    <p>Site </p>
                    <span>
                      {siteName}, {siteCountry}
                    </span>
                  </div>
                </div>
              </div>
              <div>
                <div className="d-flex justify-content-between align-items-center">
                  <img src={ICONS.Lan_lat_G3} />
                  <div className="top-headerbar">
                    <p>Lat - Long </p>
                    <span>
                      {siteLet} , {siteLang}
                    </span>
                  </div>
                </div>
              </div>
              <div>
                <div className="d-flex justify-content-between align-items-center">
                  <img src={ICONS.Sensor_G4} />
                  <div className="top-headerbar">
                    <p>Sensor</p>
                    <span>{siteSensorCount}</span>
                  </div>
                </div>
              </div>
              <div>
                <div className="d-flex justify-content-between align-items-center">
                  <img src={ICONS.calender_G2} />
                  <div className="top-headerbar">
                    <p>Installed Date </p>
                    <span>
                      <Moment format="YYYY/MM/DD">{installedDate}</Moment>
                    </span>
                  </div>
                </div>
              </div>
              <div>
                <div className="d-flex justify-content-between align-items-center">
                  <img src={ICONS.Area_G5} />
                  <div className="top-headerbar">
                    <p>Area Covered </p>
                    <span>{siteAreaCovered} Ha</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-7 col-sm-12">
              {dailyNetCarbonVal.length != "" ?(
              <div style={{paddingTop:55}}>
                  <div className="d-flex justify-content-start align-items-center">
                    <div className="daily-carboncount">
                      <h2>Total Carbon:</h2>
                    </div>
                    <div className="daily-carboncount">
                      <p>{(totalCatbon / 1000).toFixed(1)} Tonnes</p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-start align-items-center">
                    <div className="daily-carboncount">
                      <h2> Average:</h2>
                    </div>
                    <div className="daily-carboncount">
                      <p>{((totalCatbon/dailyNetCarbonVal.length-1)/1000).toFixed(1)} Tonnes</p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-start align-items-center">
                    <div className="daily-carboncount">
                      <h2> Min:</h2>
                    </div>
                    <div className="daily-carboncount">
                      <p>{((minNetcarbon)/1000).toFixed(1)} Tonnes <span style={{color:'#9e9595',fontSize:20,fontWeight:500}}>({dayjs(minValueDate.datetime).format('YYYY/MM/DD ')})</span></p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-start align-items-center">
                    <div className="daily-carboncount">
                      <h2>Daily Max:</h2>
                    </div>
                    <div className="daily-carboncount">
                      <p>{((maxNetcarbon)/1000).toFixed(1)} Tonnes <span style={{color:'#9e9595',fontSize:20,fontWeight:500}}>({dayjs(maxValueDate.datetime).format('YYYY/MM/DD ')})</span></p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-start align-items-center">
                    <div className="daily-carboncount">
                      <h2>Daily Average/Ha:</h2>
                    </div>
                    <div className="daily-carboncount">
                      <p>{(((totalCatbon/dailyNetCarbonVal.length-1)/siteAreaCovered)/1000).toFixed(1)} Tonnes <span style={{color:'#9e9595',fontSize:20,fontWeight:500}}>({dayjs(maxValueDate.datetime).format('YYYY/MM/DD ')})</span></p>
                    </div>
                  </div>
                </div>
):null}
              </div>
              <div className="col-md-5 col-sm-12">
                <div className="top-heading">
                  <h2 style={{ paddingBottom: 7 }}>Site Map</h2>
                </div>
                <div style={{ height: 250 }}>
                  {geoJSONObj && (
                    <MapContainer
                      key={Math.random()}
                      center={[
                        currentSite.sitelatitude,
                        currentSite.sitelogitude,
                      ]}
                      zoom={13}
                      trackResize={true}
                      scrollWheelZoom={false}
                      style={{
                        height: "100%",
                      }}
                    >
                      <>
                        <TileLayer
                    key={Math.random()}
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
                  />
                        <GeoJSON
                          key={Math.random()}
                          data={geoJSONObj}
                          color={bgColor ? bgColor : "green"}
                        >
                          <Popup>
                            <ul style={{ listStyle: "none" }}>
                              <li>
                                <b>Site info</b>
                              </li>
                              <li>
                                <a>
                                  <b>Site ID :</b> {currentSite.siteid}
                                </a>
                              </li>
                              <li>
                                <b>Site name :</b>
                                {currentSite.sitename}
                              </li>
                              <li>
                                <b>Total Area :</b>
                                {currentSite.totalarea}
                                {"Ha "}
                              </li>
                              <li>
                                <b>Elevation :</b>
                                {currentSite.elevation}
                              </li>
                              <li>
                                <b>Location:</b> {currentSite.location}
                              </li>
                              <li>
                                <b>Country:</b> {currentSite.country}
                              </li>
                              <li>
                                <b>Activation date :</b>{" "}
                                {new Date(
                                  currentSite.activationdate
                                ).toLocaleDateString()}
                              </li>
                              <li>
                                <b>No. Of installations :</b>{" "}
                                {currentSite.sensorcount}
                              </li>
                              <li>
                                <b>Site Type:</b> {currentSite.sitetype}
                              </li>
                              <li>
                                <b>Carbon Type:</b> {currentSite.carbontype}
                              </li>
                              <li>
                                <b>Site owner :</b> {currentSite.owner}
                              </li>
                              <li>
                                <b>Contact no :</b> {currentSite.ownercontact}
                              </li>
                            </ul>
                          </Popup>
                        </GeoJSON>
                        {sensorTable && (
                          <Marker
                            position={[
                              Number(sensorTable[0]?.latitude),
                              Number(sensorTable[0]?.longitude),
                            ]}
                            icon={
                              sensorTable[0]?.isactive == 1 &&
                              sensorTable[0]?.livestatus == 1
                                ? sensorgreen
                                : sensorTable[0]?.isactive == 1 &&
                                  sensorTable[0]?.livestatus == 0
                                ? sensorred
                                : sensorgrey
                            }
                          >
                            <Popup>
                              <ul>
                                <li> Sensor Id : {sensorTable[0]?.sensorid}</li>
                                <li>
                                  {" "}
                                  Sensor Status:{" "}
                                  {sensorTable[0]?.livestatus == 1
                                    ? "Active"
                                    : "Inactive"}
                                </li>
                                <li>
                                  {" "}
                                  Sensor Type: {sensorTable[0]?.sensortype}
                                </li>
                                <li> Location: {sensorTable[0]?.location}</li>
                                <li> Country: {sensorTable[0]?.country}</li>
                                <li>
                                  {" "}
                                  Last status :{" "}
                                  {new Date(
                                    sensorTable[0]?.lastcommunidate
                                  ).toLocaleDateString()}
                                </li>
                              </ul>
                            </Popup>
                          </Marker>
                        )}
                      </>
                    </MapContainer>
                  )}
                </div>
              </div>
            </div>
            <div
              hidden={value !== 0}
              role="tabpanel"
              value={value}
              index={0}
              style={{ marginTop: 50 }}
            >
              <Box sx={{ height: 300, width: "100%", marginLeft: -2 }}>
              <div className="top-heading">
                <h2>Daily Carbon Sequestration Trend</h2>
                </div>
                {permission === true ? (
                  <>
                   <span>Kgs</span>
                    <Line options={windOptions} data={dailyNetCarbon} />
                  </>
                ) : null}
              </Box>
            </div>
            <div className="report-name">
              <h2>Daily Sequestration Report</h2>

              <table className="table watermark">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Carbon Sequestration (In Kgs)</th>
                    <th>Change %</th>
                  </tr>
                </thead>
                <tbody>
                  {dailyNetCarbonVal?.map((row, index) => {
                    let listLm = null;
                    {
                      listLm = (
                        ((dailyNetCarbonVal?.[index + 1]?.netcarbon -
                          row?.netcarbon) /
                          row?.netcarbon) *
                        100
                      ).toFixed(1);
                      listRes.push(listLm);
                    }
                    return (
                      <>
                        {row.source === "Sensor" ? (
                          <tr>
                            <td>{dayjs(row.datetime).format("DD/MM/YYYY")}</td>
                            <td>{row.netcarbon.toFixed(1)}</td>
                            <td>
                              {isFinite(listRes[index - 1]) ? (
                                listRes[index - 1] > 0 ? (
                                  <>
                                    <img src={ICONS.Increase} width={10} />{" "}
                                    {listRes[index - 1]} %
                                  </>
                                ) : (
                                  <>
                                    <img src={ICONS.Decrease} width={10} />{" "}
                                    {listRes[index - 1]} %
                                  </>
                                )
                              ) : (
                                "0 %"
                              )}
                            </td>
                          </tr>
                        ) : null}
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        </PDFExport>
      )}
    </>
  );
};
const mapDispatchToProps = {
  getAllSites: getAllSites,
  getCurrentSite: getSite,
  getSiteCarbonByYear: getSiteCarbonByYear,
  getSiteCarbonBySource: getSiteCarbonBySource,
};
export default connect(null, mapDispatchToProps)(React.memo(SequestrationdailyCustom));
// export default GetSiteReport;
