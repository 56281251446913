import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import { persistor, store } from './store/store';
import { CookiesProvider } from "react-cookie";

import { PersistGate } from 'redux-persist/integration/react'

const container = document.getElementById('root');
const root = createRoot(container);



root.render(
    <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
            <CookiesProvider>
      <App />
      </CookiesProvider>
      </PersistGate>
    </Provider>
);

