import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Alert,
  Button,
  FormControlLabel,
  Menu,
  MenuItem,
  Snackbar,
  Switch,
} from "@mui/material";
import { useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from '@mui/icons-material/Search';
import axiosClient from "../../axios";
import { useEffect } from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import ICONS from '../../features/images/index';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
const rows = [
  {
    id: 1,
    s_name: "site name",
    p_name: "project_name",
    o_name: "owner name",
    org_name: "GAIT",
    s_file: "shape file",
    s_img: "imgg",
  },
  {
    id: 1,
    s_name: "site name",
    p_name: "project_name",
    o_name: "owner name",
    org_name: "GAIT",
    s_file: "shape file",
    s_img: "imgg",
  },
  {
    id: 1,
    s_name: "site name",
    p_name: "project_name",
    o_name: "owner name",
    org_name: "GAIT",
    s_file: "shape file",
    s_img: "imgg",
  },
  {
    id: 1,
    s_name: "site name",
    p_name: "project_name",
    o_name: "owner name",
    org_name: "GAIT",
    s_file: "shape file",
    s_img: "imgg",
  },
  {
    id: 1,
    s_name: "site name",
    p_name: "project_name",
    o_name: "owner name",
    org_name: "GAIT",
    s_file: "shape file",
    s_img: "imgg",
  },
  {
    id: 1,
    s_name: "site name",
    p_name: "project_name",
    o_name: "owner name",
    org_name: "GAIT",
    s_file: "shape file",
    s_img: "imgg",
  },
];
function ProjectsLists(props) {
  const nav = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [projectsLists , setProjectsLists] = useState([]);
  const [open, setOpen] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [ projectId, setProjectId] = useState();

  const handleClickOpenAlert = (pId) => {
    setProjectId(pId);
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    deleteProjects(projectId);
    setOpenAlert(false);
  };
  const handleCloseAlertNo = () =>{
    setOpenAlert(false);
  }
  useEffect(() =>{
    viewAllProjects();
  },[])
  const columns = [
    {
      field: "projectName",
      headerName: "Project Name",
      flex: 1,
      minWidth: 30,
    },
    {
      field: "siteName",
      headerName: "Site Name",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "siteOwnerName",
      headerName: "Owner Name ",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "organizationName",
      headerName: "Organization ",
      minWidth: 100,
    },
    {
      field: "shapefile",
      headerName: "Shape File",
      flex: 1,
      minWidth: 100,
      renderCell: (val) => (
        <p>{val.row.shapefile !== null ? <span className='shape_filetrue'>True</span> : <span className='shape_filefalse'>False</span>}</p>        
      )
    },
    {
      field: "geoReferencedSitemapPresent",
      headerName: "Site Image",
      flex: 1,
      minWidth: 100,
       renderCell: (val) => (
      //   <p>{val.row.geoReferencedSitemapPresent ? 'true' : 'false'}</p>     
      <p>{val.row.geoReferencedSitemapPresent === true ? <span className='shape_filetrue'>True</span> : <span className='shape_filefalse'>False</span>}</p>     
       )
    },

    {
      field: "action",
      headerName: "",
      flex: 1,
      minWidth: 10,
      renderCell: (val) => (
        <>
          <Button
            color="inherit"
            id="basic-button"
            aria-haspopup="true"
            onClick={(e) => {
              setAnchorEl(e.currentTarget);
              setOpen(val.value);
            }}
          >
            <MoreVertIcon />
          </Button>
          <Menu
            onClose={(e) => {
              setAnchorEl(null);
              setOpen(-1);
            }}
            anchorEl={anchorEl}
            id="basic-menu"
            open={open == val.value ? true : false}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              style={{ backgroundColor: "#000", opacity: 1, color: "#fff" }}
              disabled
              onClick={(e) => setOpen(-1)}
            >
              Actions
            </MenuItem>
            <MenuItem
              onClick={(e) => {
                nav("/edit-projects", {
                  state: {
                    projectId: val.row.id,
                  },
                });
              }}
            >
              <EditIcon htmlColor="#4EB9E9" />{" "}
              <span style={{ marginLeft: 5 }}>Edit</span>
            </MenuItem>

            <MenuItem
              onClick={(e) => {
                handleClickOpenAlert(val.row.id)
              }}
            >
              <DeleteIcon htmlColor="#FF7272" />{" "}
              <span style={{ marginLeft: 5 }}>Delete</span>
            </MenuItem>

            <MenuItem
              onClick={(e) => {
                nav("/projects-view", {
                  state: {
                    projectId: val.row.id,
                  },
                });
                
              }}
            >
              <AddIcon htmlColor="#2C4EA6" />{" "}
              <span style={{ marginLeft: 5 }}>View More </span>{" "}
            </MenuItem>
          </Menu>
        </>
      ),
    },
  ];

  const viewAllProjects = () =>{
    setLoading(true)
    axiosClient.get('v1/gait/project/viewAll')
    .then(res =>{
      if(res.status === 200){
        const tempRows = res?.data?.map((val, index) => {
          val.id = val.id
          val.action = val.id
          return (val)
        })
        setProjectsLists(tempRows);
        setLoading(false)
      }
    }).catch(err =>{

    })
  }

  const deleteProjects = (pId) =>{
    axiosClient.delete(`v1/gait/project/byId/${pId}`)
    .then(res =>{
      viewAllProjects();
    }).catch(err =>{
      console.log(err , "chcek erroro if have ")
    })
  }

  return (
    <div>
        {loading ? (
        <div>
          <img src={ICONS.SignalGreen} style={{
            width: '5%',
            position: 'absolute',
            top: '50%',
            left: '50%',
          }}/>
        </div>
      ) : (
        <>
         <Dialog
        open={openAlert}
        onClose={handleCloseAlertNo}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
         <span className="sure_to-delete"> Are you sure want to delete</span>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAlertNo}> <span className="sure_to-no"> No</span></Button>
          <Button onClick={handleCloseAlert}>
          <span className="sure_to-yes">Yes</span>
          </Button>
        </DialogActions>
      </Dialog>
        <div className="d-flex flex-row-reverse">
        <div className="add-projectbtn">
                <button onClick={()=>nav('/add-projects')}>Add Project</button>
            </div>
            <div className="search-input">
                <input type="text" name="search" placeholder="serach"/>
                <div className="search-ico">
                    <SearchIcon />
                </div>
            </div>
            
        </div>
      <DataGrid
        rows={projectsLists}
        columns={columns}
        pageSize={10}
        rowSpacingType="margin"
        rowsPerPageOptions={[10]}
        disableSelectionOnClick={true}
        disableColumnMenu={true}
        autoHeight
        style={{ marginTop: 20 }}
      />
          </>
)}
    </div>
  );
}

export default ProjectsLists;
