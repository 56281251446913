import axios from "axios";
import { store } from "./store/store";
import { createBrowserHistory } from "history";

let history = createBrowserHistory();

let axiosClient = axios.create({
  // baseURL: `http://54.206.48.250:8082/`,
  baseURL: `http://api.gaitglobal.com:8082/`,
  // baseURL:`http://54.206.48.250:8082/`
});
// 54 api is dev api and api.gaitglobal is production api 
axiosClient.interceptors.response.use(undefined, (err) => {
  const error = err.response;
  // if error is 401
  if (error.status === 401 && error.config && !error.config.__isRetryRequest) {
    // request for a new token
    // return getAuthToken().then(response => {
    //   // update the error config with new token
    //   error.config.__isRetryRequest = true;
    //   error.config.headers.token = localStorage.getItem("accessToken");
    //   return client(error.config);
    // });

    let state = store.getState();
    axiosClient
      .post(`user/logout`, {
        username: state.loginData?.user?.username,
      })
      .then((res) => {
        history.replace("/");
        window.location.reload();
      });
  }
});

export default axiosClient;
