import { Card, Grid, Tab, Tabs, TextField } from "@mui/material";
import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import { Line } from "react-chartjs-2";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { format } from "date-fns";
import Avatar from "@mui/material/Avatar";
import {
  Chart as ChartJS,
  ArcElement,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  LineController,
  BarController,
  Legend,
  Tooltip,
} from "chart.js";
import {
  DataGrid,
  GridToolbarExport,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import { getSensorInfo } from "../features/counter/APIs";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { connect, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { getAllSensors } from "../features/counter/APIs";
import { MapContainer } from "react-leaflet/MapContainer";
import { TileLayer } from "react-leaflet/TileLayer";
import { Marker, Popup } from "react-leaflet";
import L from "leaflet";
import ICONS from '../features/images';
ChartJS.register(
  ArcElement,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);
const columns = [
  { field: "id", headerName: "ID", width: 90 },
  {
    field: "Amb_Pressure",
    headerName: "Amb_Pressure",
    width: 150,
    editable: true,
  },
  {
    field: "Amb_Temp",
    headerName: "Amb_Temp",
    width: 150,
    editable: true,
  },
  {
    field: "CO2",
    headerName: "CO2",
    type: "number",
    width: 110,
    editable: true,
  },
  {
    field: "DateTime",
    headerName: "DateTime",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
  },
  {
    field: "DeviceId",
    headerName: "DeviceId",
    type: "number",
    width: 110,
    editable: true,
  },
  {
    field: "FD_CO2_Conc_ppm",
    headerName: "FD_CO2_Conc_ppm",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
  },

  {
    field: "FD_CO2_Flux_umol/m2/s",
    headerName: "FD_CO2_Flux_umol/m2/s",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
  },

  {
    field: "FD_Temp_DegC",
    headerName: "FD_Temp_DegC",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
    valueGetter: (params) => `${params.row.FD_Temp_DegC || ""} `,
  },

  {
    field: "Fc_mass_mg",
    headerName: "Fc_mass_mg",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
    valueGetter: (params) => `${params.row.Fc_mass_mg || ""} `,
  },

  {
    field: "H2O",
    headerName: "H2O",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
    valueGetter: (params) => `${params.row.H2O || ""}`,
  },

  {
    field: "Record_No",
    headerName: "Record_No",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
    valueGetter: (params) => `${params.row.Record_No || ""}`,
  },

  {
    field: "Sonic_temp",
    headerName: "Sonic_temp",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
    valueGetter: (params) => `${params.row.Sonic_temp || ""} `,
  },

  {
    field: "Source_Temp",
    headerName: "Source_Temp",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
    valueGetter: (params) => `${params.row.Source_Temp || ""} `,
  },

  {
    field: "Timestamp",
    headerName: "Timestamp",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
    valueGetter: (params) => `${params.row.Timestamp || ""} `,
  },
  {
    field: "UxCO2_Cov",
    headerName: "UxCO2_Cov",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
    valueGetter: (params) => `${params.row.UxCO2_Cov || ""} `,
  },
  {
    field: "UxH2O_Cov",
    headerName: "UxH2O_Cov",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
    valueGetter: (params) => `${params.row.UxH2O_Cov || ""} `,
  },
  {
    field: "UyCO2_Cov",
    headerName: "UyCO2_Cov",
    description: "This column has a value getter and is not sortable.",

    sortable: false,
    width: 160,
    valueGetter: (params) => `${params.row.UyCO2_Cov || ""} `,
  },
  {
    field: "UyH2O_Cov",
    headerName: "UyH2O_Cov",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
    valueGetter: (params) => `${params.row.UyH2O_Cov || ""} `,
  },
  {
    field: "UzCO2_Cov",
    headerName: "UzCO2_Cov",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
    valueGetter: (params) => `${params.row.UzCO2_Cov || ""} `,
  },
  {
    field: "UzH2O_Cov",
    headerName: "UzH2O_Cov",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
    valueGetter: (params) => `${params.row.UzH2O_Cov || ""}`,
  },
  {
    field: "Wind_Ux",
    headerName: "Wind_Ux",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
    valueGetter: (params) => `${params.row.Wind_Ux || ""}`,
  },
  {
    field: "Wind_Uy",
    headerName: "Wind_Uy",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
    valueGetter: (params) => `${params.row.Wind_Uy || ""} `,
  },
  {
    field: "Wind_Uz",
    headerName: "Wind_Uz",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
    valueGetter: (params) => `${params.row.Wind_Uz || ""} `,
  },
];

function SensorPage(props) {
  const [urlParam] = useSearchParams();
  const sensorid = urlParam.get("sensorId");
  const [value, setValue] = React.useState(0);
  const [graphDataObj, setgraphDataObj] = React.useState(null);
  const [dateValue, setDateValue] = React.useState(
    format(new Date(), "yyyy-MM-dd")
  );
  const [graphDataArray, setGraphDataArray] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const sensorgrey = L.icon({ iconUrl: ICONS.Biggrey, iconSize: 25 });
  const sensorgreen = L.icon({ iconUrl: ICONS.Biggreen, iconSize: 25 });
  const sensorred = L.icon({ iconUrl: ICONS.Bigred, iconSize: 25 });
  const sensor = useSelector((state) => state.sensorInfo?.gaitObj);
  const user = useSelector((state) => state.loginData.user);
  let acount = [];
  let icount = [];
  React.useEffect(() => {
    getAllSensorRecord();
  }, []);
  const getAllSensorRecord = async () => {
    setLoading(true);
    await getAllSensors(
      user?.personidref?.orgidref?.orgid,
      user?.personidref?.orgidref?.orgcode
    )
      .then((res) => {
        setLoading(false);
        res?.data?.gaitObj?.map((val) => {
          val.isactive === 0 ? acount.push(val) : icount.push(val);
        });
      })
      .catch((e) => {});
  };

  var options= {
    spanGaps: true,
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    stacked: false,
    plugins: {
      title: {
        display: false,
        text: 'Chart.js Line Chart - Multi Axis'
      }
    },
    scales: {
      y: {
        type: 'linear',
        display: true,
        position: 'left',
        title: { display: true, text: "Ambient Temperature (°C)" },
      },
      y2: {
        type: 'linear',
        display: true,
        position: 'left',
        title: { display: true, text: "FLUX (mg/m3/s)" },
      },
      y1: {
        type: 'linear',
        display: true,
        position: 'right',
        title: { display: true, text: "Atmospheric Pressure (kPa)" },
        grid: {
          drawOnChartArea: false,
        },
      },
      x:{
        display:true,
      }
    }
  }
  useEffect(() => {
    // https://b6n3tuzlvd6jyg3j7bbqi6vqje0opvca.lambda-url.ap-southeast-2.on.aws/?sensor_id=31580&for_date=2022-07-30
    let fetchRes = fetch(
      `https://uchohlcrkovc4bdlgmqhvt2h740utiwz.lambda-url.ap-southeast-2.on.aws/?sensor_id=${sensorid}&for_date=${dateValue}`
    );
    // let fetchRes = fetch(
    //   `https://b6n3tuzlvd6jyg3j7bbqi6vqje0opvca.lambda-url.ap-southeast-2.on.aws/?sensor_id=${sensorid}&for_date=${dateValue}`
    // );
    fetchRes
      .then((res) => res.json())
      .then((resp) => {
        
        let graphData = resp.data;
        
        let fcMsgMgData = [];
        let AmbTempData = [];
        let AmbPressureData = [];
        let timestampRes = [];
        graphData.map((val,index) =>{
          if(graphData[index].Fc_mass_mg !== undefined || graphData[index].Amb_Temp !== undefined || graphData[index].Amb_Pressure !== undefined){
            fcMsgMgData.push(graphData[index].Fc_mass_mg);
            AmbTempData.push(graphData[index].Amb_Temp);
            AmbPressureData.push(graphData[index].Amb_Pressure);
            timestampRes.push(new Date(graphData[index].Timestamp).toLocaleTimeString());
          }
        });
        
        let humidArry = fcMsgMgData.map((val,index) =>{
         return val;
        })
          let amb_Temp = AmbTempData?.map((val, index) => {
          return val;
        });
        let amb_Pressure = AmbPressureData?.map((val, index) => {
          return val;
        });
        let DateTime = timestampRes?.map((val, index) => {
          return val;
        });
        graphData?.map((val, index) => {
          val.id = index;
        });
        setGraphDataArray(graphData);

        var data = {
          datasets: [
            {
              backgroundColor: "#7DF5EE",
              borderColor: "#7DF5EE",
              fill: false,
              data: humidArry,
              id: "carbon",
              label: "Carbon Flux",
              yAxisID: "left",
              yAxisID: 'y2'
            },
            {
              backgroundColor: "#6F3895",
              borderColor: "#6F3895",
              fill: false,
              data: amb_Pressure,
              id: "ambp",
              label: "Ambient Pressure",
              yAxisID: 'y1',
            },
            {
              backgroundColor: "#FFD600",
              borderColor: "#FFD600",
              fill: false,
              data: amb_Temp,
              id: "ambt",
              label: "Ambient Temperature",
              yAxisID: 'y',
            },
          ],
          labels: DateTime,
        };
        setgraphDataObj(data);
       
        let fetchRes = fetch(
          `http://api.weatherapi.com/v1/history.json?key=fd042e5afc8a4265aaa44824222903&q=${sensor?.latitude},${sensor?.longitude}&dt=${dateValue}`
        );
        fetchRes
          .then((res) => res.json())
          .then((res) =>{
          })
      });
    props.getSensor(sensorid);
  }, [dateValue]);
  useEffect(() => {}, [sensor]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setTimeout(() => {
      setGraphDataArray(graphDataArray);
    }, 1000);
  };
  function toolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport
          csvOptions={{ fileName: "GAIT_" + format(new Date(), "dd-MM-yyyy") }}
          printOptions={{ disableToolbarButton: true }}
        />
      </GridToolbarContainer>
    );
  }
  return (
    <>
      {loading ? (
        <div>
          <img
            src={ICONS.SignalGreen}
            style={{
              width: "5%",
              position: "absolute",
              top: "50%",
              left: "50%",
            }}
          />
        </div>
      ) : (
        <>
          <Grid
            container
            spacing={2}
            style={{ marginTop: 0, alignItems: "stretch" }}
            alignItems="start"
          >
            <Grid item lg={9}>
              <Grid container>
                <Grid item md>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab
                      label="Graph "
                      style={{
                        background: "#ccc",
                        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.08)",
                        borderRadius: "2px",
                        marginRight: 10,
                      }}
                    ></Tab>
                    <Tab
                      label="Tabular"
                      style={{
                        background: "#ccc",
                        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.08)",
                        borderRadius: "2px",
                        marginRight: 10,
                      }}
                    />
                  </Tabs>
                </Grid>
                <Grid item>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      label="Filter by date"
                      value={dateValue}
                      inputFormat={"yyyy-MM-dd"}
                      onChange={(newValue) => {
                        setDateValue(format(new Date(newValue), "yyyy-MM-dd"));
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
              <div hidden={value !== 0} role="tabpanel" value={value} index={0}>
                <Box sx={{  width: "100%" }}>
                  {graphDataObj && (
                    <Line options={options} data={graphDataObj} />
                  )}
                </Box>
              </div>
              <div hidden={value !== 1} role="tabpanel" value={value} index={1}>
                {graphDataArray && graphDataArray.length > 0 && (
                  <div style={{ height: 400, width: "100%" }}>
                    {value == 1 && (
                      <DataGrid
                        components={{ Toolbar: toolbar }}
                        rows={graphDataArray}
                        getRowId={(graphDataArray) => graphDataArray.id}
                        columns={columns}
                        pageSize={10}
                        initialState={{
                          sorting: {
                            sortModel: [{ field: "DateTime", sort: "desc" }],
                          },
                        }}
                        rowsPerPageOptions={[10]}
                        disableSelectionOnClick
                      />
                    )}
                  </div>
                )}
              </div>
            </Grid>
            <Grid item lg={3}>
              <Card className="card">
                <div className="map-container" style={{ height: "100%" }}>
                  {sensor && (
                    <MapContainer
                      center={[sensor?.latitude, sensor?.longitude]}
                      zoom={6}
                      scrollWheelZoom={false}
                      style={{
                        height: "100%",
                        width: "100%",
                      }}
                      className="sensor-mapcontainer"
                    >
                      <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
                      />
                      <Marker
                        position={[
                          Number(sensor.latitude),
                          Number(sensor.longitude),
                        ]}
                        icon={
                          sensor.isactive == 1 && sensor.livestatus == 1
                            ? sensorgreen
                            : sensor.isactive == 1 && sensor.livestatus == 0
                            ? sensorred
                            : sensorgrey
                        }
                      >
                        <Popup>
                          <ul>
                            <li> Sensor Id : {sensor.sensorid}</li>
                            <li> Sensor Type: {sensor.sensortype}</li>
                            <li> Location: {sensor.location}</li>
                            <li> Country: {sensor.country}</li>
                            <li>
                              {" "}
                              Last status :{" "}
                              {new Date(
                                sensor.lastcommunidate
                              ).toLocaleDateString()}
                            </li>
                          </ul>
                        </Popup>
                      </Marker>
                    </MapContainer>
                  )}
                </div>
              </Card>
            </Grid>
            <Grid item lg={4}>
              <Card className="card card-grey h-100">
                <img src={ICONS.ReportIcon} className="sensor-reportimg" />

                <Grid item xs={10} className="s-card h-100">
                  <p>
                    Sensor ID:{" "}
                    <span className="s-info">{sensor?.sensorid}</span>
                  </p>
                  <p>
                    Sensor Make:{" "}
                    <span className="s-info">{sensor?.sensormodel}</span>
                  </p>
                  <p>
                    Sensor Make:{" "}
                    <span className="s-info"> {sensor?.sensortype}</span>
                  </p>
                  <p>
                    Sensor Manufacturing year:{" "}
                    <span className="s-info">{sensor?.sensoryear}</span>
                  </p>
                  <p>
                    Sensor Make: <span className="s-info">{sensor?.make}</span>
                  </p>
                  <p>
                    Sensor Life :{" "}
                    <span className="s-info">{sensor?.lifespan} Years</span>
                  </p>
                </Grid>
              </Card>
            </Grid>
            <Grid item lg={4}>
              <Card className="card card-grey h-100">
                <img src={ICONS.BigArea} className="sensor-locationico" />
                <Grid item xs={10} className="s-card">
                  <p>
                    Area Covered:{" "}
                    <span className="s-info">{sensor?.area} Ha</span>
                  </p>
                  <p>
                    Elevation :
                    <span className="s-info">{sensor?.elevation}m</span>{" "}
                  </p>
                  <p>
                    Sensor Location:{" "}
                    <span className="s-info">{sensor?.location}</span>{" "}
                  </p>
                  <p>
                    Country: <span className="s-info">{sensor?.country} </span>{" "}
                  </p>
                  <p>
                    Sensor Coordinates:{" "}
                    <span className="s-info">
                      {sensor?.latitude}, {sensor?.longitude}
                    </span>
                  </p>
                </Grid>
              </Card>
            </Grid>
            <Grid item lg={4}>
              <Card
                style={{ height: "auto!important" }}
                className="card card-grey  satellite-card dashcard-gray"
              >
                <div className="d-flex justify-content-start align-items-center">
                  <div>
                    <Avatar alt="avtar" src={ICONS.UserAvatar} width={34} />
                  </div>
                  <div className="sansor-details">
                    <p className="sansor-installedby">{sensor?.installedby}</p>
                  </div>
                </div>
                <div className="devider"></div>
                <div className="d-flex justify-content-start align-items-center s-card h-50">
                  <div className="date-ofinstall">
                    <p>Date of Installation</p>
                    <button>
                      <img src={ICONS.ReshotTime} />
                      {sensor?.installeddate &&
                        new Date(sensor?.installeddate).toLocaleDateString()}
                    </button>
                  </div>

                  <div className="maintenance-duedate">
                    <p>Maintenance due date</p>
                    <button>
                      <img src={ICONS.ClockSettings} />
                      {sensor?.maintenancedate &&
                        new Date(sensor?.maintenancedate).toLocaleDateString()}
                    </button>
                  </div>
                </div>
              </Card>
              <Card className="card card-grey  satellite-card h-50 sansor-communication">
                <img src={ICONS.CommunicationTower} className="communication-tower" />
                <Grid item xs={9} className="s-card">
                  <p>
                    Communication Frequency:{" "}
                    <span className="s-info"> {sensor?.frequency}</span>
                  </p>
                  <p>
                    Communication Type:{" "}
                    <span className="s-info">{sensor?.network}</span>
                  </p>
                  <p>
                    Last Communication :{" "}
                    <span className="s-info">
                      {new Date(sensor?.lastcommunidate).toLocaleDateString()}
                    </span>
                  </p>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}
const mapDispatchToProps = {
  getSensor: getSensorInfo,
};
export default connect(null, mapDispatchToProps)(SensorPage);
