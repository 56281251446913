import { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { connect, useSelector } from 'react-redux';
import { Alert, Button, FormControlLabel, Grid, Menu, MenuItem, Snackbar, Switch } from '@mui/material';
import { getAllSites } from '../features/counter/APIs';
import EditIcon from '@mui/icons-material/Edit';
import SensorsIcon from '@mui/icons-material/Sensors';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import axios from '../axios';
import axiosClient from '../axios';
import { useNavigate, useLocation } from "react-router-dom";
import ICONS from '../features/images';
import { change_site_status, v1_gait, view_all_sites } from '../features/Apis/Index';
import Iframe from 'react-iframe'
function SitesList(props) {
  const params = useLocation();
  let nav = useNavigate();
  const [rows, setRows] = useState([])
  const [open, setOpen] = useState(-1)
  const [anchorEl, setAnchorEl] = useState(null);
  const [aopen, setAlertOpen] = useState(false);
  const [siteOpen, setSiteOpen] = useState(false);
  const [updatesiteOpen, setupdatesiteOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const user = useSelector(state => state.loginData.user)

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlertOpen(false);
  };

  const handleCloseSite = (event, reason) =>{
    if (reason === 'clickaway') {
      return;
    }
    setSiteOpen(false);
  }

  const handleUpdateSite = (event, reason) =>{
    if (reason === 'clickaway') {
      return;
    }
    setupdatesiteOpen(false);
  }

  const columns = [
    { field: 'siteid', headerName: 'ID', width: 60,        minWidth: 50, },
    {
      field: "sitename", headerName: 'Site Name', flex: 1,        minWidth: 100,
    },
    {
      field: 'sitetype', headerName: 'Site Type',
      flex: 1,
      minWidth: 100,
      renderCell: (val) => (
        <span className='text'>
          {val.value}
        </span>
      ),
    },
    {
      field: 'sitelogitude',
      headerName: 'Longitude',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'sitelatitude',
      headerName: 'Latitude',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'carbontype',
      headerName: 'Carbon Type',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'soiltype',
      headerName: 'Soil Type',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'vegitationtype',
      headerName: 'Vegetation',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      flex: 1,
      minWidth: 100,
    },
    

  ];
  if (user?.personIdref?.orgidref?.orgcode == "GAIT") {
    columns.push(
      {
        field: 'isactive',
        headerName: 'Status',
        description: 'This column has a value getter and is not sortable.',
        sortable: false,
        flex: 1,
        minWidth: 120,
        renderCell: (val) => (
          <>
            <span>
              <FormControlLabel className={val.value == 1 ? 'text-green' : ""} control={<Switch onChange={(e) => deactSensor(val.row)} color='success' checked={val.value === 0 ? false : true}
              />} label={val.value == 0 ? "Inactive" : "Active"} />
            </span>
          </>
        ),
        flex: 1,
      }
    )
    columns.push(
      {
        field: 'owner',
        headerName: 'Owner',
        sortable: false,
        flex: 1,
        minWidth: 120,
        renderCell: (params) => {
          return (
            <>
              {params.value}
            </>
          );
        }
      }
    )
    columns.push(
      {
        field: 'action',
        headerName: 'Actions',
        flex: 1,
        minWidth: 90,
        renderCell: (val) => (
          <>
            <Button
              color='inherit'
              id="basic-button"
              aria-haspopup="true"
              onClick={e => {
                setAnchorEl(e.currentTarget);
                setOpen(val.value)
              }}
            >
              <MoreVertIcon />
            </Button>
            <Menu
              onClose={e => {
                setAnchorEl(null);
                setOpen(-1)
              }}
              anchorEl={anchorEl}
              id="basic-menu"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'middle',
              }}
              open={(open == val.value) ? true : false}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem style={{ backgroundColor: "#000", opacity: 1, color: "#fff" }} disabled onClick={e => setOpen(-1)}>Actions</MenuItem>
              <MenuItem onClick={e => {
                nav('/edit-site', {state:{
                  siteId: val.row} 
                })
              }}><EditIcon htmlColor='#4EB9E9'/> <span style={{marginLeft: 5}}>Edit</span></MenuItem>
              <MenuItem 
              onClick={e => {
                nav('/site-install')
              }}
              ><SensorsIcon htmlColor='#2C4EA6'/> <span style={{marginLeft: 5}}>Install </span> </MenuItem>
            </Menu>
          </>
        ),
      })
  }
 
  const getAllSites = (orgid, orgcode) => {
    setLoading(true);
    axiosClient.get(`v1/gait/site/${view_all_sites}/${orgid}/${orgcode}`).then((res) => {
      setLoading(false);
    })
  }
  const getGraphanaMap = () => {
    axiosClient.get(`http://54.66.187.193:3040/`).then((res) => {
      console.log(res , "graphana record")
    })
  }
  const deactSensor = (site) => {
    if (site.isactive == 0) {
      axios.put(`${v1_gait}${change_site_status}${site.siteid}/1`).then((res) => {
        getAllSitesandUpdate()
        setAlertOpen(true);
        getAllSites(user?.personidref?.orgidref?.orgid, user?.personidref?.orgidref?.orgcode)
      })
    } else {
      axios.put(`${v1_gait}${change_site_status}${site.siteid}/0`).then((res) => {
        getAllSitesandUpdate()
        setAlertOpen(true);
        getAllSites(user?.personidref?.orgidref?.orgid, user?.personidref?.orgidref?.orgcode)
      })
    }
  }
  const getAllSitesandUpdate = () => {
    props.getAllSites(user?.personidref?.orgidref?.orgid, user?.personidref?.orgidref?.orgcode).then((res) => {
      const tempRows = sitesList?.map((val, index) => {
        val.id = index
        val.action = index
        return (val)
      })
      setRows(tempRows)
      if(params?.state?.flag === 'update_site'){
      setupdatesiteOpen(true)
      window.history.replaceState({}, document.title)
      }
    })
  }
  const sitesList = useSelector(state => state?.sites?.gaitObj)
  useEffect(() => {
    getAllSitesandUpdate();
    getGraphanaMap();
    getAllSites(user?.personidref?.orgidref?.orgid, user?.personidref?.orgidref?.orgcode)
    showSiteAddedMsg();
  }, []);

  const showSiteAddedMsg = () =>{
    const siteAdded = localStorage.getItem('addedSite');
    if(siteAdded == 'true'){
      setSiteOpen(true);
     localStorage.removeItem('addedSite');
    }else{
      setSiteOpen(false);
    }
  }
  return (
    <div style={{ height: 650, width: '100%', paddingRight: 20, marginTop: 20 }}>
          {loading ? (
        <div>
          <img src={ICONS.SignalGreen} style={{
            width: '5%',
            position: 'absolute',
            top: '50%',
            left: '50%',
          }}/>
        </div>
      ) : (
        <>
      <Grid>
        {user?.personIdref?.orgidref?.orgcode == "GAIT" && <Button 
        onClick={e => nav('/add-sites')}
        style={{
          marginLeft: "auto",
          display: "block",
          width: 140,
          background: "linear-gradient(0deg, #55AA8B, #55AA8B), #FEFEFE",
          borderRadius: 4, color: "white",
        }}>Add Site</Button>}
      </Grid>
      {rows &&
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={10}
          rowSpacingType="margin"
          rowsPerPageOptions={[10]}
          disableSelectionOnClick={true}
          disableColumnMenu={true}
          style={{ marginTop: 20 }}
          rowHeight={55}
          disableExtendRowFullWidth={true}
        />
      }
      <Snackbar open={aopen} autoHideDuration={2000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Status changed successfully.
        </Alert>
      </Snackbar>
      <Snackbar open={siteOpen} autoHideDuration={2000} onClose={handleCloseSite} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSite} severity="info" sx={{ width: '100%' }}>
          Site created successfully.
        </Alert>
      </Snackbar>
      <Snackbar open={updatesiteOpen} autoHideDuration={2000} onClose={handleUpdateSite} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleUpdateSite} severity="success" sx={{ width: '100%' }}>
          Site updated successfully.
        </Alert>
      </Snackbar>
</>
      )}
{/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15224.11070240085!2d78.35981729278362!3d17.458389425545416!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m3!3e6!4m0!4m0!5e0!3m2!1sen!2sin!4v1665750829971!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
{/* <Iframe url={`http://localhost:3040/d/g1o380I4k/daily-report-copy?orgId=1&var-Site=802&var-Source=Sensor&from=1660287517073&to=1668063517073`}
        width='100%'
        height='100%'
        id=""
        className=""
        display="block"
        position="relative"
        allow_embedding={true}
        /> */}
           {/* {(config.roles.user2_org3 === user.role.id) &&
                    <iframe
                        src={`https://${config.sampleDash.organization3.username}:${config.sampleDash.organization3.password}@authproxy.sampleDomain/z/ccccccccc/organization_3?orgId=3&from=now-90d&to=now&var-org_user=${user.username}&var-city=${city}&kiosk=tv`} />
                }
                {(config.roles.user3_org2 === user.role.id) &&
                    <iframe
                        src={`https://${config.sampleDash.organization2.username}:${config.sampleDash.organization2.password}@authproxy.sampleDomain/z/bbbbbbbbb/organization_2?orgId=2&from=now-90d&to=now&var-org_user=${user.username}&kiosk=tv`} />
                } */}
        {/* <iframe
                       src={`https://${config.sampleDash.organization1.username}:${config.sampleDash.organization1.password}@authproxy.sampleDomain/z/aaaaaaaaa/organization_1?orgId=1&from=now-90d&to=now&var-org_user=${type}&var-state=${state}&var-top=5&kiosk=tv`} /> */}
          {/* <iframe
            src={`https://${config.sampleDash.organization3.username}:${config.sampleDash.organization3.password}@authproxy.sampleDomain/z/ccccccccc/organization_3?orgId=3&from=now-90d&to=now&var-org_user=${user.username}&var-city=${city}&kiosk=tv`} /> */}
    </div>
  );
}
const mapDispatchToProps = {
  getAllSites: getAllSites,
}
export default connect(null, mapDispatchToProps)(SitesList);
