import axios from "../../axios";
import store from "../../store/store";
const initialState = {
  sensorInfo: null,
  sitename: null,
  users: [],
};

export default function sensorReducer(state = initialState, action) {
  switch (action.type) {
    // omit other reducer cases
    case "sensorInfo": {
      return { ...state, sensorInfo: action.payload };
    }
    case "sitename": {
      return { ...state, sitename: action.payload };
    }
    case "sites": {
      return { ...state, sites: action.payload };
    }
    case "currentSite": {
      return { ...state, currentSite: action.payload };
    }
    case "sensorTable": {
      return { ...state, sensorTable: action.payload };
    }
    case "siteCarbon": {
      return { ...state, siteCarbon: action.payload };
    }
    case "siteCarbonBySource": {
      return { ...state, siteCarbonBySource: action.payload };
    }
    case "loginData": {
      return { ...state, loginData: action.payload };
    }
    case "users": {
      return { ...state, users: action.payload };
    }
    // case "getAllSiteTypes": {
    //   return { ...state, getAllSiteTypes: action.payload };
    // }
    default:
      return state;
  }
}
// Thunk function
export const getSensorInfo = (sensorid) => async (dispatch, getState) => {
  axios
    .get(`v1/gait/sensor/view_sensor_installation_info?sensorId=${sensorid}`)
    .then((res) => {
      dispatch({ type: "sensorInfo", payload: res.data });
      dispatch({ type: "sitename", payload: res.data.gaitObj.sitename });
    });
};
export const getAllSites = (orgid, orgcode) => async (dispatch, getState) => {
  axios.get(`v1/gait/site/view_all_sites/${orgid}/${orgcode}`).then((res) => {
    dispatch({ type: "sites", payload: res.data });
  });
};
export const getAllUsers = (orgid, orgcode) => async (dispatch, getState) => {
  axios.get(`gait/um/viewallusers/${orgid}/${orgcode}`).then((res) => {
    dispatch({ type: "users", payload: res.data });
  });
};

export const getSite = (siteId) => async (dispatch, getState) => {
  axios.get(`v1/gait/site/view_site_info?siteId=${siteId}`).then((res) => {
    dispatch({ type: "currentSite", payload: res?.data });
  });
};
export const getSensorTable = (siteId) => async (dispatch, getState) => {
  axios
    .get(`v1/gait/site/view_site_sensors_list?siteId=${siteId}`)
    .then((res) => {
      dispatch({ type: "sensorTable", payload: res.data });
    });
};
export const getSiteCarbonByYear =
  (siteId, year=2022) => async (dispatch, getState) => {
    axios
      .get(
        `v1/gait/site/view_site_carbon_data_by_year?siteId=${siteId}&year=${year}`
      )
      .then((res) => {
        res?.data?.gaitObj?.map((val) => {
          val.totalcarbon = (val.totalcarbon / 1000).toFixed(2);
        });
        dispatch({ type: "siteCarbon", payload: res.data });
      });
  };

export const getSiteCarbonBySource = (siteId) => async (dispatch, getState) => {
  axios
    .get(`v1/gait/site/view_site_carbon_by_source?siteId=${siteId}`)
    .then((res) => {
      dispatch({ type: "siteCarbonBySource", payload: res.data });
    });
};

export const saveLoginData = (data) => async (dispatch, getState) => {
  dispatch({ type: "loginData", payload: data.data });
};

// export const getAllSiteTypes = () => async (dispatch, getState) =>{
//   axios.get(`v1/gait/lukup/sitetypes`).then(res =>{
//     dispatch({ type: "getAllSiteTypes", payload: res.data });
//   })
// }

export const getAllSensors = async (orgid, orgcode) => {
  return axios.get(`v1/gait/sensor/view_all_sensors/${orgid}/${orgcode}`);
};

export const getWeatherChartData = async () => {
  return axios.get(`v1/gait/siteWeather/viewSiteHourlyWeather_by_SiteId_dates?siteId=802&fromDate=2022-07-28&toDate=2022-07-30`)
}

export const getCarbonChartData = async () =>{
  return axios.get(`v1/gait/site/view_site_carbon_info_by_date_range?siteId=802&fromDate=2022-08-01&toDate=2022-09-02`)
}

export const  getWeatherData = async (dispatch) =>{
  return axios.get(`v1/gait/siteWeather/viewSiteHourlyWeather_by_SiteId_dates?siteId=802&fromDate=2022-07-28&toDate=2022-07-30`)
}

export const getCarbonData = async () =>{
  return axios.get(`v1/gait/site/view_site_carbon_info_by_date_range?siteId=802&fromDate=2022-08-01&toDate=2022-09-02`)
}




export const sensorInfo = (state) => state.sensorInfo;
