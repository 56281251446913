import {Grid} from "@mui/material";
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { Line } from "react-chartjs-2";
import { format } from "date-fns";
import Typography from "@mui/material/Typography";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  ArcElement,
} from "chart.js";
import {
  GridToolbarExport,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import { getSensorInfo } from "../../features/counter/APIs";
import { connect } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import L from "leaflet";
import ICONS from '../../features/images';

ChartJS.register(
  ArcElement,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

const columns = [
  { field: "id", headerName: "ID", width: 90 },
  {
    field: "Amb_Pressure",
    headerName: "Amb_Pressure",
    width: 150,
    editable: true,
  },
  {
    field: "Amb_Temp",
    headerName: "Amb_Temp",
    width: 150,
    editable: true,
  },
  {
    field: "CO2",
    headerName: "CO2",
    type: "number",
    width: 110,
    editable: true,
  },
  {
    field: "DateTime",
    headerName: "DateTime",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
  },
  {
    field: "DeviceId",
    headerName: "DeviceId",
    type: "number",
    width: 110,
    editable: true,
  },
  {
    field: "FD_CO2_Conc_ppm",
    headerName: "FD_CO2_Conc_ppm",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
  },

  {
    field: "FD_CO2_Flux_umol/m2/s",
    headerName: "FD_CO2_Flux_umol/m2/s",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
  },

  {
    field: "FD_Temp_DegC",
    headerName: "FD_Temp_DegC",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
    valueGetter: (params) => `${params.row.FD_Temp_DegC || ""} `,
  },

  {
    field: "Fc_mass_mg",
    headerName: "Fc_mass_mg",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
    valueGetter: (params) => `${params.row.Fc_mass_mg || ""} `,
  },

  {
    field: "H2O",
    headerName: "H2O",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
    valueGetter: (params) => `${params.row.H2O || ""}`,
  },

  {
    field: "Record_No",
    headerName: "Record_No",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
    valueGetter: (params) => `${params.row.Record_No || ""}`,
  },

  {
    field: "Sonic_temp",
    headerName: "Sonic_temp",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
    valueGetter: (params) => `${params.row.Sonic_temp || ""} `,
  },

  {
    field: "Source_Temp",
    headerName: "Source_Temp",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
    valueGetter: (params) => `${params.row.Source_Temp || ""} `,
  },

  {
    field: "Timestamp",
    headerName: "Timestamp",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
    valueGetter: (params) => `${params.row.Timestamp || ""} `,
  },
  {
    field: "UxCO2_Cov",
    headerName: "UxCO2_Cov",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
    valueGetter: (params) => `${params.row.UxCO2_Cov || ""} `,
  },
  {
    field: "UxH2O_Cov",
    headerName: "UxH2O_Cov",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
    valueGetter: (params) => `${params.row.UxH2O_Cov || ""} `,
  },
  {
    field: "UyCO2_Cov",
    headerName: "UyCO2_Cov",
    description: "This column has a value getter and is not sortable.",

    sortable: false,
    width: 160,
    valueGetter: (params) => `${params.row.UyCO2_Cov || ""} `,
  },
  {
    field: "UyH2O_Cov",
    headerName: "UyH2O_Cov",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
    valueGetter: (params) => `${params.row.UyH2O_Cov || ""} `,
  },
  {
    field: "UzCO2_Cov",
    headerName: "UzCO2_Cov",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
    valueGetter: (params) => `${params.row.UzCO2_Cov || ""} `,
  },
  {
    field: "UzH2O_Cov",
    headerName: "UzH2O_Cov",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
    valueGetter: (params) => `${params.row.UzH2O_Cov || ""}`,
  },
  {
    field: "Wind_Ux",
    headerName: "Wind_Ux",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
    valueGetter: (params) => `${params.row.Wind_Ux || ""}`,
  },
  {
    field: "Wind_Uy",
    headerName: "Wind_Uy",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
    valueGetter: (params) => `${params.row.Wind_Uy || ""} `,
  },
  {
    field: "Wind_Uz",
    headerName: "Wind_Uz",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
    valueGetter: (params) => `${params.row.Wind_Uz || ""} `,
  },
];

function SensorReport(props) {
  const location = useLocation();
  const [urlParam] = useSearchParams();
  const sensorid = location.state.sensorId;
  const [value, setValue] = React.useState(0);
  const [graphDataObj, setgraphDataObj] = React.useState(null);
  const [dateValue, setDateValue] = React.useState(
    format(new Date(), "yyyy-MM-dd")
  );
  const [graphDataArray, setGraphDataArray] = React.useState([]);
  const [windXYZ, setWindXYZ] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [permission, setPermission] = React.useState(false);
  const [windVal_Y, setWindVal_Y] = useState({});
  const [windVal_Z, setWindVal_Z] = useState({});
  const [coTwoData, setCoTwoData] = useState({});
  const [h2oTwoData, setHTwoData] = useState({});
  // const sensorgrey = L.icon({ iconUrl: sgrey, iconSize: 25 });
  // const sensorgreen = L.icon({ iconUrl: sgreen, iconSize: 25 });
  // const sensorred = L.icon({ iconUrl: sred, iconSize: 25 });

  // const sensor = useSelector((state) => state.sensorInfo?.gaitObj);
  // const user = useSelector((state) => state.loginData.user);
  const [tabValue, setTabValue] = React.useState(0);
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  const handleChangetab = (event, newValue) => {
    setTabValue(newValue);
  };
  let acount = [];
  let icount = [];

  React.useEffect(() => {
    setTimeout(() => {
      setPermission(true);
    }, 3000);
  }, []);
  var options = {
    spanGaps: true,
    elements: {
      rectangle: {
        borderWidth: 2,
      },
    },
    layout: {
      padding: 0,
    },
    legend: {
      display: true,
      labels: {
        boxWidth: 16,
      },
    },
    maintainAspectRatio: false,
    responsive: true,

    title: {
      display: false,
    },
    tooltips: {
      intersect: false,
      mode: "index",
      position: "nearest",
      callbacks: {},
    },
    scales: {
      y: {
        title: { display: true, text: "FLUX (mg/m3/s)" },
        display:true,
      },
      y1: {
        type: 'linear',
        display: false,
        position: 'right',
        
      },
      x: {
        display: true,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };
  var windOptions = {
    spanGaps: true,
    elements: {
      rectangle: {
        borderWidth: 1,
      },
    },
    layout: {
      padding: 0,
      right: 1000,
    },
    legend: {
      display: false,
      labels: {
        boxWidth: 16,
      },
    },
    maintainAspectRatio: false,
    responsive: true,

    title: {
      display: false,
    },
    tooltips: {
      intersect: false,
      mode: "index",
      position: "nearest",
      callbacks: {},
    },
    scales: {
      y: {
        type: 'linear',
        display: true,
        position: 'left',
        title: { display: true, text: "WIND (m/s)" },
      },
      y1: {
        type: 'linear',
        display: true,
        position: 'right',
     
        grid: {
          drawOnChartArea: false,
        },
      },
      x: {
        display: false,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };
  var winLegendOption = {
    spanGaps: true,
    elements: {
      rectangle: {
        borderWidth: 1,
      },
    },
    layout: {
      padding: 0,
      right: 1000,
    },
    legend: {
      display: false,
      labels: {
        boxWidth: 16,
      },
    },
    maintainAspectRatio: false,
    responsive: true,

    title: {
      display: false,
    },
    tooltips: {
      intersect: false,
      mode: "index",
      position: "nearest",
      callbacks: {},
    },
    scales: {
      y: {
        type: 'linear',
        display: true,
        position: 'left',
        title: { display: true, text: "H2O (ppm)" }
      },
      y1: {
        type: 'linear',
        display: false,
        position: 'right',
       
      },
      x: {
        display: false,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  var winLegendOption2 = {
    spanGaps: true,
    elements: {
      rectangle: {
        borderWidth: 1,
      },
    },
    layout: {
      padding: 0,
      right: 1000,
    },
    legend: {
      display: false,
      labels: {
        boxWidth: 16,
      },
    },
    maintainAspectRatio: false,
    responsive: true,

    title: {
      display: false,
    },
    tooltips: {
      intersect: false,
      mode: "index",
      position: "nearest",
      callbacks: {},
    },
    scales: {
      y: {
        display: true,
        title: { display: true, text: "CO2 (ppm)" }
      },
      x: {
        display: false,
        
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const getMapData = () =>{
    setLoading(true);
    // let fetchRes = fetch(`https://uchohlcrkovc4bdlgmqhvt2h740utiwz.lambda-url.ap-southeast-2.on.aws/?sensor_id=${sensorid}&for_date=${dateValue}`)
    fetch(
      `https://uchohlcrkovc4bdlgmqhvt2h740utiwz.lambda-url.ap-southeast-2.on.aws/?sensor_id=${location.state.sensorId}&for_date=${location.state.fluxDate}`
    )
      .then((res) => res.json())
      .then((resp) => {

        let graphData = resp?.data;
        let fcMsgMgData = [];
        let AmbTempData = [];
        let AmbPressureData = [];
        let timestampRes = [];
        let windXRes = [];
        let windYRes = [];
        let windZRes = [];
        let h2oRes = [];
        let co2Res = [];
        let allArr = [];
        graphData.map((val,index) =>{
          
          if(graphData[index].Fc_mass_mg !== undefined || graphData[index].Wind_Uy !== undefined || graphData[index].Wind_Uz !== undefined || graphData[index].Wind_Ux !== undefined){
              fcMsgMgData.push(graphData[index].Fc_mass_mg);
            windXRes.push(graphData[index].Wind_Ux)
            windYRes.push(graphData[index].Wind_Uy)
            windZRes.push(graphData[index].Wind_Uz)
              h2oRes.push(graphData[index].H2O)
              co2Res.push(graphData[index].CO2)
            timestampRes.push(new Date(graphData[index].Timestamp).toLocaleTimeString());
          }
        });
        
        let humidArry = fcMsgMgData.map((val,index) =>{
         return val;
        })
        let DateTime = timestampRes?.map((val, index) => {
          return val;
        });
         let windx = windXRes?.map((val, index) => {
          return val;
        });
        let windy = windYRes?.map((val, index) => {
          return val;
        });
        let windz = windZRes?.map((val, index) => {
          return val;
        });
        let co2Value = co2Res?.map((val, index) => {
          return (val *24.45)/44.01;
        });
        let ho2Value = h2oRes?.map((val, index) => {
          return val;
        });
        setGraphDataArray(graphData);
        var data = {
          datasets: [
            {
              backgroundColor: "#3E9877",
              borderColor: "#3E9877",
              fill: false,
              data: humidArry,
              id: "carbon",
              label: "Carbon Flux",
              yAxisID: "y",
            },
          ],
          labels: DateTime,
        };
        setgraphDataObj(data);
        var wind_x = {
          datasets: [
            {
              backgroundColor: "#55aa8b",
              borderColor: "#55aa8b",
              fill: false,
              data: windx,
              id: "carbon",
              label: "Wind_Ux",
              yAxisID: 'y',
            },
            {
              backgroundColor: "#6F3895",
              borderColor: "#6F3895",
              fill: false,
              data: windy,
              id: "ambp",
              label: "Wind_Uy",
              yAxisID: 'y1',
            },
            {
              backgroundColor: "#FFD600",
              borderColor: "#FFD600",
              fill: false,
              data: windz,
              id: "ambt",
              label: "Wind_Uz",
              yAxisID: 'y',
            },
          ],
          labels: DateTime,
        };
        setWindXYZ(wind_x);
        var wind_y = {
          datasets: [
            {
              backgroundColor: "#6F3895",
              borderColor: "#6F3895",
              fill: false,
              data: windy,
              id: "ambp",
              label: "Wind_Uy",
              yAxisID: "right",
            },
          ],
          labels: DateTime,
        };
        setWindVal_Y(wind_y);
        var wind_z = {
          datasets: [
            {
              backgroundColor: "#FFD600",
              borderColor: "#FFD600",
              fill: false,
              data: windz,
              id: "ambt",
              label: "Wind_Uz",
              yAxisID: "left",
            },
          ],
          labels: DateTime,
        };
        setWindVal_Z(wind_z);
        var co2Dataset = {
          datasets: [
            {
              backgroundColor: "#000",
              borderColor: "#000",
              fill: false,
              data: co2Value,
              id: "ambt",
              label: "CO2",
              yAxisID: "y",
            },
          ],
          labels: DateTime,
        };
        setCoTwoData(co2Dataset);

        var h2oDataset = {
          datasets: [
            {
              backgroundColor: "blue",
              borderColor: "blue",
              fill: false,
              data: ho2Value,
              id: "ambt",
              label: "H2O",
            },
          ],
          labels: DateTime,
        };
        setHTwoData(h2oDataset);
        setLoading(false);
      });
  }

  useEffect(() => {
    getMapData();
    props.getSensor(sensorid);
  }, [dateValue]);
  function toolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport
          csvOptions={{ fileName: "GAIT_" + format(new Date(), "dd-MM-yyyy") }}
          printOptions={{ disableToolbarButton: true }}
        />
      </GridToolbarContainer>
    );
  }
  return (
    <>
    
      {loading ? (
        <div>
          <img
            src={ICONS.SignalGreen}
            style={{
              width: "5%",
              position: "absolute",
              top: "50%",
              left: "50%",
            }}
          />
        </div>
      ) : (
        <>
          <Grid
            container
            spacing={2}
            style={{ marginTop: 0, alignItems: "stretch" }}
            alignItems="start"
          >
            <Grid item lg={12}>
              <div hidden={value !== 0} role="tabpanel" value={value} index={0}>
                <Box sx={{ height: 150, width: "100%"}}>
                  {permission === true ? (
                    <>
                          {windXYZ != {} ?(<Line options={windOptions}  data={windXYZ}/>):null}
                    </>
                  ) : null}
                </Box>
              </div>
              <div hidden={value !== 0} role="tabpanel" value={value} index={0}>
                <Box sx={{ height: 150, width:'97.5%',marginLeft:0.3}}>
                  {permission === true ? (
                    <>
                          {h2oTwoData !={} ?(<Line options={winLegendOption} data={h2oTwoData} />):null}
                    </>
                  ) : null}
                </Box>
              </div>
              <div hidden={value !== 0} role="tabpanel" value={value} index={0}>
                <Box sx={{ height: 150, width:'97.5%',}}>
                  {permission === true ? (
                    <>
                          {coTwoData !={} ? (<Line options={winLegendOption2} data={coTwoData} />):null}
                    </>
                  ) : null}
                </Box>
              </div>
              <div hidden={value !== 0} role="tabpanel" value={value} index={0}>
                <Box sx={{ height: 150,width:'97.5%', marginLeft:-0.5}}>
                  {graphDataObj && (
                    <>
                          <Line options={options} data={graphDataObj} />
                    </>
                  )}
                </Box>
              </div>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}

const mapDispatchToProps = {
  getSensor: getSensorInfo,
};
export default connect(null, mapDispatchToProps)(SensorReport);
