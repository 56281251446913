import {
  Avatar,
  Button,
  Card,
  CardContent,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect } from "react";
import { getAllSites, getSite } from "../features/counter/APIs";
import { connect, useSelector } from "react-redux";
import axios from "../axios";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import ICONS from '../features/images';
function SensorTrack(props) {
  let nav = useNavigate();
  const [tracking, setTracking] = useState([]);
  const [sensorId, setsensorid] = useSearchParams();
  const [sensorData, setsensorData] = useState({});
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    axios
      .get(
        `v1/gait/sensor/view_sensor_tracker_info?sensorId=${sensorId.get(
          "sensorid"
        )}`
      )
      .then((res) => {
        setTracking(res.data.gaitObj);
      });
    setLoading(true);
    axios
      .get(
        `v1/gait/sensor/view_sensor_info_byid?sensorId=${sensorId.get(
          "sensorid"
        )}`
      )
      .then((res) => {
        setsensorData(res.data.gaitObj);
      });
    setLoading(false);
  }, []);

  return (
    <>
      {loading ? (
        <div>
          <img
            src={ICONS.SignalGreen}
            style={{
              width: "5%",
              position: "absolute",
              top: "50%",
              left: "50%",
            }}
          />
        </div>
      ) : (
        <>
          <Grid
            container
            spacing={2}
            style={{ marginTop: 5, alignItems: "stretch" }}
            alignItems="start"
          >
            <Grid item lg={12}>
              <Card className="card">
                <CardContent style={{ padding: 10 }}>
                  <Grid container spacing={1}>
                    <Grid item lg={4} className="track-card">
                      <Card className="card card-grey">
                        <div className="">
                          <div className="f-icon">
                            <img width={"10%"} src={ICONS.reportIcon} />
                          </div>
                          <div className="card-items">
                            <p>
                              Sensor ID:{" "}
                              <span className="s-info">
                                {sensorData?.sensorid}
                              </span>
                            </p>
                            <p>
                              Sensor Make:{" "}
                              <span className="s-info">
                                {sensorData?.sensormodel}
                              </span>
                            </p>
                            <p>
                              Sensor Make:{" "}
                              <span className="s-info">
                                {" "}
                                {sensorData?.sensortype}
                              </span>
                            </p>
                            <p>
                              Sensor Manufacturing year111:{" "}
                              <span className="s-info">
                                {sensorData?.sensoryear}
                              </span>
                            </p>
                            <p>
                              Sensor Make:{" "}
                              <span className="s-info">{sensorData?.make}</span>
                            </p>
                            <p>
                              Sensor Life :{" "}
                              <span className="s-info">
                                {sensorData?.lifespan}
                              </span>
                            </p>
                          </div>
                        </div>
                      </Card>
                    </Grid>
                    <Grid item lg={4} className="track-card">
                      <Card className="card card-grey">
                        <div className="card-items">
                          <div className="bx-area">
                            <img width={"10%"} src={ICONS.bxArea} />
                          </div>
                          <div className="bx-items">
                            <p>
                              Sensor Location:{" "}
                              <span className="s-info">
                                {tracking[tracking.length - 1]?.location}
                              </span>{" "}
                            </p>
                            <p>
                              State:{" "}
                              <span className="s-info">
                                {tracking[tracking.length - 1]?.state}{" "}
                              </span>
                            </p>
                          </div>
                        </div>
                        <div className="card-items">
                          <div className="bx-area">
                            <img width={"10%"} src={ICONS.Map_Location} />
                          </div>
                          <div className="bx-items">
                            <p>
                              Sensor Coordinates:{" "}
                              <span className="s-info">
                                {tracking[tracking.length - 1]?.lattitude},{" "}
                                {tracking[tracking.length - 1]?.longitude}
                              </span>
                            </p>
                          </div>
                        </div>
                        <div className="card-items">
                          <div className="bx-items">
                            <p>
                              Status :{" "}
                              <Button
                                style={{ borderColor: "green", color: "green" }}
                                variant="outlined"
                              >
                                <img
                                  height={20}
                                  src={ICONS.Single_Green}
                                  style={{ marginRight: 5 }}
                                />{" "}
                                {tracking[tracking.length - 1]?.sensorstage}
                              </Button>
                            </p>
                          </div>
                        </div>
                      </Card>
                    </Grid>
                    <Grid item lg={4} className="track-card">
              <Card className="card card-grey">
                <CardContent
                  style={{ border: "none", background: "transparant" }}
                >
                  <Grid style={{ marginTop: 0 }} container alignItems={"top"}>
                    <div className="card-items">
                      <div className="bx-area">
                        <Avatar
                          sx={{
                            bgcolor: "#55AA8B",
                            fontSize: 46,
                            width: "70px",
                            height: "70px",
                          }}
                          alt="S"
                          src="ss"
                        />
                      </div>
                      <div className="bx-items">
                        <p>
                          Proprietor Name: <span>{"Santosh Chanda"}</span>
                        </p>

                        <p>
                          Phone no: <span> {"+91 9987876545"}</span>
                        </p>

                        <p>
                          Alternate no: <span>{"+91 9822546652"}</span>{" "}
                        </p>

                        <p>
                          Email: <span>{"santosh@gaitglobal.com"}</span>
                        </p>
                      </div>
                    </div>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
           
          </Grid>
          <h3
            style={{
              paddingTop: "20px",
              paddingBottom: "20px",
              display: "block",
            }}
          >
            Sensor Previous history{" "}
            <Button
              onClick={() =>
                nav("/add-track", { state: { sensorID: sensorData?.sensorid } })
              }
              style={{ background: "black", color: "#fff", float: "right" }}
              variant={"filled"}
            >
              Add Track
            </Button>
          </h3>
          {tracking.length > 0 ? (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Country</TableCell>
                    <TableCell>Location</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Proprietor</TableCell>
                    <TableCell>Stage</TableCell>
                    <TableCell>Notes</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tracking?.map((row) => (
                    <TableRow
                      key={row.name}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.country || "N/A"}
                      </TableCell>
                      <TableCell>{row.location || "N/A"}</TableCell>
                      <TableCell>
                        {new Date(row.insertdate || "N/A").toDateString()}
                      </TableCell>
                      <TableCell>
                        {row.fname || "N/A"} {row.lname}
                      </TableCell>
                      <TableCell>{row.sensorstage || "N/A"}</TableCell>
                      <TableCell className="track-tblcell">{row.notes || "N/A"}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <h4 style={{ color: "#555" }}>No Data Avaialable</h4>
          )}
        </>
      )}
    </>
  );
}
const mapDispatchToProps = {
  getAllSites: getAllSites,
  getCurrentSite: getSite,
};

export default connect(null, mapDispatchToProps)(SensorTrack);
