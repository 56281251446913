import React from "react";
import Calendar from "../../src/assets/svgs/calendar_report.svg";
import SettingGif from "../../src/assets/gifs/69362-green-setting-wheel.gif";
import { useState } from "react";
import { useEffect } from "react";
import Plygon from '../assets/svgs/polygon-55.svg';
let arrr = {
  1: {
    Fc_mass: -0.01363062,
    C_Fc_mass: -0.0158,
    date: "12 Jan 2022",

    data: [
      {
        Date: "2022-01-12",
        Time: "06:00:00",
        Type: "OneMinData",
        Iteration: 1,
        AirTemp: 11.65924,
        CO2: 738.5578,
        H2O: 4.09857,
        Wind_Ux: 1.470667,
        Wind_Uy: 0.8065468,
        Wind_Uz: 0.1402707,
        Fc_mass: null,
        I_AirTemp: 11.65924,
        I_CO2: 738.5578,
        I_H2O: 4.09857,
        I_Wind_Ux: 1.470667,
        I_Wind_Uy: 0.8065468,
        I_Wind_Uz: 0.1402707,
        C_Fc_mass: null,
      },
      {
        Date: "2022-01-12",
        Time: "06:01:00",
        Type: "OneMinData",
        Iteration: 1,
        AirTemp: 11.5101,
        CO2: 739.3511,
        H2O: 4.150342,
        Wind_Ux: 1.515636,
        Wind_Uy: 0.7066514,
        Wind_Uz: 0.1492309,
        Fc_mass: null,
        I_AirTemp: 11.5101,
        I_CO2: 739.3511,
        I_H2O: 4.150342,
        I_Wind_Ux: 1.515636,
        I_Wind_Uy: 0.7066514,
        I_Wind_Uz: 0.1492309,
        C_Fc_mass: null,
      },
      {
        Date: "2022-01-12",
        Time: "06:02:00",
        Type: "OneMinData",
        Iteration: 1,
        AirTemp: 11.49798,
        CO2: 740.2138,
        H2O: 4.021264,
        Wind_Ux: 1.332546,
        Wind_Uy: 0.5360264,
        Wind_Uz: 0.1638719,
        Fc_mass: null,
        I_AirTemp: 11.49798,
        I_CO2: 740.2138,
        I_H2O: 4.021264,
        I_Wind_Ux: 1.332546,
        I_Wind_Uy: 0.5360264,
        I_Wind_Uz: 0.1638719,
        C_Fc_mass: null,
      },
      {
        Date: "2022-01-12",
        Time: "06:03:00",
        Type: "OneMinData",
        Iteration: 1,
        AirTemp: 11.52441,
        CO2: 740.8283,
        H2O: 3.759328,
        Wind_Ux: 1.382029,
        Wind_Uy: 0.2790947,
        Wind_Uz: 0.1310679,
        Fc_mass: null,
        I_AirTemp: 11.52441,
        I_CO2: 740.8283,
        I_H2O: 3.759328,
        I_Wind_Ux: 1.382029,
        I_Wind_Uy: 0.2790947,
        I_Wind_Uz: 0.1310679,
        C_Fc_mass: null,
      },
      {
        Date: "2022-01-12",
        Time: "06:04:00",
        Type: "OneMinData",
        Iteration: 1,
        AirTemp: null,
        CO2: null,
        H2O: null,
        Wind_Ux: null,
        Wind_Uy: null,
        Wind_Uz: null,
        Fc_mass: null,
        I_AirTemp: 11.50923,
        I_CO2: 740.8914,
        I_H2O: 3.791516,
        I_Wind_Ux: 1.269387,
        I_Wind_Uy: 0.2196972,
        I_Wind_Uz: 0.105632,
        C_Fc_mass: null,
      },
      {
        Date: "2022-01-12",
        Time: "06:05:00",
        Type: "OneMinData",
        Iteration: 1,
        AirTemp: null,
        CO2: null,
        H2O: null,
        Wind_Ux: null,
        Wind_Uy: null,
        Wind_Uz: null,
        Fc_mass: null,
        I_AirTemp: 11.62093,
        I_CO2: 740.4295,
        I_H2O: 3.732685,
        I_Wind_Ux: 1.208043,
        I_Wind_Uy: 0.300555,
        I_Wind_Uz: 0.1239022,
        C_Fc_mass: null,
      },
      {
        Date: "2022-01-12",
        Time: "06:06:00",
        Type: "OneMinData",
        Iteration: 1,
        AirTemp: 11.45961,
        CO2: 740.8734,
        H2O: 3.845378,
        Wind_Ux: 1.218919,
        Wind_Uy: 0.1339009,
        Wind_Uz: 0.108454,
        Fc_mass: null,
        I_AirTemp: 11.45961,
        I_CO2: 740.8734,
        I_H2O: 3.845378,
        I_Wind_Ux: 1.218919,
        I_Wind_Uy: 0.1339009,
        I_Wind_Uz: 0.108454,
        C_Fc_mass: null,
      },
      {
        Date: "2022-01-12",
        Time: "06:07:00",
        Type: "OneMinData",
        Iteration: 1,
        AirTemp: 11.18246,
        CO2: 742.3125,
        H2O: 3.864009,
        Wind_Ux: 1.142292,
        Wind_Uy: -0.08303146,
        Wind_Uz: 0.08151239,
        Fc_mass: null,
        I_AirTemp: 11.18246,
        I_CO2: 742.3125,
        I_H2O: 3.864009,
        I_Wind_Ux: 1.142292,
        I_Wind_Uy: -0.08303146,
        I_Wind_Uz: 0.08151239,
        C_Fc_mass: null,
      },
      {
        Date: "2022-01-12",
        Time: "06:08:00",
        Type: "OneMinData",
        Iteration: 1,
        AirTemp: 10.75501,
        CO2: 748.0745,
        H2O: 3.831086,
        Wind_Ux: 1.18972,
        Wind_Uy: -0.5433043,
        Wind_Uz: 0.100656,
        Fc_mass: null,
        I_AirTemp: 10.75501,
        I_CO2: 748.0745,
        I_H2O: 3.831086,
        I_Wind_Ux: 1.18972,
        I_Wind_Uy: -0.5433043,
        I_Wind_Uz: 0.100656,
        C_Fc_mass: null,
      },
      {
        Date: "2022-01-12",
        Time: "06:09:00",
        Type: "OneMinData",
        Iteration: 1,
        AirTemp: 10.74237,
        CO2: 745.1129,
        H2O: 3.759979,
        Wind_Ux: 1.37446,
        Wind_Uy: -0.345061,
        Wind_Uz: 0.09439423,
        Fc_mass: null,
        I_AirTemp: 10.74237,
        I_CO2: 745.1129,
        I_H2O: 3.759979,
        I_Wind_Ux: 1.37446,
        I_Wind_Uy: -0.345061,
        I_Wind_Uz: 0.09439423,
        C_Fc_mass: null,
      },
      {
        Date: "2022-01-12",
        Time: "06:10:00",
        Type: "OneMinData",
        Iteration: 1,
        AirTemp: 10.72361,
        CO2: 743.9594,
        H2O: 3.750088,
        Wind_Ux: 1.455156,
        Wind_Uy: -0.2750689,
        Wind_Uz: 0.1199239,
        Fc_mass: null,
        I_AirTemp: 10.72361,
        I_CO2: 743.9594,
        I_H2O: 3.750088,
        I_Wind_Ux: 1.455156,
        I_Wind_Uy: -0.2750689,
        I_Wind_Uz: 0.1199239,
        C_Fc_mass: null,
      },
      {
        Date: "2022-01-12",
        Time: "06:11:00",
        Type: "OneMinData",
        Iteration: 1,
        AirTemp: 11.11453,
        CO2: 741.4914,
        H2O: 3.603058,
        Wind_Ux: 1.630838,
        Wind_Uy: -0.2001288,
        Wind_Uz: 0.1506114,
        Fc_mass: null,
        I_AirTemp: 11.11453,
        I_CO2: 741.4914,
        I_H2O: 3.603058,
        I_Wind_Ux: 1.630838,
        I_Wind_Uy: -0.2001288,
        I_Wind_Uz: 0.1506114,
        C_Fc_mass: null,
      },
      {
        Date: "2022-01-12",
        Time: "06:12:00",
        Type: "OneMinData",
        Iteration: 1,
        AirTemp: 11.24038,
        CO2: 739.9075,
        H2O: 3.52444,
        Wind_Ux: 1.486418,
        Wind_Uy: -0.2863784,
        Wind_Uz: 0.1198662,
        Fc_mass: null,
        I_AirTemp: 11.24038,
        I_CO2: 739.9075,
        I_H2O: 3.52444,
        I_Wind_Ux: 1.486418,
        I_Wind_Uy: -0.2863784,
        I_Wind_Uz: 0.1198662,
        C_Fc_mass: null,
      },
      {
        Date: "2022-01-12",
        Time: "06:13:00",
        Type: "OneMinData",
        Iteration: 1,
        AirTemp: 10.08168,
        CO2: 747.3147,
        H2O: 3.788838,
        Wind_Ux: 1.27848,
        Wind_Uy: -0.4787713,
        Wind_Uz: 0.09874999,
        Fc_mass: null,
        I_AirTemp: 10.08168,
        I_CO2: 747.3147,
        I_H2O: 3.788838,
        I_Wind_Ux: 1.27848,
        I_Wind_Uy: -0.4787713,
        I_Wind_Uz: 0.09874999,
        C_Fc_mass: null,
      },
      {
        Date: "2022-01-12",
        Time: "06:14:00",
        Type: "OneMinData",
        Iteration: 1,
        AirTemp: 10.36104,
        CO2: 745.8706,
        H2O: 3.816497,
        Wind_Ux: 1.503747,
        Wind_Uy: -0.4802914,
        Wind_Uz: 0.1121024,
        Fc_mass: null,
        I_AirTemp: 10.36104,
        I_CO2: 745.8706,
        I_H2O: 3.816497,
        I_Wind_Ux: 1.503747,
        I_Wind_Uy: -0.4802914,
        I_Wind_Uz: 0.1121024,
        C_Fc_mass: null,
      },
      {
        Date: "2022-01-12",
        Time: "06:15:00",
        Type: "OneMinData",
        Iteration: 1,
        AirTemp: 10.58682,
        CO2: 744.5173,
        H2O: 3.717074,
        Wind_Ux: 1.44006,
        Wind_Uy: -0.7191757,
        Wind_Uz: 0.1184872,
        Fc_mass: null,
        I_AirTemp: 10.58682,
        I_CO2: 744.5173,
        I_H2O: 3.717074,
        I_Wind_Ux: 1.44006,
        I_Wind_Uy: -0.7191757,
        I_Wind_Uz: 0.1184872,
        C_Fc_mass: null,
      },
      {
        Date: "2022-01-12",
        Time: "06:16:00",
        Type: "OneMinData",
        Iteration: 1,
        AirTemp: 9.495498,
        CO2: 753.0328,
        H2O: 3.886073,
        Wind_Ux: 1.297804,
        Wind_Uy: -0.5767738,
        Wind_Uz: 0.09477905,
        Fc_mass: null,
        I_AirTemp: 9.495498,
        I_CO2: 753.0328,
        I_H2O: 3.886073,
        I_Wind_Ux: 1.297804,
        I_Wind_Uy: -0.5767738,
        I_Wind_Uz: 0.09477905,
        C_Fc_mass: null,
      },
      {
        Date: "2022-01-12",
        Time: "06:17:00",
        Type: "OneMinData",
        Iteration: 1,
        AirTemp: 9.957478,
        CO2: 750.4612,
        H2O: 3.904684,
        Wind_Ux: 1.385651,
        Wind_Uy: -0.7418017,
        Wind_Uz: 0.07456745,
        Fc_mass: null,
        I_AirTemp: 9.957478,
        I_CO2: 750.4612,
        I_H2O: 3.904684,
        I_Wind_Ux: 1.385651,
        I_Wind_Uy: -0.7418017,
        I_Wind_Uz: 0.07456745,
        C_Fc_mass: null,
      },
      {
        Date: "2022-01-12",
        Time: "06:18:00",
        Type: "OneMinData",
        Iteration: 1,
        AirTemp: 11.09418,
        CO2: 739.9556,
        H2O: 3.837958,
        Wind_Ux: 1.424498,
        Wind_Uy: -1.128538,
        Wind_Uz: 0.144419,
        Fc_mass: null,
        I_AirTemp: 11.09418,
        I_CO2: 739.9556,
        I_H2O: 3.837958,
        I_Wind_Ux: 1.424498,
        I_Wind_Uy: -1.128538,
        I_Wind_Uz: 0.144419,
        C_Fc_mass: null,
      },
      {
        Date: "2022-01-12",
        Time: "06:19:00",
        Type: "OneMinData",
        Iteration: 1,
        AirTemp: 11.11318,
        CO2: 739.161,
        H2O: 3.929289,
        Wind_Ux: 1.128501,
        Wind_Uy: -1.080629,
        Wind_Uz: 0.1390757,
        Fc_mass: null,
        I_AirTemp: 11.11318,
        I_CO2: 739.161,
        I_H2O: 3.929289,
        I_Wind_Ux: 1.128501,
        I_Wind_Uy: -1.080629,
        I_Wind_Uz: 0.1390757,
        C_Fc_mass: null,
      },
      {
        Date: "2022-01-12",
        Time: "06:20:00",
        Type: "OneMinData",
        Iteration: 1,
        AirTemp: 10.5521,
        CO2: 743.7126,
        H2O: 3.91616,
        Wind_Ux: 1.375406,
        Wind_Uy: -0.6571407,
        Wind_Uz: 0.08858318,
        Fc_mass: null,
        I_AirTemp: 10.5521,
        I_CO2: 743.7126,
        I_H2O: 3.91616,
        I_Wind_Ux: 1.375406,
        I_Wind_Uy: -0.6571407,
        I_Wind_Uz: 0.08858318,
        C_Fc_mass: null,
      },
      {
        Date: "2022-01-12",
        Time: "06:21:00",
        Type: "OneMinData",
        Iteration: 1,
        AirTemp: 10.03202,
        CO2: 748.6844,
        H2O: 3.880173,
        Wind_Ux: 1.021274,
        Wind_Uy: -1.053248,
        Wind_Uz: 0.05127,
        Fc_mass: null,
        I_AirTemp: 10.03202,
        I_CO2: 748.6844,
        I_H2O: 3.880173,
        I_Wind_Ux: 1.021274,
        I_Wind_Uy: -1.053248,
        I_Wind_Uz: 0.05127,
        C_Fc_mass: null,
      },
      {
        Date: "2022-01-12",
        Time: "06:22:00",
        Type: "OneMinData",
        Iteration: 1,
        AirTemp: 9.688617,
        CO2: 750.6696,
        H2O: 3.926718,
        Wind_Ux: 1.068765,
        Wind_Uy: -0.9309346,
        Wind_Uz: 0.08457393,
        Fc_mass: null,
        I_AirTemp: 9.688617,
        I_CO2: 750.6696,
        I_H2O: 3.926718,
        I_Wind_Ux: 1.068765,
        I_Wind_Uy: -0.9309346,
        I_Wind_Uz: 0.08457393,
        C_Fc_mass: null,
      },
      {
        Date: "2022-01-12",
        Time: "06:23:00",
        Type: "OneMinData",
        Iteration: 1,
        AirTemp: 10.28862,
        CO2: 745.4973,
        H2O: 3.745462,
        Wind_Ux: 0.7819139,
        Wind_Uy: -1.472341,
        Wind_Uz: 0.05606788,
        Fc_mass: null,
        I_AirTemp: 10.28862,
        I_CO2: 745.4973,
        I_H2O: 3.745462,
        I_Wind_Ux: 0.7819139,
        I_Wind_Uy: -1.472341,
        I_Wind_Uz: 0.05606788,
        C_Fc_mass: null,
      },
      {
        Date: "2022-01-12",
        Time: "06:24:00",
        Type: "OneMinData",
        Iteration: 1,
        AirTemp: 10.24312,
        CO2: 745.9455,
        H2O: 3.634818,
        Wind_Ux: 0.7924661,
        Wind_Uy: -1.441931,
        Wind_Uz: 0.02409453,
        Fc_mass: null,
        I_AirTemp: 10.24312,
        I_CO2: 745.9455,
        I_H2O: 3.634818,
        I_Wind_Ux: 0.7924661,
        I_Wind_Uy: -1.441931,
        I_Wind_Uz: 0.02409453,
        C_Fc_mass: null,
      },
      {
        Date: "2022-01-12",
        Time: "06:25:00",
        Type: "OneMinData",
        Iteration: 1,
        AirTemp: 10.90748,
        CO2: 741.4074,
        H2O: 3.537414,
        Wind_Ux: 0.9514754,
        Wind_Uy: -1.433327,
        Wind_Uz: 0.08156221,
        Fc_mass: null,
        I_AirTemp: 10.90748,
        I_CO2: 741.4074,
        I_H2O: 3.537414,
        I_Wind_Ux: 0.9514754,
        I_Wind_Uy: -1.433327,
        I_Wind_Uz: 0.08156221,
        C_Fc_mass: null,
      },
      {
        Date: "2022-01-12",
        Time: "06:26:00",
        Type: "OneMinData",
        Iteration: 1,
        AirTemp: 10.23095,
        CO2: 745.2474,
        H2O: 3.550903,
        Wind_Ux: 1.086444,
        Wind_Uy: -1.287737,
        Wind_Uz: 0.08209762,
        Fc_mass: null,
        I_AirTemp: 10.23095,
        I_CO2: 745.2474,
        I_H2O: 3.550903,
        I_Wind_Ux: 1.086444,
        I_Wind_Uy: -1.287737,
        I_Wind_Uz: 0.08209762,
        C_Fc_mass: null,
      },
      {
        Date: "2022-01-12",
        Time: "06:27:00",
        Type: "OneMinData",
        Iteration: 1,
        AirTemp: 9.522514,
        CO2: 754.3214,
        H2O: 3.707774,
        Wind_Ux: 0.6826549,
        Wind_Uy: -0.94411,
        Wind_Uz: 0.06919445,
        Fc_mass: null,
        I_AirTemp: 9.522514,
        I_CO2: 754.3214,
        I_H2O: 3.707774,
        I_Wind_Ux: 0.6826549,
        I_Wind_Uy: -0.94411,
        I_Wind_Uz: 0.06919445,
        C_Fc_mass: null,
      },
      {
        Date: "2022-01-12",
        Time: "06:28:00",
        Type: "OneMinData",
        Iteration: 1,
        AirTemp: 9.348112,
        CO2: 756.3326,
        H2O: 3.667652,
        Wind_Ux: 0.6750604,
        Wind_Uy: -0.7332163,
        Wind_Uz: 0.07568702,
        Fc_mass: null,
        I_AirTemp: 9.348112,
        I_CO2: 756.3326,
        I_H2O: 3.667652,
        I_Wind_Ux: 0.6750604,
        I_Wind_Uy: -0.7332163,
        I_Wind_Uz: 0.07568702,
        C_Fc_mass: null,
      },
      {
        Date: "2022-01-12",
        Time: "06:29:00",
        Type: "OneMinData",
        Iteration: 1,
        AirTemp: 10.74778,
        CO2: 744.4299,
        H2O: 3.424215,
        Wind_Ux: 0.4183888,
        Wind_Uy: -0.6705005,
        Wind_Uz: 0.02851401,
        Fc_mass: null,
        I_AirTemp: 10.74778,
        I_CO2: 744.4299,
        I_H2O: 3.424215,
        I_Wind_Ux: 0.4183888,
        I_Wind_Uy: -0.6705005,
        I_Wind_Uz: 0.02851401,
        C_Fc_mass: null,
      },
    ],
  },
  2: {
    Fc_mass: 0.03655518,
    C_Fc_mass: 0.047,
    date: "06 Feb 2022",
    data: [
      {
        Date: "2022-05-20",
        Time: "07:00:00",
        Type: "OneMinData",
        Iteration: 3,
        AirTemp: 10.9115,
        CO2: 746.7756,
        H2O: 3.064178,
        Wind_Ux: -0.008927758,
        Wind_Uy: -1.072981,
        Wind_Uz: 0.03720422,
        Fc_mass: null,
        I_AirTemp: 10.9115,
        I_CO2: 746.7756,
        I_H2O: 3.064178,
        I_Wind_Ux: -0.008927758,
        I_Wind_Uy: -1.072981,
        I_Wind_Uz: 0.03720422,
        C_Fc_mass: null,
      },
      {
        Date: "2022-05-20",
        Time: "07:01:00",
        Type: "OneMinData",
        Iteration: 3,
        AirTemp: 9.976029,
        CO2: 757.3302,
        H2O: 3.213065,
        Wind_Ux: 0.09275811,
        Wind_Uy: -1.013925,
        Wind_Uz: 0.04266751,
        Fc_mass: null,
        I_AirTemp: 9.976029,
        I_CO2: 757.3302,
        I_H2O: 3.213065,
        I_Wind_Ux: 0.09275811,
        I_Wind_Uy: -1.013925,
        I_Wind_Uz: 0.04266751,
        C_Fc_mass: null,
      },
      {
        Date: "2022-05-20",
        Time: "07:02:00",
        Type: "OneMinData",
        Iteration: 3,
        AirTemp: 10.51138,
        CO2: 749.0246,
        H2O: 3.12233,
        Wind_Ux: 0.372899,
        Wind_Uy: -1.036879,
        Wind_Uz: 0.02038316,
        Fc_mass: null,
        I_AirTemp: 10.51138,
        I_CO2: 749.0246,
        I_H2O: 3.12233,
        I_Wind_Ux: 0.372899,
        I_Wind_Uy: -1.036879,
        I_Wind_Uz: 0.02038316,
        C_Fc_mass: null,
      },
      {
        Date: "2022-05-20",
        Time: "07:03:00",
        Type: "OneMinData",
        Iteration: 3,
        AirTemp: 11.01794,
        CO2: 742.2494,
        H2O: 3.043673,
        Wind_Ux: 0.2903781,
        Wind_Uy: -0.9701636,
        Wind_Uz: 0.00713366,
        Fc_mass: null,
        I_AirTemp: 11.01794,
        I_CO2: 742.2494,
        I_H2O: 3.043673,
        I_Wind_Ux: 0.2903781,
        I_Wind_Uy: -0.9701636,
        I_Wind_Uz: 0.00713366,
        C_Fc_mass: null,
      },
      {
        Date: "2022-05-20",
        Time: "07:04:00",
        Type: "OneMinData",
        Iteration: 3,
        AirTemp: 10.04982,
        CO2: 755.435,
        H2O: 3.164368,
        Wind_Ux: 0.3272759,
        Wind_Uy: -1.041717,
        Wind_Uz: 0.0167562,
        Fc_mass: null,
        I_AirTemp: 10.04982,
        I_CO2: 755.435,
        I_H2O: 3.164368,
        I_Wind_Ux: 0.3272759,
        I_Wind_Uy: -1.041717,
        I_Wind_Uz: 0.0167562,
        C_Fc_mass: null,
      },
      {
        Date: "2022-05-20",
        Time: "07:05:00",
        Type: "OneMinData",
        Iteration: 3,
        AirTemp: 10.62208,
        CO2: 745.4863,
        H2O: 3.082397,
        Wind_Ux: 0.2207016,
        Wind_Uy: -1.088092,
        Wind_Uz: 0.01538051,
        Fc_mass: null,
        I_AirTemp: 10.62208,
        I_CO2: 745.4863,
        I_H2O: 3.082397,
        I_Wind_Ux: 0.2207016,
        I_Wind_Uy: -1.088092,
        I_Wind_Uz: 0.01538051,
        C_Fc_mass: null,
      },
      {
        Date: "2022-05-20",
        Time: "07:06:00",
        Type: "OneMinData",
        Iteration: 3,
        AirTemp: null,
        CO2: null,
        H2O: null,
        Wind_Ux: null,
        Wind_Uy: null,
        Wind_Uz: null,
        Fc_mass: null,
        I_AirTemp: 10.09115,
        I_CO2: 751.0179,
        I_H2O: 3.148126,
        I_Wind_Ux: 0.3928216,
        I_Wind_Uy: -0.9986901,
        I_Wind_Uz: 0.01035202,
        C_Fc_mass: null,
      },
      {
        Date: "2022-05-20",
        Time: "07:07:00",
        Type: "OneMinData",
        Iteration: 3,
        AirTemp: 9.710501,
        CO2: 752.755,
        H2O: 3.18469,
        Wind_Ux: 0.58919,
        Wind_Uy: -0.4350785,
        Wind_Uz: 0.01919194,
        Fc_mass: null,
        I_AirTemp: 9.710501,
        I_CO2: 752.755,
        I_H2O: 3.18469,
        I_Wind_Ux: 0.58919,
        I_Wind_Uy: -0.4350785,
        I_Wind_Uz: 0.01919194,
        C_Fc_mass: null,
      },
      {
        Date: "2022-05-20",
        Time: "07:08:00",
        Type: "OneMinData",
        Iteration: 3,
        AirTemp: 8.776397,
        CO2: 765.492,
        H2O: 3.332897,
        Wind_Ux: 0.3263594,
        Wind_Uy: -0.01701019,
        Wind_Uz: -0.000570647,
        Fc_mass: null,
        I_AirTemp: 8.776397,
        I_CO2: 765.492,
        I_H2O: 3.332897,
        I_Wind_Ux: 0.3263594,
        I_Wind_Uy: -0.01701019,
        I_Wind_Uz: -0.000570647,
        C_Fc_mass: null,
      },
      {
        Date: "2022-05-20",
        Time: "07:09:00",
        Type: "OneMinData",
        Iteration: 3,
        AirTemp: 8.850356,
        CO2: 763.1762,
        H2O: 3.353882,
        Wind_Ux: 0.2454598,
        Wind_Uy: -0.395022,
        Wind_Uz: -0.03430083,
        Fc_mass: null,
        I_AirTemp: 8.850356,
        I_CO2: 763.1762,
        I_H2O: 3.353882,
        I_Wind_Ux: 0.2454598,
        I_Wind_Uy: -0.395022,
        I_Wind_Uz: -0.03430083,
        C_Fc_mass: null,
      },
      {
        Date: "2022-05-20",
        Time: "07:10:00",
        Type: "OneMinData",
        Iteration: 3,
        AirTemp: 8.097466,
        CO2: 769.955,
        H2O: 3.450075,
        Wind_Ux: 0.4478738,
        Wind_Uy: -0.6455155,
        Wind_Uz: 0.01145799,
        Fc_mass: null,
        I_AirTemp: 8.097466,
        I_CO2: 769.955,
        I_H2O: 3.450075,
        I_Wind_Ux: 0.4478738,
        I_Wind_Uy: -0.6455155,
        I_Wind_Uz: 0.01145799,
        C_Fc_mass: null,
      },
      {
        Date: "2022-05-20",
        Time: "07:11:00",
        Type: "OneMinData",
        Iteration: 3,
        AirTemp: 9.739504,
        CO2: 750.57,
        H2O: 3.211802,
        Wind_Ux: 0.6873345,
        Wind_Uy: -1.156526,
        Wind_Uz: 0.04520511,
        Fc_mass: null,
        I_AirTemp: 9.739504,
        I_CO2: 750.57,
        I_H2O: 3.211802,
        I_Wind_Ux: 0.6873345,
        I_Wind_Uy: -1.156526,
        I_Wind_Uz: 0.04520511,
        C_Fc_mass: null,
      },
      {
        Date: "2022-05-20",
        Time: "07:12:00",
        Type: "OneMinData",
        Iteration: 3,
        AirTemp: 9.296772,
        CO2: 756.4757,
        H2O: 3.272756,
        Wind_Ux: 0.6067209,
        Wind_Uy: -0.9004603,
        Wind_Uz: 0.03066237,
        Fc_mass: null,
        I_AirTemp: 9.296772,
        I_CO2: 756.4757,
        I_H2O: 3.272756,
        I_Wind_Ux: 0.6067209,
        I_Wind_Uy: -0.9004603,
        I_Wind_Uz: 0.03066237,
        C_Fc_mass: null,
      },
      {
        Date: "2022-05-20",
        Time: "07:13:00",
        Type: "OneMinData",
        Iteration: 3,
        AirTemp: 9.700702,
        CO2: 750.2939,
        H2O: 3.207861,
        Wind_Ux: 0.8112201,
        Wind_Uy: -0.7544738,
        Wind_Uz: 0.05222081,
        Fc_mass: null,
        I_AirTemp: 9.700702,
        I_CO2: 750.2939,
        I_H2O: 3.207861,
        I_Wind_Ux: 0.8112201,
        I_Wind_Uy: -0.7544738,
        I_Wind_Uz: 0.05222081,
        C_Fc_mass: null,
      },
      {
        Date: "2022-05-20",
        Time: "07:14:00",
        Type: "OneMinData",
        Iteration: 3,
        AirTemp: 9.502628,
        CO2: 755.8762,
        H2O: 3.270585,
        Wind_Ux: 0.8144755,
        Wind_Uy: -0.7832854,
        Wind_Uz: 0.07027414,
        Fc_mass: null,
        I_AirTemp: 9.502628,
        I_CO2: 755.8762,
        I_H2O: 3.270585,
        I_Wind_Ux: 0.8144755,
        I_Wind_Uy: -0.7832854,
        I_Wind_Uz: 0.07027414,
        C_Fc_mass: null,
      },
      {
        Date: "2022-05-20",
        Time: "07:15:00",
        Type: "OneMinData",
        Iteration: 3,
        AirTemp: 9.959581,
        CO2: 755.6049,
        H2O: 3.22534,
        Wind_Ux: 0.6842491,
        Wind_Uy: -0.737146,
        Wind_Uz: 0.05082466,
        Fc_mass: null,
        I_AirTemp: 9.959581,
        I_CO2: 755.6049,
        I_H2O: 3.22534,
        I_Wind_Ux: 0.6842491,
        I_Wind_Uy: -0.737146,
        I_Wind_Uz: 0.05082466,
        C_Fc_mass: null,
      },
      {
        Date: "2022-05-20",
        Time: "07:16:00",
        Type: "OneMinData",
        Iteration: 3,
        AirTemp: 9.462995,
        CO2: 767.8474,
        H2O: 3.320194,
        Wind_Ux: 0.7180842,
        Wind_Uy: -0.6208038,
        Wind_Uz: 0.1056874,
        Fc_mass: null,
        I_AirTemp: 9.462995,
        I_CO2: 767.8474,
        I_H2O: 3.320194,
        I_Wind_Ux: 0.7180842,
        I_Wind_Uy: -0.6208038,
        I_Wind_Uz: 0.1056874,
        C_Fc_mass: null,
      },
      {
        Date: "2022-05-20",
        Time: "07:17:00",
        Type: "OneMinData",
        Iteration: 3,
        AirTemp: 9.399499,
        CO2: 759.7502,
        H2O: 3.290419,
        Wind_Ux: 0.5161609,
        Wind_Uy: -1.01791,
        Wind_Uz: 0.05959733,
        Fc_mass: null,
        I_AirTemp: 9.399499,
        I_CO2: 759.7502,
        I_H2O: 3.290419,
        I_Wind_Ux: 0.5161609,
        I_Wind_Uy: -1.01791,
        I_Wind_Uz: 0.05959733,
        C_Fc_mass: null,
      },
      {
        Date: "2022-05-20",
        Time: "07:18:00",
        Type: "OneMinData",
        Iteration: 3,
        AirTemp: 9.139059,
        CO2: 761.2161,
        H2O: 3.317863,
        Wind_Ux: 0.5277132,
        Wind_Uy: -0.9501142,
        Wind_Uz: 0.0529433,
        Fc_mass: null,
        I_AirTemp: 9.139059,
        I_CO2: 761.2161,
        I_H2O: 3.317863,
        I_Wind_Ux: 0.5277132,
        I_Wind_Uy: -0.9501142,
        I_Wind_Uz: 0.0529433,
        C_Fc_mass: null,
      },
      {
        Date: "2022-05-20",
        Time: "07:19:00",
        Type: "OneMinData",
        Iteration: 3,
        AirTemp: 8.948771,
        CO2: 763.7808,
        H2O: 3.350034,
        Wind_Ux: 0.6373171,
        Wind_Uy: -1.142377,
        Wind_Uz: 0.06045646,
        Fc_mass: null,
        I_AirTemp: 8.948771,
        I_CO2: 763.7808,
        I_H2O: 3.350034,
        I_Wind_Ux: 0.6373171,
        I_Wind_Uy: -1.142377,
        I_Wind_Uz: 0.06045646,
        C_Fc_mass: null,
      },
      {
        Date: "2022-05-20",
        Time: "07:20:00",
        Type: "OneMinData",
        Iteration: 3,
        AirTemp: 9.45038,
        CO2: 754.323,
        H2O: 3.248923,
        Wind_Ux: 0.5175056,
        Wind_Uy: -0.9716914,
        Wind_Uz: 0.03784319,
        Fc_mass: null,
        I_AirTemp: 9.45038,
        I_CO2: 754.323,
        I_H2O: 3.248923,
        I_Wind_Ux: 0.5175056,
        I_Wind_Uy: -0.9716914,
        I_Wind_Uz: 0.03784319,
        C_Fc_mass: null,
      },
      {
        Date: "2022-05-20",
        Time: "07:21:00",
        Type: "OneMinData",
        Iteration: 3,
        AirTemp: 9.285898,
        CO2: 757.1162,
        H2O: 3.269223,
        Wind_Ux: 0.484733,
        Wind_Uy: -1.509426,
        Wind_Uz: 0.0670758,
        Fc_mass: null,
        I_AirTemp: 9.285898,
        I_CO2: 757.1162,
        I_H2O: 3.269223,
        I_Wind_Ux: 0.484733,
        I_Wind_Uy: -1.509426,
        I_Wind_Uz: 0.0670758,
        C_Fc_mass: null,
      },
      {
        Date: "2022-05-20",
        Time: "07:22:00",
        Type: "OneMinData",
        Iteration: 3,
        AirTemp: 10.0129,
        CO2: 746.5789,
        H2O: 3.119435,
        Wind_Ux: 0.4164486,
        Wind_Uy: -1.554895,
        Wind_Uz: 0.03664897,
        Fc_mass: null,
        I_AirTemp: 10.0129,
        I_CO2: 746.5789,
        I_H2O: 3.119435,
        I_Wind_Ux: 0.4164486,
        I_Wind_Uy: -1.554895,
        I_Wind_Uz: 0.03664897,
        C_Fc_mass: null,
      },
      {
        Date: "2022-05-20",
        Time: "07:23:00",
        Type: "OneMinData",
        Iteration: 3,
        AirTemp: 9.7817,
        CO2: 748.8637,
        H2O: 3.152918,
        Wind_Ux: 0.4395119,
        Wind_Uy: -1.68613,
        Wind_Uz: 0.02987245,
        Fc_mass: null,
        I_AirTemp: 9.7817,
        I_CO2: 748.8637,
        I_H2O: 3.152918,
        I_Wind_Ux: 0.4395119,
        I_Wind_Uy: -1.68613,
        I_Wind_Uz: 0.02987245,
        C_Fc_mass: null,
      },
      {
        Date: "2022-05-20",
        Time: "07:24:00",
        Type: "OneMinData",
        Iteration: 3,
        AirTemp: 9.847813,
        CO2: 748.5406,
        H2O: 3.129513,
        Wind_Ux: 0.3645982,
        Wind_Uy: -1.781017,
        Wind_Uz: 0.023607,
        Fc_mass: null,
        I_AirTemp: 9.847813,
        I_CO2: 748.5406,
        I_H2O: 3.129513,
        I_Wind_Ux: 0.3645982,
        I_Wind_Uy: -1.781017,
        I_Wind_Uz: 0.023607,
        C_Fc_mass: null,
      },
      {
        Date: "2022-05-20",
        Time: "07:25:00",
        Type: "OneMinData",
        Iteration: 3,
        AirTemp: 9.616764,
        CO2: 750.4124,
        H2O: 3.153421,
        Wind_Ux: 0.3829362,
        Wind_Uy: -1.740901,
        Wind_Uz: 0.04060952,
        Fc_mass: null,
        I_AirTemp: 9.616764,
        I_CO2: 750.4124,
        I_H2O: 3.153421,
        I_Wind_Ux: 0.3829362,
        I_Wind_Uy: -1.740901,
        I_Wind_Uz: 0.04060952,
        C_Fc_mass: null,
      },
      {
        Date: "2022-05-20",
        Time: "07:26:00",
        Type: "OneMinData",
        Iteration: 3,
        AirTemp: 8.489395,
        CO2: 762.5535,
        H2O: 3.323064,
        Wind_Ux: 0.5075926,
        Wind_Uy: -1.374457,
        Wind_Uz: 0.01954826,
        Fc_mass: null,
        I_AirTemp: 8.489395,
        I_CO2: 762.5535,
        I_H2O: 3.323064,
        I_Wind_Ux: 0.5075926,
        I_Wind_Uy: -1.374457,
        I_Wind_Uz: 0.01954826,
        C_Fc_mass: null,
      },
      {
        Date: "2022-05-20",
        Time: "07:27:00",
        Type: "OneMinData",
        Iteration: 3,
        AirTemp: 8.35305,
        CO2: 762.393,
        H2O: 3.385412,
        Wind_Ux: 0.718091,
        Wind_Uy: -1.148617,
        Wind_Uz: -0.01203962,
        Fc_mass: null,
        I_AirTemp: 8.35305,
        I_CO2: 762.393,
        I_H2O: 3.385412,
        I_Wind_Ux: 0.718091,
        I_Wind_Uy: -1.148617,
        I_Wind_Uz: -0.01203962,
        C_Fc_mass: null,
      },
      {
        Date: "2022-05-20",
        Time: "07:28:00",
        Type: "OneMinData",
        Iteration: 3,
        AirTemp: 8.882098,
        CO2: 754.9913,
        H2O: 3.30683,
        Wind_Ux: 1.011721,
        Wind_Uy: -1.559508,
        Wind_Uz: 0.04091675,
        Fc_mass: null,
        I_AirTemp: 8.882098,
        I_CO2: 754.9913,
        I_H2O: 3.30683,
        I_Wind_Ux: 1.011721,
        I_Wind_Uy: -1.559508,
        I_Wind_Uz: 0.04091675,
        C_Fc_mass: null,
      },
      {
        Date: "2022-05-20",
        Time: "07:29:00",
        Type: "OneMinData",
        Iteration: 3,
        AirTemp: 9.297639,
        CO2: 751.251,
        H2O: 3.251577,
        Wind_Ux: 1.312729,
        Wind_Uy: -1.74025,
        Wind_Uz: 0.0744537,
        Fc_mass: null,
        I_AirTemp: 9.297639,
        I_CO2: 751.251,
        I_H2O: 3.251577,
        I_Wind_Ux: 1.312729,
        I_Wind_Uy: -1.74025,
        I_Wind_Uz: 0.0744537,
        C_Fc_mass: null,
      },
    ],
  },
  3: {
    Fc_mass: 0.1516892,
    C_Fc_mass: 0.134,
    date: "20 May 2022",
    data: [
      {
        Date: "2022-05-20",
        Time: "07:00:00",
        Type: "OneMinData",
        Iteration: 3,
        AirTemp: 10.9115,
        CO2: 746.7756,
        H2O: 3.064178,
        Wind_Ux: -0.008927758,
        Wind_Uy: -1.072981,
        Wind_Uz: 0.03720422,
        Fc_mass: null,
        I_AirTemp: 10.9115,
        I_CO2: 746.7756,
        I_H2O: 3.064178,
        I_Wind_Ux: -0.008927758,
        I_Wind_Uy: -1.072981,
        I_Wind_Uz: 0.03720422,
        C_Fc_mass: null,
      },
      {
        Date: "2022-05-20",
        Time: "07:01:00",
        Type: "OneMinData",
        Iteration: 3,
        AirTemp: 9.976029,
        CO2: 757.3302,
        H2O: 3.213065,
        Wind_Ux: 0.09275811,
        Wind_Uy: -1.013925,
        Wind_Uz: 0.04266751,
        Fc_mass: null,
        I_AirTemp: 9.976029,
        I_CO2: 757.3302,
        I_H2O: 3.213065,
        I_Wind_Ux: 0.09275811,
        I_Wind_Uy: -1.013925,
        I_Wind_Uz: 0.04266751,
        C_Fc_mass: null,
      },
      {
        Date: "2022-05-20",
        Time: "07:02:00",
        Type: "OneMinData",
        Iteration: 3,
        AirTemp: 10.51138,
        CO2: 749.0246,
        H2O: 3.12233,
        Wind_Ux: 0.372899,
        Wind_Uy: -1.036879,
        Wind_Uz: 0.02038316,
        Fc_mass: null,
        I_AirTemp: 10.51138,
        I_CO2: 749.0246,
        I_H2O: 3.12233,
        I_Wind_Ux: 0.372899,
        I_Wind_Uy: -1.036879,
        I_Wind_Uz: 0.02038316,
        C_Fc_mass: null,
      },
      {
        Date: "2022-05-20",
        Time: "07:03:00",
        Type: "OneMinData",
        Iteration: 3,
        AirTemp: 11.01794,
        CO2: 742.2494,
        H2O: 3.043673,
        Wind_Ux: 0.2903781,
        Wind_Uy: -0.9701636,
        Wind_Uz: 0.00713366,
        Fc_mass: null,
        I_AirTemp: 11.01794,
        I_CO2: 742.2494,
        I_H2O: 3.043673,
        I_Wind_Ux: 0.2903781,
        I_Wind_Uy: -0.9701636,
        I_Wind_Uz: 0.00713366,
        C_Fc_mass: null,
      },
      {
        Date: "2022-05-20",
        Time: "07:04:00",
        Type: "OneMinData",
        Iteration: 3,
        AirTemp: 10.04982,
        CO2: 755.435,
        H2O: 3.164368,
        Wind_Ux: 0.3272759,
        Wind_Uy: -1.041717,
        Wind_Uz: 0.0167562,
        Fc_mass: null,
        I_AirTemp: 10.04982,
        I_CO2: 755.435,
        I_H2O: 3.164368,
        I_Wind_Ux: 0.3272759,
        I_Wind_Uy: -1.041717,
        I_Wind_Uz: 0.0167562,
        C_Fc_mass: null,
      },
      {
        Date: "2022-05-20",
        Time: "07:05:00",
        Type: "OneMinData",
        Iteration: 3,
        AirTemp: 10.62208,
        CO2: 745.4863,
        H2O: 3.082397,
        Wind_Ux: 0.2207016,
        Wind_Uy: -1.088092,
        Wind_Uz: 0.01538051,
        Fc_mass: null,
        I_AirTemp: 10.62208,
        I_CO2: 745.4863,
        I_H2O: 3.082397,
        I_Wind_Ux: 0.2207016,
        I_Wind_Uy: -1.088092,
        I_Wind_Uz: 0.01538051,
        C_Fc_mass: null,
      },
      {
        Date: "2022-05-20",
        Time: "07:06:00",
        Type: "OneMinData",
        Iteration: 3,
        AirTemp: null,
        CO2: null,
        H2O: null,
        Wind_Ux: null,
        Wind_Uy: null,
        Wind_Uz: null,
        Fc_mass: null,
        I_AirTemp: 10.09115,
        I_CO2: 751.0179,
        I_H2O: 3.148126,
        I_Wind_Ux: 0.3928216,
        I_Wind_Uy: -0.9986901,
        I_Wind_Uz: 0.01035202,
        C_Fc_mass: null,
      },
      {
        Date: "2022-05-20",
        Time: "07:07:00",
        Type: "OneMinData",
        Iteration: 3,
        AirTemp: 9.710501,
        CO2: 752.755,
        H2O: 3.18469,
        Wind_Ux: 0.58919,
        Wind_Uy: -0.4350785,
        Wind_Uz: 0.01919194,
        Fc_mass: null,
        I_AirTemp: 9.710501,
        I_CO2: 752.755,
        I_H2O: 3.18469,
        I_Wind_Ux: 0.58919,
        I_Wind_Uy: -0.4350785,
        I_Wind_Uz: 0.01919194,
        C_Fc_mass: null,
      },
      {
        Date: "2022-05-20",
        Time: "07:08:00",
        Type: "OneMinData",
        Iteration: 3,
        AirTemp: 8.776397,
        CO2: 765.492,
        H2O: 3.332897,
        Wind_Ux: 0.3263594,
        Wind_Uy: -0.01701019,
        Wind_Uz: -0.000570647,
        Fc_mass: null,
        I_AirTemp: 8.776397,
        I_CO2: 765.492,
        I_H2O: 3.332897,
        I_Wind_Ux: 0.3263594,
        I_Wind_Uy: -0.01701019,
        I_Wind_Uz: -0.000570647,
        C_Fc_mass: null,
      },
      {
        Date: "2022-05-20",
        Time: "07:09:00",
        Type: "OneMinData",
        Iteration: 3,
        AirTemp: 8.850356,
        CO2: 763.1762,
        H2O: 3.353882,
        Wind_Ux: 0.2454598,
        Wind_Uy: -0.395022,
        Wind_Uz: -0.03430083,
        Fc_mass: null,
        I_AirTemp: 8.850356,
        I_CO2: 763.1762,
        I_H2O: 3.353882,
        I_Wind_Ux: 0.2454598,
        I_Wind_Uy: -0.395022,
        I_Wind_Uz: -0.03430083,
        C_Fc_mass: null,
      },
      {
        Date: "2022-05-20",
        Time: "07:10:00",
        Type: "OneMinData",
        Iteration: 3,
        AirTemp: 8.097466,
        CO2: 769.955,
        H2O: 3.450075,
        Wind_Ux: 0.4478738,
        Wind_Uy: -0.6455155,
        Wind_Uz: 0.01145799,
        Fc_mass: null,
        I_AirTemp: 8.097466,
        I_CO2: 769.955,
        I_H2O: 3.450075,
        I_Wind_Ux: 0.4478738,
        I_Wind_Uy: -0.6455155,
        I_Wind_Uz: 0.01145799,
        C_Fc_mass: null,
      },
      {
        Date: "2022-05-20",
        Time: "07:11:00",
        Type: "OneMinData",
        Iteration: 3,
        AirTemp: 9.739504,
        CO2: 750.57,
        H2O: 3.211802,
        Wind_Ux: 0.6873345,
        Wind_Uy: -1.156526,
        Wind_Uz: 0.04520511,
        Fc_mass: null,
        I_AirTemp: 9.739504,
        I_CO2: 750.57,
        I_H2O: 3.211802,
        I_Wind_Ux: 0.6873345,
        I_Wind_Uy: -1.156526,
        I_Wind_Uz: 0.04520511,
        C_Fc_mass: null,
      },
      {
        Date: "2022-05-20",
        Time: "07:12:00",
        Type: "OneMinData",
        Iteration: 3,
        AirTemp: 9.296772,
        CO2: 756.4757,
        H2O: 3.272756,
        Wind_Ux: 0.6067209,
        Wind_Uy: -0.9004603,
        Wind_Uz: 0.03066237,
        Fc_mass: null,
        I_AirTemp: 9.296772,
        I_CO2: 756.4757,
        I_H2O: 3.272756,
        I_Wind_Ux: 0.6067209,
        I_Wind_Uy: -0.9004603,
        I_Wind_Uz: 0.03066237,
        C_Fc_mass: null,
      },
      {
        Date: "2022-05-20",
        Time: "07:13:00",
        Type: "OneMinData",
        Iteration: 3,
        AirTemp: 9.700702,
        CO2: 750.2939,
        H2O: 3.207861,
        Wind_Ux: 0.8112201,
        Wind_Uy: -0.7544738,
        Wind_Uz: 0.05222081,
        Fc_mass: null,
        I_AirTemp: 9.700702,
        I_CO2: 750.2939,
        I_H2O: 3.207861,
        I_Wind_Ux: 0.8112201,
        I_Wind_Uy: -0.7544738,
        I_Wind_Uz: 0.05222081,
        C_Fc_mass: null,
      },
      {
        Date: "2022-05-20",
        Time: "07:14:00",
        Type: "OneMinData",
        Iteration: 3,
        AirTemp: 9.502628,
        CO2: 755.8762,
        H2O: 3.270585,
        Wind_Ux: 0.8144755,
        Wind_Uy: -0.7832854,
        Wind_Uz: 0.07027414,
        Fc_mass: null,
        I_AirTemp: 9.502628,
        I_CO2: 755.8762,
        I_H2O: 3.270585,
        I_Wind_Ux: 0.8144755,
        I_Wind_Uy: -0.7832854,
        I_Wind_Uz: 0.07027414,
        C_Fc_mass: null,
      },
      {
        Date: "2022-05-20",
        Time: "07:15:00",
        Type: "OneMinData",
        Iteration: 3,
        AirTemp: 9.959581,
        CO2: 755.6049,
        H2O: 3.22534,
        Wind_Ux: 0.6842491,
        Wind_Uy: -0.737146,
        Wind_Uz: 0.05082466,
        Fc_mass: null,
        I_AirTemp: 9.959581,
        I_CO2: 755.6049,
        I_H2O: 3.22534,
        I_Wind_Ux: 0.6842491,
        I_Wind_Uy: -0.737146,
        I_Wind_Uz: 0.05082466,
        C_Fc_mass: null,
      },
      {
        Date: "2022-05-20",
        Time: "07:16:00",
        Type: "OneMinData",
        Iteration: 3,
        AirTemp: 9.462995,
        CO2: 767.8474,
        H2O: 3.320194,
        Wind_Ux: 0.7180842,
        Wind_Uy: -0.6208038,
        Wind_Uz: 0.1056874,
        Fc_mass: null,
        I_AirTemp: 9.462995,
        I_CO2: 767.8474,
        I_H2O: 3.320194,
        I_Wind_Ux: 0.7180842,
        I_Wind_Uy: -0.6208038,
        I_Wind_Uz: 0.1056874,
        C_Fc_mass: null,
      },
      {
        Date: "2022-05-20",
        Time: "07:17:00",
        Type: "OneMinData",
        Iteration: 3,
        AirTemp: 9.399499,
        CO2: 759.7502,
        H2O: 3.290419,
        Wind_Ux: 0.5161609,
        Wind_Uy: -1.01791,
        Wind_Uz: 0.05959733,
        Fc_mass: null,
        I_AirTemp: 9.399499,
        I_CO2: 759.7502,
        I_H2O: 3.290419,
        I_Wind_Ux: 0.5161609,
        I_Wind_Uy: -1.01791,
        I_Wind_Uz: 0.05959733,
        C_Fc_mass: null,
      },
      {
        Date: "2022-05-20",
        Time: "07:18:00",
        Type: "OneMinData",
        Iteration: 3,
        AirTemp: 9.139059,
        CO2: 761.2161,
        H2O: 3.317863,
        Wind_Ux: 0.5277132,
        Wind_Uy: -0.9501142,
        Wind_Uz: 0.0529433,
        Fc_mass: null,
        I_AirTemp: 9.139059,
        I_CO2: 761.2161,
        I_H2O: 3.317863,
        I_Wind_Ux: 0.5277132,
        I_Wind_Uy: -0.9501142,
        I_Wind_Uz: 0.0529433,
        C_Fc_mass: null,
      },
      {
        Date: "2022-05-20",
        Time: "07:19:00",
        Type: "OneMinData",
        Iteration: 3,
        AirTemp: 8.948771,
        CO2: 763.7808,
        H2O: 3.350034,
        Wind_Ux: 0.6373171,
        Wind_Uy: -1.142377,
        Wind_Uz: 0.06045646,
        Fc_mass: null,
        I_AirTemp: 8.948771,
        I_CO2: 763.7808,
        I_H2O: 3.350034,
        I_Wind_Ux: 0.6373171,
        I_Wind_Uy: -1.142377,
        I_Wind_Uz: 0.06045646,
        C_Fc_mass: null,
      },
      {
        Date: "2022-05-20",
        Time: "07:20:00",
        Type: "OneMinData",
        Iteration: 3,
        AirTemp: 9.45038,
        CO2: 754.323,
        H2O: 3.248923,
        Wind_Ux: 0.5175056,
        Wind_Uy: -0.9716914,
        Wind_Uz: 0.03784319,
        Fc_mass: null,
        I_AirTemp: 9.45038,
        I_CO2: 754.323,
        I_H2O: 3.248923,
        I_Wind_Ux: 0.5175056,
        I_Wind_Uy: -0.9716914,
        I_Wind_Uz: 0.03784319,
        C_Fc_mass: null,
      },
      {
        Date: "2022-05-20",
        Time: "07:21:00",
        Type: "OneMinData",
        Iteration: 3,
        AirTemp: 9.285898,
        CO2: 757.1162,
        H2O: 3.269223,
        Wind_Ux: 0.484733,
        Wind_Uy: -1.509426,
        Wind_Uz: 0.0670758,
        Fc_mass: null,
        I_AirTemp: 9.285898,
        I_CO2: 757.1162,
        I_H2O: 3.269223,
        I_Wind_Ux: 0.484733,
        I_Wind_Uy: -1.509426,
        I_Wind_Uz: 0.0670758,
        C_Fc_mass: null,
      },
      {
        Date: "2022-05-20",
        Time: "07:22:00",
        Type: "OneMinData",
        Iteration: 3,
        AirTemp: 10.0129,
        CO2: 746.5789,
        H2O: 3.119435,
        Wind_Ux: 0.4164486,
        Wind_Uy: -1.554895,
        Wind_Uz: 0.03664897,
        Fc_mass: null,
        I_AirTemp: 10.0129,
        I_CO2: 746.5789,
        I_H2O: 3.119435,
        I_Wind_Ux: 0.4164486,
        I_Wind_Uy: -1.554895,
        I_Wind_Uz: 0.03664897,
        C_Fc_mass: null,
      },
      {
        Date: "2022-05-20",
        Time: "07:23:00",
        Type: "OneMinData",
        Iteration: 3,
        AirTemp: 9.7817,
        CO2: 748.8637,
        H2O: 3.152918,
        Wind_Ux: 0.4395119,
        Wind_Uy: -1.68613,
        Wind_Uz: 0.02987245,
        Fc_mass: null,
        I_AirTemp: 9.7817,
        I_CO2: 748.8637,
        I_H2O: 3.152918,
        I_Wind_Ux: 0.4395119,
        I_Wind_Uy: -1.68613,
        I_Wind_Uz: 0.02987245,
        C_Fc_mass: null,
      },
      {
        Date: "2022-05-20",
        Time: "07:24:00",
        Type: "OneMinData",
        Iteration: 3,
        AirTemp: 9.847813,
        CO2: 748.5406,
        H2O: 3.129513,
        Wind_Ux: 0.3645982,
        Wind_Uy: -1.781017,
        Wind_Uz: 0.023607,
        Fc_mass: null,
        I_AirTemp: 9.847813,
        I_CO2: 748.5406,
        I_H2O: 3.129513,
        I_Wind_Ux: 0.3645982,
        I_Wind_Uy: -1.781017,
        I_Wind_Uz: 0.023607,
        C_Fc_mass: null,
      },
      {
        Date: "2022-05-20",
        Time: "07:25:00",
        Type: "OneMinData",
        Iteration: 3,
        AirTemp: 9.616764,
        CO2: 750.4124,
        H2O: 3.153421,
        Wind_Ux: 0.3829362,
        Wind_Uy: -1.740901,
        Wind_Uz: 0.04060952,
        Fc_mass: null,
        I_AirTemp: 9.616764,
        I_CO2: 750.4124,
        I_H2O: 3.153421,
        I_Wind_Ux: 0.3829362,
        I_Wind_Uy: -1.740901,
        I_Wind_Uz: 0.04060952,
        C_Fc_mass: null,
      },
      {
        Date: "2022-05-20",
        Time: "07:26:00",
        Type: "OneMinData",
        Iteration: 3,
        AirTemp: 8.489395,
        CO2: 762.5535,
        H2O: 3.323064,
        Wind_Ux: 0.5075926,
        Wind_Uy: -1.374457,
        Wind_Uz: 0.01954826,
        Fc_mass: null,
        I_AirTemp: 8.489395,
        I_CO2: 762.5535,
        I_H2O: 3.323064,
        I_Wind_Ux: 0.5075926,
        I_Wind_Uy: -1.374457,
        I_Wind_Uz: 0.01954826,
        C_Fc_mass: null,
      },
      {
        Date: "2022-05-20",
        Time: "07:27:00",
        Type: "OneMinData",
        Iteration: 3,
        AirTemp: 8.35305,
        CO2: 762.393,
        H2O: 3.385412,
        Wind_Ux: 0.718091,
        Wind_Uy: -1.148617,
        Wind_Uz: -0.01203962,
        Fc_mass: null,
        I_AirTemp: 8.35305,
        I_CO2: 762.393,
        I_H2O: 3.385412,
        I_Wind_Ux: 0.718091,
        I_Wind_Uy: -1.148617,
        I_Wind_Uz: -0.01203962,
        C_Fc_mass: null,
      },
      {
        Date: "2022-05-20",
        Time: "07:28:00",
        Type: "OneMinData",
        Iteration: 3,
        AirTemp: 8.882098,
        CO2: 754.9913,
        H2O: 3.30683,
        Wind_Ux: 1.011721,
        Wind_Uy: -1.559508,
        Wind_Uz: 0.04091675,
        Fc_mass: null,
        I_AirTemp: 8.882098,
        I_CO2: 754.9913,
        I_H2O: 3.30683,
        I_Wind_Ux: 1.011721,
        I_Wind_Uy: -1.559508,
        I_Wind_Uz: 0.04091675,
        C_Fc_mass: null,
      },
      {
        Date: "2022-05-20",
        Time: "07:29:00",
        Type: "OneMinData",
        Iteration: 3,
        AirTemp: 9.297639,
        CO2: 751.251,
        H2O: 3.251577,
        Wind_Ux: 1.312729,
        Wind_Uy: -1.74025,
        Wind_Uz: 0.0744537,
        Fc_mass: null,
        I_AirTemp: 9.297639,
        I_CO2: 751.251,
        I_H2O: 3.251577,
        I_Wind_Ux: 1.312729,
        I_Wind_Uy: -1.74025,
        I_Wind_Uz: 0.0744537,
        C_Fc_mass: null,
      },
    ],
  },
  4: {
    Fc_mass: 0.1993403,
    C_Fc_mass: 0.273,
    date: "15 Jun 2022",
    data: [
      {
        Date: "2022-06-15",
        Time: "07:30:00",
        Type: "OneMinData",
        Iteration: 4,
        AirTemp: 8.688546,
        CO2: 756.8006,
        H2O: 3.279717,
        Wind_Ux: 0.704157,
        Wind_Uy: -1.295852,
        Wind_Uz: 0.07341958,
        Fc_mass: null,
        I_AirTemp: 8.688546,
        I_CO2: 756.8006,
        I_H2O: 3.279717,
        I_Wind_Ux: 0.704157,
        I_Wind_Uy: -1.295852,
        I_Wind_Uz: 0.07341958,
        C_Fc_mass: null,
      },
      {
        Date: "2022-06-15",
        Time: "07:31:00",
        Type: "OneMinData",
        Iteration: 4,
        AirTemp: 8.633181,
        CO2: 759.9201,
        H2O: 3.296324,
        Wind_Ux: 0.834314,
        Wind_Uy: -1.017415,
        Wind_Uz: 0.024172,
        Fc_mass: null,
        I_AirTemp: 8.633181,
        I_CO2: 759.9201,
        I_H2O: 3.296324,
        I_Wind_Ux: 0.834314,
        I_Wind_Uy: -1.017415,
        I_Wind_Uz: 0.024172,
        C_Fc_mass: null,
      },
      {
        Date: "2022-06-15",
        Time: "07:32:00",
        Type: "OneMinData",
        Iteration: 4,
        AirTemp: 8.790605,
        CO2: 756.3918,
        H2O: 3.249902,
        Wind_Ux: 0.8882713,
        Wind_Uy: -0.5849338,
        Wind_Uz: 0.05270194,
        Fc_mass: null,
        I_AirTemp: 8.790605,
        I_CO2: 756.3918,
        I_H2O: 3.249902,
        I_Wind_Ux: 0.8882713,
        I_Wind_Uy: -0.5849338,
        I_Wind_Uz: 0.05270194,
        C_Fc_mass: null,
      },
      {
        Date: "2022-06-15",
        Time: "07:33:00",
        Type: "OneMinData",
        Iteration: 4,
        AirTemp: 9.01382,
        CO2: 755.5115,
        H2O: 3.222607,
        Wind_Ux: 1.129236,
        Wind_Uy: -0.2250833,
        Wind_Uz: 0.0832541,
        Fc_mass: null,
        I_AirTemp: 9.01382,
        I_CO2: 755.5115,
        I_H2O: 3.222607,
        I_Wind_Ux: 1.129236,
        I_Wind_Uy: -0.2250833,
        I_Wind_Uz: 0.0832541,
        C_Fc_mass: null,
      },
      {
        Date: "2022-06-15",
        Time: "07:34:00",
        Type: "OneMinData",
        Iteration: 4,
        AirTemp: 8.463874,
        CO2: 764.6152,
        H2O: 3.335558,
        Wind_Ux: 0.872241,
        Wind_Uy: -0.6952142,
        Wind_Uz: 0.02674675,
        Fc_mass: null,
        I_AirTemp: 8.463874,
        I_CO2: 764.6152,
        I_H2O: 3.335558,
        I_Wind_Ux: 0.872241,
        I_Wind_Uy: -0.6952142,
        I_Wind_Uz: 0.02674675,
        C_Fc_mass: null,
      },
      {
        Date: "2022-06-15",
        Time: "07:35:00",
        Type: "OneMinData",
        Iteration: 4,
        AirTemp: 7.552726,
        CO2: 770.7017,
        H2O: 3.415642,
        Wind_Ux: 0.9828899,
        Wind_Uy: -0.3953199,
        Wind_Uz: 0.1596222,
        Fc_mass: null,
        I_AirTemp: 7.552726,
        I_CO2: 770.7017,
        I_H2O: 3.415642,
        I_Wind_Ux: 0.9828899,
        I_Wind_Uy: -0.3953199,
        I_Wind_Uz: 0.1596222,
        C_Fc_mass: null,
      },
      {
        Date: "2022-06-15",
        Time: "07:36:00",
        Type: "OneMinData",
        Iteration: 4,
        AirTemp: 8.762406,
        CO2: 760.4747,
        H2O: 3.274405,
        Wind_Ux: 0.6102051,
        Wind_Uy: -0.6204362,
        Wind_Uz: 0.05960789,
        Fc_mass: null,
        I_AirTemp: 8.762406,
        I_CO2: 760.4747,
        I_H2O: 3.274405,
        I_Wind_Ux: 0.6102051,
        I_Wind_Uy: -0.6204362,
        I_Wind_Uz: 0.05960789,
        C_Fc_mass: null,
      },
      {
        Date: "2022-06-15",
        Time: "07:37:00",
        Type: "OneMinData",
        Iteration: 4,
        AirTemp: 8.317817,
        CO2: 774.6492,
        H2O: 3.369666,
        Wind_Ux: 0.1763121,
        Wind_Uy: -0.6798566,
        Wind_Uz: 0.04602822,
        Fc_mass: null,
        I_AirTemp: 8.317817,
        I_CO2: 774.6492,
        I_H2O: 3.369666,
        I_Wind_Ux: 0.1763121,
        I_Wind_Uy: -0.6798566,
        I_Wind_Uz: 0.04602822,
        C_Fc_mass: null,
      },
      {
        Date: "2022-06-15",
        Time: "07:38:00",
        Type: "OneMinData",
        Iteration: 4,
        AirTemp: 8.324186,
        CO2: 770.4354,
        H2O: 3.358883,
        Wind_Ux: 0.388716,
        Wind_Uy: -0.7407525,
        Wind_Uz: 0.04136807,
        Fc_mass: null,
        I_AirTemp: 8.324186,
        I_CO2: 770.4354,
        I_H2O: 3.358883,
        I_Wind_Ux: 0.388716,
        I_Wind_Uy: -0.7407525,
        I_Wind_Uz: 0.04136807,
        C_Fc_mass: null,
      },
      {
        Date: "2022-06-15",
        Time: "07:39:00",
        Type: "OneMinData",
        Iteration: 4,
        AirTemp: 9.407612,
        CO2: 753.1258,
        H2O: 3.237875,
        Wind_Ux: 0.5080876,
        Wind_Uy: -1.141128,
        Wind_Uz: 0.008491582,
        Fc_mass: null,
        I_AirTemp: 9.407612,
        I_CO2: 753.1258,
        I_H2O: 3.237875,
        I_Wind_Ux: 0.5080876,
        I_Wind_Uy: -1.141128,
        I_Wind_Uz: 0.008491582,
        C_Fc_mass: null,
      },
      {
        Date: "2022-06-15",
        Time: "07:40:00",
        Type: "OneMinData",
        Iteration: 4,
        AirTemp: null,
        CO2: null,
        H2O: null,
        Wind_Ux: null,
        Wind_Uy: null,
        Wind_Uz: null,
        Fc_mass: null,
        I_AirTemp: 9.316438,
        I_CO2: 755.971,
        I_H2O: 3.259802,
        I_Wind_Ux: 0.2513714,
        I_Wind_Uy: -0.9782563,
        I_Wind_Uz: 0.07295154,
        C_Fc_mass: null,
      },
      {
        Date: "2022-06-15",
        Time: "07:41:00",
        Type: "OneMinData",
        Iteration: 4,
        AirTemp: null,
        CO2: null,
        H2O: null,
        Wind_Ux: null,
        Wind_Uy: null,
        Wind_Uz: null,
        Fc_mass: null,
        I_AirTemp: 8.953829,
        I_CO2: 760.1791,
        I_H2O: 3.293455,
        I_Wind_Ux: 0.5508382,
        I_Wind_Uy: -1.130474,
        I_Wind_Uz: 0.06093657,
        C_Fc_mass: null,
      },
      {
        Date: "2022-06-15",
        Time: "07:42:00",
        Type: "OneMinData",
        Iteration: 4,
        AirTemp: 9.559386,
        CO2: 748.8314,
        H2O: 3.170563,
        Wind_Ux: 0.785368,
        Wind_Uy: -1.27642,
        Wind_Uz: 0.000110261,
        Fc_mass: null,
        I_AirTemp: 9.559386,
        I_CO2: 748.8314,
        I_H2O: 3.170563,
        I_Wind_Ux: 0.785368,
        I_Wind_Uy: -1.27642,
        I_Wind_Uz: 0.000110261,
        C_Fc_mass: null,
      },
      {
        Date: "2022-06-15",
        Time: "07:43:00",
        Type: "OneMinData",
        Iteration: 4,
        AirTemp: 9.486407,
        CO2: 748.3594,
        H2O: 3.132302,
        Wind_Ux: 0.3725222,
        Wind_Uy: -1.530111,
        Wind_Uz: 0.03276358,
        Fc_mass: null,
        I_AirTemp: 9.486407,
        I_CO2: 748.3594,
        I_H2O: 3.132302,
        I_Wind_Ux: 0.3725222,
        I_Wind_Uy: -1.530111,
        I_Wind_Uz: 0.03276358,
        C_Fc_mass: null,
      },
      {
        Date: "2022-06-15",
        Time: "07:44:00",
        Type: "OneMinData",
        Iteration: 4,
        AirTemp: 9.204546,
        CO2: 750.7331,
        H2O: 3.189789,
        Wind_Ux: 0.07784902,
        Wind_Uy: -2.2018,
        Wind_Uz: 0.06985302,
        Fc_mass: null,
        I_AirTemp: 9.204546,
        I_CO2: 750.7331,
        I_H2O: 3.189789,
        I_Wind_Ux: 0.07784902,
        I_Wind_Uy: -2.2018,
        I_Wind_Uz: 0.06985302,
        C_Fc_mass: null,
      },
      {
        Date: "2022-06-15",
        Time: "07:45:00",
        Type: "OneMinData",
        Iteration: 4,
        AirTemp: 9.507386,
        CO2: 748.4512,
        H2O: 3.159641,
        Wind_Ux: 0.2559626,
        Wind_Uy: -1.960401,
        Wind_Uz: 0.02789688,
        Fc_mass: null,
        I_AirTemp: 9.507386,
        I_CO2: 748.4512,
        I_H2O: 3.159641,
        I_Wind_Ux: 0.2559626,
        I_Wind_Uy: -1.960401,
        I_Wind_Uz: 0.02789688,
        C_Fc_mass: null,
      },
      {
        Date: "2022-06-15",
        Time: "07:46:00",
        Type: "OneMinData",
        Iteration: 4,
        AirTemp: 9.834137,
        CO2: 749.2541,
        H2O: 3.169866,
        Wind_Ux: -0.01104767,
        Wind_Uy: -1.424128,
        Wind_Uz: 0.03222329,
        Fc_mass: null,
        I_AirTemp: 9.834137,
        I_CO2: 749.2541,
        I_H2O: 3.169866,
        I_Wind_Ux: -0.01104767,
        I_Wind_Uy: -1.424128,
        I_Wind_Uz: 0.03222329,
        C_Fc_mass: null,
      },
      {
        Date: "2022-06-15",
        Time: "07:47:00",
        Type: "OneMinData",
        Iteration: 4,
        AirTemp: 8.289526,
        CO2: 764.3771,
        H2O: 3.340326,
        Wind_Ux: 0.2541926,
        Wind_Uy: -1.270666,
        Wind_Uz: 0.04716588,
        Fc_mass: null,
        I_AirTemp: 8.289526,
        I_CO2: 764.3771,
        I_H2O: 3.340326,
        I_Wind_Ux: 0.2541926,
        I_Wind_Uy: -1.270666,
        I_Wind_Uz: 0.04716588,
        C_Fc_mass: null,
      },
      {
        Date: "2022-06-15",
        Time: "07:48:00",
        Type: "OneMinData",
        Iteration: 4,
        AirTemp: 8.792955,
        CO2: 754.4486,
        H2O: 3.232656,
        Wind_Ux: 0.6295401,
        Wind_Uy: -1.812307,
        Wind_Uz: 0.089049,
        Fc_mass: null,
        I_AirTemp: 8.792955,
        I_CO2: 754.4486,
        I_H2O: 3.232656,
        I_Wind_Ux: 0.6295401,
        I_Wind_Uy: -1.812307,
        I_Wind_Uz: 0.089049,
        C_Fc_mass: null,
      },
      {
        Date: "2022-06-15",
        Time: "07:49:00",
        Type: "OneMinData",
        Iteration: 4,
        AirTemp: 10.01729,
        CO2: 744.7047,
        H2O: 3.083311,
        Wind_Ux: 0.551522,
        Wind_Uy: -1.778492,
        Wind_Uz: 0.05808987,
        Fc_mass: null,
        I_AirTemp: 10.01729,
        I_CO2: 744.7047,
        I_H2O: 3.083311,
        I_Wind_Ux: 0.551522,
        I_Wind_Uy: -1.778492,
        I_Wind_Uz: 0.05808987,
        C_Fc_mass: null,
      },
      {
        Date: "2022-06-15",
        Time: "07:50:00",
        Type: "OneMinData",
        Iteration: 4,
        AirTemp: 8.85237,
        CO2: 753.955,
        H2O: 3.218138,
        Wind_Ux: 0.5448296,
        Wind_Uy: -1.693365,
        Wind_Uz: 0.05738187,
        Fc_mass: null,
        I_AirTemp: 8.85237,
        I_CO2: 753.955,
        I_H2O: 3.218138,
        I_Wind_Ux: 0.5448296,
        I_Wind_Uy: -1.693365,
        I_Wind_Uz: 0.05738187,
        C_Fc_mass: null,
      },
      {
        Date: "2022-06-15",
        Time: "07:51:00",
        Type: "OneMinData",
        Iteration: 4,
        AirTemp: 9.513461,
        CO2: 747.8603,
        H2O: 3.12524,
        Wind_Ux: 0.5145231,
        Wind_Uy: -1.977665,
        Wind_Uz: 0.07432509,
        Fc_mass: null,
        I_AirTemp: 9.513461,
        I_CO2: 747.8603,
        I_H2O: 3.12524,
        I_Wind_Ux: 0.5145231,
        I_Wind_Uy: -1.977665,
        I_Wind_Uz: 0.07432509,
        C_Fc_mass: null,
      },
      {
        Date: "2022-06-15",
        Time: "07:52:00",
        Type: "OneMinData",
        Iteration: 4,
        AirTemp: 8.560322,
        CO2: 755.4979,
        H2O: 3.238636,
        Wind_Ux: 0.4092155,
        Wind_Uy: -2.087923,
        Wind_Uz: 0.02901937,
        Fc_mass: null,
        I_AirTemp: 8.560322,
        I_CO2: 755.4979,
        I_H2O: 3.238636,
        I_Wind_Ux: 0.4092155,
        I_Wind_Uy: -2.087923,
        I_Wind_Uz: 0.02901937,
        C_Fc_mass: null,
      },
      {
        Date: "2022-06-15",
        Time: "07:53:00",
        Type: "OneMinData",
        Iteration: 4,
        AirTemp: 8.57512,
        CO2: 754.7506,
        H2O: 3.262427,
        Wind_Ux: 0.4480214,
        Wind_Uy: -2.074415,
        Wind_Uz: 0.01645926,
        Fc_mass: null,
        I_AirTemp: 8.57512,
        I_CO2: 754.7506,
        I_H2O: 3.262427,
        I_Wind_Ux: 0.4480214,
        I_Wind_Uy: -2.074415,
        I_Wind_Uz: 0.01645926,
        C_Fc_mass: null,
      },
      {
        Date: "2022-06-15",
        Time: "07:54:00",
        Type: "OneMinData",
        Iteration: 4,
        AirTemp: 8.144118,
        CO2: 757.6273,
        H2O: 3.368473,
        Wind_Ux: 0.4783286,
        Wind_Uy: -1.648257,
        Wind_Uz: 0.03548435,
        Fc_mass: null,
        I_AirTemp: 8.144118,
        I_CO2: 757.6273,
        I_H2O: 3.368473,
        I_Wind_Ux: 0.4783286,
        I_Wind_Uy: -1.648257,
        I_Wind_Uz: 0.03548435,
        C_Fc_mass: null,
      },
      {
        Date: "2022-06-15",
        Time: "07:55:00",
        Type: "OneMinData",
        Iteration: 4,
        AirTemp: 8.075963,
        CO2: 759.4777,
        H2O: 3.397884,
        Wind_Ux: 0.5168091,
        Wind_Uy: -1.655842,
        Wind_Uz: 0.08807489,
        Fc_mass: null,
        I_AirTemp: 8.075963,
        I_CO2: 759.4777,
        I_H2O: 3.397884,
        I_Wind_Ux: 0.5168091,
        I_Wind_Uy: -1.655842,
        I_Wind_Uz: 0.08807489,
        C_Fc_mass: null,
      },
      {
        Date: "2022-06-15",
        Time: "07:56:00",
        Type: "OneMinData",
        Iteration: 4,
        AirTemp: 8.441432,
        CO2: 755.5506,
        H2O: 3.308544,
        Wind_Ux: 0.7168442,
        Wind_Uy: -1.634365,
        Wind_Uz: 0.02568256,
        Fc_mass: null,
        I_AirTemp: 8.441432,
        I_CO2: 755.5506,
        I_H2O: 3.308544,
        I_Wind_Ux: 0.7168442,
        I_Wind_Uy: -1.634365,
        I_Wind_Uz: 0.02568256,
        C_Fc_mass: null,
      },
      {
        Date: "2022-06-15",
        Time: "07:57:00",
        Type: "OneMinData",
        Iteration: 4,
        AirTemp: 8.946974,
        CO2: 752.4775,
        H2O: 3.258739,
        Wind_Ux: 0.6826046,
        Wind_Uy: -2.018134,
        Wind_Uz: 0.08569808,
        Fc_mass: null,
        I_AirTemp: 8.946974,
        I_CO2: 752.4775,
        I_H2O: 3.258739,
        I_Wind_Ux: 0.6826046,
        I_Wind_Uy: -2.018134,
        I_Wind_Uz: 0.08569808,
        C_Fc_mass: null,
      },
      {
        Date: "2022-06-15",
        Time: "07:58:00",
        Type: "OneMinData",
        Iteration: 4,
        AirTemp: 9.033257,
        CO2: 752.5119,
        H2O: 3.260958,
        Wind_Ux: 0.4638095,
        Wind_Uy: -1.549454,
        Wind_Uz: 0.04470661,
        Fc_mass: null,
        I_AirTemp: 9.033257,
        I_CO2: 752.5119,
        I_H2O: 3.260958,
        I_Wind_Ux: 0.4638095,
        I_Wind_Uy: -1.549454,
        I_Wind_Uz: 0.04470661,
        C_Fc_mass: null,
      },
      {
        Date: "2022-06-15",
        Time: "07:59:00",
        Type: "OneMinData",
        Iteration: 4,
        AirTemp: 8.798154,
        CO2: 753.9231,
        H2O: 3.31414,
        Wind_Ux: 0.6649859,
        Wind_Uy: -1.241412,
        Wind_Uz: 0.02513075,
        Fc_mass: null,
        I_AirTemp: 8.798154,
        I_CO2: 753.9231,
        I_H2O: 3.31414,
        I_Wind_Ux: 0.6649859,
        I_Wind_Uy: -1.241412,
        I_Wind_Uz: 0.02513075,
        C_Fc_mass: null,
      },
    ],
  },
  5: {
    Fc_mass: 0.09272177,
    C_Fc_mass: 0.072,
    date: "08 Jul 2022",
    data: [
      {
        Date: "2022-07-08",
        Time: "08:00:00",
        Type: "OneMinData",
        Iteration: 5,
        AirTemp: 8.5687,
        CO2: 754.9446,
        H2O: 3.329079,
        Wind_Ux: 0.7389551,
        Wind_Uy: -1.585763,
        Wind_Uz: 0.06278686,
        Fc_mass: null,
        I_AirTemp: 8.5687,
        I_CO2: 754.9446,
        I_H2O: 3.329079,
        I_Wind_Ux: 0.7389551,
        I_Wind_Uy: -1.585763,
        I_Wind_Uz: 0.06278686,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-08",
        Time: "08:01:00",
        Type: "OneMinData",
        Iteration: 5,
        AirTemp: 8.704199,
        CO2: 753.031,
        H2O: 3.33753,
        Wind_Ux: 1.497413,
        Wind_Uy: -1.242443,
        Wind_Uz: 0.1578713,
        Fc_mass: null,
        I_AirTemp: 8.704199,
        I_CO2: 753.031,
        I_H2O: 3.33753,
        I_Wind_Ux: 1.497413,
        I_Wind_Uy: -1.242443,
        I_Wind_Uz: 0.1578713,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-08",
        Time: "08:02:00",
        Type: "OneMinData",
        Iteration: 5,
        AirTemp: 8.276602,
        CO2: 758.1978,
        H2O: 3.313211,
        Wind_Ux: 0.6394362,
        Wind_Uy: -1.649017,
        Wind_Uz: 0.06034213,
        Fc_mass: null,
        I_AirTemp: 8.276602,
        I_CO2: 758.1978,
        I_H2O: 3.313211,
        I_Wind_Ux: 0.6394362,
        I_Wind_Uy: -1.649017,
        I_Wind_Uz: 0.06034213,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-08",
        Time: "08:03:00",
        Type: "OneMinData",
        Iteration: 5,
        AirTemp: 8.040829,
        CO2: 759.8572,
        H2O: 3.340405,
        Wind_Ux: 0.6987696,
        Wind_Uy: -1.807525,
        Wind_Uz: 0.02391462,
        Fc_mass: null,
        I_AirTemp: 8.040829,
        I_CO2: 759.8572,
        I_H2O: 3.340405,
        I_Wind_Ux: 0.6987696,
        I_Wind_Uy: -1.807525,
        I_Wind_Uz: 0.02391462,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-08",
        Time: "08:04:00",
        Type: "OneMinData",
        Iteration: 5,
        AirTemp: 8.905558,
        CO2: 749.9933,
        H2O: 3.247772,
        Wind_Ux: 1.164185,
        Wind_Uy: -1.771164,
        Wind_Uz: 0.0372878,
        Fc_mass: null,
        I_AirTemp: 8.905558,
        I_CO2: 749.9933,
        I_H2O: 3.247772,
        I_Wind_Ux: 1.164185,
        I_Wind_Uy: -1.771164,
        I_Wind_Uz: 0.0372878,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-08",
        Time: "08:05:00",
        Type: "OneMinData",
        Iteration: 5,
        AirTemp: 9.073083,
        CO2: 749.6989,
        H2O: 3.191691,
        Wind_Ux: 0.697325,
        Wind_Uy: -1.902534,
        Wind_Uz: 0.02992816,
        Fc_mass: null,
        I_AirTemp: 9.073083,
        I_CO2: 749.6989,
        I_H2O: 3.191691,
        I_Wind_Ux: 0.697325,
        I_Wind_Uy: -1.902534,
        I_Wind_Uz: 0.02992816,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-08",
        Time: "08:06:00",
        Type: "OneMinData",
        Iteration: 5,
        AirTemp: 9.327518,
        CO2: 748.1968,
        H2O: 3.167587,
        Wind_Ux: 0.9291362,
        Wind_Uy: -1.45865,
        Wind_Uz: 0.02446602,
        Fc_mass: null,
        I_AirTemp: 9.327518,
        I_CO2: 748.1968,
        I_H2O: 3.167587,
        I_Wind_Ux: 0.9291362,
        I_Wind_Uy: -1.45865,
        I_Wind_Uz: 0.02446602,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-08",
        Time: "08:07:00",
        Type: "OneMinData",
        Iteration: 5,
        AirTemp: 9.219046,
        CO2: 749.2359,
        H2O: 3.167147,
        Wind_Ux: 0.874547,
        Wind_Uy: -1.043761,
        Wind_Uz: 0.03608647,
        Fc_mass: null,
        I_AirTemp: 9.219046,
        I_CO2: 749.2359,
        I_H2O: 3.167147,
        I_Wind_Ux: 0.874547,
        I_Wind_Uy: -1.043761,
        I_Wind_Uz: 0.03608647,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-08",
        Time: "08:08:00",
        Type: "OneMinData",
        Iteration: 5,
        AirTemp: 9.300726,
        CO2: 749.6321,
        H2O: 3.151837,
        Wind_Ux: 0.5369319,
        Wind_Uy: -1.359968,
        Wind_Uz: 0.01461346,
        Fc_mass: null,
        I_AirTemp: 9.300726,
        I_CO2: 749.6321,
        I_H2O: 3.151837,
        I_Wind_Ux: 0.5369319,
        I_Wind_Uy: -1.359968,
        I_Wind_Uz: 0.01461346,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-08",
        Time: "08:09:00",
        Type: "OneMinData",
        Iteration: 5,
        AirTemp: 9.458202,
        CO2: 749.3758,
        H2O: 3.145171,
        Wind_Ux: 0.3229564,
        Wind_Uy: -1.45008,
        Wind_Uz: 0.04701932,
        Fc_mass: null,
        I_AirTemp: 9.458202,
        I_CO2: 749.3758,
        I_H2O: 3.145171,
        I_Wind_Ux: 0.3229564,
        I_Wind_Uy: -1.45008,
        I_Wind_Uz: 0.04701932,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-08",
        Time: "08:10:00",
        Type: "OneMinData",
        Iteration: 5,
        AirTemp: 9.553532,
        CO2: 747.2113,
        H2O: 3.140423,
        Wind_Ux: 0.5161599,
        Wind_Uy: -1.073128,
        Wind_Uz: 0.05053799,
        Fc_mass: null,
        I_AirTemp: 9.553532,
        I_CO2: 747.2113,
        I_H2O: 3.140423,
        I_Wind_Ux: 0.5161599,
        I_Wind_Uy: -1.073128,
        I_Wind_Uz: 0.05053799,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-08",
        Time: "08:11:00",
        Type: "OneMinData",
        Iteration: 5,
        AirTemp: 9.142054,
        CO2: 751.9452,
        H2O: 3.173005,
        Wind_Ux: 0.2618176,
        Wind_Uy: -0.8889066,
        Wind_Uz: -0.006006114,
        Fc_mass: null,
        I_AirTemp: 9.142054,
        I_CO2: 751.9452,
        I_H2O: 3.173005,
        I_Wind_Ux: 0.2618176,
        I_Wind_Uy: -0.8889066,
        I_Wind_Uz: -0.006006114,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-08",
        Time: "08:12:00",
        Type: "OneMinData",
        Iteration: 5,
        AirTemp: 9.37593,
        CO2: 754.0596,
        H2O: 3.153802,
        Wind_Ux: 0.2865753,
        Wind_Uy: -1.387552,
        Wind_Uz: 0.05189746,
        Fc_mass: null,
        I_AirTemp: 9.37593,
        I_CO2: 754.0596,
        I_H2O: 3.153802,
        I_Wind_Ux: 0.2865753,
        I_Wind_Uy: -1.387552,
        I_Wind_Uz: 0.05189746,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-08",
        Time: "08:13:00",
        Type: "OneMinData",
        Iteration: 5,
        AirTemp: 9.985731,
        CO2: 745.9834,
        H2O: 3.109927,
        Wind_Ux: 0.3276462,
        Wind_Uy: -1.227611,
        Wind_Uz: 0.04546408,
        Fc_mass: null,
        I_AirTemp: 9.985731,
        I_CO2: 745.9834,
        I_H2O: 3.109927,
        I_Wind_Ux: 0.3276462,
        I_Wind_Uy: -1.227611,
        I_Wind_Uz: 0.04546408,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-08",
        Time: "08:14:00",
        Type: "OneMinData",
        Iteration: 5,
        AirTemp: 9.993038,
        CO2: 744.4068,
        H2O: 3.083418,
        Wind_Ux: 0.08592934,
        Wind_Uy: -1.252687,
        Wind_Uz: -0.00742219,
        Fc_mass: null,
        I_AirTemp: 9.993038,
        I_CO2: 744.4068,
        I_H2O: 3.083418,
        I_Wind_Ux: 0.08592934,
        I_Wind_Uy: -1.252687,
        I_Wind_Uz: -0.00742219,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-08",
        Time: "08:15:00",
        Type: "OneMinData",
        Iteration: 5,
        AirTemp: 9.40357,
        CO2: 750.1125,
        H2O: 3.164891,
        Wind_Ux: 0.2267729,
        Wind_Uy: -1.205348,
        Wind_Uz: -0.0291445,
        Fc_mass: null,
        I_AirTemp: 9.40357,
        I_CO2: 750.1125,
        I_H2O: 3.164891,
        I_Wind_Ux: 0.2267729,
        I_Wind_Uy: -1.205348,
        I_Wind_Uz: -0.0291445,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-08",
        Time: "08:16:00",
        Type: "OneMinData",
        Iteration: 5,
        AirTemp: 9.717172,
        CO2: 747.3301,
        H2O: 3.151963,
        Wind_Ux: 0.1590279,
        Wind_Uy: -1.804646,
        Wind_Uz: 0.007140868,
        Fc_mass: null,
        I_AirTemp: 9.717172,
        I_CO2: 747.3301,
        I_H2O: 3.151963,
        I_Wind_Ux: 0.1590279,
        I_Wind_Uy: -1.804646,
        I_Wind_Uz: 0.007140868,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-08",
        Time: "08:17:00",
        Type: "OneMinData",
        Iteration: 5,
        AirTemp: 9.70847,
        CO2: 746.4082,
        H2O: 3.155216,
        Wind_Ux: 0.05839971,
        Wind_Uy: -1.805494,
        Wind_Uz: -0.001001993,
        Fc_mass: null,
        I_AirTemp: 9.70847,
        I_CO2: 746.4082,
        I_H2O: 3.155216,
        I_Wind_Ux: 0.05839971,
        I_Wind_Uy: -1.805494,
        I_Wind_Uz: -0.001001993,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-08",
        Time: "08:18:00",
        Type: "OneMinData",
        Iteration: 5,
        AirTemp: null,
        CO2: null,
        H2O: null,
        Wind_Ux: null,
        Wind_Uy: null,
        Wind_Uz: null,
        Fc_mass: null,
        I_AirTemp: 9.902809,
        I_CO2: 746.6739,
        I_H2O: 3.111968,
        I_Wind_Ux: -0.2740999,
        I_Wind_Uy: -1.68877,
        I_Wind_Uz: -0.03016215,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-08",
        Time: "08:19:00",
        Type: "OneMinData",
        Iteration: 5,
        AirTemp: null,
        CO2: null,
        H2O: null,
        Wind_Ux: null,
        Wind_Uy: null,
        Wind_Uz: null,
        Fc_mass: null,
        I_AirTemp: 8.690016,
        I_CO2: 762.614,
        I_H2O: 3.224304,
        I_Wind_Ux: 0.08283399,
        I_Wind_Uy: -1.13937,
        I_Wind_Uz: 0.07015212,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-08",
        Time: "08:20:00",
        Type: "OneMinData",
        Iteration: 5,
        AirTemp: null,
        CO2: null,
        H2O: null,
        Wind_Ux: null,
        Wind_Uy: null,
        Wind_Uz: null,
        Fc_mass: null,
        I_AirTemp: 9.642916,
        I_CO2: 749.1583,
        I_H2O: 3.110946,
        I_Wind_Ux: 0.473691,
        I_Wind_Uy: -1.366107,
        I_Wind_Uz: 0.008649267,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-08",
        Time: "08:21:00",
        Type: "OneMinData",
        Iteration: 5,
        AirTemp: 9.46984,
        CO2: 749.7657,
        H2O: 3.125269,
        Wind_Ux: 0.1975494,
        Wind_Uy: -1.293296,
        Wind_Uz: 0.00406825,
        Fc_mass: null,
        I_AirTemp: 9.46984,
        I_CO2: 749.7657,
        I_H2O: 3.125269,
        I_Wind_Ux: 0.1975494,
        I_Wind_Uy: -1.293296,
        I_Wind_Uz: 0.00406825,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-08",
        Time: "08:22:00",
        Type: "OneMinData",
        Iteration: 5,
        AirTemp: 9.128942,
        CO2: 753.5496,
        H2O: 3.166103,
        Wind_Ux: -0.02867313,
        Wind_Uy: -1.154398,
        Wind_Uz: -0.004140742,
        Fc_mass: null,
        I_AirTemp: 9.128942,
        I_CO2: 753.5496,
        I_H2O: 3.166103,
        I_Wind_Ux: -0.02867313,
        I_Wind_Uy: -1.154398,
        I_Wind_Uz: -0.004140742,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-08",
        Time: "08:23:00",
        Type: "OneMinData",
        Iteration: 5,
        AirTemp: 8.247914,
        CO2: 766.4515,
        H2O: 3.257601,
        Wind_Ux: -0.5415243,
        Wind_Uy: -0.9764033,
        Wind_Uz: -0.06886627,
        Fc_mass: null,
        I_AirTemp: 8.247914,
        I_CO2: 766.4515,
        I_H2O: 3.257601,
        I_Wind_Ux: -0.5415243,
        I_Wind_Uy: -0.9764033,
        I_Wind_Uz: -0.06886627,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-08",
        Time: "08:24:00",
        Type: "OneMinData",
        Iteration: 5,
        AirTemp: 9.100479,
        CO2: 756.7433,
        H2O: 3.173401,
        Wind_Ux: -0.1959701,
        Wind_Uy: -1.048892,
        Wind_Uz: 0.03047274,
        Fc_mass: null,
        I_AirTemp: 9.100479,
        I_CO2: 756.7433,
        I_H2O: 3.173401,
        I_Wind_Ux: -0.1959701,
        I_Wind_Uy: -1.048892,
        I_Wind_Uz: 0.03047274,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-08",
        Time: "08:25:00",
        Type: "OneMinData",
        Iteration: 5,
        AirTemp: 7.372629,
        CO2: 773.6112,
        H2O: 3.365743,
        Wind_Ux: -0.2564965,
        Wind_Uy: -0.9655162,
        Wind_Uz: 0.08897462,
        Fc_mass: null,
        I_AirTemp: 7.372629,
        I_CO2: 773.6112,
        I_H2O: 3.365743,
        I_Wind_Ux: -0.2564965,
        I_Wind_Uy: -0.9655162,
        I_Wind_Uz: 0.08897462,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-08",
        Time: "08:26:00",
        Type: "OneMinData",
        Iteration: 5,
        AirTemp: 8.520766,
        CO2: 759.4761,
        H2O: 3.287831,
        Wind_Ux: -0.4256689,
        Wind_Uy: -0.6515088,
        Wind_Uz: -0.01306823,
        Fc_mass: null,
        I_AirTemp: 8.520766,
        I_CO2: 759.4761,
        I_H2O: 3.287831,
        I_Wind_Ux: -0.4256689,
        I_Wind_Uy: -0.6515088,
        I_Wind_Uz: -0.01306823,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-08",
        Time: "08:27:00",
        Type: "OneMinData",
        Iteration: 5,
        AirTemp: 7.96269,
        CO2: 765.0734,
        H2O: 3.365448,
        Wind_Ux: -0.1075398,
        Wind_Uy: -0.7245354,
        Wind_Uz: 0.04732165,
        Fc_mass: null,
        I_AirTemp: 7.96269,
        I_CO2: 765.0734,
        I_H2O: 3.365448,
        I_Wind_Ux: -0.1075398,
        I_Wind_Uy: -0.7245354,
        I_Wind_Uz: 0.04732165,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-08",
        Time: "08:28:00",
        Type: "OneMinData",
        Iteration: 5,
        AirTemp: 8.652691,
        CO2: 758.8756,
        H2O: 3.279476,
        Wind_Ux: -0.243532,
        Wind_Uy: -0.5813318,
        Wind_Uz: 0.007441068,
        Fc_mass: null,
        I_AirTemp: 8.652691,
        I_CO2: 758.8756,
        I_H2O: 3.279476,
        I_Wind_Ux: -0.243532,
        I_Wind_Uy: -0.5813318,
        I_Wind_Uz: 0.007441068,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-08",
        Time: "08:29:00",
        Type: "OneMinData",
        Iteration: 5,
        AirTemp: 8.75847,
        CO2: 757.4955,
        H2O: 3.259631,
        Wind_Ux: -0.5591601,
        Wind_Uy: -0.6994217,
        Wind_Uz: -0.07519433,
        Fc_mass: null,
        I_AirTemp: 8.75847,
        I_CO2: 757.4955,
        I_H2O: 3.259631,
        I_Wind_Ux: -0.5591601,
        I_Wind_Uy: -0.6994217,
        I_Wind_Uz: -0.07519433,
        C_Fc_mass: null,
      },
    ],
  },
  6: {
    Fc_mass: 0.0578551,
    C_Fc_mass: 0.0312,
    date: "15 Jul 2022",
    data: [
      {
        Date: "2022-07-15",
        Time: "08:30:00",
        Type: "OneMinData",
        Iteration: 6,
        AirTemp: 8.983402,
        CO2: 751.7231,
        H2O: 3.295373,
        Wind_Ux: -0.6405731,
        Wind_Uy: -0.1532723,
        Wind_Uz: -0.130407,
        Fc_mass: null,
        I_AirTemp: 8.983402,
        I_CO2: 751.7231,
        I_H2O: 3.295373,
        I_Wind_Ux: -0.6405731,
        I_Wind_Uy: -0.1532723,
        I_Wind_Uz: -0.130407,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-15",
        Time: "08:31:00",
        Type: "OneMinData",
        Iteration: 6,
        AirTemp: 9.076137,
        CO2: 750.1847,
        H2O: 3.418229,
        Wind_Ux: -0.6637345,
        Wind_Uy: -0.2004643,
        Wind_Uz: -0.1276767,
        Fc_mass: null,
        I_AirTemp: 9.076137,
        I_CO2: 750.1847,
        I_H2O: 3.418229,
        I_Wind_Ux: -0.6637345,
        I_Wind_Uy: -0.2004643,
        I_Wind_Uz: -0.1276767,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-15",
        Time: "08:32:00",
        Type: "OneMinData",
        Iteration: 6,
        AirTemp: 8.707334,
        CO2: 752.8857,
        H2O: 3.347923,
        Wind_Ux: -0.7034973,
        Wind_Uy: -0.6286402,
        Wind_Uz: -0.1281583,
        Fc_mass: null,
        I_AirTemp: 8.707334,
        I_CO2: 752.8857,
        I_H2O: 3.347923,
        I_Wind_Ux: -0.7034973,
        I_Wind_Uy: -0.6286402,
        I_Wind_Uz: -0.1281583,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-15",
        Time: "08:33:00",
        Type: "OneMinData",
        Iteration: 6,
        AirTemp: 8.789726,
        CO2: 753.1673,
        H2O: 3.257446,
        Wind_Ux: -0.7666088,
        Wind_Uy: -0.6864975,
        Wind_Uz: -0.09643401,
        Fc_mass: null,
        I_AirTemp: 8.789726,
        I_CO2: 753.1673,
        I_H2O: 3.257446,
        I_Wind_Ux: -0.7666088,
        I_Wind_Uy: -0.6864975,
        I_Wind_Uz: -0.09643401,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-15",
        Time: "08:34:00",
        Type: "OneMinData",
        Iteration: 6,
        AirTemp: 8.560618,
        CO2: 756.3634,
        H2O: 3.221311,
        Wind_Ux: -0.834433,
        Wind_Uy: -1.010645,
        Wind_Uz: -0.1100263,
        Fc_mass: null,
        I_AirTemp: 8.560618,
        I_CO2: 756.3634,
        I_H2O: 3.221311,
        I_Wind_Ux: -0.834433,
        I_Wind_Uy: -1.010645,
        I_Wind_Uz: -0.1100263,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-15",
        Time: "08:35:00",
        Type: "OneMinData",
        Iteration: 6,
        AirTemp: 8.579236,
        CO2: 756.2344,
        H2O: 3.258302,
        Wind_Ux: -0.811984,
        Wind_Uy: -0.9659009,
        Wind_Uz: -0.04125696,
        Fc_mass: null,
        I_AirTemp: 8.579236,
        I_CO2: 756.2344,
        I_H2O: 3.258302,
        I_Wind_Ux: -0.811984,
        I_Wind_Uy: -0.9659009,
        I_Wind_Uz: -0.04125696,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-15",
        Time: "08:36:00",
        Type: "OneMinData",
        Iteration: 6,
        AirTemp: 8.410348,
        CO2: 757.8033,
        H2O: 3.256638,
        Wind_Ux: -0.6588124,
        Wind_Uy: -1.035083,
        Wind_Uz: -0.09126014,
        Fc_mass: null,
        I_AirTemp: 8.410348,
        I_CO2: 757.8033,
        I_H2O: 3.256638,
        I_Wind_Ux: -0.6588124,
        I_Wind_Uy: -1.035083,
        I_Wind_Uz: -0.09126014,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-15",
        Time: "08:37:00",
        Type: "OneMinData",
        Iteration: 6,
        AirTemp: 8.214041,
        CO2: 760.5758,
        H2O: 3.293157,
        Wind_Ux: -0.4072819,
        Wind_Uy: -1.165402,
        Wind_Uz: 0.1061735,
        Fc_mass: null,
        I_AirTemp: 8.214041,
        I_CO2: 760.5758,
        I_H2O: 3.293157,
        I_Wind_Ux: -0.4072819,
        I_Wind_Uy: -1.165402,
        I_Wind_Uz: 0.1061735,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-15",
        Time: "08:38:00",
        Type: "OneMinData",
        Iteration: 6,
        AirTemp: null,
        CO2: null,
        H2O: null,
        Wind_Ux: null,
        Wind_Uy: null,
        Wind_Uz: null,
        Fc_mass: null,
        I_AirTemp: 9.374459,
        I_CO2: 749.9986,
        I_H2O: 3.178178,
        I_Wind_Ux: -0.4081263,
        I_Wind_Uy: -1.171583,
        I_Wind_Uz: 0.09711576,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-15",
        Time: "08:39:00",
        Type: "OneMinData",
        Iteration: 6,
        AirTemp: null,
        CO2: null,
        H2O: null,
        Wind_Ux: null,
        Wind_Uy: null,
        Wind_Uz: null,
        Fc_mass: null,
        I_AirTemp: 9.260326,
        I_CO2: 752.1433,
        I_H2O: 3.164524,
        I_Wind_Ux: -0.2632273,
        I_Wind_Uy: -1.422571,
        I_Wind_Uz: 0.129044,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-15",
        Time: "08:40:00",
        Type: "OneMinData",
        Iteration: 6,
        AirTemp: null,
        CO2: null,
        H2O: null,
        Wind_Ux: null,
        Wind_Uy: null,
        Wind_Uz: null,
        Fc_mass: null,
        I_AirTemp: 9.463208,
        I_CO2: 747.8767,
        I_H2O: 3.095803,
        I_Wind_Ux: 0.09477298,
        I_Wind_Uy: -1.497745,
        I_Wind_Uz: 0.0902294,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-15",
        Time: "08:41:00",
        Type: "OneMinData",
        Iteration: 6,
        AirTemp: 7.610102,
        CO2: 767.404,
        H2O: 3.300037,
        Wind_Ux: -0.2371126,
        Wind_Uy: -1.185035,
        Wind_Uz: 0.1043443,
        Fc_mass: null,
        I_AirTemp: 7.610102,
        I_CO2: 767.404,
        I_H2O: 3.300037,
        I_Wind_Ux: -0.2371126,
        I_Wind_Uy: -1.185035,
        I_Wind_Uz: 0.1043443,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-15",
        Time: "08:42:00",
        Type: "OneMinData",
        Iteration: 6,
        AirTemp: 8.575796,
        CO2: 754.7722,
        H2O: 3.197893,
        Wind_Ux: -0.6764611,
        Wind_Uy: -1.991545,
        Wind_Uz: 0.1334938,
        Fc_mass: null,
        I_AirTemp: 8.575796,
        I_CO2: 754.7722,
        I_H2O: 3.197893,
        I_Wind_Ux: -0.6764611,
        I_Wind_Uy: -1.991545,
        I_Wind_Uz: 0.1334938,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-15",
        Time: "08:43:00",
        Type: "OneMinData",
        Iteration: 6,
        AirTemp: 8.613077,
        CO2: 753.5573,
        H2O: 3.230591,
        Wind_Ux: -0.9905066,
        Wind_Uy: -1.987234,
        Wind_Uz: 0.09090977,
        Fc_mass: null,
        I_AirTemp: 8.613077,
        I_CO2: 753.5573,
        I_H2O: 3.230591,
        I_Wind_Ux: -0.9905066,
        I_Wind_Uy: -1.987234,
        I_Wind_Uz: 0.09090977,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-15",
        Time: "08:44:00",
        Type: "OneMinData",
        Iteration: 6,
        AirTemp: 9.212332,
        CO2: 748.5114,
        H2O: 3.167072,
        Wind_Ux: -1.057674,
        Wind_Uy: -1.677588,
        Wind_Uz: -0.02394747,
        Fc_mass: null,
        I_AirTemp: 9.212332,
        I_CO2: 748.5114,
        I_H2O: 3.167072,
        I_Wind_Ux: -1.057674,
        I_Wind_Uy: -1.677588,
        I_Wind_Uz: -0.02394747,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-15",
        Time: "08:45:00",
        Type: "OneMinData",
        Iteration: 6,
        AirTemp: 9.001744,
        CO2: 751.2839,
        H2O: 3.193275,
        Wind_Ux: -0.8363327,
        Wind_Uy: -1.79503,
        Wind_Uz: 0.08031772,
        Fc_mass: null,
        I_AirTemp: 9.001744,
        I_CO2: 751.2839,
        I_H2O: 3.193275,
        I_Wind_Ux: -0.8363327,
        I_Wind_Uy: -1.79503,
        I_Wind_Uz: 0.08031772,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-15",
        Time: "08:46:00",
        Type: "OneMinData",
        Iteration: 6,
        AirTemp: 8.248127,
        CO2: 758.3603,
        H2O: 3.261504,
        Wind_Ux: -0.5486274,
        Wind_Uy: -1.650245,
        Wind_Uz: 0.09680238,
        Fc_mass: null,
        I_AirTemp: 8.248127,
        I_CO2: 758.3603,
        I_H2O: 3.261504,
        I_Wind_Ux: -0.5486274,
        I_Wind_Uy: -1.650245,
        I_Wind_Uz: 0.09680238,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-15",
        Time: "08:47:00",
        Type: "OneMinData",
        Iteration: 6,
        AirTemp: 9.20007,
        CO2: 750.2638,
        H2O: 3.163474,
        Wind_Ux: -0.354163,
        Wind_Uy: -1.672762,
        Wind_Uz: 0.14094,
        Fc_mass: null,
        I_AirTemp: 9.20007,
        I_CO2: 750.2638,
        I_H2O: 3.163474,
        I_Wind_Ux: -0.354163,
        I_Wind_Uy: -1.672762,
        I_Wind_Uz: 0.14094,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-15",
        Time: "08:48:00",
        Type: "OneMinData",
        Iteration: 6,
        AirTemp: 9.564378,
        CO2: 748.1193,
        H2O: 3.136353,
        Wind_Ux: -0.217426,
        Wind_Uy: -0.8989193,
        Wind_Uz: 0.02308405,
        Fc_mass: null,
        I_AirTemp: 9.564378,
        I_CO2: 748.1193,
        I_H2O: 3.136353,
        I_Wind_Ux: -0.217426,
        I_Wind_Uy: -0.8989193,
        I_Wind_Uz: 0.02308405,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-15",
        Time: "08:49:00",
        Type: "OneMinData",
        Iteration: 6,
        AirTemp: 8.997161,
        CO2: 752.186,
        H2O: 3.166509,
        Wind_Ux: 0.01628256,
        Wind_Uy: -1.239227,
        Wind_Uz: 0.03164399,
        Fc_mass: null,
        I_AirTemp: 8.997161,
        I_CO2: 752.186,
        I_H2O: 3.166509,
        I_Wind_Ux: 0.01628256,
        I_Wind_Uy: -1.239227,
        I_Wind_Uz: 0.03164399,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-15",
        Time: "08:50:00",
        Type: "OneMinData",
        Iteration: 6,
        AirTemp: 8.749414,
        CO2: 755.2555,
        H2O: 3.198819,
        Wind_Ux: 0.001187334,
        Wind_Uy: -1.100088,
        Wind_Uz: 0.07408131,
        Fc_mass: null,
        I_AirTemp: 8.749414,
        I_CO2: 755.2555,
        I_H2O: 3.198819,
        I_Wind_Ux: 0.001187334,
        I_Wind_Uy: -1.100088,
        I_Wind_Uz: 0.07408131,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-15",
        Time: "08:51:00",
        Type: "OneMinData",
        Iteration: 6,
        AirTemp: 8.433408,
        CO2: 759.9844,
        H2O: 3.246849,
        Wind_Ux: -0.06141038,
        Wind_Uy: -0.8705892,
        Wind_Uz: 0.03995902,
        Fc_mass: null,
        I_AirTemp: 8.433408,
        I_CO2: 759.9844,
        I_H2O: 3.246849,
        I_Wind_Ux: -0.06141038,
        I_Wind_Uy: -0.8705892,
        I_Wind_Uz: 0.03995902,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-15",
        Time: "08:52:00",
        Type: "OneMinData",
        Iteration: 6,
        AirTemp: 9.183765,
        CO2: 750.736,
        H2O: 3.101734,
        Wind_Ux: 0.2581524,
        Wind_Uy: -0.9183624,
        Wind_Uz: 0.04537283,
        Fc_mass: null,
        I_AirTemp: 9.183765,
        I_CO2: 750.736,
        I_H2O: 3.101734,
        I_Wind_Ux: 0.2581524,
        I_Wind_Uy: -0.9183624,
        I_Wind_Uz: 0.04537283,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-15",
        Time: "08:53:00",
        Type: "OneMinData",
        Iteration: 6,
        AirTemp: 7.081211,
        CO2: 770.9202,
        H2O: 3.299932,
        Wind_Ux: 0.4741352,
        Wind_Uy: -0.4013262,
        Wind_Uz: 0.05232724,
        Fc_mass: null,
        I_AirTemp: 7.081211,
        I_CO2: 770.9202,
        I_H2O: 3.299932,
        I_Wind_Ux: 0.4741352,
        I_Wind_Uy: -0.4013262,
        I_Wind_Uz: 0.05232724,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-15",
        Time: "08:54:00",
        Type: "OneMinData",
        Iteration: 6,
        AirTemp: 8.30762,
        CO2: 758.6588,
        H2O: 3.1843,
        Wind_Ux: 0.3139987,
        Wind_Uy: -0.5257216,
        Wind_Uz: 0.01913939,
        Fc_mass: null,
        I_AirTemp: 8.30762,
        I_CO2: 758.6588,
        I_H2O: 3.1843,
        I_Wind_Ux: 0.3139987,
        I_Wind_Uy: -0.5257216,
        I_Wind_Uz: 0.01913939,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-15",
        Time: "08:55:00",
        Type: "OneMinData",
        Iteration: 6,
        AirTemp: 8.402538,
        CO2: 759.8659,
        H2O: 3.174792,
        Wind_Ux: 0.1996614,
        Wind_Uy: -0.8117306,
        Wind_Uz: -0.1160616,
        Fc_mass: null,
        I_AirTemp: 8.402538,
        I_CO2: 759.8659,
        I_H2O: 3.174792,
        I_Wind_Ux: 0.1996614,
        I_Wind_Uy: -0.8117306,
        I_Wind_Uz: -0.1160616,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-15",
        Time: "08:56:00",
        Type: "OneMinData",
        Iteration: 6,
        AirTemp: 7.354898,
        CO2: 769.9097,
        H2O: 3.258934,
        Wind_Ux: 0.247005,
        Wind_Uy: -0.6909801,
        Wind_Uz: -0.03198957,
        Fc_mass: null,
        I_AirTemp: 7.354898,
        I_CO2: 769.9097,
        I_H2O: 3.258934,
        I_Wind_Ux: 0.247005,
        I_Wind_Uy: -0.6909801,
        I_Wind_Uz: -0.03198957,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-15",
        Time: "08:57:00",
        Type: "OneMinData",
        Iteration: 6,
        AirTemp: 7.339601,
        CO2: 768.7541,
        H2O: 3.241008,
        Wind_Ux: 0.4695867,
        Wind_Uy: -0.7940075,
        Wind_Uz: 0.002655861,
        Fc_mass: null,
        I_AirTemp: 7.339601,
        I_CO2: 768.7541,
        I_H2O: 3.241008,
        I_Wind_Ux: 0.4695867,
        I_Wind_Uy: -0.7940075,
        I_Wind_Uz: 0.002655861,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-15",
        Time: "08:58:00",
        Type: "OneMinData",
        Iteration: 6,
        AirTemp: 8.679208,
        CO2: 753.7046,
        H2O: 3.131643,
        Wind_Ux: 0.7021741,
        Wind_Uy: -0.6086789,
        Wind_Uz: 0.162447,
        Fc_mass: null,
        I_AirTemp: 8.679208,
        I_CO2: 753.7046,
        I_H2O: 3.131643,
        I_Wind_Ux: 0.7021741,
        I_Wind_Uy: -0.6086789,
        I_Wind_Uz: 0.162447,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-15",
        Time: "08:59:00",
        Type: "OneMinData",
        Iteration: 6,
        AirTemp: 8.076382,
        CO2: 760.2947,
        H2O: 3.181864,
        Wind_Ux: 0.9900785,
        Wind_Uy: -0.4830308,
        Wind_Uz: 0.1745585,
        Fc_mass: null,
        I_AirTemp: 8.076382,
        I_CO2: 760.2947,
        I_H2O: 3.181864,
        I_Wind_Ux: 0.9900785,
        I_Wind_Uy: -0.4830308,
        I_Wind_Uz: 0.1745585,
        C_Fc_mass: null,
      },
    ],
  },
  7: {
    Fc_mass: -0.02663764,
    C_Fc_mass: -0.059,
    date: "30 Jul 2022",
    data: [
      {
        Date: "2022-07-30",
        Time: "09:00:00",
        Type: "OneMinData",
        Iteration: 7,
        AirTemp: 6.848401,
        CO2: 778.5518,
        H2O: 3.270963,
        Wind_Ux: 0.5929206,
        Wind_Uy: -0.2596847,
        Wind_Uz: 0.1051571,
        Fc_mass: null,
        I_AirTemp: 6.848401,
        I_CO2: 778.5518,
        I_H2O: 3.270963,
        I_Wind_Ux: 0.5929206,
        I_Wind_Uy: -0.2596847,
        I_Wind_Uz: 0.1051571,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-30",
        Time: "09:01:00",
        Type: "OneMinData",
        Iteration: 7,
        AirTemp: 8.567907,
        CO2: 752.8558,
        H2O: 3.192879,
        Wind_Ux: 0.8674334,
        Wind_Uy: 0.1148594,
        Wind_Uz: 0.09123146,
        Fc_mass: null,
        I_AirTemp: 8.567907,
        I_CO2: 752.8558,
        I_H2O: 3.192879,
        I_Wind_Ux: 0.8674334,
        I_Wind_Uy: 0.1148594,
        I_Wind_Uz: 0.09123146,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-30",
        Time: "09:02:00",
        Type: "OneMinData",
        Iteration: 7,
        AirTemp: 8.846321,
        CO2: 750.622,
        H2O: 3.172219,
        Wind_Ux: 0.3386962,
        Wind_Uy: -0.2289194,
        Wind_Uz: -0.00920429,
        Fc_mass: null,
        I_AirTemp: 8.846321,
        I_CO2: 750.622,
        I_H2O: 3.172219,
        I_Wind_Ux: 0.3386962,
        I_Wind_Uy: -0.2289194,
        I_Wind_Uz: -0.00920429,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-30",
        Time: "09:03:00",
        Type: "OneMinData",
        Iteration: 7,
        AirTemp: 8.740116,
        CO2: 753.4893,
        H2O: 3.172643,
        Wind_Ux: 0.2444027,
        Wind_Uy: -0.4853232,
        Wind_Uz: 0.02549115,
        Fc_mass: null,
        I_AirTemp: 8.740116,
        I_CO2: 753.4893,
        I_H2O: 3.172643,
        I_Wind_Ux: 0.2444027,
        I_Wind_Uy: -0.4853232,
        I_Wind_Uz: 0.02549115,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-30",
        Time: "09:04:00",
        Type: "OneMinData",
        Iteration: 7,
        AirTemp: 8.992346,
        CO2: 751.4611,
        H2O: 3.153326,
        Wind_Ux: 0.2290109,
        Wind_Uy: -0.434958,
        Wind_Uz: 0.007919289,
        Fc_mass: null,
        I_AirTemp: 8.992346,
        I_CO2: 751.4611,
        I_H2O: 3.153326,
        I_Wind_Ux: 0.2290109,
        I_Wind_Uy: -0.434958,
        I_Wind_Uz: 0.007919289,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-30",
        Time: "09:05:00",
        Type: "OneMinData",
        Iteration: 7,
        AirTemp: 9.089924,
        CO2: 750.8231,
        H2O: 3.136233,
        Wind_Ux: 0.2431309,
        Wind_Uy: -0.658178,
        Wind_Uz: 0.01097555,
        Fc_mass: null,
        I_AirTemp: 9.089924,
        I_CO2: 750.8231,
        I_H2O: 3.136233,
        I_Wind_Ux: 0.2431309,
        I_Wind_Uy: -0.658178,
        I_Wind_Uz: 0.01097555,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-30",
        Time: "09:06:00",
        Type: "OneMinData",
        Iteration: 7,
        AirTemp: 9.277525,
        CO2: 748.7555,
        H2O: 3.111337,
        Wind_Ux: 0.01360151,
        Wind_Uy: -0.941807,
        Wind_Uz: 0.01567362,
        Fc_mass: null,
        I_AirTemp: 9.277525,
        I_CO2: 748.7555,
        I_H2O: 3.111337,
        I_Wind_Ux: 0.01360151,
        I_Wind_Uy: -0.941807,
        I_Wind_Uz: 0.01567362,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-30",
        Time: "09:07:00",
        Type: "OneMinData",
        Iteration: 7,
        AirTemp: 9.522004,
        CO2: 746.6207,
        H2O: 3.081899,
        Wind_Ux: 0.08298378,
        Wind_Uy: -0.6587517,
        Wind_Uz: 0.02237917,
        Fc_mass: null,
        I_AirTemp: 9.522004,
        I_CO2: 746.6207,
        I_H2O: 3.081899,
        I_Wind_Ux: 0.08298378,
        I_Wind_Uy: -0.6587517,
        I_Wind_Uz: 0.02237917,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-30",
        Time: "09:08:00",
        Type: "OneMinData",
        Iteration: 7,
        AirTemp: 8.019674,
        CO2: 762.9337,
        H2O: 3.18569,
        Wind_Ux: 0.2609738,
        Wind_Uy: -0.7450062,
        Wind_Uz: 0.09794182,
        Fc_mass: null,
        I_AirTemp: 8.019674,
        I_CO2: 762.9337,
        I_H2O: 3.18569,
        I_Wind_Ux: 0.2609738,
        I_Wind_Uy: -0.7450062,
        I_Wind_Uz: 0.09794182,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-30",
        Time: "09:09:00",
        Type: "OneMinData",
        Iteration: 7,
        AirTemp: 6.742266,
        CO2: 778.6985,
        H2O: 3.290086,
        Wind_Ux: 0.1432236,
        Wind_Uy: -0.7972494,
        Wind_Uz: 0.03976834,
        Fc_mass: null,
        I_AirTemp: 6.742266,
        I_CO2: 778.6985,
        I_H2O: 3.290086,
        I_Wind_Ux: 0.1432236,
        I_Wind_Uy: -0.7972494,
        I_Wind_Uz: 0.03976834,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-30",
        Time: "09:10:00",
        Type: "OneMinData",
        Iteration: 7,
        AirTemp: 8.706434,
        CO2: 754.7392,
        H2O: 3.123774,
        Wind_Ux: -0.003751166,
        Wind_Uy: -1.043341,
        Wind_Uz: -0.0280951,
        Fc_mass: null,
        I_AirTemp: 8.706434,
        I_CO2: 754.7392,
        I_H2O: 3.123774,
        I_Wind_Ux: -0.003751166,
        I_Wind_Uy: -1.043341,
        I_Wind_Uz: -0.0280951,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-30",
        Time: "09:11:00",
        Type: "OneMinData",
        Iteration: 7,
        AirTemp: 8.934152,
        CO2: 752.0744,
        H2O: 3.085561,
        Wind_Ux: 0.02895385,
        Wind_Uy: -1.184002,
        Wind_Uz: 0.03498153,
        Fc_mass: null,
        I_AirTemp: 8.934152,
        I_CO2: 752.0744,
        I_H2O: 3.085561,
        I_Wind_Ux: 0.02895385,
        I_Wind_Uy: -1.184002,
        I_Wind_Uz: 0.03498153,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-30",
        Time: "09:12:00",
        Type: "OneMinData",
        Iteration: 7,
        AirTemp: 6.902406,
        CO2: 774.2398,
        H2O: 3.26994,
        Wind_Ux: 0.1138913,
        Wind_Uy: -1.048651,
        Wind_Uz: -0.07667189,
        Fc_mass: null,
        I_AirTemp: 6.902406,
        I_CO2: 774.2398,
        I_H2O: 3.26994,
        I_Wind_Ux: 0.1138913,
        I_Wind_Uy: -1.048651,
        I_Wind_Uz: -0.07667189,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-30",
        Time: "09:13:00",
        Type: "OneMinData",
        Iteration: 7,
        AirTemp: null,
        CO2: null,
        H2O: null,
        Wind_Ux: null,
        Wind_Uy: null,
        Wind_Uz: null,
        Fc_mass: null,
        I_AirTemp: 7.623798,
        I_CO2: 767.0928,
        I_H2O: 3.220751,
        I_Wind_Ux: 0.03609515,
        I_Wind_Uy: -1.16301,
        I_Wind_Uz: 0.04068362,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-30",
        Time: "09:14:00",
        Type: "OneMinData",
        Iteration: 7,
        AirTemp: 9.045933,
        CO2: 751.8733,
        H2O: 3.092605,
        Wind_Ux: 0.1747554,
        Wind_Uy: -1.076904,
        Wind_Uz: 0.0904072,
        Fc_mass: null,
        I_AirTemp: 9.045933,
        I_CO2: 751.8733,
        I_H2O: 3.092605,
        I_Wind_Ux: 0.1747554,
        I_Wind_Uy: -1.076904,
        I_Wind_Uz: 0.0904072,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-30",
        Time: "09:15:00",
        Type: "OneMinData",
        Iteration: 7,
        AirTemp: 9.25134,
        CO2: 748.2962,
        H2O: 3.057992,
        Wind_Ux: 0.5508639,
        Wind_Uy: -0.6678724,
        Wind_Uz: 0.09018461,
        Fc_mass: null,
        I_AirTemp: 9.25134,
        I_CO2: 748.2962,
        I_H2O: 3.057992,
        I_Wind_Ux: 0.5508639,
        I_Wind_Uy: -0.6678724,
        I_Wind_Uz: 0.09018461,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-30",
        Time: "09:16:00",
        Type: "OneMinData",
        Iteration: 7,
        AirTemp: 8.880341,
        CO2: 750.1042,
        H2O: 3.078371,
        Wind_Ux: 0.860368,
        Wind_Uy: -0.4187048,
        Wind_Uz: 0.09064217,
        Fc_mass: null,
        I_AirTemp: 8.880341,
        I_CO2: 750.1042,
        I_H2O: 3.078371,
        I_Wind_Ux: 0.860368,
        I_Wind_Uy: -0.4187048,
        I_Wind_Uz: 0.09064217,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-30",
        Time: "09:17:00",
        Type: "OneMinData",
        Iteration: 7,
        AirTemp: 8.902386,
        CO2: 749.6124,
        H2O: 3.07514,
        Wind_Ux: 0.8342021,
        Wind_Uy: -0.8075983,
        Wind_Uz: 0.06585546,
        Fc_mass: null,
        I_AirTemp: 8.902386,
        I_CO2: 749.6124,
        I_H2O: 3.07514,
        I_Wind_Ux: 0.8342021,
        I_Wind_Uy: -0.8075983,
        I_Wind_Uz: 0.06585546,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-30",
        Time: "09:18:00",
        Type: "OneMinData",
        Iteration: 7,
        AirTemp: 8.289686,
        CO2: 755.1366,
        H2O: 3.117863,
        Wind_Ux: 1.012996,
        Wind_Uy: -0.9360284,
        Wind_Uz: 0.08347666,
        Fc_mass: null,
        I_AirTemp: 8.289686,
        I_CO2: 755.1366,
        I_H2O: 3.117863,
        I_Wind_Ux: 1.012996,
        I_Wind_Uy: -0.9360284,
        I_Wind_Uz: 0.08347666,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-30",
        Time: "09:19:00",
        Type: "OneMinData",
        Iteration: 7,
        AirTemp: 7.801757,
        CO2: 759.0247,
        H2O: 3.123852,
        Wind_Ux: 1.120193,
        Wind_Uy: -0.9423313,
        Wind_Uz: 0.1329377,
        Fc_mass: null,
        I_AirTemp: 7.801757,
        I_CO2: 759.0247,
        I_H2O: 3.123852,
        I_Wind_Ux: 1.120193,
        I_Wind_Uy: -0.9423313,
        I_Wind_Uz: 0.1329377,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-30",
        Time: "09:20:00",
        Type: "OneMinData",
        Iteration: 7,
        AirTemp: 8.332785,
        CO2: 756.5294,
        H2O: 3.105572,
        Wind_Ux: 1.176141,
        Wind_Uy: -0.9851214,
        Wind_Uz: 0.1391663,
        Fc_mass: null,
        I_AirTemp: 8.332785,
        I_CO2: 756.5294,
        I_H2O: 3.105572,
        I_Wind_Ux: 1.176141,
        I_Wind_Uy: -0.9851214,
        I_Wind_Uz: 0.1391663,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-30",
        Time: "09:21:00",
        Type: "OneMinData",
        Iteration: 7,
        AirTemp: 8.414782,
        CO2: 754.4695,
        H2O: 3.088499,
        Wind_Ux: 1.12266,
        Wind_Uy: -1.089611,
        Wind_Uz: 0.08767602,
        Fc_mass: null,
        I_AirTemp: 8.414782,
        I_CO2: 754.4695,
        I_H2O: 3.088499,
        I_Wind_Ux: 1.12266,
        I_Wind_Uy: -1.089611,
        I_Wind_Uz: 0.08767602,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-30",
        Time: "09:22:00",
        Type: "OneMinData",
        Iteration: 7,
        AirTemp: 8.55825,
        CO2: 754.3489,
        H2O: 3.139121,
        Wind_Ux: 0.9534586,
        Wind_Uy: -0.4242192,
        Wind_Uz: 0.08536495,
        Fc_mass: null,
        I_AirTemp: 8.55825,
        I_CO2: 754.3489,
        I_H2O: 3.139121,
        I_Wind_Ux: 0.9534586,
        I_Wind_Uy: -0.4242192,
        I_Wind_Uz: 0.08536495,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-30",
        Time: "09:23:00",
        Type: "OneMinData",
        Iteration: 7,
        AirTemp: 8.792871,
        CO2: 749.6018,
        H2O: 3.076119,
        Wind_Ux: 1.510941,
        Wind_Uy: -0.2765131,
        Wind_Uz: 0.1382969,
        Fc_mass: null,
        I_AirTemp: 8.792871,
        I_CO2: 749.6018,
        I_H2O: 3.076119,
        I_Wind_Ux: 1.510941,
        I_Wind_Uy: -0.2765131,
        I_Wind_Uz: 0.1382969,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-30",
        Time: "09:24:00",
        Type: "OneMinData",
        Iteration: 7,
        AirTemp: 6.756826,
        CO2: 766.0062,
        H2O: 3.215819,
        Wind_Ux: 1.224608,
        Wind_Uy: -0.6726025,
        Wind_Uz: 0.09494398,
        Fc_mass: null,
        I_AirTemp: 6.756826,
        I_CO2: 766.0062,
        I_H2O: 3.215819,
        I_Wind_Ux: 1.224608,
        I_Wind_Uy: -0.6726025,
        I_Wind_Uz: 0.09494398,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-30",
        Time: "09:25:00",
        Type: "OneMinData",
        Iteration: 7,
        AirTemp: 8.237557,
        CO2: 753.909,
        H2O: 3.067638,
        Wind_Ux: 1.662124,
        Wind_Uy: -1.404098,
        Wind_Uz: 0.1156704,
        Fc_mass: null,
        I_AirTemp: 8.237557,
        I_CO2: 753.909,
        I_H2O: 3.067638,
        I_Wind_Ux: 1.662124,
        I_Wind_Uy: -1.404098,
        I_Wind_Uz: 0.1156704,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-30",
        Time: "09:26:00",
        Type: "OneMinData",
        Iteration: 7,
        AirTemp: 8.480728,
        CO2: 750.7133,
        H2O: 3.04151,
        Wind_Ux: 1.484672,
        Wind_Uy: -1.118151,
        Wind_Uz: 0.1136328,
        Fc_mass: null,
        I_AirTemp: 8.480728,
        I_CO2: 750.7133,
        I_H2O: 3.04151,
        I_Wind_Ux: 1.484672,
        I_Wind_Uy: -1.118151,
        I_Wind_Uz: 0.1136328,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-30",
        Time: "09:27:00",
        Type: "OneMinData",
        Iteration: 7,
        AirTemp: 8.622558,
        CO2: 751.6458,
        H2O: 3.126201,
        Wind_Ux: 1.480224,
        Wind_Uy: -1.020673,
        Wind_Uz: 0.1184069,
        Fc_mass: null,
        I_AirTemp: 8.622558,
        I_CO2: 751.6458,
        I_H2O: 3.126201,
        I_Wind_Ux: 1.480224,
        I_Wind_Uy: -1.020673,
        I_Wind_Uz: 0.1184069,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-30",
        Time: "09:28:00",
        Type: "OneMinData",
        Iteration: 7,
        AirTemp: 9.224582,
        CO2: 746.542,
        H2O: 3.043717,
        Wind_Ux: 1.216209,
        Wind_Uy: -1.018954,
        Wind_Uz: 0.08902525,
        Fc_mass: null,
        I_AirTemp: 9.224582,
        I_CO2: 746.542,
        I_H2O: 3.043717,
        I_Wind_Ux: 1.216209,
        I_Wind_Uy: -1.018954,
        I_Wind_Uz: 0.08902525,
        C_Fc_mass: null,
      },
      {
        Date: "2022-07-30",
        Time: "09:29:00",
        Type: "OneMinData",
        Iteration: 7,
        AirTemp: 8.268408,
        CO2: 754.7497,
        H2O: 3.182499,
        Wind_Ux: 0.7369126,
        Wind_Uy: -1.164698,
        Wind_Uz: 0.1230522,
        Fc_mass: null,
        I_AirTemp: 8.268408,
        I_CO2: 754.7497,
        I_H2O: 3.182499,
        I_Wind_Ux: 0.7369126,
        I_Wind_Uy: -1.164698,
        I_Wind_Uz: 0.1230522,
        C_Fc_mass: null,
      },
    ],
  },
  8: {
    Fc_mass: 0.07858526,
    C_Fc_mass: 0.042,
    date: "07 Aug 2022",
    data: [
      {
        Date: "2022-08-07",
        Time: "09:30:00",
        Type: "OneMinData",
        Iteration: 8,
        AirTemp: 7.855343,
        CO2: 757.8374,
        H2O: 3.183593,
        Wind_Ux: 0.6515479,
        Wind_Uy: -1.260209,
        Wind_Uz: 0.05023164,
        Fc_mass: null,
        I_AirTemp: 7.855343,
        I_CO2: 757.8374,
        I_H2O: 3.183593,
        I_Wind_Ux: 0.6515479,
        I_Wind_Uy: -1.260209,
        I_Wind_Uz: 0.05023164,
        C_Fc_mass: null,
      },
      {
        Date: "2022-08-07",
        Time: "09:31:00",
        Type: "OneMinData",
        Iteration: 8,
        AirTemp: 7.820947,
        CO2: 757.5064,
        H2O: 3.158628,
        Wind_Ux: 0.6922758,
        Wind_Uy: -1.002381,
        Wind_Uz: -0.02160752,
        Fc_mass: null,
        I_AirTemp: 7.820947,
        I_CO2: 757.5064,
        I_H2O: 3.158628,
        I_Wind_Ux: 0.6922758,
        I_Wind_Uy: -1.002381,
        I_Wind_Uz: -0.02160752,
        C_Fc_mass: null,
      },
      {
        Date: "2022-08-07",
        Time: "09:32:00",
        Type: "OneMinData",
        Iteration: 8,
        AirTemp: 7.188566,
        CO2: 762.1436,
        H2O: 3.191635,
        Wind_Ux: 0.7399548,
        Wind_Uy: -0.9834005,
        Wind_Uz: 0.01855179,
        Fc_mass: null,
        I_AirTemp: 7.188566,
        I_CO2: 762.1436,
        I_H2O: 3.191635,
        I_Wind_Ux: 0.7399548,
        I_Wind_Uy: -0.9834005,
        I_Wind_Uz: 0.01855179,
        C_Fc_mass: null,
      },
      {
        Date: "2022-08-07",
        Time: "09:33:00",
        Type: "OneMinData",
        Iteration: 8,
        AirTemp: 7.443106,
        CO2: 760.8318,
        H2O: 3.146853,
        Wind_Ux: 0.5745204,
        Wind_Uy: -1.16482,
        Wind_Uz: -0.03065944,
        Fc_mass: null,
        I_AirTemp: 7.443106,
        I_CO2: 760.8318,
        I_H2O: 3.146853,
        I_Wind_Ux: 0.5745204,
        I_Wind_Uy: -1.16482,
        I_Wind_Uz: -0.03065944,
        C_Fc_mass: null,
      },
      {
        Date: "2022-08-07",
        Time: "09:34:00",
        Type: "OneMinData",
        Iteration: 8,
        AirTemp: 7.601417,
        CO2: 760.8391,
        H2O: 3.143786,
        Wind_Ux: 1.074388,
        Wind_Uy: -1.178568,
        Wind_Uz: 0.0663841,
        Fc_mass: null,
        I_AirTemp: 7.601417,
        I_CO2: 760.8391,
        I_H2O: 3.143786,
        I_Wind_Ux: 1.074388,
        I_Wind_Uy: -1.178568,
        I_Wind_Uz: 0.0663841,
        C_Fc_mass: null,
      },
      {
        Date: "2022-08-07",
        Time: "09:35:00",
        Type: "OneMinData",
        Iteration: 8,
        AirTemp: 9.265786,
        CO2: 747.0841,
        H2O: 3.010641,
        Wind_Ux: 0.7174744,
        Wind_Uy: -1.232656,
        Wind_Uz: 0.0591376,
        Fc_mass: null,
        I_AirTemp: 9.265786,
        I_CO2: 747.0841,
        I_H2O: 3.010641,
        I_Wind_Ux: 0.7174744,
        I_Wind_Uy: -1.232656,
        I_Wind_Uz: 0.0591376,
        C_Fc_mass: null,
      },
      {
        Date: "2022-08-07",
        Time: "09:36:00",
        Type: "OneMinData",
        Iteration: 8,
        AirTemp: 8.933008,
        CO2: 750.289,
        H2O: 3.0549,
        Wind_Ux: 0.6162328,
        Wind_Uy: -0.9156484,
        Wind_Uz: 0.03899264,
        Fc_mass: null,
        I_AirTemp: 8.933008,
        I_CO2: 750.289,
        I_H2O: 3.0549,
        I_Wind_Ux: 0.6162328,
        I_Wind_Uy: -0.9156484,
        I_Wind_Uz: 0.03899264,
        C_Fc_mass: null,
      },
      {
        Date: "2022-08-07",
        Time: "09:37:00",
        Type: "OneMinData",
        Iteration: 8,
        AirTemp: 8.75859,
        CO2: 752.4758,
        H2O: 3.043066,
        Wind_Ux: 0.3180312,
        Wind_Uy: -0.7933292,
        Wind_Uz: 0.05298042,
        Fc_mass: null,
        I_AirTemp: 8.75859,
        I_CO2: 752.4758,
        I_H2O: 3.043066,
        I_Wind_Ux: 0.3180312,
        I_Wind_Uy: -0.7933292,
        I_Wind_Uz: 0.05298042,
        C_Fc_mass: null,
      },
      {
        Date: "2022-08-07",
        Time: "09:38:00",
        Type: "OneMinData",
        Iteration: 8,
        AirTemp: 8.313877,
        CO2: 757.0898,
        H2O: 3.092113,
        Wind_Ux: 0.0685769,
        Wind_Uy: -0.8732366,
        Wind_Uz: 0.003089186,
        Fc_mass: null,
        I_AirTemp: 8.313877,
        I_CO2: 757.0898,
        I_H2O: 3.092113,
        I_Wind_Ux: 0.0685769,
        I_Wind_Uy: -0.8732366,
        I_Wind_Uz: 0.003089186,
        C_Fc_mass: null,
      },
      {
        Date: "2022-08-07",
        Time: "09:39:00",
        Type: "OneMinData",
        Iteration: 8,
        AirTemp: 8.301322,
        CO2: 757.8758,
        H2O: 3.098123,
        Wind_Ux: -0.01017438,
        Wind_Uy: -0.8434499,
        Wind_Uz: -0.005929238,
        Fc_mass: null,
        I_AirTemp: 8.301322,
        I_CO2: 757.8758,
        I_H2O: 3.098123,
        I_Wind_Ux: -0.01017438,
        I_Wind_Uy: -0.8434499,
        I_Wind_Uz: -0.005929238,
        C_Fc_mass: null,
      },
      {
        Date: "2022-08-07",
        Time: "09:40:00",
        Type: "OneMinData",
        Iteration: 8,
        AirTemp: 8.758014,
        CO2: 752.0662,
        H2O: 3.053557,
        Wind_Ux: -0.05443029,
        Wind_Uy: -0.4195314,
        Wind_Uz: -0.02172496,
        Fc_mass: null,
        I_AirTemp: 8.758014,
        I_CO2: 752.0662,
        I_H2O: 3.053557,
        I_Wind_Ux: -0.05443029,
        I_Wind_Uy: -0.4195314,
        I_Wind_Uz: -0.02172496,
        C_Fc_mass: null,
      },
      {
        Date: "2022-08-07",
        Time: "09:41:00",
        Type: "OneMinData",
        Iteration: 8,
        AirTemp: 8.042168,
        CO2: 764.4994,
        H2O: 3.150821,
        Wind_Ux: 0.03879125,
        Wind_Uy: -0.3752709,
        Wind_Uz: 0.04107549,
        Fc_mass: null,
        I_AirTemp: 8.042168,
        I_CO2: 764.4994,
        I_H2O: 3.150821,
        I_Wind_Ux: 0.03879125,
        I_Wind_Uy: -0.3752709,
        I_Wind_Uz: 0.04107549,
        C_Fc_mass: null,
      },
      {
        Date: "2022-08-07",
        Time: "09:42:00",
        Type: "OneMinData",
        Iteration: 8,
        AirTemp: 7.021566,
        CO2: 776.9043,
        H2O: 3.241832,
        Wind_Ux: 0.06242457,
        Wind_Uy: -0.7534091,
        Wind_Uz: 0.05345818,
        Fc_mass: null,
        I_AirTemp: 7.021566,
        I_CO2: 776.9043,
        I_H2O: 3.241832,
        I_Wind_Ux: 0.06242457,
        I_Wind_Uy: -0.7534091,
        I_Wind_Uz: 0.05345818,
        C_Fc_mass: null,
      },
      {
        Date: "2022-08-07",
        Time: "09:43:00",
        Type: "OneMinData",
        Iteration: 8,
        AirTemp: 6.728954,
        CO2: 776.9789,
        H2O: 3.254675,
        Wind_Ux: 0.2124005,
        Wind_Uy: -0.5435973,
        Wind_Uz: 0.04882712,
        Fc_mass: null,
        I_AirTemp: 6.728954,
        I_CO2: 776.9789,
        I_H2O: 3.254675,
        I_Wind_Ux: 0.2124005,
        I_Wind_Uy: -0.5435973,
        I_Wind_Uz: 0.04882712,
        C_Fc_mass: null,
      },
      {
        Date: "2022-08-07",
        Time: "09:44:00",
        Type: "OneMinData",
        Iteration: 8,
        AirTemp: 6.790788,
        CO2: 774.671,
        H2O: 3.249678,
        Wind_Ux: 0.2092524,
        Wind_Uy: -0.6624127,
        Wind_Uz: 0.09898454,
        Fc_mass: null,
        I_AirTemp: 6.790788,
        I_CO2: 774.671,
        I_H2O: 3.249678,
        I_Wind_Ux: 0.2092524,
        I_Wind_Uy: -0.6624127,
        I_Wind_Uz: 0.09898454,
        C_Fc_mass: null,
      },
      {
        Date: "2022-08-07",
        Time: "09:45:00",
        Type: "OneMinData",
        Iteration: 8,
        AirTemp: 7.185324,
        CO2: 769.2461,
        H2O: 3.206639,
        Wind_Ux: 0.4744926,
        Wind_Uy: -0.6319155,
        Wind_Uz: 0.1029541,
        Fc_mass: null,
        I_AirTemp: 7.185324,
        I_CO2: 769.2461,
        I_H2O: 3.206639,
        I_Wind_Ux: 0.4744926,
        I_Wind_Uy: -0.6319155,
        I_Wind_Uz: 0.1029541,
        C_Fc_mass: null,
      },
      {
        Date: "2022-08-07",
        Time: "09:46:00",
        Type: "OneMinData",
        Iteration: 8,
        AirTemp: 7.731428,
        CO2: 761.3388,
        H2O: 3.148706,
        Wind_Ux: 0.5190877,
        Wind_Uy: -0.5493266,
        Wind_Uz: 0.03128326,
        Fc_mass: null,
        I_AirTemp: 7.731428,
        I_CO2: 761.3388,
        I_H2O: 3.148706,
        I_Wind_Ux: 0.5190877,
        I_Wind_Uy: -0.5493266,
        I_Wind_Uz: 0.03128326,
        C_Fc_mass: null,
      },
      {
        Date: "2022-08-07",
        Time: "09:47:00",
        Type: "OneMinData",
        Iteration: 8,
        AirTemp: null,
        CO2: null,
        H2O: null,
        Wind_Ux: null,
        Wind_Uy: null,
        Wind_Uz: null,
        Fc_mass: null,
        I_AirTemp: 8.287627,
        I_CO2: 754.3507,
        I_H2O: 3.072928,
        I_Wind_Ux: 0.4838443,
        I_Wind_Uy: -0.536885,
        I_Wind_Uz: 0.02930508,
        C_Fc_mass: null,
      },
      {
        Date: "2022-08-07",
        Time: "09:48:00",
        Type: "OneMinData",
        Iteration: 8,
        AirTemp: null,
        CO2: null,
        H2O: null,
        Wind_Ux: null,
        Wind_Uy: null,
        Wind_Uz: null,
        Fc_mass: null,
        I_AirTemp: 8.418243,
        I_CO2: 753.6194,
        I_H2O: 3.045463,
        I_Wind_Ux: 0.5542959,
        I_Wind_Uy: -0.2882519,
        I_Wind_Uz: 0.01782901,
        C_Fc_mass: null,
      },
      {
        Date: "2022-08-07",
        Time: "09:49:00",
        Type: "OneMinData",
        Iteration: 8,
        AirTemp: null,
        CO2: null,
        H2O: null,
        Wind_Ux: null,
        Wind_Uy: null,
        Wind_Uz: null,
        Fc_mass: null,
        I_AirTemp: 7.515025,
        I_CO2: 767.9102,
        I_H2O: 3.14114,
        I_Wind_Ux: 0.374848,
        I_Wind_Uy: -0.5508872,
        I_Wind_Uz: 0.05876521,
        C_Fc_mass: null,
      },
      {
        Date: "2022-08-07",
        Time: "09:50:00",
        Type: "OneMinData",
        Iteration: 8,
        AirTemp: null,
        CO2: null,
        H2O: null,
        Wind_Ux: null,
        Wind_Uy: null,
        Wind_Uz: null,
        Fc_mass: null,
        I_AirTemp: 8.677418,
        I_CO2: 751.4286,
        I_H2O: 3.037372,
        I_Wind_Ux: 0.347725,
        I_Wind_Uy: -0.9356529,
        I_Wind_Uz: 0.03264607,
        C_Fc_mass: null,
      },
      {
        Date: "2022-08-07",
        Time: "09:51:00",
        Type: "OneMinData",
        Iteration: 8,
        AirTemp: 8.079421,
        CO2: 758.0733,
        H2O: 3.08865,
        Wind_Ux: 0.3017132,
        Wind_Uy: -0.8424918,
        Wind_Uz: 0.04010709,
        Fc_mass: null,
        I_AirTemp: 8.079421,
        I_CO2: 758.0733,
        I_H2O: 3.08865,
        I_Wind_Ux: 0.3017132,
        I_Wind_Uy: -0.8424918,
        I_Wind_Uz: 0.04010709,
        C_Fc_mass: null,
      },
      {
        Date: "2022-08-07",
        Time: "09:52:00",
        Type: "OneMinData",
        Iteration: 8,
        AirTemp: 8.156502,
        CO2: 756.3631,
        H2O: 3.095463,
        Wind_Ux: 0.2438594,
        Wind_Uy: -1.022796,
        Wind_Uz: 0.06337931,
        Fc_mass: null,
        I_AirTemp: 8.156502,
        I_CO2: 756.3631,
        I_H2O: 3.095463,
        I_Wind_Ux: 0.2438594,
        I_Wind_Uy: -1.022796,
        I_Wind_Uz: 0.06337931,
        C_Fc_mass: null,
      },
      {
        Date: "2022-08-07",
        Time: "09:53:00",
        Type: "OneMinData",
        Iteration: 8,
        AirTemp: 8.374572,
        CO2: 754.195,
        H2O: 3.085944,
        Wind_Ux: 0.2609736,
        Wind_Uy: -0.8816281,
        Wind_Uz: 0.07133753,
        Fc_mass: null,
        I_AirTemp: 8.374572,
        I_CO2: 754.195,
        I_H2O: 3.085944,
        I_Wind_Ux: 0.2609736,
        I_Wind_Uy: -0.8816281,
        I_Wind_Uz: 0.07133753,
        C_Fc_mass: null,
      },
      {
        Date: "2022-08-07",
        Time: "09:54:00",
        Type: "OneMinData",
        Iteration: 8,
        AirTemp: 6.12175,
        CO2: 780.1425,
        H2O: 3.247071,
        Wind_Ux: 0.2351871,
        Wind_Uy: -0.7410734,
        Wind_Uz: 0.04014664,
        Fc_mass: null,
        I_AirTemp: 6.12175,
        I_CO2: 780.1425,
        I_H2O: 3.247071,
        I_Wind_Ux: 0.2351871,
        I_Wind_Uy: -0.7410734,
        I_Wind_Uz: 0.04014664,
        C_Fc_mass: null,
      },
      {
        Date: "2022-08-07",
        Time: "09:55:00",
        Type: "OneMinData",
        Iteration: 8,
        AirTemp: 6.235593,
        CO2: 779.3969,
        H2O: 3.254973,
        Wind_Ux: 0.2970832,
        Wind_Uy: -0.4302178,
        Wind_Uz: -0.0762423,
        Fc_mass: null,
        I_AirTemp: 6.235593,
        I_CO2: 779.3969,
        I_H2O: 3.254973,
        I_Wind_Ux: 0.2970832,
        I_Wind_Uy: -0.4302178,
        I_Wind_Uz: -0.0762423,
        C_Fc_mass: null,
      },
      {
        Date: "2022-08-07",
        Time: "09:56:00",
        Type: "OneMinData",
        Iteration: 8,
        AirTemp: 7.464254,
        CO2: 763.5487,
        H2O: 3.173019,
        Wind_Ux: 0.4246982,
        Wind_Uy: -0.468345,
        Wind_Uz: -0.05110112,
        Fc_mass: null,
        I_AirTemp: 7.464254,
        I_CO2: 763.5487,
        I_H2O: 3.173019,
        I_Wind_Ux: 0.4246982,
        I_Wind_Uy: -0.468345,
        I_Wind_Uz: -0.05110112,
        C_Fc_mass: null,
      },
      {
        Date: "2022-08-07",
        Time: "09:57:00",
        Type: "OneMinData",
        Iteration: 8,
        AirTemp: 7.488327,
        CO2: 760.8783,
        H2O: 3.184632,
        Wind_Ux: 0.4894773,
        Wind_Uy: -0.3464847,
        Wind_Uz: 0.06732774,
        Fc_mass: null,
        I_AirTemp: 7.488327,
        I_CO2: 760.8783,
        I_H2O: 3.184632,
        I_Wind_Ux: 0.4894773,
        I_Wind_Uy: -0.3464847,
        I_Wind_Uz: 0.06732774,
        C_Fc_mass: null,
      },
      {
        Date: "2022-08-07",
        Time: "09:58:00",
        Type: "OneMinData",
        Iteration: 8,
        AirTemp: 7.624022,
        CO2: 759.8749,
        H2O: 3.182774,
        Wind_Ux: 0.3901826,
        Wind_Uy: -0.3343371,
        Wind_Uz: -0.009296072,
        Fc_mass: null,
        I_AirTemp: 7.624022,
        I_CO2: 759.8749,
        I_H2O: 3.182774,
        I_Wind_Ux: 0.3901826,
        I_Wind_Uy: -0.3343371,
        I_Wind_Uz: -0.009296072,
        C_Fc_mass: null,
      },
      {
        Date: "2022-08-07",
        Time: "09:59:00",
        Type: "OneMinData",
        Iteration: 8,
        AirTemp: 7.760289,
        CO2: 757.8421,
        H2O: 3.15629,
        Wind_Ux: 0.3772025,
        Wind_Uy: -0.4464301,
        Wind_Uz: 0.01579484,
        Fc_mass: null,
        I_AirTemp: 7.760289,
        I_CO2: 757.8421,
        I_H2O: 3.15629,
        I_Wind_Ux: 0.3772025,
        I_Wind_Uy: -0.4464301,
        I_Wind_Uz: 0.01579484,
        C_Fc_mass: null,
      },
    ],
  },
  9: {
    Fc_mass: 0.03990701,
    C_Fc_mass: 0.056,
    date: "20 Aug 2022",
    data: [
      {
        Date: "2022-08-20",
        Time: "10:00:00",
        Type: "OneMinData",
        Iteration: 9,
        AirTemp: 7.477728,
        CO2: 761.4606,
        H2O: 3.174243,
        Wind_Ux: 0.3079828,
        Wind_Uy: -0.7203554,
        Wind_Uz: -0.0147056,
        Fc_mass: null,
        I_AirTemp: 7.477728,
        I_CO2: 761.4606,
        I_H2O: 3.174243,
        I_Wind_Ux: 0.3079828,
        I_Wind_Uy: -0.7203554,
        I_Wind_Uz: -0.0147056,
        C_Fc_mass: null,
      },
      {
        Date: "2022-08-20",
        Time: "10:01:00",
        Type: "OneMinData",
        Iteration: 9,
        AirTemp: 7.410089,
        CO2: 765.29,
        H2O: 3.175301,
        Wind_Ux: 0.4433983,
        Wind_Uy: -0.6199983,
        Wind_Uz: 0.05363804,
        Fc_mass: null,
        I_AirTemp: 7.410089,
        I_CO2: 765.29,
        I_H2O: 3.175301,
        I_Wind_Ux: 0.4433983,
        I_Wind_Uy: -0.6199983,
        I_Wind_Uz: 0.05363804,
        C_Fc_mass: null,
      },
      {
        Date: "2022-08-20",
        Time: "10:02:00",
        Type: "OneMinData",
        Iteration: 9,
        AirTemp: 7.496976,
        CO2: 765.1118,
        H2O: 3.174738,
        Wind_Ux: 0.4823569,
        Wind_Uy: -0.8844908,
        Wind_Uz: 0.09680338,
        Fc_mass: null,
        I_AirTemp: 7.496976,
        I_CO2: 765.1118,
        I_H2O: 3.174738,
        I_Wind_Ux: 0.4823569,
        I_Wind_Uy: -0.8844908,
        I_Wind_Uz: 0.09680338,
        C_Fc_mass: null,
      },
      {
        Date: "2022-08-20",
        Time: "10:03:00",
        Type: "OneMinData",
        Iteration: 9,
        AirTemp: 8.384121,
        CO2: 752.8043,
        H2O: 3.113639,
        Wind_Ux: 0.3879918,
        Wind_Uy: -0.9758118,
        Wind_Uz: 0.01539987,
        Fc_mass: null,
        I_AirTemp: 8.384121,
        I_CO2: 752.8043,
        I_H2O: 3.113639,
        I_Wind_Ux: 0.3879918,
        I_Wind_Uy: -0.9758118,
        I_Wind_Uz: 0.01539987,
        C_Fc_mass: null,
      },
      {
        Date: "2022-08-20",
        Time: "10:04:00",
        Type: "OneMinData",
        Iteration: 9,
        AirTemp: 7.705882,
        CO2: 759.4213,
        H2O: 3.154006,
        Wind_Ux: 0.4357546,
        Wind_Uy: -0.8908226,
        Wind_Uz: 0.07429078,
        Fc_mass: null,
        I_AirTemp: 7.705882,
        I_CO2: 759.4213,
        I_H2O: 3.154006,
        I_Wind_Ux: 0.4357546,
        I_Wind_Uy: -0.8908226,
        I_Wind_Uz: 0.07429078,
        C_Fc_mass: null,
      },
      {
        Date: "2022-08-20",
        Time: "10:05:00",
        Type: "OneMinData",
        Iteration: 9,
        AirTemp: 8.216379,
        CO2: 754.3001,
        H2O: 3.071878,
        Wind_Ux: 0.8154043,
        Wind_Uy: -1.16366,
        Wind_Uz: 0.03919938,
        Fc_mass: null,
        I_AirTemp: 8.216379,
        I_CO2: 754.3001,
        I_H2O: 3.071878,
        I_Wind_Ux: 0.8154043,
        I_Wind_Uy: -1.16366,
        I_Wind_Uz: 0.03919938,
        C_Fc_mass: null,
      },
      {
        Date: "2022-08-20",
        Time: "10:06:00",
        Type: "OneMinData",
        Iteration: 9,
        AirTemp: null,
        CO2: null,
        H2O: null,
        Wind_Ux: null,
        Wind_Uy: null,
        Wind_Uz: null,
        Fc_mass: null,
        I_AirTemp: 8.106602,
        I_CO2: 754.0698,
        I_H2O: 3.084169,
        I_Wind_Ux: 0.6922974,
        I_Wind_Uy: -1.370089,
        I_Wind_Uz: 0.06205101,
        C_Fc_mass: null,
      },
      {
        Date: "2022-08-20",
        Time: "10:07:00",
        Type: "OneMinData",
        Iteration: 9,
        AirTemp: 7.80068,
        CO2: 757.6087,
        H2O: 3.147217,
        Wind_Ux: 0.1635598,
        Wind_Uy: -1.456152,
        Wind_Uz: 0.08233912,
        Fc_mass: null,
        I_AirTemp: 7.80068,
        I_CO2: 757.6087,
        I_H2O: 3.147217,
        I_Wind_Ux: 0.1635598,
        I_Wind_Uy: -1.456152,
        I_Wind_Uz: 0.08233912,
        C_Fc_mass: null,
      },
      {
        Date: "2022-08-20",
        Time: "10:08:00",
        Type: "OneMinData",
        Iteration: 9,
        AirTemp: 6.974497,
        CO2: 767.1686,
        H2O: 3.227161,
        Wind_Ux: 0.2112563,
        Wind_Uy: -1.60793,
        Wind_Uz: 0.007117816,
        Fc_mass: null,
        I_AirTemp: 6.974497,
        I_CO2: 767.1686,
        I_H2O: 3.227161,
        I_Wind_Ux: 0.2112563,
        I_Wind_Uy: -1.60793,
        I_Wind_Uz: 0.007117816,
        C_Fc_mass: null,
      },
      {
        Date: "2022-08-20",
        Time: "10:09:00",
        Type: "OneMinData",
        Iteration: 9,
        AirTemp: 8.288382,
        CO2: 753.1413,
        H2O: 3.122807,
        Wind_Ux: 0.4311805,
        Wind_Uy: -1.701482,
        Wind_Uz: 0.08922928,
        Fc_mass: null,
        I_AirTemp: 8.288382,
        I_CO2: 753.1413,
        I_H2O: 3.122807,
        I_Wind_Ux: 0.4311805,
        I_Wind_Uy: -1.701482,
        I_Wind_Uz: 0.08922928,
        C_Fc_mass: null,
      },
      {
        Date: "2022-08-20",
        Time: "10:10:00",
        Type: "OneMinData",
        Iteration: 9,
        AirTemp: 7.968528,
        CO2: 756.386,
        H2O: 3.138999,
        Wind_Ux: 0.2114849,
        Wind_Uy: -1.308558,
        Wind_Uz: 0.02297195,
        Fc_mass: null,
        I_AirTemp: 7.968528,
        I_CO2: 756.386,
        I_H2O: 3.138999,
        I_Wind_Ux: 0.2114849,
        I_Wind_Uy: -1.308558,
        I_Wind_Uz: 0.02297195,
        C_Fc_mass: null,
      },
      {
        Date: "2022-08-20",
        Time: "10:11:00",
        Type: "OneMinData",
        Iteration: 9,
        AirTemp: 5.283382,
        CO2: 779.8654,
        H2O: 3.321495,
        Wind_Ux: 0.2764054,
        Wind_Uy: -0.510772,
        Wind_Uz: 0.01153323,
        Fc_mass: null,
        I_AirTemp: 5.283382,
        I_CO2: 779.8654,
        I_H2O: 3.321495,
        I_Wind_Ux: 0.2764054,
        I_Wind_Uy: -0.510772,
        I_Wind_Uz: 0.01153323,
        C_Fc_mass: null,
      },
      {
        Date: "2022-08-20",
        Time: "10:12:00",
        Type: "OneMinData",
        Iteration: 9,
        AirTemp: 6.909985,
        CO2: 764.3793,
        H2O: 3.183654,
        Wind_Ux: 0.3685211,
        Wind_Uy: -0.9218949,
        Wind_Uz: 0.03240687,
        Fc_mass: null,
        I_AirTemp: 6.909985,
        I_CO2: 764.3793,
        I_H2O: 3.183654,
        I_Wind_Ux: 0.3685211,
        I_Wind_Uy: -0.9218949,
        I_Wind_Uz: 0.03240687,
        C_Fc_mass: null,
      },
      {
        Date: "2022-08-20",
        Time: "10:13:00",
        Type: "OneMinData",
        Iteration: 9,
        AirTemp: 8.500495,
        CO2: 750.9602,
        H2O: 3.057735,
        Wind_Ux: 0.5423999,
        Wind_Uy: -0.985977,
        Wind_Uz: 0.07580136,
        Fc_mass: null,
        I_AirTemp: 8.500495,
        I_CO2: 750.9602,
        I_H2O: 3.057735,
        I_Wind_Ux: 0.5423999,
        I_Wind_Uy: -0.985977,
        I_Wind_Uz: 0.07580136,
        C_Fc_mass: null,
      },
      {
        Date: "2022-08-20",
        Time: "10:14:00",
        Type: "OneMinData",
        Iteration: 9,
        AirTemp: 6.455871,
        CO2: 767.8055,
        H2O: 3.185243,
        Wind_Ux: 0.8647344,
        Wind_Uy: -0.3202307,
        Wind_Uz: 0.1316388,
        Fc_mass: null,
        I_AirTemp: 6.455871,
        I_CO2: 767.8055,
        I_H2O: 3.185243,
        I_Wind_Ux: 0.8647344,
        I_Wind_Uy: -0.3202307,
        I_Wind_Uz: 0.1316388,
        C_Fc_mass: null,
      },
      {
        Date: "2022-08-20",
        Time: "10:15:00",
        Type: "OneMinData",
        Iteration: 9,
        AirTemp: 6.671327,
        CO2: 764.6374,
        H2O: 3.16081,
        Wind_Ux: 0.8275814,
        Wind_Uy: -0.3199194,
        Wind_Uz: 0.06936963,
        Fc_mass: null,
        I_AirTemp: 6.671327,
        I_CO2: 764.6374,
        I_H2O: 3.16081,
        I_Wind_Ux: 0.8275814,
        I_Wind_Uy: -0.3199194,
        I_Wind_Uz: 0.06936963,
        C_Fc_mass: null,
      },
      {
        Date: "2022-08-20",
        Time: "10:16:00",
        Type: "OneMinData",
        Iteration: 9,
        AirTemp: 7.185795,
        CO2: 760.6944,
        H2O: 3.109897,
        Wind_Ux: 0.8287279,
        Wind_Uy: -0.9127029,
        Wind_Uz: 0.1856442,
        Fc_mass: null,
        I_AirTemp: 7.185795,
        I_CO2: 760.6944,
        I_H2O: 3.109897,
        I_Wind_Ux: 0.8287279,
        I_Wind_Uy: -0.9127029,
        I_Wind_Uz: 0.1856442,
        C_Fc_mass: null,
      },
      {
        Date: "2022-08-20",
        Time: "10:17:00",
        Type: "OneMinData",
        Iteration: 9,
        AirTemp: 6.769686,
        CO2: 767.4765,
        H2O: 3.1494,
        Wind_Ux: 0.5408039,
        Wind_Uy: -0.8799177,
        Wind_Uz: 0.05923638,
        Fc_mass: null,
        I_AirTemp: 6.769686,
        I_CO2: 767.4765,
        I_H2O: 3.1494,
        I_Wind_Ux: 0.5408039,
        I_Wind_Uy: -0.8799177,
        I_Wind_Uz: 0.05923638,
        C_Fc_mass: null,
      },
      {
        Date: "2022-08-20",
        Time: "10:18:00",
        Type: "OneMinData",
        Iteration: 9,
        AirTemp: 6.59414,
        CO2: 768.3111,
        H2O: 3.157218,
        Wind_Ux: 0.813472,
        Wind_Uy: -0.7940505,
        Wind_Uz: -0.01345411,
        Fc_mass: null,
        I_AirTemp: 6.59414,
        I_CO2: 768.3111,
        I_H2O: 3.157218,
        I_Wind_Ux: 0.813472,
        I_Wind_Uy: -0.7940505,
        I_Wind_Uz: -0.01345411,
        C_Fc_mass: null,
      },
      {
        Date: "2022-08-20",
        Time: "10:19:00",
        Type: "OneMinData",
        Iteration: 9,
        AirTemp: 7.150963,
        CO2: 761.7794,
        H2O: 3.174075,
        Wind_Ux: 1.595114,
        Wind_Uy: -0.9888912,
        Wind_Uz: 0.1278303,
        Fc_mass: null,
        I_AirTemp: 7.150963,
        I_CO2: 761.7794,
        I_H2O: 3.174075,
        I_Wind_Ux: 1.595114,
        I_Wind_Uy: -0.9888912,
        I_Wind_Uz: 0.1278303,
        C_Fc_mass: null,
      },
      {
        Date: "2022-08-20",
        Time: "10:20:00",
        Type: "OneMinData",
        Iteration: 9,
        AirTemp: 8.212278,
        CO2: 751.9597,
        H2O: 3.152396,
        Wind_Ux: 1.913996,
        Wind_Uy: -0.9943526,
        Wind_Uz: 0.1588074,
        Fc_mass: null,
        I_AirTemp: 8.212278,
        I_CO2: 751.9597,
        I_H2O: 3.152396,
        I_Wind_Ux: 1.913996,
        I_Wind_Uy: -0.9943526,
        I_Wind_Uz: 0.1588074,
        C_Fc_mass: null,
      },
      {
        Date: "2022-08-20",
        Time: "10:21:00",
        Type: "OneMinData",
        Iteration: 9,
        AirTemp: 8.222243,
        CO2: 751.8904,
        H2O: 3.145952,
        Wind_Ux: 1.569552,
        Wind_Uy: -1.111054,
        Wind_Uz: 0.09005401,
        Fc_mass: null,
        I_AirTemp: 8.222243,
        I_CO2: 751.8904,
        I_H2O: 3.145952,
        I_Wind_Ux: 1.569552,
        I_Wind_Uy: -1.111054,
        I_Wind_Uz: 0.09005401,
        C_Fc_mass: null,
      },
      {
        Date: "2022-08-20",
        Time: "10:22:00",
        Type: "OneMinData",
        Iteration: 9,
        AirTemp: 8.15031,
        CO2: 751.9613,
        H2O: 3.136194,
        Wind_Ux: 1.473973,
        Wind_Uy: -1.214746,
        Wind_Uz: 0.09981207,
        Fc_mass: null,
        I_AirTemp: 8.15031,
        I_CO2: 751.9613,
        I_H2O: 3.136194,
        I_Wind_Ux: 1.473973,
        I_Wind_Uy: -1.214746,
        I_Wind_Uz: 0.09981207,
        C_Fc_mass: null,
      },
      {
        Date: "2022-08-20",
        Time: "10:23:00",
        Type: "OneMinData",
        Iteration: 9,
        AirTemp: 8.213171,
        CO2: 751.7802,
        H2O: 3.168836,
        Wind_Ux: 1.530173,
        Wind_Uy: -0.6996884,
        Wind_Uz: 0.1094272,
        Fc_mass: null,
        I_AirTemp: 8.213171,
        I_CO2: 751.7802,
        I_H2O: 3.168836,
        I_Wind_Ux: 1.530173,
        I_Wind_Uy: -0.6996884,
        I_Wind_Uz: 0.1094272,
        C_Fc_mass: null,
      },
      {
        Date: "2022-08-20",
        Time: "10:24:00",
        Type: "OneMinData",
        Iteration: 9,
        AirTemp: 7.072629,
        CO2: 760.3227,
        H2O: 3.215946,
        Wind_Ux: 1.063029,
        Wind_Uy: -0.6663758,
        Wind_Uz: 0.08691423,
        Fc_mass: null,
        I_AirTemp: 7.072629,
        I_CO2: 760.3227,
        I_H2O: 3.215946,
        I_Wind_Ux: 1.063029,
        I_Wind_Uy: -0.6663758,
        I_Wind_Uz: 0.08691423,
        C_Fc_mass: null,
      },
      {
        Date: "2022-08-20",
        Time: "10:25:00",
        Type: "OneMinData",
        Iteration: 9,
        AirTemp: 6.942865,
        CO2: 762.0287,
        H2O: 3.243898,
        Wind_Ux: 1.004692,
        Wind_Uy: -0.7194513,
        Wind_Uz: 0.08150355,
        Fc_mass: null,
        I_AirTemp: 6.942865,
        I_CO2: 762.0287,
        I_H2O: 3.243898,
        I_Wind_Ux: 1.004692,
        I_Wind_Uy: -0.7194513,
        I_Wind_Uz: 0.08150355,
        C_Fc_mass: null,
      },
      {
        Date: "2022-08-20",
        Time: "10:26:00",
        Type: "OneMinData",
        Iteration: 9,
        AirTemp: 7.230056,
        CO2: 759.435,
        H2O: 3.291802,
        Wind_Ux: 1.10007,
        Wind_Uy: -0.808218,
        Wind_Uz: 0.09173207,
        Fc_mass: null,
        I_AirTemp: 7.230056,
        I_CO2: 759.435,
        I_H2O: 3.291802,
        I_Wind_Ux: 1.10007,
        I_Wind_Uy: -0.808218,
        I_Wind_Uz: 0.09173207,
        C_Fc_mass: null,
      },
      {
        Date: "2022-08-20",
        Time: "10:27:00",
        Type: "OneMinData",
        Iteration: 9,
        AirTemp: 7.16293,
        CO2: 760.2146,
        H2O: 3.213122,
        Wind_Ux: 0.8698891,
        Wind_Uy: -1.204082,
        Wind_Uz: 0.03140111,
        Fc_mass: null,
        I_AirTemp: 7.16293,
        I_CO2: 760.2146,
        I_H2O: 3.213122,
        I_Wind_Ux: 0.8698891,
        I_Wind_Uy: -1.204082,
        I_Wind_Uz: 0.03140111,
        C_Fc_mass: null,
      },
      {
        Date: "2022-08-20",
        Time: "10:28:00",
        Type: "OneMinData",
        Iteration: 9,
        AirTemp: 7.978237,
        CO2: 754.2705,
        H2O: 3.145241,
        Wind_Ux: 0.8077758,
        Wind_Uy: -1.364553,
        Wind_Uz: 0.05189481,
        Fc_mass: null,
        I_AirTemp: 7.978237,
        I_CO2: 754.2705,
        I_H2O: 3.145241,
        I_Wind_Ux: 0.8077758,
        I_Wind_Uy: -1.364553,
        I_Wind_Uz: 0.05189481,
        C_Fc_mass: null,
      },
      {
        Date: "2022-08-20",
        Time: "10:29:00",
        Type: "OneMinData",
        Iteration: 9,
        AirTemp: 8.210513,
        CO2: 753.0197,
        H2O: 3.164044,
        Wind_Ux: -0.2214982,
        Wind_Uy: -1.530954,
        Wind_Uz: -0.002932907,
        Fc_mass: null,
        I_AirTemp: 8.210513,
        I_CO2: 753.0197,
        I_H2O: 3.164044,
        I_Wind_Ux: -0.2214982,
        I_Wind_Uy: -1.530954,
        I_Wind_Uz: -0.002932907,
        C_Fc_mass: null,
      },
    ],
  },
  10: {
    Fc_mass: 0.1170313,
    C_Fc_mass: 0.08,
    date: "05 Sep 2022",
    data: [
      {
        Date: "2022-09-05",
        Time: "10:30:00",
        Type: "OneMinData",
        Iteration: 10,
        AirTemp: 8.79588,
        CO2: 749.8333,
        H2O: 3.452232,
        Wind_Ux: -0.4863978,
        Wind_Uy: -1.224103,
        Wind_Uz: -0.02100513,
        Fc_mass: null,
        I_AirTemp: 8.79588,
        I_CO2: 749.8333,
        I_H2O: 3.452232,
        I_Wind_Ux: -0.4863978,
        I_Wind_Uy: -1.224103,
        I_Wind_Uz: -0.02100513,
        C_Fc_mass: null,
      },
      {
        Date: "2022-09-05",
        Time: "10:31:00",
        Type: "OneMinData",
        Iteration: 10,
        AirTemp: 8.64689,
        CO2: 751.3886,
        H2O: 3.510003,
        Wind_Ux: -0.265762,
        Wind_Uy: -1.03465,
        Wind_Uz: 0.03082278,
        Fc_mass: null,
        I_AirTemp: 8.64689,
        I_CO2: 751.3886,
        I_H2O: 3.510003,
        I_Wind_Ux: -0.265762,
        I_Wind_Uy: -1.03465,
        I_Wind_Uz: 0.03082278,
        C_Fc_mass: null,
      },
      {
        Date: "2022-09-05",
        Time: "10:32:00",
        Type: "OneMinData",
        Iteration: 10,
        AirTemp: 7.827501,
        CO2: 757.8643,
        H2O: 3.335814,
        Wind_Ux: -0.3735707,
        Wind_Uy: -1.642432,
        Wind_Uz: 0.07365221,
        Fc_mass: null,
        I_AirTemp: 7.827501,
        I_CO2: 757.8643,
        I_H2O: 3.335814,
        I_Wind_Ux: -0.3735707,
        I_Wind_Uy: -1.642432,
        I_Wind_Uz: 0.07365221,
        C_Fc_mass: null,
      },
      {
        Date: "2022-09-05",
        Time: "10:33:00",
        Type: "OneMinData",
        Iteration: 10,
        AirTemp: 7.82709,
        CO2: 756.6639,
        H2O: 3.35178,
        Wind_Ux: -0.4439084,
        Wind_Uy: -1.619576,
        Wind_Uz: 0.08968667,
        Fc_mass: null,
        I_AirTemp: 7.82709,
        I_CO2: 756.6639,
        I_H2O: 3.35178,
        I_Wind_Ux: -0.4439084,
        I_Wind_Uy: -1.619576,
        I_Wind_Uz: 0.08968667,
        C_Fc_mass: null,
      },
      {
        Date: "2022-09-05",
        Time: "10:34:00",
        Type: "OneMinData",
        Iteration: 10,
        AirTemp: 7.95309,
        CO2: 755.1808,
        H2O: 3.327761,
        Wind_Ux: -0.2013476,
        Wind_Uy: -1.558442,
        Wind_Uz: 0.1171807,
        Fc_mass: null,
        I_AirTemp: 7.95309,
        I_CO2: 755.1808,
        I_H2O: 3.327761,
        I_Wind_Ux: -0.2013476,
        I_Wind_Uy: -1.558442,
        I_Wind_Uz: 0.1171807,
        C_Fc_mass: null,
      },
      {
        Date: "2022-09-05",
        Time: "10:35:00",
        Type: "OneMinData",
        Iteration: 10,
        AirTemp: 6.326363,
        CO2: 768.357,
        H2O: 3.350067,
        Wind_Ux: -0.147142,
        Wind_Uy: -1.351244,
        Wind_Uz: 0.1695513,
        Fc_mass: null,
        I_AirTemp: 6.326363,
        I_CO2: 768.357,
        I_H2O: 3.350067,
        I_Wind_Ux: -0.147142,
        I_Wind_Uy: -1.351244,
        I_Wind_Uz: 0.1695513,
        C_Fc_mass: null,
      },
      {
        Date: "2022-09-05",
        Time: "10:36:00",
        Type: "OneMinData",
        Iteration: 10,
        AirTemp: 7.409138,
        CO2: 760.8819,
        H2O: 3.378843,
        Wind_Ux: -0.2989103,
        Wind_Uy: -1.399395,
        Wind_Uz: 0.1196863,
        Fc_mass: null,
        I_AirTemp: 7.409138,
        I_CO2: 760.8819,
        I_H2O: 3.378843,
        I_Wind_Ux: -0.2989103,
        I_Wind_Uy: -1.399395,
        I_Wind_Uz: 0.1196863,
        C_Fc_mass: null,
      },
      {
        Date: "2022-09-05",
        Time: "10:37:00",
        Type: "OneMinData",
        Iteration: 10,
        AirTemp: 7.261957,
        CO2: 761.7726,
        H2O: 3.340612,
        Wind_Ux: -0.3346244,
        Wind_Uy: -1.595269,
        Wind_Uz: 0.21042,
        Fc_mass: null,
        I_AirTemp: 7.261957,
        I_CO2: 761.7726,
        I_H2O: 3.340612,
        I_Wind_Ux: -0.3346244,
        I_Wind_Uy: -1.595269,
        I_Wind_Uz: 0.21042,
        C_Fc_mass: null,
      },
      {
        Date: "2022-09-05",
        Time: "10:38:00",
        Type: "OneMinData",
        Iteration: 10,
        AirTemp: null,
        CO2: null,
        H2O: null,
        Wind_Ux: null,
        Wind_Uy: null,
        Wind_Uz: null,
        Fc_mass: null,
        I_AirTemp: 7.92223,
        I_CO2: 755.5367,
        I_H2O: 3.269067,
        I_Wind_Ux: -0.4202407,
        I_Wind_Uy: -1.952448,
        I_Wind_Uz: 0.1094755,
        C_Fc_mass: null,
      },
      {
        Date: "2022-09-05",
        Time: "10:39:00",
        Type: "OneMinData",
        Iteration: 10,
        AirTemp: null,
        CO2: null,
        H2O: null,
        Wind_Ux: null,
        Wind_Uy: null,
        Wind_Uz: null,
        Fc_mass: null,
        I_AirTemp: 7.435439,
        I_CO2: 760.1351,
        I_H2O: 3.279501,
        I_Wind_Ux: -0.7278137,
        I_Wind_Uy: -1.750745,
        I_Wind_Uz: 0.1251736,
        C_Fc_mass: null,
      },
      {
        Date: "2022-09-05",
        Time: "10:40:00",
        Type: "OneMinData",
        Iteration: 10,
        AirTemp: 7.973589,
        CO2: 755.1045,
        H2O: 3.199614,
        Wind_Ux: -0.6821014,
        Wind_Uy: -1.95787,
        Wind_Uz: 0.1111058,
        Fc_mass: null,
        I_AirTemp: 7.973589,
        I_CO2: 755.1045,
        I_H2O: 3.199614,
        I_Wind_Ux: -0.6821014,
        I_Wind_Uy: -1.95787,
        I_Wind_Uz: 0.1111058,
        C_Fc_mass: null,
      },
      {
        Date: "2022-09-05",
        Time: "10:41:00",
        Type: "OneMinData",
        Iteration: 10,
        AirTemp: 7.497891,
        CO2: 758.6394,
        H2O: 3.283371,
        Wind_Ux: -0.3540766,
        Wind_Uy: -1.709156,
        Wind_Uz: 0.1421048,
        Fc_mass: null,
        I_AirTemp: 7.497891,
        I_CO2: 758.6394,
        I_H2O: 3.283371,
        I_Wind_Ux: -0.3540766,
        I_Wind_Uy: -1.709156,
        I_Wind_Uz: 0.1421048,
        C_Fc_mass: null,
      },
      {
        Date: "2022-09-05",
        Time: "10:42:00",
        Type: "OneMinData",
        Iteration: 10,
        AirTemp: 6.814294,
        CO2: 764.5474,
        H2O: 3.382594,
        Wind_Ux: -0.4927249,
        Wind_Uy: -1.504661,
        Wind_Uz: 0.06157367,
        Fc_mass: null,
        I_AirTemp: 6.814294,
        I_CO2: 764.5474,
        I_H2O: 3.382594,
        I_Wind_Ux: -0.4927249,
        I_Wind_Uy: -1.504661,
        I_Wind_Uz: 0.06157367,
        C_Fc_mass: null,
      },
      {
        Date: "2022-09-05",
        Time: "10:43:00",
        Type: "OneMinData",
        Iteration: 10,
        AirTemp: 7.665323,
        CO2: 758.134,
        H2O: 3.484458,
        Wind_Ux: -0.8768696,
        Wind_Uy: -1.465195,
        Wind_Uz: 0.0237127,
        Fc_mass: null,
        I_AirTemp: 7.665323,
        I_CO2: 758.134,
        I_H2O: 3.484458,
        I_Wind_Ux: -0.8768696,
        I_Wind_Uy: -1.465195,
        I_Wind_Uz: 0.0237127,
        C_Fc_mass: null,
      },
      {
        Date: "2022-09-05",
        Time: "10:44:00",
        Type: "OneMinData",
        Iteration: 10,
        AirTemp: 7.949234,
        CO2: 756.2342,
        H2O: 3.465911,
        Wind_Ux: -1.017952,
        Wind_Uy: -1.529224,
        Wind_Uz: -0.0226842,
        Fc_mass: null,
        I_AirTemp: 7.949234,
        I_CO2: 756.2342,
        I_H2O: 3.465911,
        I_Wind_Ux: -1.017952,
        I_Wind_Uy: -1.529224,
        I_Wind_Uz: -0.0226842,
        C_Fc_mass: null,
      },
      {
        Date: "2022-09-05",
        Time: "10:45:00",
        Type: "OneMinData",
        Iteration: 10,
        AirTemp: 7.851548,
        CO2: 757.1057,
        H2O: 3.488287,
        Wind_Ux: -0.9487734,
        Wind_Uy: -1.714475,
        Wind_Uz: 0.02448274,
        Fc_mass: null,
        I_AirTemp: 7.851548,
        I_CO2: 757.1057,
        I_H2O: 3.488287,
        I_Wind_Ux: -0.9487734,
        I_Wind_Uy: -1.714475,
        I_Wind_Uz: 0.02448274,
        C_Fc_mass: null,
      },
      {
        Date: "2022-09-05",
        Time: "10:46:00",
        Type: "OneMinData",
        Iteration: 10,
        AirTemp: 7.663382,
        CO2: 758.7921,
        H2O: 3.554242,
        Wind_Ux: -0.9228622,
        Wind_Uy: -1.787081,
        Wind_Uz: 0.08160503,
        Fc_mass: null,
        I_AirTemp: 7.663382,
        I_CO2: 758.7921,
        I_H2O: 3.554242,
        I_Wind_Ux: -0.9228622,
        I_Wind_Uy: -1.787081,
        I_Wind_Uz: 0.08160503,
        C_Fc_mass: null,
      },
      {
        Date: "2022-09-05",
        Time: "10:47:00",
        Type: "OneMinData",
        Iteration: 10,
        AirTemp: 7.945358,
        CO2: 756.852,
        H2O: 3.691782,
        Wind_Ux: -0.7792128,
        Wind_Uy: -1.376406,
        Wind_Uz: -0.01803983,
        Fc_mass: null,
        I_AirTemp: 7.945358,
        I_CO2: 756.852,
        I_H2O: 3.691782,
        I_Wind_Ux: -0.7792128,
        I_Wind_Uy: -1.376406,
        I_Wind_Uz: -0.01803983,
        C_Fc_mass: null,
      },
      {
        Date: "2022-09-05",
        Time: "10:48:00",
        Type: "OneMinData",
        Iteration: 10,
        AirTemp: 8.461162,
        CO2: 752.2264,
        H2O: 3.607857,
        Wind_Ux: -0.835419,
        Wind_Uy: -1.242774,
        Wind_Uz: -0.01601859,
        Fc_mass: null,
        I_AirTemp: 8.461162,
        I_CO2: 752.2264,
        I_H2O: 3.607857,
        I_Wind_Ux: -0.835419,
        I_Wind_Uy: -1.242774,
        I_Wind_Uz: -0.01601859,
        C_Fc_mass: null,
      },
      {
        Date: "2022-09-05",
        Time: "10:49:00",
        Type: "OneMinData",
        Iteration: 10,
        AirTemp: 8.298933,
        CO2: 754.0804,
        H2O: 3.585184,
        Wind_Ux: -0.592391,
        Wind_Uy: -1.295905,
        Wind_Uz: 0.02705985,
        Fc_mass: null,
        I_AirTemp: 8.298933,
        I_CO2: 754.0804,
        I_H2O: 3.585184,
        I_Wind_Ux: -0.592391,
        I_Wind_Uy: -1.295905,
        I_Wind_Uz: 0.02705985,
        C_Fc_mass: null,
      },
      {
        Date: "2022-09-05",
        Time: "10:50:00",
        Type: "OneMinData",
        Iteration: 10,
        AirTemp: 8.335782,
        CO2: 754.272,
        H2O: 3.465903,
        Wind_Ux: -0.9251469,
        Wind_Uy: -1.342645,
        Wind_Uz: -0.007500453,
        Fc_mass: null,
        I_AirTemp: 8.335782,
        I_CO2: 754.272,
        I_H2O: 3.465903,
        I_Wind_Ux: -0.9251469,
        I_Wind_Uy: -1.342645,
        I_Wind_Uz: -0.007500453,
        C_Fc_mass: null,
      },
      {
        Date: "2022-09-05",
        Time: "10:51:00",
        Type: "OneMinData",
        Iteration: 10,
        AirTemp: 8.138633,
        CO2: 756.4661,
        H2O: 3.592092,
        Wind_Ux: -0.8213466,
        Wind_Uy: -1.285541,
        Wind_Uz: -0.000126535,
        Fc_mass: null,
        I_AirTemp: 8.138633,
        I_CO2: 756.4661,
        I_H2O: 3.592092,
        I_Wind_Ux: -0.8213466,
        I_Wind_Uy: -1.285541,
        I_Wind_Uz: -0.000126535,
        C_Fc_mass: null,
      },
      {
        Date: "2022-09-05",
        Time: "10:52:00",
        Type: "OneMinData",
        Iteration: 10,
        AirTemp: 7.963065,
        CO2: 756.7774,
        H2O: 3.543861,
        Wind_Ux: -0.9178079,
        Wind_Uy: -1.183171,
        Wind_Uz: -0.0582507,
        Fc_mass: null,
        I_AirTemp: 7.963065,
        I_CO2: 756.7774,
        I_H2O: 3.543861,
        I_Wind_Ux: -0.9178079,
        I_Wind_Uy: -1.183171,
        I_Wind_Uz: -0.0582507,
        C_Fc_mass: null,
      },
      {
        Date: "2022-09-05",
        Time: "10:53:00",
        Type: "OneMinData",
        Iteration: 10,
        AirTemp: 8.057519,
        CO2: 755.6602,
        H2O: 3.551628,
        Wind_Ux: -0.8442321,
        Wind_Uy: -0.9092223,
        Wind_Uz: -0.04357726,
        Fc_mass: null,
        I_AirTemp: 8.057519,
        I_CO2: 755.6602,
        I_H2O: 3.551628,
        I_Wind_Ux: -0.8442321,
        I_Wind_Uy: -0.9092223,
        I_Wind_Uz: -0.04357726,
        C_Fc_mass: null,
      },
      {
        Date: "2022-09-05",
        Time: "10:54:00",
        Type: "OneMinData",
        Iteration: 10,
        AirTemp: 8.363946,
        CO2: 752.9668,
        H2O: 3.530626,
        Wind_Ux: -0.7417303,
        Wind_Uy: -0.563994,
        Wind_Uz: -0.07430794,
        Fc_mass: null,
        I_AirTemp: 8.363946,
        I_CO2: 752.9668,
        I_H2O: 3.530626,
        I_Wind_Ux: -0.7417303,
        I_Wind_Uy: -0.563994,
        I_Wind_Uz: -0.07430794,
        C_Fc_mass: null,
      },
      {
        Date: "2022-09-05",
        Time: "10:55:00",
        Type: "OneMinData",
        Iteration: 10,
        AirTemp: 8.278776,
        CO2: 753.7783,
        H2O: 3.449974,
        Wind_Ux: -1.007365,
        Wind_Uy: -1.074037,
        Wind_Uz: -0.09427532,
        Fc_mass: null,
        I_AirTemp: 8.278776,
        I_CO2: 753.7783,
        I_H2O: 3.449974,
        I_Wind_Ux: -1.007365,
        I_Wind_Uy: -1.074037,
        I_Wind_Uz: -0.09427532,
        C_Fc_mass: null,
      },
      {
        Date: "2022-09-05",
        Time: "10:56:00",
        Type: "OneMinData",
        Iteration: 10,
        AirTemp: 7.769618,
        CO2: 758.5702,
        H2O: 3.465884,
        Wind_Ux: -0.9583713,
        Wind_Uy: -1.435255,
        Wind_Uz: -0.05203253,
        Fc_mass: null,
        I_AirTemp: 7.769618,
        I_CO2: 758.5702,
        I_H2O: 3.465884,
        I_Wind_Ux: -0.9583713,
        I_Wind_Uy: -1.435255,
        I_Wind_Uz: -0.05203253,
        C_Fc_mass: null,
      },
      {
        Date: "2022-09-05",
        Time: "10:57:00",
        Type: "OneMinData",
        Iteration: 10,
        AirTemp: 7.608218,
        CO2: 760.4362,
        H2O: 3.500602,
        Wind_Ux: -0.9169653,
        Wind_Uy: -1.59306,
        Wind_Uz: 0.02504347,
        Fc_mass: null,
        I_AirTemp: 7.608218,
        I_CO2: 760.4362,
        I_H2O: 3.500602,
        I_Wind_Ux: -0.9169653,
        I_Wind_Uy: -1.59306,
        I_Wind_Uz: 0.02504347,
        C_Fc_mass: null,
      },
      {
        Date: "2022-09-05",
        Time: "10:58:00",
        Type: "OneMinData",
        Iteration: 10,
        AirTemp: 7.715126,
        CO2: 757.8903,
        H2O: 3.524638,
        Wind_Ux: -0.9276663,
        Wind_Uy: -1.241555,
        Wind_Uz: -0.0113273,
        Fc_mass: null,
        I_AirTemp: 7.715126,
        I_CO2: 757.8903,
        I_H2O: 3.524638,
        I_Wind_Ux: -0.9276663,
        I_Wind_Uy: -1.241555,
        I_Wind_Uz: -0.0113273,
        C_Fc_mass: null,
      },
      {
        Date: "2022-09-05",
        Time: "10:59:00",
        Type: "OneMinData",
        Iteration: 10,
        AirTemp: 7.755808,
        CO2: 758.1698,
        H2O: 3.601552,
        Wind_Ux: -0.5715557,
        Wind_Uy: -1.089456,
        Wind_Uz: 0.04387931,
        Fc_mass: null,
        I_AirTemp: 7.755808,
        I_CO2: 758.1698,
        I_H2O: 3.601552,
        I_Wind_Ux: -0.5715557,
        I_Wind_Uy: -1.089456,
        I_Wind_Uz: 0.04387931,
        C_Fc_mass: null,
      },
    ],
  },
};

function MissingData(props) {
  const [indexNum, setIndexNum] = useState(1);
  // useEffect(()=>{
  //   checekArr();
  // },[checekArr])
  // console.log(arrr)
  useEffect(() => {
    const interval = setInterval(() => {
      setIndexNum(Math.floor(Math.random() * 10) + 1);
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      {/* <div className="row"> */}
      <h2 className="ai-ml">
        AI/ML Imputation & Flux Correction Model in Action
      </h2>
      <p className="random-flux">Newzealand Site - Random flux data</p>
      <div className="tbl-reportres">
        <img src={Calendar} />{" "}
        <span>
          {/* { arrr[indexNum]?.map((val, index, arr) =>{
            if (arr.length - 1 === index) {
              return (val.Date)
            }
          })} */}
          {arrr?.[indexNum]?.date}
        </span>
      </div>
      <div className="d-flex justify-content-between position-relative flex-wrap">
        <div className='without-i'>
          {/* <div className="tbl-reportres">
           <img src={Calendar} /> <span>
          {arrr?.[indexNum]?.date}
           </span>
            </div> */}
          <div class="reportmain-sitediv">
            <>
              <table width={"100%"}>
                <thead className="reporttbl-head flux-tbl">
                  <tr>
                    <th>Time</th>
                    <th>AirTemp</th>
                    <th>CO2 </th>
                    <th>H2O </th>
                    <th>Wind Ux </th>
                    <th>Wind Uy </th>
                    <th>Wind Uz</th>
                  </tr>
                </thead>
                {/* 8051523887 */}
                {/* filterSiteInfo */}
                <div style={{ marginTop: 10 }}></div>
                {console.log(indexNum, "indexNum")}
                {arrr[indexNum]?.data?.map((val, index) => {
                  return (
                    <tr className="report-tblbody">
                      <td className="repot-tblcard reporttbl-body">
                        {val.Time || (
                          <span
                            style={{ color: "#000", background: "#FF8787" }}
                          >
                            ----
                          </span>
                        )}
                      </td>
                      <td className="repot-tblcard reporttbl-body">
                        {val.AirTemp === null ? (
                          <span
                            style={{ color: "#000", background: "#FF8787" }}
                          >
                            ----
                          </span>
                        ):Number(val.AirTemp).toFixed(4)}
                      </td>
                      <td className="repot-tblcard reporttbl-body">
                        {val.CO2 === null ? (
                          <span
                            style={{ color: "#000", background: "#FF8787" }}
                          >
                            ----
                          </span>
                        ): Number(val.CO2).toFixed(4)}
                      </td>
                      <td className="repot-tblcard reporttbl-body">
                        {val.H2O === null ? (
                          <span
                            style={{ color: "#000", background: "#FF8787" }}
                          >
                            ----
                          </span>
                        ):Number(val.H2O).toFixed(4)}
                      </td>
                      <td className="repot-tblcard reporttbl-body">
                        {val.Wind_Ux ===null ? (
                          <span
                            style={{ color: "#000", background: "#FF8787" }}
                          >
                            ----
                          </span>
                        ):Number(val.Wind_Ux).toFixed(4)}
                      </td>
                      <td className="repot-tblcard reporttbl-body">
                        {val.Wind_Uy === null ? (
                          <span
                            style={{ color: "#000", background: "#FF8787" }}
                          >
                            ----
                          </span>
                        ):Number(val.Wind_Uy).toFixed(4)}
                      </td>
                      <td className="repot-tblcard reporttbl-body">
                        {val.Wind_Uz === null ? (
                          <span
                            style={{ color: "#000", background: "#FF8787" }}
                          >
                            ----
                          </span>
                        ):Number(val.Wind_Uz).toFixed(4)}
                      </td>
                    </tr>
                  );
                })}

                <div style={{ marginTop: 10 }}></div>
              </table>
            </>
          </div>
          <div className="flux-section">
            <div className="d-flex flex-row-reverse align-items-center">
              <div className="fluxreport">
                <p>{arrr?.[indexNum]?.Fc_mass}</p>
              </div>
              <div className="fluxData">
                <p>Orginal Flux &nbsp;&nbsp;</p>
              </div>
            </div>
          </div>
        </div>
        <div className="middle-border position-absolute">
          <div className="r_border"></div>
          <div className="poligon-fluxtop"><img src={Plygon} /> </div>
          <img src={SettingGif} width="60px" className="report-setting" />
          <div className="poligon-fluxleft"><img src={Plygon} /> </div>
          <div className="l_border"></div>
        </div>
        <div className='with-i'>
          <div class="reportmain-sitediv">
            <>
              <table width={"100%"}>
                <thead className="reporttbl-head flux-tbl">
                  <tr>
                    <th>Time</th>
                    <th>AirTemp</th>
                    <th>CO2 </th>
                    <th>H2O </th>
                    <th>Wind Ux </th>
                    <th>Wind Uy </th>
                    <th>Wind Uz</th>
                  </tr>
                </thead>
                {/* 8051523887 */}
                {/* filterSiteInfo */}
                <div style={{ marginTop: 10 }}></div>
                {arrr[indexNum]?.data?.map((val, index) => {
                  return (
                    <tr className="report-tblbody">
                      <td className="repot-tblcard reporttbl-body">{val.Time}</td>
                      <td className="repot-tblcard reporttbl-body">
                        {val.AirTemp === null ? (
                          <span
                            style={{ color: "#000", background: "#6FEABD" }}
                          >
                            {Number(val.I_AirTemp).toFixed(4)}
                          </span>
                        ) : (
                          Number(val.I_AirTemp).toFixed(4)
                        )}
                      </td>
                      <td className="repot-tblcard reporttbl-body">
                        {val.CO2 === null ? (
                          <span
                            style={{ color: "#000", background: "#6FEABD" }}
                          >
                            {Number(val.I_CO2).toFixed(4)}
                          </span>
                        ) : (
                          Number(val.I_CO2).toFixed(4)
                        )}
                      </td>
                      <td className="repot-tblcard reporttbl-body">
                        {val.H2O === null ? (
                          <span
                            style={{ color: "#000", background: "#6FEABD" }}
                          >
                            {Number(val.I_H2O).toFixed(4)}
                          </span>
                        ) : (
                          Number(val.I_H2O).toFixed(4)
                        )}
                      </td>
                      <td className="repot-tblcard reporttbl-body">
                        {val.Wind_Ux === null ? (
                          <span
                            style={{ color: "#000", background: "#6FEABD" }}
                          >
                            {Number(val.I_Wind_Ux).toFixed(4)}
                          </span>
                        ) : (
                          Number(val.I_Wind_Ux).toFixed(4)
                        )}
                      </td>
                      <td className="repot-tblcard reporttbl-body">
                        {val.Wind_Uy === null ? (
                          <span
                            style={{ color: "#000", background: "#6FEABD" }}
                          >
                            {Number(val.I_Wind_Uy).toFixed(4)}
                          </span>
                        ) : (
                          Number(val.I_Wind_Uy).toFixed(4)
                        )}
                      </td>
                      <td className="repot-tblcard reporttbl-body">
                        {val.Wind_Uz === null ? (
                          <span
                            style={{ color: "#000", background: "#6FEABD" }}
                          >
                            {Number(val.I_Wind_Uz).toFixed(4)}
                          </span>
                        ) : (
                          Number(val.I_Wind_Uz).toFixed(4)
                        )}
                      </td>
                    </tr>
                  );
                })}

                <div style={{ marginTop: 10 }}></div>
              </table>
            </>
          </div>
          <div className="flux-section">
            <div className="d-flex flex-row-reverse align-items-center">
              <div className="fluxreport corrected-flux">
                <p>{arrr?.[indexNum]?.C_Fc_mass}</p>
              </div>
              <div className="fluxData">
                <p>Corrected Flux &nbsp;&nbsp;</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MissingData;
