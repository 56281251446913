import React, { useState } from "react";
import * as Yup from "yup";
import { Formik, Form, ErrorMessage, Field } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { TextInput } from "../../components/TextField";
import { Button } from "@mui/material";
import axiosClient from "../../axios";
import axios from "../../axios";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RadioBtn } from "../../components/RadioBtn";
const EditSensor = () => {
  let nav = useNavigate();
  let params = useLocation();
  const [sensorTypes, setSensorTypes] = useState([]);
  const [sensoridCurr, setsensorid] = useState([]);
  const [formikData,SetFormikData] = useState({
    sensortypeid:'',
    sensortype:'',
    sensormodel:'',
    make:'',
    sensoryear:'',
    price:'',
    lifespan:'',
    state:'',
  })
  const user = useSelector((state) => state.loginData.user);

  function getSensorTypes() {
    axios.get("/v1/gait/site/view_sensor_types").then(res => {
      let tepm = res.data.gaitObj
      setSensorTypes(tepm)
    })
  }
  useEffect(() => {
    getSensorTypes();
    prefillValues();
  }, []);
  const prefillValues = (sensor) => {

    axiosClient.get("v1/gait/sensor/view_sensor_info_byid?sensorId=" + params.state.sensorId).then((resp) => {
      let sensorRes = resp.data.gaitObj
      
      setsensorid(sensorRes.sensorid)

      let formik = {
        sensortypeid: sensorRes?.sensortypeid, 
        sensortype:sensorRes?.sensortype,
        sensormodel:sensorRes?.sensormodel,
        make:sensorRes?.make,
        sensoryear:sensorRes?.sensoryear,
        price:sensorRes?.price,
        lifespan:sensorRes?.lifespan,
        state:sensorRes?.isactive,
      }
      SetFormikData(formik);
    })
  }

  const updateSensor = (val) => {
    axiosClient.put("v1/gait/sensor/update_sensor_info", {
      "sensorid": sensoridCurr,
      "sensortypeidref": {
        "sensortypeid": val?.sensortypeid
      },
      "sensormodel": val?.sensormodel,
      "sensormake": val?.make,
      "sensoryear": val?.sensoryear,
      "sensorprice": Number(val?.price),
      "sensorlifespan": val?.lifespan,
      "isactive": val?.state,
      "insertedby": user.orguserid,
      "modifiedby": user.orguserid
    }).then(res => {
      if(res.status === 200){
        alert("Sensor updated successfully");
        nav('/sensors',{state:{
          flag: "update_sensor"}});
      }else{
        alert("Sensor not updated");
      }
    
    })
  }
  const validate = Yup.object({
    sensortype: Yup.string().required("Sensor type is require"),
    sensormodel: Yup.string().required("Sensor model is require"),
    make: Yup.string().required("Make is required"),
    sensoryear: Yup.string().required("Sensor year is required"),
    price: Yup.string().required("Price is require"),
    lifespan: Yup.string().required("Lifespan in required"),
    state: Yup.string().required("Status is required."),
  });
  return (
    <Formik
      initialValues={formikData}
      validationSchema={validate}
      enableReinitialize ={true}
      onSubmit={(values) => {
        updateSensor(values);
      }}
    >
      {(formik) => (
        <Form>
          <Button
            onClick={(e) => {
              nav("/sensors");
            }}
            style={{
              marginLeft: "auto",
              display: "block",
              width: 140,
              background: "linear-gradient(0deg, #55AA8B, #55AA8B), #FEFEFE",
              borderRadius: 4,
              color: "white",
            }}
          >
            View All
          </Button>
          <div className="row form-top sites-content">
          <div className="col-md-3 col-sm-12">
                <div className="d-flex justify-content-between align-item-center">
                  <div>
                  <label className="form-label">Sensor Type</label>
                  </div>
                  <div>
                  </div>
                </div>
                <select as="select" name="sensortype" className="form-select">
                  {formik.values.sensortype ? (<option selected value={formik.values.sensortypeid}>{formik.values.sensortype}</option>):""}
                  
                  {sensorTypes?.map(val => {
                  return (<option value={val.sensortypeid}>{val.sensortype}</option>)
                })}
                 
                </select>
                <ErrorMessage
                  name="sensortype"
                  render={msg => <div className="error">
                    &nbsp;Please select status </div>}
                />
          
              </div>
            <div className="col-md-3 col-sm-12">
              <TextInput
                label="Sensor Model"
                name="sensormodel"
                type="text"
                placeholder="sensor model"
              />
            </div>
            <div className="col-md-3 col-sm-12">
              <TextInput
                label="Sensor Make"
                name="make"
                type="text"
                placeholder="sensor make"
              />
            </div>

            <div className="row addsite-body">
              <div className="col-md-3 col-sm-12">
                <div className="d-flex justify-content-between align-item-center">
                  <div>
                    <label className="form-label">Sensor Year</label>
                  </div>
                  <div></div>
                </div>
                <Field as="select" name="sensoryear" className="form-select">
                <option >-Select-</option>
                <option value="2022">2022</option>
                <option value="2021">2021</option>
                <option value="2020">2020</option>
                <option value="2019">2019</option>
                <option value="2018">2018</option>
                <option value="2017">2017</option>
                <option value="2016">2016</option>
                <option value="2015">2015</option>
                <option value="2014">2014</option>
                <option value="2013">2013</option>
                <option value="2012">2012</option>
                <option value="2011">2011</option>
                <option value="2010">2010</option>
                  
                </Field>
                <ErrorMessage
                  name="sensoryear"
                  render={(msg) => (
                    <div className="error">&nbsp;Please select site type </div>
                  )}
                />
              </div>

              <div className="col-md-3 col-sm-12">
                <TextInput
                  label="Sensor Price ($)"
                  name="price"
                  type="text"
                  placeholder="sensor make"
                />
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="row">
                <label className="form-label">Sensor Lifespan</label>
                <div className="col-md-2 col-sm-12">
                  <div className="radio">
                  {formik.values.lifespan===1 ? (
                    <RadioBtn label="1 Year"
                      type="radio"
                      name="lifespan"
                      value="1"
                      checked
                      />
                  ):(
                    <RadioBtn label="1 Year"
                      type="radio"
                      name="lifespan"
                      value="1"
                      />
                  )}
                  
                  </div>
                  </div>
                  <div className="col-md-2 col-sm-12">
                  <div className="radio">
                    {formik.values.lifespan===2 ? (
                        <RadioBtn  label="2 Year"
                        type="radio"
                        name="lifespan"
                        value="2"
                        checked
                        />
                    ) : (
                      <RadioBtn  label="2 Year"
                      type="radio"
                      name="lifespan"
                      value="2"/>
                    )}
                
                  </div>
                  </div>
                  <div className="col-md-2 col-sm-12">
                  <div className="radio">
                  {formik.values.lifespan===5 ? (
                    <RadioBtn  label="5 Year"
                      type="radio"
                      name="lifespan"
                      value="5"
                      checked
                      />
                  ) : (
                    <RadioBtn  label="5 Year"
                      type="radio"
                      name="lifespan"
                      value="5"/>
                  )}
                  
                  </div>
                  </div>
                  <div className="col-md-2 col-sm-12">
                  <div className="radio">
                  {formik.values.lifespan===10 ? (
                      <RadioBtn  label="10 Year"
                      type="radio"
                      name="lifespan"
                      value="10"
                      checked
                      />
                  ) : (
                    <RadioBtn  label="10 Year"
                    type="radio"
                    name="lifespan"
                    value="10"/>
                  )}
                   
                  </div>
                  </div>
                  <div className="col-md-3 col-sm-12">
                  <TextInput
                  label=""
                  name="lifespan"
                  type="text"
                  placeholder="sensor make"
                  style={{marginTop:-25}}
                />
                  </div>
                </div>
              </div>
            </div>

            <div className="row addsite-body">
              <div className="col-md-3 col-sm-12">
                <div className="d-flex justify-content-between align-item-center">
                  <div>
                  <label className="form-label">Status</label>
                  </div>
                  <div>
                  </div>
                </div>
                <Field as="select" name="state" className="form-select">
                  <option>-select-</option>
                  <option value={1}>Active</option>
                  <option value={0}>InActive</option>
                 
                </Field>
                <ErrorMessage
                  name="state"
                  render={msg => <div className="error">
                    &nbsp;Please select status </div>}
                />
          
              </div>
            </div>
            <div className="d-flex justify-content-end bottom-button add-sitebtn">
              <Button
                style={{
                  marginLeft: 15,
                  display: "block",
                  width: 140,
                  borderRadius: 4,
                  borderColor: "#FF6E6E",
                  borderWidth: 1,
                  color: "#FF6E6E",
                  border: "1px solid",
                  fontFamily: "Poppins",
                  fontSize: 13,
                }}
                onClick={(e) => {
                  nav("/sensors");
                }}
              >
                Cancel
              </Button>

              <Button
                style={{
                  marginLeft: 15,
                  display: "block",
                  width: 140,
                  background:
                    "linear-gradient(0deg, #55AA8B, #55AA8B), #FEFEFE",
                  borderRadius: 4,
                  color: "white",
                  fontFamily: "Poppins",
                  fontSize: 13,
                }}
                type="submit"
              >
                Create
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default EditSensor;
