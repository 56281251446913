import React from "react";
import { Button } from "@mui/material";
import { PDFExport } from "@progress/kendo-react-pdf";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { MapContainer, Marker, GeoJSON, Popup, TileLayer } from "react-leaflet";
import ICONS from "../../features/images";
import { useState } from "react";
import { useEffect } from "react";
import { Catalogues } from "../../components/Mock";
import axiosClient from "../../axios";
import { useLocation } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import L from "leaflet";
import axios from "axios";
import "./tbl.css";
import {
  getAllSites,
  getSite,
  getSiteCarbonByYear,
  getSiteCarbonBySource,
} from "../../features/counter/APIs";
import dayjs from "dayjs";
import Box from "@mui/material/Box";
import { Line } from "react-chartjs-2";
const labels = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];
let checkValue = [];
let analysisMaxValOfNdre = [];
let analysisMinValOfNdre = [];
let analysisMeanValOfNdre = [];
let analysisDateValOfNdre = [];
let checkAnalysisType = [];
let analysisDateValOfNdmi = [];
let analysisMinValOfNdmi = [];
let analysisMaxValOfNdmi = [];
let analysisMeanValOfNdvi = [];
let analysisDateValOfNdvi = [];
let analysisMinValOfNdvi = [];
let analysisMaxValOfNdvi = [];
let analysisMeanValOfNdmi = [];
let uniqueNdre = [];
let testVall = [];
function SiteAnalysis(props) {
  const location = useLocation();
  const pdfExportComponent = React.useRef(null);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [list, setList] = useState([]);
  const [newList, setNewList] = useState([]);
  const [geoJSONObj, setgeoJSONObj] = React.useState(null);
  const [bgColor, setBgColor] = React.useState("#55AA8B");
  const [getSiteRecord, setGetSiteRecord] = useState("");
  const [siteSensorCount, setSiteSensorCount] = useState("");
  const sensorgrey = L.icon({ iconUrl: ICONS.sgrey, iconSize: 25 });
  const sensorgreen = L.icon({ iconUrl: ICONS.sgreen, iconSize: 25 });
  const sensorred = L.icon({ iconUrl: ICONS.sred, iconSize: 25 });
  const currentSite = useSelector((state) => state.currentSite?.gaitObj);
  const sensorTable = useSelector((state) => state.sensorTable?.gaitObj);
  const siteCarbon = useSelector((state) => state.siteCarbon?.gaitObj);
  const user = useSelector((state) => state.loginData?.user);
  const [allReportOpt, setAllReportOpt] = useState();
  const [T2020, setT2020] = useState();
  const [T2021, setT2021] = useState();
  const [T2022, setT2022] = useState();
  const [Ndre, setNdre] = useState();
  const [loading, setLoading] = React.useState(false);
  const [dailyNetCarbon, setDailyNetCarbon] = useState([]);
  const [NDVIStatus, setNDVIStatus] = useState([]);
  const [siteAllInfo, setSiteAllInfo] = useState([]);
  const [allSiteResult, setAllSiteResult] = useState({});
  const [NDMIStatus, setNDMIStatus] = useState([]);
  const [NDREAllStatus, setNDREAllStatus] = useState([]);
  const [NDVIAllStatus, setNDVIAllStatus] = useState([]);
  const [NDMIAllStatus, setNDMIAllStatus] = useState([]);
  const [getAllCheck, setGetAllCheck] = useState(true);
  const [selected, setSelected] = useState([]);
  const [filterSiteInfo, setFilterSiteInfo] = useState([]);
  const [filterSiteInfoOfNDVI, setFilterSiteInfoOfNDVI] = useState([]);
  const [filterSiteInfoOfNDMI, setFilterSiteInfoOfNDMI] = useState([]);
  const [ndreCEA1, setNdreCEA1] = useState([]);
  const [objectKeysVal, setObjectKeysVal] = useState([]);
  const [analysisArrReport, setAnalysisArrReport] = useState([]);
  const [analysisReportLineChart, setAnalysisReportLineChart] = useState("");
  const [maxValueOfNDRE, setMaxValueOfNDRE] = useState([]);
  const [analysisReportLineChartNdmi, setAnalysisReportLineChartNdmi] =
    useState("");
  const [analysisReportLineChartNdvi, setAnalysisReportLineChartNdvi] =
    useState("");
  const [uniqueNdreVal, setuniqueNdre] = useState([]);
  const [reports, setReports] = useState({
    fruites: allReportOpt,
  });
  const [barChartData, setChartData] = React.useState({
    labels: labels,
    datasets: [
      {
        type: "line",
        label: "Dataset 1",
        borderColor: "#259A6C",
        borderWidth: 2,
        fill: false,
        data: [123, 234, 434, 345, 45, 34, 232],
        borderWidth: 1,
      },
      {
        type: "bar",
        label: "",
        borderWidth: 1,
        data: [123, 234, 244, 345, 45, 34, 232],
      },
    ],
  });
  // const filterReportAnalysisData= () =>{
  analysisMaxValOfNdre.filter((element, index) => {
    return analysisMaxValOfNdre.indexOf(element) === index;
  });
  // setuniqueNdre(uniqueNdre);
  // }
  useEffect(() => {
    // NDRE
    var analysisreportLineChartForNdre = {
      datasets: [
        {
          backgroundColor: "black",
          borderColor: "black",
          fill: false,
          data: analysisMaxValOfNdre,
          id: "carbon",
          label: "Max",
        },
        {
          backgroundColor: "#55aa8b",
          borderColor: "#55aa8b",
          fill: false,
          data: analysisMeanValOfNdre,
          id: "carbon",
          label: "Mean",
        },
        {
          backgroundColor: "blue",
          borderColor: "blue",
          fill: false,
          data: analysisMinValOfNdre,
          id: "carbon",
          label: "Min",
        },
      ],
      labels: analysisDateValOfNdre,
    };
    setAnalysisReportLineChart(analysisreportLineChartForNdre);
    // NDMI
    var analysisreportLineChartForNdmi = {
      datasets: [
        {
          backgroundColor: "black",
          borderColor: "black",
          fill: false,
          data: analysisMaxValOfNdmi,
          id: "carbon",
          label: "Max",
        },
        {
          backgroundColor: "#55aa8b",
          borderColor: "#55aa8b",
          fill: false,
          data: analysisMeanValOfNdmi,
          id: "carbon",
          label: "Mean",
        },
        {
          backgroundColor: "blue",
          borderColor: "blue",
          fill: false,
          data: analysisMinValOfNdmi,
          id: "carbon",
          label: "Min",
        },
      ],
      labels: analysisDateValOfNdre,
    };
    setAnalysisReportLineChartNdmi(analysisreportLineChartForNdmi);

    // NDMI
    var analysisreportLineChartForNdvi = {
      datasets: [
        {
          backgroundColor: "black",
          borderColor: "black",
          fill: false,
          data: analysisMaxValOfNdvi,
          id: "carbon",
          label: "Max",
        },
        {
          backgroundColor: "#55aa8b",
          borderColor: "#55aa8b",
          fill: false,
          data: analysisMeanValOfNdvi,
          id: "carbon",
          label: "Mean",
        },
        {
          backgroundColor: "blue",
          borderColor: "blue",
          fill: false,
          data: analysisMinValOfNdvi,
          id: "carbon",
          label: "Min",
        },
      ],
      labels: analysisDateValOfNdre,
    };
    setAnalysisReportLineChartNdvi(analysisreportLineChartForNdvi);
    getAllSiteReports();

    setList(Catalogues);
    getSiteReport();

    makeNewArrList();
  }, []);
  useEffect(() => {
    firstRenderSiteReport();
    getSensorTable();
    if (siteCarbon) {
      let satelliteData = [];
      siteCarbon?.map((val) => {
        if (val.source == "Satellite") {
          satelliteData.push({ x: labels[val.month - 1], y: val.totalcarbon });
        }
      });

      let sensorData = [];
      siteCarbon?.map((val) => {
        if (val.source == "Sensor") {
          sensorData.push({ x: labels[val.month - 1], y: val.totalcarbon });
        }
      });
      let temp = {
        labels: labels,
        datasets: [
          {
            type: "line",
            label: "Soil Carbon",
            borderColor: "#259A6C",
            backgroundColor: "transparent",
            borderWidth: 2,
            fill: false,
            data: sensorData,
          },
          {
            type: "bar",
            label: "",
            borderColor: "#259A6C",
            backgroundColor: "#259A6C",
            data: sensorData,
          },
          {
            type: "bar",
            label: "",
            borderColor: "#75f0c0",
            backgroundColor: "#75f0c0",
            data: satelliteData,
          },
        ],
      };
      setChartData(temp);
    }

    if (siteCarbon) {
      let satelliteData = [];

      siteCarbon.map((val) => {
        if (val.source == "Satellite") {
          satelliteData.push({ x: labels[val.month - 1], y: val.totalcarbon });
        }
      });

      let sensorData = [];
      siteCarbon.map((val) => {
        if (val.source == "Sensor") {
          sensorData.push({ x: labels[val.month - 1], y: val.totalcarbon });
        }
      });
    }
    props.getAllSites(
      user?.personidref?.orgidref?.orgid,
      user?.personidref?.orgidref?.orgcode
    );
    props.getCurrentSite(location?.state?.siteId);
    getSite(location?.state?.siteId);
  }, []);
  const getSensorTable = () => {
    setLoading(true);
    axiosClient
      .get(
        `v1/gait/site/view_site_sensors_list?siteId=${location?.state?.siteId}`
      )
      .then((res) => {
        let siteInfo = res?.data?.gaitObj;
        setSiteAllInfo(siteInfo);
        setLoading(false);
      });
  };
  const getSite = (siteId) => {
    axiosClient
      .get(`v1/gait/site/view_site_info?siteId=${location?.state?.siteId}`)
      .then((res) => {
        if (res?.data != "" && res?.data != null) {
          if (res?.data?.gaitObj?.shapefile != "NA") {
            
            axios.get(res?.data?.gaitObj?.shapefile).then((resp) => {
              setgeoJSONObj(resp?.data);
            });
          }
          setGetSiteRecord(res?.data?.gaitObj);
          setSiteSensorCount(res?.data?.gaitObj?.sensorcount);
        }
      });
  };
  const makeNewArrList = () => {
    let listVal = [];
    newList.map((val, index) => {
      listVal.push({ id: `0${index + 1}`, name: val });
    });
    setList(listVal);
  };
  const getSiteReport = () => {
    axiosClient
      .get(`v1/gait/siteAnalysis/analysises/${location?.state?.siteId}`)
      .then((res) => {
        setNewList(res?.data?.list);
        let reportArrr = [];
        res?.data?.list?.map((val, index) => {
          reportArrr.push({
            id: index + 1,
            value: val,
            isChecked: false,
            label: val,
          });
        });
        setAllReportOpt(reportArrr);
        setReports(reportArrr);
        makeNewArrList();
      });
  };

  const getAllSiteReports = () => {
    let T20 = [];
    let T21 = [];
    let T22 = [];

    axiosClient
      .post(`v1/gait/siteAnalysis/byfilter`, {
        siteId: location?.state?.siteId,
        analysis: location?.state?.reportArr,
      })
      .then((res) => {
        let ndreTestCode = [];
        let ndviTestCode = [];
        let ndmiTestCode = [];
        let ndreCA1 = [];

        setNdre(res?.data);
        setObjectKeysVal(Object.keys(res?.data));

        // test ndre
        let analysisArrReport = [];
        Object.keys(res?.data)?.map((val, index) => {
          analysisArrReport.push(val);
        });

        setAnalysisArrReport(analysisArrReport);

        // NDRE
        if (location.state.siteId === "805") {
          res?.data?.NDRE?.CEA1?.map((val, index) => {
            if (val?.year === 2017) {
              ndreCA1.push({
                year: "2017",
                newdata: res?.data?.NDRE?.CEA1?.map((val, index) => {
                  if (val.year === 2017) {
                    return val;
                  }
                }),
              });
            }
            if (val?.year === 2018) {
              ndreCA1.push({
                year: "2018",
                newdata: res?.data?.NDRE?.CEA1?.map((val, index) => {
                  if (val.year === 2018) {
                    return val;
                  }
                }),
              });
            }

            if (val?.year === 2019) {
              ndreCA1.push({
                year: "2019",
                newdata: res?.data?.NDRE?.CEA1?.map((val, index) => {
                  if (val.year === 2019) {
                    return val;
                  }
                }),
              });
            }

            if (val?.year === 2020) {
              ndreCA1.push({
                year: "2020",
                newdata: res?.data?.NDRE?.CEA1?.map((val, index) => {
                  if (val.year === 2020) {
                    return val;
                  }
                }),
              });
            }

            if (val?.year === 2021) {
              ndreCA1.push({
                year: "2021",
                newdata: res?.data?.NDRE?.CEA1?.map((val, index) => {
                  if (val.year === 2021) {
                    return val;
                  }
                }),
              });
            }

            if (val?.year === 2022) {
              ndreCA1.push({
                year: "2022",
                newdata: res?.data?.NDRE?.CEA1?.map((val, index) => {
                  if (val.year === 2022) {
                    return val;
                  }
                }),
              });
            }
            return;
          });
          let finalCEA1 = ndreCA1?.filter(
            (ele, ind) =>
              ind ===
              ndreCA1.findIndex(
                (elem) => elem.year === ele.year && elem.year === ele.year
              )
          );
          setNdreCEA1(finalCEA1);
        }
        res?.data?.NDRE?.Z1?.map((val, index) => {
          if (val?.year === 2017) {
            ndreTestCode.push({
              year: "2017",
              newdata: res?.data?.NDRE?.Z1?.map((val, index) => {
                if (val.year === 2017) {
                  return val;
                }
              }),
            });
          }
          if (val?.year === 2018) {
            ndreTestCode.push({
              year: "2018",
              newdata: res?.data?.NDRE?.Z1?.map((val, index) => {
                if (val.year === 2018) {
                  return val;
                }
              }),
            });
          }

          if (val?.year === 2019) {
            ndreTestCode.push({
              year: "2019",
              newdata: res?.data?.NDRE?.Z1?.map((val, index) => {
                if (val?.year === 2019) {
                  return val;
                }
              }),
            });
          }

          if (val?.year === 2020) {
            ndreTestCode.push({
              year: "2020",
              newdata: res?.data?.NDRE?.Z1?.map((val, index) => {
                if (val.year === 2020) {
                  return val;
                }
              }),
            });
          }

          if (val?.year === 2021) {
            ndreTestCode.push({
              year: "2021",
              newdata: res?.data?.NDRE?.Z1?.map((val, index) => {
                if (val.year === 2021) {
                  return val;
                }
              }),
            });
          }

          if (val?.year === 2022) {
            ndreTestCode.push({
              year: "2022",
              newdata: res?.data?.NDRE?.Z1?.map((val, index) => {
                if (val.year === 2022) {
                  return val;
                }
              }),
            });
          }
          return;
        });
        let finalVal = ndreTestCode.filter(
          (ele, ind) =>
            ind ===
            ndreTestCode.findIndex(
              (elem) => elem.year === ele.year && elem.year === ele.year
            )
        );
        setFilterSiteInfo(finalVal);
        // NDVI
        res?.data?.NDVI?.Z1?.map((val, index) => {
          if (val?.year === 2020) {
            ndviTestCode.push({
              year: "2020",
              newdata: res?.data?.NDVI?.Z1?.map((val, index) => {
                if (val.year === 2020) {
                  return val;
                }
              }),
            });
          }

          if (val?.year === 2021) {
            ndviTestCode.push({
              year: "2021",
              newdata: res?.data?.NDVI?.Z1?.map((val, index) => {
                if (val?.year === 2021) {
                  return val;
                }
              }),
            });
          }

          if (val?.year === 2022) {
            ndviTestCode.push({
              year: "2022",
              newdata: res?.data?.NDVI?.Z1?.map((val, index) => {
                if (val?.year === 2022) {
                  return val;
                }
              }),
            });
          }
          return;
        });
        let finalValOfNdvi = ndviTestCode.filter(
          (ele, ind) =>
            ind ===
            ndviTestCode.findIndex(
              (elem) => elem.year === ele.year && elem.year === ele.year
            )
        );
        setFilterSiteInfoOfNDVI(finalValOfNdvi);

        // NDMI

        res?.data?.NDMI?.Z1?.map((val, index) => {
          if (val.year === 2020) {
            ndmiTestCode.push({
              year: "2020",
              newdata: res?.data?.NDMI?.Z1?.map((val, index) => {
                if (val.year === 2020) {
                  return val;
                }
              }),
            });
          }

          if (val?.year === 2021) {
            ndmiTestCode.push({
              year: "2021",
              newdata: res?.data?.NDMI?.Z1?.map((val, index) => {
                if (val?.year === 2021) {
                  return val;
                }
              }),
            });
          }

          if (val?.year === 2022) {
            ndmiTestCode.push({
              year: "2022",
              newdata: res?.data?.NDMI?.Z1?.map((val, index) => {
                if (val?.year === 2022) {
                  return val;
                }
              }),
            });
          }
          return;
        });
        let finalValOfNdmi = ndmiTestCode.filter(
          (ele, ind) =>
            ind ===
            ndmiTestCode.findIndex(
              (elem) => elem.year === ele.year && elem.year === ele.year
            )
        );
        setFilterSiteInfoOfNDMI(finalValOfNdmi);

        res?.data?.NDRE?.Z1?.map((val, index) => {
          if (val?.year == 2020) {
            T20.push(val);
          }
          if (val?.year == 2021) {
            T21.push(val);
          }
          if (val?.year == 2022) {
            T22.push(val);
          }
        });
        setT2020(T20);
        setT2021(T21);
        setT2022(T22);
        let ndreStatus = res?.data?.NDRE?.Z1?.map((val, index) => {
          return res?.data?.NDRE?.Z1?.[index]?.mean;
        });
        let ndreStatusmin = res?.data?.NDRE?.Z1?.map((val, index) => {
          return res?.data?.NDRE?.Z1?.[index]?.min;
        });
        let ndreStatusmax = res?.data?.NDRE?.Z1?.map((val, index) => {
          return res?.data?.NDRE?.Z1?.[index]?.max;
        });

        let DateTime = res?.data?.NDRE?.Z1?.map((val, index) => {
          return dayjs(res?.data?.NDRE?.Z1?.[index]?.date).format("DD/MM/YYYY");
        });
        var ndre_ = {
          datasets: [
            {
              backgroundColor: "#55aa8b",
              borderColor: "#55aa8b",
              fill: false,
              data: ndreStatus,
              id: "carbon",
              label: "Mean",
              yAxisID: "left",
            },
            {
              backgroundColor: "blue",
              borderColor: "blue",
              fill: false,
              data: ndreStatusmin,
              id: "carbon",
              label: "Min",
              yAxisID: "left",
            },
            {
              backgroundColor: "black",
              borderColor: "black",
              fill: false,
              data: ndreStatusmax,
              id: "carbon",
              label: "Max",
              yAxisID: "left",
            },
          ],
          labels: DateTime,
        };
        setDailyNetCarbon(ndre_);
        // NDVI
        let ndviStatus = res?.data?.NDVI?.Z1?.map((val, index) => {
          return res?.data?.NDVI?.Z1?.[index]?.mean;
        });
        let ndviStatusmin = res?.data?.NDVI?.Z1?.map((val, index) => {
          return res?.data?.NDVI?.Z1?.[index]?.min;
        });
        let ndviStatusmax = res?.data?.NDVI?.Z1?.map((val, index) => {
          return res?.data?.NDVI?.Z1?.[index]?.max;
        });

        let ndviDateTime = res?.data?.NDVI?.Z1?.map((val, index) => {
          return dayjs(res?.data?.NDVI?.Z1?.[index]?.date).format("DD/MM/YYYY");
        });
        var ndvi_ = {
          datasets: [
            {
              backgroundColor: "#55aa8b",
              borderColor: "#55aa8b",
              fill: false,
              data: ndviStatus,
              id: "carbon",
              label: "Monthly Carbon",
              yAxisID: "left",
            },
            {
              backgroundColor: "blue",
              borderColor: "blue",
              fill: false,
              data: ndviStatusmin,
              id: "carbon",
              label: "Min",
              yAxisID: "left",
            },
            {
              backgroundColor: "black",
              borderColor: "black",
              fill: false,
              data: ndviStatusmax,
              id: "carbon",
              label: "Max",
              yAxisID: "left",
            },
          ],
          labels: ndviDateTime,
        };
        setNDVIStatus(ndvi_);

        // NDMI
        let ndmiStatus = res?.data?.NDMI?.Z1?.map((val, index) => {
          return res?.data?.NDMI?.Z1?.[index]?.mean;
        });
        let ndmiStatusmin = res?.data?.NDMI?.Z1?.map((val, index) => {
          return res?.data?.NDMI?.Z1?.[index]?.min;
        });
        let ndmiStatusmax = res?.data?.NDMI?.Z1?.map((val, index) => {
          return res?.data?.NDMI?.Z1?.[index]?.max;
        });

        let ndmiDateTime = res?.data?.NDMI?.Z1?.map((val, index) => {
          return dayjs(res?.data?.NDMI?.Z1?.[index]?.date).format("DD/MM/YYYY");
        });
        var ndmi_ = {
          datasets: [
            {
              backgroundColor: "#55aa8b",
              borderColor: "#55aa8b",
              fill: false,
              data: ndmiStatus,
              id: "carbon",
              label: "Monthly Carbon",
              yAxisID: "left",
            },
            {
              backgroundColor: "blue",
              borderColor: "blue",
              fill: false,
              data: ndmiStatusmin,
              id: "carbon",
              label: "Min",
              yAxisID: "left",
            },
            {
              backgroundColor: "black",
              borderColor: "black",
              fill: false,
              data: ndmiStatusmax,
              id: "carbon",
              label: "Max",
              yAxisID: "left",
            },
          ],
          labels: ndmiDateTime,
        };
        setNDMIStatus(ndmi_);
      });
  };
  const firstRenderSiteReport = () => {
    axiosClient
      .get(`v1/gait/siteAnalysis/bysiteId/${location?.state?.siteId}`)
      .then((res) => {
        setAllSiteResult(res);
        // NDRE
        let ndreAllStatus = res?.data?.NDRE?.Z1?.map((val, index) => {
          return res?.data?.NDRE?.Z1?.[index]?.mean;
        });
        let ndreAllMin = res?.data?.NDRE?.Z1?.map((val, index) => {
          return res?.data?.NDRE?.Z1?.[index]?.min;
        });
        let ndreAllMax = res?.data?.NDRE?.Z1?.map((val, index) => {
          return res?.data?.NDRE?.Z1?.[index]?.max;
        });

        let DateTime = res?.data?.NDRE?.Z1?.map((val, index) => {
          return dayjs(res?.data?.NDRE?.Z1?.[index]?.date).format("DD/MM/YYYY");
        });
        var ndreall_ = {
          datasets: [
            {
              backgroundColor: "black",
              borderColor: "black",
              fill: false,
              data: ndreAllMax,
              id: "carbon",
              label: "Max",
            },
            {
              backgroundColor: "#55aa8b",
              borderColor: "#55aa8b",
              fill: false,
              data: ndreAllStatus,
              id: "carbon",
              label: "Mean",
            },
            {
              backgroundColor: "blue",
              borderColor: "blue",
              fill: false,
              data: ndreAllMin,
              id: "carbon",
              label: "Min",
            },
          ],
          labels: DateTime,
        };
        setNDREAllStatus(ndreall_);

        // NDVI
        // NDVI
        let ndviAllStatus = res?.data?.NDVI?.Z1?.map((val, index) => {
          return res?.data?.NDVI?.Z1?.[index]?.mean;
        });
        let ndviAllMin = res?.data?.NDVI?.Z1?.map((val, index) => {
          return res?.data?.NDVI?.Z1?.[index]?.min;
        });
        let ndviAllMax = res?.data?.NDVI?.Z1?.map((val, index) => {
          return res?.data?.NDVI?.Z1?.[index]?.max;
        });
        let ndviAllDateTime = res?.data?.NDVI?.Z1?.map((val, index) => {
          return dayjs(res?.data?.NDVI?.Z1?.[index]?.date).format("DD/MM/YYYY");
        });
        var ndviAll_ = {
          datasets: [
            {
              backgroundColor: "#55aa8b",
              borderColor: "#55aa8b",
              fill: false,
              data: ndviAllStatus,
              id: "carbon",
              label: "Mean",
            },
            {
              backgroundColor: "blue",
              borderColor: "blue",
              fill: false,
              data: ndviAllMin,
              id: "carbon",
              label: "Min",
            },
            {
              backgroundColor: "black",
              borderColor: "black",
              fill: false,
              data: ndviAllMax,
              id: "carbon",
              label: "Max",
            },
          ],
          labels: ndviAllDateTime,
        };
        setNDVIAllStatus(ndviAll_);
        // NDMIII
        let ndmiAllStatus = res?.data?.NDMI?.Z1?.map((val, index) => {
          return res?.data?.NDMI?.Z1?.[index]?.mean;
        });
        let ndmiAllMin = res?.data?.NDMI?.Z1?.map((val, index) => {
          return res?.data?.NDMI?.Z1?.[index]?.min;
        });
        let ndmiAllMax = res?.data?.NDMI?.Z1?.map((val, index) => {
          return res?.data?.NDMI?.Z1?.[index]?.max;
        });
        let ndmiAllDateTime = res?.data?.NDMI?.Z1?.map((val, index) => {
          return dayjs(res?.data?.NDMI?.Z1?.[index]?.date).format("DD/MM/YYYY");
        });
        var ndmiAll_ = {
          datasets: [
            {
              backgroundColor: "#55aa8b",
              borderColor: "#55aa8b",
              fill: false,
              data: ndmiAllStatus,
              id: "carbon",
              label: "Mean",
            },
            {
              backgroundColor: "blue",
              borderColor: "blue",
              fill: false,
              data: ndmiAllMin,
              id: "carbon",
              label: "Min",
            },
            {
              backgroundColor: "black",
              borderColor: "black",
              fill: false,
              data: ndmiAllMax,
              id: "carbon",
              label: "Max",
            },
          ],
          labels: ndmiAllDateTime,
        };
        setNDMIAllStatus(ndmiAll_);
      });
  };
  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(list.map((li) => li?.id));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const handleClickCheckbox = (e) => {
    setAllSiteResult([]);
    checkValue.push(e?.target?.name);
  };
  const handleAllChecked = (event) => {
    setNdre([]);
    setGetAllCheck(event?.target?.checked);
  };
  var windOptions = {
    elements: {
      rectangle: {
        borderWidth: 2,
      },
    },
    layout: {
      padding: 0,
    },
    legend: {
      display: true,
      labels: {
        boxWidth: 50,
        fontSize: 25,
        fontColor: "gray",
      },
    },
    maintainAspectRatio: false,
    responsive: true,

    title: {
      display: false,
    },
    tooltips: {
      intersect: false,
      mode: "index",
      position: "nearest",
      callbacks: {},
    },
    scales: {
      y: {
        type: "linear",
        display: true,
        position: "left",
        title: { display: false, text: "MIN" },
      },
      y2: {
        type: "linear",
        display: false,
        position: "left",
        title: { display: true, text: "MEAN" },
      },
      y1: {
        type: "linear",
        display: false,
        position: "right",
        title: { display: true, text: "MAX" },
        grid: {
          drawOnChartArea: false,
        },
      },
      x: {
        display: true,
      },
    },
  };

  var windOptionsforEach = {
    elements: {
      rectangle: {
        borderWidth: 2,
      },
    },
    layout: {
      padding: 0,
    },
    legend: {
      display: true,
      labels: {
        boxWidth: 50,
        fontSize: 25,
        fontColor: "gray",
      },
    },
    maintainAspectRatio: false,
    responsive: true,

    title: {
      display: false,
    },
    tooltips: {
      intersect: false,
      mode: "index",
      position: "nearest",
      callbacks: {},
    },
    scales: {
      y: {
        display: false,
      },
      x: {
        display: true,
      },
    },
  };

  const columns = [
    {
      field: "year",
      headerName: "Year",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "quarter",
      headerName: "Quarter",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      flex: 1,
      minWidth: 100,
    },
    {
      field: "date",
      headerName: "Date",
      flex: 1,
      minWidth: 100,

      valueFormatter: (params) => dayjs(params?.value).format("DD-MMMM-YYYY"),
    },
    {
      field: "min",
      headerName: "Min",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "max",
      headerName: "Max",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      flex: 1,
      minWidth: 100,
    },
    {
      field: "mean",
      headerName: "Mean",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      flex: 1,
      minWidth: 100,
    },
    {
      field: "std",
      headerName: "Stdev",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      flex: 1,
      minWidth: 100,
    },
  ];
  const columns1 = [
    // { field: 'id', headerName: 'ID', width: 70 },
    {
      field: "analysis",
      headerName: "Name",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "min",
      headerName: "Min",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "max",
      headerName: "Max",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      flex: 1,
      minWidth: 100,
    },
    {
      field: "mean",
      headerName: "Mean",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      flex: 1,
      minWidth: 100,
    },
    {
      field: "std",
      headerName: "Stdev",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      flex: 1,
      minWidth: 100,
    },
  ];
  return (
    <>
      {loading ? (
        <div>
          <img
            src={ICONS.SignalGreen}
            style={{
              width: "5%",
              position: "absolute",
              top: "50%",
              left: "50%",
            }}
          />
        </div>
      ) : (
        <PDFExport
          paperSize="A2"
          ref={pdfExportComponent}
          margin={{ right: 5 }}
          fileName={`${siteAllInfo?.[0]?.sitename}.pdf`}
        >
          <div className="container">
            <div className="row">
              <div className="main-sitediv">
                <div className="d-flex justify-content-between flex-wrap sitea-report">
                  <div className="site-name" style={{ width: "60%" }}>
                    <p style={{ textAlign: "left" }}>Site Analysis Report</p>
                  </div>
                  <div className="download-site">
                    <Button
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                      onClick={() => {
                        if (pdfExportComponent.current) {
                          pdfExportComponent.current.save();
                        }
                      }}
                      style={{
                        marginLeft: 15,
                        display: "block",
                        width: 170,
                        background:
                          "linear-gradient(0deg, #55AA8B, #55AA8B), #FEFEFE",
                        borderRadius: 4,
                        color: "white",
                        fontFamily: "Poppins",
                        fontSize: 13,
                        margin: "auto",
                      }}
                    >
                      <FileDownloadOutlinedIcon /> Download PDF
                    </Button>
                  </div>
                </div>
                <div className="border-bottom"></div>
                <div className="site-information">
                  {/* <div className="row"> */}
                  <div className="d-flex justify-content-between flex-wrap testclssss">
                    <div className="report-siteinfo">
                      <h2>Site Information</h2>

                      <div className="site-infoborder"></div>
                    </div>
                    <div className="report-sitemap">
                      <h2 style={{ paddingBottom: 7 }}>Site Map</h2>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between flex-wrap align-items-center">
                    <div>
                      {/* <div className="col-md-5 col-sm-12"> */}

                      <div className="site-borderration"></div>
                      <div className="d-flex">
                        <div className="site-names">
                          <p>Site Name : </p>
                        </div>
                        <div className="site-namedy">
                          <p>&nbsp; {siteAllInfo?.[0]?.sitename}</p>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="site-names">
                          <p>Country : </p>
                        </div>
                        <div className="site-namedy">
                          <p> &nbsp;{siteAllInfo?.[0]?.country}</p>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="site-names">
                          <p>Coordinates : </p>
                        </div>
                        <div className="site-namedy">
                          <p>
                            &nbsp; {siteAllInfo?.[0]?.longitude} ,{" "}
                            {siteAllInfo?.[0]?.latitude}
                          </p>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="site-names">
                          <p>Area (in Ha) : </p>
                        </div>
                        <div className="site-namedy">
                          <p>&nbsp; {siteAllInfo?.[0]?.area} Ha</p>
                        </div>
                      </div>
                    </div>
                    <div style={{ width: "50%" }}>
                      {/* <div className="col-md-6"> */}

                      <div style={{ height: 290 }}>
                        {geoJSONObj && (
                          <MapContainer
                            key={Math.random()}
                            center={[
                              currentSite?.sitelatitude,
                              currentSite?.sitelogitude,
                            ]}
                            zoom={13}
                            trackResize={true}
                            scrollWheelZoom={false}
                            style={{
                              height: "100%",
                            }}
                          >
                            <>
                              <TileLayer
                                key={Math.random()}
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
                              />
                              <GeoJSON
                                key={Math.random()}
                                data={geoJSONObj}
                                color={bgColor ? bgColor : "green"}
                              >
                                {/* <Popup>
                                  <ul style={{ listStyle: "none" }}>
                                    <li>
                                      <b>Site info</b>
                                    </li>
                                    <li>
                                      <a>
                                        <b>Site ID :</b> {currentSite.siteid}
                                      </a>
                                    </li>
                                    <li>
                                      <b>Site name :</b>
                                      {currentSite.sitename}
                                    </li>
                                    <li>
                                      <b>Total Area :</b>
                                      {currentSite.totalarea}
                                      {"Ha "}
                                    </li>
                                    <li>
                                      <b>Elevation :</b>
                                      {currentSite.elevation}
                                    </li>
                                    <li>
                                      <b>Location:</b> {currentSite.location}
                                    </li>
                                    <li>
                                      <b>Country:</b> {currentSite.country}
                                    </li>
                                    <li>
                                      <b>Activation date :</b>{" "}
                                      {new Date(
                                        currentSite.activationdate
                                      ).toLocaleDateString()}
                                    </li>
                                    <li>
                                      <b>No. Of installations :</b>{" "}
                                      {currentSite.sensorcount}
                                    </li>
                                    <li>
                                      <b>Site Type:</b> {currentSite.sitetype}
                                    </li>
                                    <li>
                                      <b>Carbon Type:</b>{" "}
                                      {currentSite.carbontype}
                                    </li>
                                    <li>
                                      <b>Site owner :</b> {currentSite.owner}
                                    </li>
                                    <li>
                                      <b>Contact no :</b>{" "}
                                      {currentSite.ownercontact}
                                    </li>
                                  </ul>
                                </Popup> */}
                              </GeoJSON>
                              {/* {sensorTable && (
                                <Marker
                                  position={[
                                    Number(sensorTable[0]?.latitude),
                                    Number(sensorTable[0]?.longitude),
                                  ]}
                                  icon={
                                    sensorTable[0]?.isactive == 1 &&
                                    sensorTable[0]?.livestatus == 1
                                      ? sensorgreen
                                      : sensorTable[0]?.isactive == 1 &&
                                        sensorTable[0]?.livestatus == 0
                                      ? sensorred
                                      : sensorgrey
                                  }
                                >
                                  <Popup>
                                    <ul>
                                      <li>
                                        {" "}
                                        Sensor Id : {sensorTable[0]?.sensorid}
                                      </li>
                                      <li>
                                        {" "}
                                        Sensor Status:{" "}
                                        {sensorTable[0]?.livestatus == 1
                                          ? "Active"
                                          : "Inactive"}
                                      </li>
                                      <li>
                                        {" "}
                                        Sensor Type:{" "}
                                        {sensorTable[0]?.sensortype}
                                      </li>
                                      <li>
                                        {" "}
                                        Location: {sensorTable[0]?.location}
                                      </li>
                                      <li>
                                        {" "}
                                        Country: {sensorTable[0]?.country}
                                      </li>
                                      <li>
                                        {" "}
                                        Last status :{" "}
                                        {new Date(
                                          sensorTable[0]?.lastcommunidate
                                        ).toLocaleDateString()}
                                      </li>
                                    </ul>
                                  </Popup>
                                </Marker>
                              )} */}
                            </>
                          </MapContainer>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>

          <>
            {analysisArrReport?.map((val, index) => {
              return (
                <>
                  {Ndre?.[val]?.map((afterVal, afterValIndex) => {
                    return (
                      <>
                        <div className="main-sitediv">
                          <div className="tbl-res">
                            <h2>{analysisArrReport?.[index]} </h2>
                            <div className="site-border"></div>
                          </div>
                          {Object.keys(afterVal)?.map((zone, zoneIndex) => {
                            return (
                              <>
                                <div
                                  className={`zone-border${zoneIndex}`}
                                ></div>
                                <div className="zone-section">
                                  <div className="row">
                                    <div className="tbl-res">
                                      <h2 className="zonal-statistics">
                                        Zonal Statistics {zone}
                                      </h2>
                                    </div>
                                    <>
                                      <table width={"100%"}>
                                        <thead className="tbl-head">
                                          <tr>
                                            <th>Year</th>
                                            <th>Quarter</th>
                                            <th>Date</th>
                                            <th>Min</th>
                                            <th>Max</th>
                                            <th>Mean</th>
                                            <th>Stdev</th>
                                          </tr>
                                        </thead>
                                        {/* filterSiteInfo */}
                                        {Object.keys(afterVal?.[zone])?.map(
                                          (yearVal, yearIndex) => {
                                            return (
                                              <>
                                                {/* {analysisMeanValOfNdmi.push(
                                                afterVal?.[zone][yearVal][yearIndex]?.mean
                                                )} */}
                                                {/* {console.log(yearIndex , "mainvalmainval")} */}
                                                <div
                                                  style={{ marginTop: 10 }}
                                                ></div>
                                                <tr className="tbl-bodytr">
                                                  <td className="td-firstchield">
                                                    {yearVal}
                                                  </td>
                                                  {/* <span style={{display:'none'}}>
                                                    {afterVal?.[zone][yearVal][yearIndex].analysis === "NDMI" ? (
                                                      analysisDateValOfNdmi.push(
                                                        dayjs(
                                                          afterVal?.[zone][yearVal][yearIndex]?.date
                                                        ).format(
                                                          "DD-MM-YYYY"
                                                        )
                                                      )
                                                      
                                                    ):("")}

                                                          {afterVal?.[zone][yearVal][yearIndex].analysis ===
                                                                "NDMI"
                                                                  ? analysisMinValOfNdmi.push(
                                                                    afterVal?.[zone][yearVal][yearIndex]?.min
                                                                    )
                                                                  : ""}
                                                                  {afterVal?.[zone][yearVal][yearIndex].analysis ===
                                                                "NDMI"
                                                                  ? analysisMaxValOfNdmi.push(
                                                                    afterVal?.[zone][yearVal][yearIndex]?.max
                                                                    )
                                                                  : ""}
                                                                   {afterVal?.[zone][yearVal][yearIndex].analysis ===
                                                                "NDMI"
                                                                  ? analysisMeanValOfNdmi.push(
                                                                    afterVal?.[zone][yearVal][yearIndex]?.mean
                                                                    )
                                                                  : ""}
                                                  </span> */}
                                                  <td>
                                                    {afterVal?.[zone]?.[
                                                      yearVal
                                                    ]?.map(
                                                      (mainval, mainindex) => {
                                                        return (
                                                          <tr>
                                                            <td>
                                                              {mainval?.quarter}
                                                            </td>
                                                          </tr>
                                                        );
                                                      }
                                                    )}
                                                  </td>

                                                  <td>
                                                    {afterVal?.[zone]?.[
                                                      yearVal
                                                    ]?.map(
                                                      (mainval, mainindex) => {
                                                        return (
                                                          <tr>
                                                            <td>
                                                              <span
                                                                style={{
                                                                  display:
                                                                    "none",
                                                                }}
                                                              >
                                                                {mainval?.analysis ===
                                                                "NDRE"
                                                                  ? analysisDateValOfNdre.push(
                                                                      dayjs(
                                                                        mainval?.date
                                                                      ).format(
                                                                        "DD-MM-YYYY"
                                                                      )
                                                                    )
                                                                  : ""}
                                                                {mainval?.analysis ===
                                                                "NDMI"
                                                                  ? analysisDateValOfNdmi.push(
                                                                      dayjs(
                                                                        mainval?.date
                                                                      ).format(
                                                                        "DD-MM-YYYY"
                                                                      )
                                                                    )
                                                                  : ""}

                                                                {mainval?.analysis ===
                                                                "NDVI"
                                                                  ? analysisDateValOfNdvi.push(
                                                                      dayjs(
                                                                        mainval?.date
                                                                      ).format(
                                                                        "DD-MM-YYYY"
                                                                      )
                                                                    )
                                                                  : ""}
                                                              </span>
                                                              {dayjs(
                                                                mainval?.date
                                                              ).format(
                                                                "DD-MMM"
                                                              )}
                                                            </td>
                                                          </tr>
                                                        );
                                                      }
                                                    )}
                                                  </td>

                                                  <td>
                                                    {afterVal?.[zone]?.[
                                                      yearVal
                                                    ]?.map(
                                                      (mainval, mainindex) => {
                                                        return (
                                                          <tr>
                                                            <td>
                                                              <span
                                                                style={{
                                                                  display:
                                                                    "none",
                                                                }}
                                                              >
                                                                {mainval.analysis ===
                                                                "NDRE"
                                                                  ? analysisMinValOfNdre.push(
                                                                      mainval.min
                                                                    )
                                                                  : ""}
                                                                {mainval.analysis ===
                                                                "NDMI"
                                                                  ? analysisMinValOfNdmi.push(
                                                                      mainval.min
                                                                    )
                                                                  : ""}
                                                                {mainval.analysis ===
                                                                "NDVI"
                                                                  ? analysisMinValOfNdvi.push(
                                                                      mainval.min
                                                                    )
                                                                  : ""}
                                                              </span>
                                                              {mainval.min}
                                                            </td>
                                                          </tr>
                                                        );
                                                      }
                                                    )}
                                                  </td>

                                                  <td>
                                                    {afterVal?.[zone]?.[
                                                      yearVal
                                                    ]?.map(
                                                      (mainval, mainindex) => {
                                                        return (
                                                          <tr>
                                                            <td>
                                                              <span
                                                                style={{
                                                                  display:
                                                                    "none",
                                                                }}
                                                              >
                                                                {mainval?.analysis ===
                                                                "NDRE"
                                                                  ? analysisMaxValOfNdre.push(
                                                                      mainval.max
                                                                    )
                                                                  : ""}

                                                                {mainval.analysis ===
                                                                "NDMI"
                                                                  ? analysisMaxValOfNdmi.push(
                                                                      mainval.max
                                                                    )
                                                                  : ""}

                                                                {mainval.analysis ===
                                                                "NDVI"
                                                                  ? analysisMaxValOfNdvi.push(
                                                                      mainval.max
                                                                    )
                                                                  : ""}
                                                              </span>
                                                              {mainval.max}
                                                            </td>
                                                          </tr>
                                                        );
                                                      }
                                                    )}
                                                  </td>

                                                  <td>
                                                    {afterVal?.[zone]?.[yearVal]
                                                      ?.slice(0, 5)
                                                      .map(
                                                        (
                                                          mainval,
                                                          mainindex
                                                        ) => {
                                                          return (
                                                            <tr>
                                                              <td>
                                                                <span
                                                                  style={{
                                                                    display:
                                                                      "none",
                                                                  }}
                                                                >
                                                                  {checkAnalysisType.push(
                                                                    mainval.analysis
                                                                  )}
                                                                  {mainval.analysis ===
                                                                  "NDRE"
                                                                    ? analysisMeanValOfNdre.push(
                                                                        mainval?.mean
                                                                      )
                                                                    : ""}

                                                                  {mainval.analysis ===
                                                                  "NDVI"
                                                                    ? analysisMeanValOfNdvi.push(
                                                                        mainval?.mean
                                                                      )
                                                                    : ""}

                                                                  {mainval.analysis ===
                                                                  "NDMI"
                                                                    ? analysisMeanValOfNdmi.push(
                                                                        mainval?.mean
                                                                      )
                                                                    : ""}
                                                                </span>
                                                                {mainval?.mean}
                                                              </td>
                                                            </tr>
                                                          );
                                                        }
                                                      )}
                                                  </td>

                                                  <td>
                                                    {afterVal?.[zone]?.[
                                                      yearVal
                                                    ]?.map(
                                                      (mainval, mainindex) => {
                                                        return (
                                                          <tr>
                                                            <td>
                                                              {" "}
                                                              {mainval?.std}
                                                            </td>
                                                          </tr>
                                                        );
                                                      }
                                                    )}
                                                  </td>
                                                </tr>
                                              </>
                                            );
                                          }
                                        )}
                                        <div style={{ marginTop: 10 }}></div>
                                      </table>
                                      {val === "NDRE" ? (
                                        <div className="analysis-graph">
                                          <div className="linechart-heading">
                                            <h2 className="zonal-statistics">
                                              Zonal Variability {zone}
                                            </h2>
                                          </div>
                                          <Box
                                            sx={{
                                              height: 300,
                                              width: "100%",
                                            }}
                                          >
                                            <Line
                                              options={windOptions}
                                              data={analysisReportLineChart}
                                            />
                                          </Box>
                                        </div>
                                      ) : (
                                        ""
                                      )}

                                      {val === "NDMI" ? (
                                        <div className="analysis-graph">
                                          <div className="linechart-heading">
                                            <h2 className="zonal-statistics">
                                              Zonal Variability {zone}
                                            </h2>
                                          </div>
                                          <Box
                                            sx={{
                                              height: 300,
                                              width: "100%",
                                            }}
                                          >
                                            <Line
                                              options={windOptions}
                                              data={analysisReportLineChartNdmi}
                                            />
                                          </Box>
                                        </div>
                                      ) : (
                                        ""
                                      )}

                                      {val === "NDVI" ? (
                                        <div className="analysis-graph">
                                          <div className="linechart-heading">
                                            <h2 className="zonal-statistics">
                                              Zonal Variability {zone}
                                            </h2>
                                          </div>
                                          <Box
                                            sx={{
                                              height: 300,
                                              width: "100%",
                                            }}
                                          >
                                            <Line
                                              options={windOptions}
                                              data={analysisReportLineChartNdvi}
                                            />
                                          </Box>
                                        </div>
                                      ) : (
                                        ""
                                      )}

                                      {Object.keys(afterVal?.[zone])?.map(
                                        (yearVal, yearIndex) => {
                                          return (
                                            <>
                                              {afterVal?.[zone]?.[yearVal]?.map(
                                                (mainval, mainindex) => {
                                                  return (
                                                    <>
                                                      {mainval.analysis ===
                                                        "Slope" ||
                                                      mainval.analysis ===
                                                        "Elevation" ||
                                                      mainval.analysis ===
                                                        "Canopy Height" ? (
                                                        <div className="report-images">
                                                          <img
                                                            src={
                                                              mainval.imageurl
                                                            }
                                                            width="100%"
                                                            crossorigin="anonymous"
                                                          />
                                                        </div>
                                                      ) : (
                                                        <></>
                                                      )}
                                                    </>
                                                  );
                                                }
                                              )}
                                            </>
                                          );
                                        }
                                      )}
                                    </>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </>
                    );
                  })}
                </>
              );
            })}
          </>
          <div className="main-sitediv">
            <div className="container">
              <div className="glossary-heading">
                <h2>Glossary</h2>
              </div>
              <div className="border-bottom"></div>
              <div className="glossary-main">
                <div className="glossary-border"></div>
                <h4>Normalized difference red edge index (NDRE) </h4>
                <p>
                  NDRE Statistics Normalized difference red edge index (NDRE) is
                  a method of measuring the amount of chlorophyll in the plants.
                  The best time to apply the normalized difference red edge
                  index is when the crop is mature and ripening. NDRE
                  variability is computed for 3-year 2020 to 2022.
                </p>
              </div>

              <div className="glossary-main">
                <div className="glossary-border"></div>
                <h4>Normalized difference vegetation index(NDVI) </h4>
                <p>
                  NDVI (Normalized Difference Vegetation Index) defines values
                  from -1.0 to 1.0, basically representing greens, where
                  negative values are mainly formed from clouds, water and snow,
                  and values close to zero are primarily formed from rocks and
                  bare soil. NDVI variability is computed for 3-year 2020 to
                  2022.
                </p>
              </div>

              <div className="glossary-main">
                <div className="glossary-border"></div>
                <h4>Normalized difference moistureindex(NDMI) </h4>
                <p>
                  The Normalized Difference Moisture Index (NDMI) detects
                  moisture levels in vegetation using a combination of
                  near-infrared (NIR) and short-wave infrared (SWIR) spectral
                  bands. It is a reliable indicator of water stress in crops.
                  NDMI variability is computed for 3-year 2020 to 2022.
                </p>
              </div>

              <div className="glossary-main">
                <div className="glossary-border"></div>
                <h4>DEM (Digital Elevation Model) Data </h4>
                <p>
                  Digital Elevation Model is the digital representation of the
                  topographic surface elevation with respect to any reference
                  datum.We have downloaded the SRTM data which has 30 m spatial
                  resolution.Slope is the steepness or the degree of incline of
                  a surface.The hill shade function produces a 3D representation
                  of the terrain surface, with the sun's relative position
                  considered for shading the image.
                </p>
              </div>

              <div className="glossary-main">
                <div className="glossary-border"></div>
                <h4>Canopy Height </h4>
                <p>
                  Canopy Height Model (CHM) is the residual distance between the
                  ground and the top of the objects above the ground. This
                  includes the actual heights of trees, buildings, and any other
                  objects on the earth’s surface. This CHM is created by
                  subtracting the DTM (Digital Terrain Model) from the DSM
                  (Digital Surface Model).
                </p>
              </div>
            </div>
          </div>
        </PDFExport>
      )}
    </>
  );
}

// export default SiteAnalysis;
const mapDispatchToProps = {
  getAllSites: getAllSites,
  getCurrentSite: getSite,
  getSiteCarbonByYear: getSiteCarbonByYear,
  getSiteCarbonBySource: getSiteCarbonBySource,
};
export default connect(null, mapDispatchToProps)(React.memo(SiteAnalysis));
