import React, { useState } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { useNavigate,  useLocation } from "react-router-dom";
import { TextInput } from "../../components/TextField";
import { Button } from "@mui/material";
import axiosClient from "../../axios";
import axios from "../../axios";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { TextArea } from "../../components/TextArea";
const AddTrack = () => {
  let nav = useNavigate();
  let location = useLocation();
  const user = useSelector((state) => state.loginData.user);
  const [sensorData, setsensorData] = useState({});
  const [usersList, setUsers] = useState([]);
  useEffect(() => {
    getAllUsers(user?.personidref?.orgidref?.orgid, user?.personidref?.orgidref?.orgcode);
    axios.get(`v1/gait/sensor/view_sensor_info_byid?sensorId=${location.state.sensorID}`).then((res) => {
        setsensorData(res.data.gaitObj);
    })
  }, []);
  const addSensorTracking = (val) => {
}
const getAllUsers = (orgid, orgcode) => {
  axiosClient.get(`gait/um/viewallusers/${orgid}/${orgcode}`).then((res) => {
    setUsers(res.data.gaitObj);
  })
}
  const validate = Yup.object({
    country: Yup.string().required("Country field is require"),
    state: Yup.string().required("State field is require"),
    city: Yup.string().required("City field is required"),
    location: Yup.string().required("Location is required"),
    longitude: Yup.string().required("Longitude is require"),
    latitude: Yup.string().required("Latitude in required"),
    notes: Yup.string().required("Notes is required"),
    sensorstate: Yup.string().required("Sensor stage is required."),
  });
  return (
    <Formik
      initialValues={{
        country:'',
        state:'',
        city:'',
        location:'',
        longitude:'',
        latitude:'',
        notes:'',
        stageowner:'',
        sensorstate:'',
      }}
      validationSchema={validate}
      onSubmit={(values) => {
        addSensorTracking(values);
      }}
    >
      {(formik) => (
        <Form>
          <Button
            onClick={(e) => {
              nav("/sensors");
            }}
            style={{
              marginLeft: "auto",
              display: "block",
              width: 140,
              background: "linear-gradient(0deg, #55AA8B, #55AA8B), #FEFEFE",
              borderRadius: 4,
              color: "white",
            }}
          >
            View All
          </Button>
          <div className="row form-top sites-content">
          <div className="col-md-3 col-sm-12">
              <TextInput
                label="Country"
                name="country"
                type="text"
                placeholder="country"
              />
            </div>
            <div className="col-md-3 col-sm-12">
              <TextInput
                label="State"
                name="state"
                type="text"
                placeholder="state"
              />
            </div>
            <div className="col-md-3 col-sm-12">
              <TextInput
                label="City"
                name="city"
                type="text"
                placeholder="city"
              />
            </div>
              <div className="col-md-3 col-sm-12">
              <TextInput
                label="Location"
                name="location"
                type="text"
                placeholder="location"
              />
            </div>

            <div className="row addsite-body">
              
            <div className="col-md-3 col-sm-12">
              <TextInput
                label="Longitude"
                name="longitude"
                type="text"
                placeholder="Longitude"
              />
            </div>

              <div className="col-md-3 col-sm-12">
                <TextInput
                  label="Latitude"
                  name="latitude"
                  type="text"
                  placeholder="Latitude"
                />
              </div>
               <div className="col-md-3 col-sm-12">
                <div className="d-flex justify-content-between align-item-center">
                  <div>
                  <label className="form-label">Stage Owner</label>
                  </div>
                  <div>
                  </div>
                </div>
                <Field as="select" name="stageowner" className="form-select">
                  <option selected value={user?.orguserid} >{user.personIdref.firstname} {user.personIdref.lastname}</option>
                  {usersList?.map((val, index) => {
                  return (<option value={val.orguserid} key={index}>{val.personIdref.firstname} {val.personIdref.lastname}</option>)
                })}
                </Field>
                
              </div>
                <div className="col-md-3 col-sm-12">
                <TextInput
                  label="Stage"
                  name="sensorstate"
                  type="text"
                  placeholder="Stage"
                />
              </div>
            </div>
            <div className="row addsite-body">
              <div className="col-md-6 col-sm-12">
                <TextArea  label="Note"  name="notes" placeholder="type here!!" rows="4" />
              </div>
            
            </div>
            <div className="d-flex justify-content-end bottom-button add-sitebtn">
              <Button
                style={{
                  marginLeft: 15,
                  display: "block",
                  width: 140,
                  borderRadius: 4,
                  borderColor: "#FF6E6E",
                  borderWidth: 1,
                  color: "#FF6E6E",
                  border: "1px solid",
                  fontFamily: "Poppins",
                  fontSize: 13,
                }}
                onClick={(e) => {
                  nav("/sensors");
                }}
              >
                Cancel
              </Button>

              <Button
                style={{
                  marginLeft: 15,
                  display: "block",
                  width: 140,
                  background:
                    "linear-gradient(0deg, #55AA8B, #55AA8B), #FEFEFE",
                  borderRadius: 4,
                  color: "white",
                  fontFamily: "Poppins",
                  fontSize: 13,
                }}
                type="submit"
              >
                Create
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddTrack;
