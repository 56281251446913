import React, { useEffect } from "react";
import { Chart as ChartJS } from "chart.js";
import { Line } from "react-chartjs-2";
import { useLocation } from "react-router-dom";
import ICONS from '../../features/images';
import axiosClient from "../../axios";
import { useState } from "react";
import Plot from "react-plotly.js";
import { Radar } from "react-chartjs-2";
import Moment from "react-moment";
import {
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  ArcElement,
} from "chart.js";
import dayjs from "dayjs";

ChartJS.register(
  ArcElement,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}
const data = [
  { year: 1980, efficiency: 24.3, sales: 8949000 },
  { year: 1985, efficiency: 27.6, sales: 10979000 },
  { year: 1990, efficiency: 28, sales: 9303000 },
  { year: 1991, efficiency: 28.4, sales: 8185000 },
  { year: 1992, efficiency: 27.9, sales: 8213000 },
  { year: 1993, efficiency: 28.4, sales: 8518000 },
  { year: 1994, efficiency: 28.3, sales: 8991000 },
  { year: 1995, efficiency: 28.6, sales: 8620000 },
  { year: 1996, efficiency: 28.5, sales: 8479000 },
  { year: 1997, efficiency: 28.7, sales: 8217000 },
  { year: 1998, efficiency: 28.8, sales: 8085000 },
  { year: 1999, efficiency: 28.3, sales: 8638000 },
  { year: 2000, efficiency: 28.5, sales: 8778000 },
  { year: 2001, efficiency: 28.8, sales: 8352000 },
  { year: 2002, efficiency: 29, sales: 8042000 },
  { year: 2003, efficiency: 29.5, sales: 7556000 },
  { year: 2004, efficiency: 29.5, sales: 7483000 },
  { year: 2005, efficiency: 30.3, sales: 7660000 },
  { year: 2006, efficiency: 30.1, sales: 7762000 },
  { year: 2007, efficiency: 31.2, sales: 7562000 },
  { year: 2008, efficiency: 31.5, sales: 6769000 },
  { year: 2009, efficiency: 32.9, sales: 5402000 },
  { year: 2010, efficiency: 33.9, sales: 5636000 },
  { year: 2011, efficiency: 33.1, sales: 6093000 },
  { year: 2012, efficiency: 35.3, sales: 7245000 },
  { year: 2013, efficiency: 36.4, sales: 7586000 },
  { year: 2014, efficiency: 36.5, sales: 7708000 },
  { year: 2015, efficiency: 37.2, sales: 7517000 },
  { year: 2016, efficiency: 37.7, sales: 6873000 },
  { year: 2017, efficiency: 39.4, sales: 6081000 },
];
const ress = [{ name: "1" }, { name: "2" }, { name: "3" }];
const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];
let d = new Date();
const labels = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];
const CarbonCountFilter = () => {
  const [loading, setLoading] = useState(true);
  const [totalEmission, setTotalEmission] = useState("");
  const [dataCountVal, setdataCountRes] = useState("");
  const [plotgraphData, setplotgraphData] = useState("");
  const [dailyNetCarbonavg, setDailyNetCarbonAvg] = useState([]);
  const [dailyNetCarbonVal, setDailyNetCarbonVal] = useState([]);
  const [siteName, setSiteName] = useState("");
  const [siteLet, setSiteLat] = useState("");
  const [siteLang, setSiteLang] = useState("");
  const [siteSensorCount, setSiteSensorCount] = useState("");
  const [siteAreaCovered, setSiteAreaCovered] = useState("");
  const [siteCountry, setSiteCountry] = useState("");
  const [installedDate, setInstalledDate] = useState("");
  const [getSiteRecord, setGetSiteRecord] = useState("");
  const [totalCatbon, setTotalCarbon] = useState("");
  const [graphDataReq,setGraphDataReq] = useState("");
  const [plotgraphData1, setplotgraphData1] = useState("");
  const location = useLocation();
  useEffect(() => {
    getDailyNetCarbonval();
    getDailyNetCarbonvalAvg();
    getSensorTable();
    getInstalledSensor();
  }, []);

  const getDailyNetCarbonval = () => {
    setLoading(true);
    axiosClient
      .get(
        `v1/gait/site/view_carbon_by_deviceid_dates?deviceId=${location.state.sensorId}&fromdate=${location.state.frmdate}&todate=${location.state.todate}`
      )
      .then((res) => {
       
        let graphData = res?.data?.gaitObj;
        setGraphDataReq(graphData);
        let absorptionsData = graphData?.map((val, index) => {
          return graphData[index].absorption.toFixed(2);
        });
        // let filteredDate = [];
        let countCarbonDatex = graphData?.map((val, index) => {
          return graphData[index].timestamp.substring(0, 10);
        });
        
        let emissionsArr = [];
        let absorptionsArr = [];
        let timeFrameArr = [];
        let carbonDateArr = [];

        let uniqueDatesArr = [...new Set(countCarbonDatex)];
        for (var j = 0; j < uniqueDatesArr?.length; j++) {
          let dateArr = [
            uniqueDatesArr[j],
            uniqueDatesArr[j],
            uniqueDatesArr[j],
            uniqueDatesArr[j],
            uniqueDatesArr[j],
            uniqueDatesArr[j],
            uniqueDatesArr[j],
            uniqueDatesArr[j],
          ];
          let dateEmission = [];
          let dateAbsorption = [];
          let dateTimeframe = [];
          for (var i = 0; i < graphData?.length; i++) {
            if (
              uniqueDatesArr[j] === graphData?.[i]?.timestamp.substring(0, 10)
            ) {
              // carr.push(i);
              dateEmission.push(graphData[i].emission);
              dateAbsorption.push(graphData[i].absorption);
              dateTimeframe.push(graphData[i].timeinterval);
            }
          }
          emissionsArr.push(dateEmission);
          absorptionsArr.push(dateAbsorption);
          timeFrameArr.push(dateTimeframe);
          if (dateTimeframe?.length != 0) {
            carbonDateArr.push(dateArr);
          }
        }
        let emissionsData = graphData?.map((val, index) => {
          return graphData[index].emission.toFixed(2);
        });
        let countDate = graphData?.map((val, index) => {
          return `${dayjs(graphData[index].timestamp).format("DD/MM/YYYY")} ${
            graphData[index].timeinterval
          }`;
        });
        let countCarbonAmPmy = graphData?.map((val, index) => {
          return `${graphData[index].timeinterval}`;
        });
        setTotalEmission(totalEmission);
        var dataCountRes = {
          labels: countDate,
          datasets: [
            {
              fill: true,
              label: "Absorption",
              data: absorptionsData,
              borderColor: "#3E9877",
              backgroundColor: "#468d73ad",
              
            },
            {
              fill: true,
              label: "Emission",
              data: emissionsData,
              borderColor: "#EE4343",
              backgroundColor: "#ee4343a8",
            },
          ],
        };
        // plot map
        const plotgraphData = {
          z: emissionsArr,
          x: countCarbonAmPmy,
          y: carbonDateArr,
          showscale: false,
          masterGraph: {
            title: "",
            xAxis: "Time Frame",
            yAxis:'Date',
            zAxis:'Kg',
          }
        };
        const plotgraphData1 = {
          z: absorptionsArr,
          x: countCarbonAmPmy,
          y: carbonDateArr,
          showscale: false,
        };
        
        setLoading(false);
        setplotgraphData(plotgraphData);
        setplotgraphData1(plotgraphData1);
        setdataCountRes(dataCountRes);
      });
  };
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Carbon Filter",
      },
      scales: {
        xAxes: [
          {
            gridLines: {
              display: false
            },
            scaleLabel: {
              display: true,
              labelString: "Date"
            },
            stacked: false,
            ticks: {
              autoSkip: true,
              beginAtZero: true
            },
            time: {
              tooltipFormat: "[Q]Q - YYYY",
              unit: "quarter"
            },
            type: "time"
          }
        ],
        yAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: "Purchase amount (USD)"
            },
            id: "left",
            stacked: false,
            ticks: {
              beginAtZero: true
            }
          },
          {
            scaleLabel: {
              display: true,
              labelString: "Purchase count"
            },
            id: "right",
            position: "right",
            stacked: false,
            ticks: {
              beginAtZero: true
            }
          }
        ]
      },
    },
  };
  const getDailyNetCarbonvalAvg = () => {
    axiosClient
      .get(
        `v1/gait/site/view_avg_carbon_by_deviceid?deviceId=${location.state.sensorId}`
      )
      .then((res) => {
        setDailyNetCarbonVal(res?.data?.gaitObj);
        const graphData = res?.data?.gaitObj;
        let timeIntervalArr2 = [];
        graphData?.map((val, index) => {
          if (val.timeinterval === "0-3" || val.timeinterval === "00-03") {
            timeIntervalArr2[0] = val;
          }
          if (val.timeinterval === "3-6" || val.timeinterval === "03-06") {
            timeIntervalArr2[1] = val;
          }
          if (val.timeinterval === "6-9" || val.timeinterval === "06-09") {
            timeIntervalArr2[2] = val;
          }
          if (val.timeinterval === "9-12" || val.timeinterval === "09-12") {
            timeIntervalArr2[3] = val;
          }
          if (val.timeinterval === "12-15") {
            timeIntervalArr2[4] = val;
          }
          if (val.timeinterval === "15-18") {
            timeIntervalArr2[5] = val;
          }
          if (val.timeinterval === "18-21") {
            timeIntervalArr2[6] = val;
          }
          if (val.timeinterval === "21-24") {
            timeIntervalArr2[7] = val;
          }
        });

        let emissionCarbon = timeIntervalArr2?.map((val, index) => {
          return (timeIntervalArr2[index]?.emission).toFixed(2);
        });

        let absorptionCarbon = timeIntervalArr2?.map((val, index) => {
          return (timeIntervalArr2[index]?.absorption).toFixed(2);
        });

        let carbonCount = 0;
        graphData?.map((val, index) => {
          carbonCount += val.netcarbon;
        });
        setTotalCarbon(carbonCount);
        let DateTime = timeIntervalArr2?.map((val, index) => {
          return timeIntervalArr2[index].timeinterval;
        });
        const dataRadar = {
          labels: DateTime,
          datasets: [
            {
              label: "Absorption",
              data: absorptionCarbon,
              fill: false,
              backgroundColor: "#55aa8b",
              borderColor: "#55aa8b",
              pointBackgroundColor: "#55aa8b",
              pointBorderColor: "#fff",
              pointHoverBackgroundColor: "#fff",
              pointHoverBorderColor: "#55aa8b",
            },
            {
              label: "Emmission",
              data: emissionCarbon,
              fill: false,
              backgroundColor: "#EE4343",
              borderColor: "#EE4343",
              pointBackgroundColor: "#EE4343",
              pointBorderColor: "#fff",
              pointHoverBackgroundColor: "#fff",
              pointHoverBorderColor: "#EE4343",
            },
          ],
        };
        setDailyNetCarbonAvg(dataRadar);
      });
  };
  const getSensorTable = () => {
    axiosClient
      .get(
        `v1/gait/site/view_site_sensors_list?siteId=${location.state.siteId}`
      )
      .then((res) => {
        let siteInfo = res.data.gaitObj;
        if (res) {
          setSiteName(siteInfo?.[0]?.sitename);
          setSiteLat(siteInfo?.[0]?.latitude);
          setSiteLang(siteInfo?.[0]?.longitude);
          setSiteCountry(siteInfo?.[0]?.country);
          setSiteAreaCovered(siteInfo?.[0]?.area);
          setInstalledDate(siteInfo?.[0]?.installeddate);
        }
      });
  };
  const getInstalledSensor = () => {
    axiosClient
      .get(
        `v1/gait/site/view_site_info?siteId=${location.state.siteId}`
      )
      .then((res) => {
        let siteInfo = res.data.gaitObj;
        if (res) {
          setSiteSensorCount(res?.data?.gaitObj?.sensorcount);
        }
      });
  };

  const avgConfig = {
    type: "radar",
    data: data,

    options: {
      elements: {
        line: {
          borderWidth: 1,
        },
      },
    },
  };
  return (
    <>
    
      {loading ? (
        <div>
          <img
            src={ICONS.SignalGreen}
            style={{
              width: "5%",
              position: "absolute",
              top: "50%",
              left: "50%",
            }}
          />
        </div>
      ) : (
        <>
          <div className="d-flex justify-content-between">
            <div className="main-logo">
              <div className="mylogo">
                <img src={ICONS.MyLogo} />
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-between flex-wrap top-barinfo">
            <div>
              <div className="d-flex justify-content-between align-items-center">
                <img src={ICONS.Location_G1} />
                <div className="top-headerbar">
                  <p>Site </p>
                  <span>
                    {siteName}, {siteCountry}
                  </span>
                </div>
              </div>
            </div>
            <div>
              <div className="d-flex justify-content-between align-items-center">
                <img src={ICONS.Lan_lat_G3} />
                <div className="top-headerbar">
                  <p>Lat - Long </p>
                  <span>
                    {siteLet} , {siteLang}
                  </span>
                </div>
              </div>
            </div>
            <div>
              <div className="d-flex justify-content-between align-items-center">
                <img src={ICONS.Sensor_G4} />
                <div className="top-headerbar">
                  <p>Sensor</p>
                  <span>{siteSensorCount}</span>
                </div>
              </div>
            </div>
            <div>
              <div className="d-flex justify-content-between align-items-center">
                <img src={ICONS.calender_G2} />
                <div className="top-headerbar">
                  <p>Installed Date </p>
                  <span>
                    <Moment format="YYYY/MM/DD">{installedDate}</Moment>
                  </span>
                </div>
              </div>
            </div>
            <div>
              <div className="d-flex justify-content-between align-items-center">
                <img src={ICONS.Area_G5} />
                <div className="top-headerbar">
                  <p>Area Covered </p>
                  <span>{siteAreaCovered} Ha</span>
                </div>
              </div>
            </div>
          </div>
          <div>
            
            {graphDataReq?.length > 0 ? (
              <>
              <div className="top-heading">
              <p>Daily Flux Pattern</p>
            </div>
              <div className="line-chart">
            <p>Kgs</p>
            <Line options={options} data={dataCountVal} />
            </div>
            </>
            ):null}
            
          </div>
          <div className="top-heading">
            <p>3 Hour Average Flux - Diurnal Pattern</p>
          </div>
          <div style={{ maxWidth: 700, width: "100%", margin: "auto" }}>
            <Radar data={dailyNetCarbonavg} options={avgConfig} />
          </div>
          <div className="container">
          
            {graphDataReq?.length > 0 ? (
              <>
                <div className="top-heading">
                <p>3D Surface Area - Diurnal Pattern</p>
              </div>
              <Plot
              data={[
                
                {
                  type: "surface",
                  z: plotgraphData1.z,
                  // x: plotgraphData1.x,
                  x: ['21-24', '18-21','15-18','12-15','9-12','6-9','3-6','0-3'],
                  y: plotgraphData1.y,
                },
                {
                  type: "surface",
                  z: plotgraphData.z,
                  x: ['21-24', '18-21','15-18','12-15','9-12','6-9','3-6','0-3'],
                  // x:plotgraphData.x,
                  y: plotgraphData.y,
                  color:'red',
                  backgroundColor:'red',

                },
              ]}
              layout={{
                width: 1100,
                height: 600,
                
                title: plotgraphData.masterGraph.title,
                annotations: [
                  {
                    text: '',

                    font: {
                      size: 30,
                      color: "#444444",
                      weight:900,
                      family:'poppins',
                    },
                    showarrow: false,
                    align: "center",
                    y: 1.1,
                    xref: "paper",
                    yref: "paper",
                    display:'none',
                  },
                ],
                scene: {
                  xaxis: {
                    title: plotgraphData.masterGraph.xAxis,
                    titlefont: {
                      family: "Poppins",
                      size: 12,
                      color: "#000",
                      weight:'700'
                    },
                    family: "Poppins",
                    size: 12,
                    color: "#000",
                    weight:'700'
                  },
                  yaxis: {
                    title: plotgraphData.masterGraph.yAxis,
                    titlefont: {
                      family: "Poppins",
                      size: 12,
                      color: "#000",
                      weight:'700'
                    },
                    family: "Poppins",
                    size: 12,
                    color: "#000",
                    weight:'700'
                  },
                  zaxis: {
                    title: plotgraphData.masterGraph.zAxis,
                    titlefont: {
                      family: "Poppins",
                      size: 12,
                      color: "#000",
                      weight:'700'
                    },
                    family: "Poppins",
                      size: 12,
                      color: "#000",
                      weight:'700'
                  },
                },
              }}
            />
            </>
            ):null}
            
          </div>
        </>
      )}
    </>
  );
};

export default CarbonCountFilter;
