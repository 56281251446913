import React, { useEffect, useState } from "react";
import "./App.css";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Button } from "@mui/material";
import Header from "./pages/Header";
import Homepage from "./pages/HomePage";
import ICONS from './features/images';
import SensorPage from "./pages/SensorPage";
import DashboardIcon from "@mui/icons-material/Dashboard";
import CellTowerOutlinedIcon from "@mui/icons-material/CellTowerOutlined";
import MemoryIcon from '@mui/icons-material/Memory';
import {
  BrowserRouter,
  Route,
  Routes,
  Outlet,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { useSelector, connect } from "react-redux";
import LoginPage from "./pages/LoginPage";
import SensorStatus from "./pages/SensorStatus";
import { saveLoginData } from "./features/counter/APIs";
import ResetPassword from "./pages/ResetPassword";
import UserList from "./pages/UserList";
import SensorsList from "./pages/SensorsList";
import SensorTrack from "./pages/SensorTrack";
import SitesList from "./pages/SitesList";
import UserProfileView from "./pages/Users/UserProfileView";
import CreateUser from "./pages/Users/CreateUser";
import UpdateUser from "./pages/Users/UpdateUser";
import AddSites from "./pages/Sites/AddSites";
import EditSite from "./pages/Sites/EditSite";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import AddSensor from "./pages/Sensor/AddSensor";
import EditSensor from "./pages/Sensor/EditSensor";
import AddTrack from "./pages/Sensor/AddTrack";
import SiteInstall from "./pages/Sites/SiteInstall";
import GetSiteReport from "./pages/Report/GetSiteReport";
import Report from "./pages/Report/Report";
import SensorReport from "./pages/Report/SensorReport";
import GetReport from "./pages/Report/GetReport";
import DailySensorReport from "./pages/Report/DailySensorReport";
import CarbonFluxReport from "./pages/Report/CarbonFluxReport";
import SequestrationdailyAll from "./pages/Report/SequestrationdailyAll";
import SequestrationMonthlyAll from "./pages/Report/SequestrationMonthlyAll";
import SequestrationdailyCustom from "./pages/Report/SequestrationdailyCustom";
import SequestrationMonthlyCustom from "./pages/Report/SequestrationMonthlyCustom";
import TestMapImpliment from "./pages/Report/TestMapImpliment";
import CarbonCountFilter from "./pages/Report/CarbonCountFilter";
import OtpVerification from "./pages/OtpVerification";
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import SiteAnalysis from "./pages/Report/SiteAnalysis";
import MissingData from "./pages/MissingData";
import AddProjects from "./pages/Projects/AddProjects";
import ProjectsLists from "./pages/Projects/ProjectsLists";
import ProjectView from "./pages/Projects/ProjectView";
import EditProject from "./pages/Projects/EditProject";
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ProjectCardView from "./pages/Projects/ProjectCardView";
import ProjectAnalysis from "./pages/Projects/ProjectAnalysis";
const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

interface Props {
  window?: () => Window;
}
const AppLayout = (props:Props) => {
  const { window } = props;
  const token = useSelector((state) => state.loginData?.user);
  const [mobileOpen, setMobileOpen] = useState(false);
  let navigate = useNavigate();
  let location = useLocation();
  const [open, setOpen] = React.useState(false);
  useEffect(() => {
    if (!token) {
      navigate("/");
    }
  }, [token]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const Drawertop = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
  }));
  
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const containerdrawer = window !== undefined ? () => window().document.body : undefined;
  return (
    <>
      <Box sx={{ display: "flex", width: "calc(100% - 50px)" }}>
        <CssBaseline />
        
        <Drawertop variant="permanent" open={open} className="drawer-sidemenu">
          <Button
            size="small"
            color="secondary"
            variant="contained"
            aria-label="open drawer"
            className="toggle-menu"
            onClick={handleDrawerOpen}
            sx={{
              position: "absolute",
              right: "-26px",
              minWidth: 25,
              ...(open && { display: "none" }),
            }}
          >
            <ChevronRightIcon htmlColor="#000" fontSize="small" />
          </Button>
          <Button
            className="toggle-menu"
            size="small"
            color="secondary"
            variant="contained"
            aria-label="open drawer"
            onClick={handleDrawerClose}
            sx={{
              minWidth: 25,
              position: "absolute",
              right: "-26px",
              ...(!open && { display: "none" }),
            }}
          >
            <ChevronLeftIcon htmlColor="#000" fontSize="small" />
          </Button>
          <a
            onClick={(e) => navigate("/main")}
            style={{ marginLeft: "auto", marginRight: "auto", marginTop: 20 }}
          >
            <img width={40} src={ICONS.logo} />
          </a>

          <List style={{ marginTop: 30 }}>
            <ListItem
              key={"1"}
              disablePadding
              sx={{ display: "block" }}
              onClick={(e) => navigate("/main")}
              style={
                location.pathname === "/main"
                  ? { backgroundColor: "#55aa8b38" }
                  : null
              }
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {location.pathname === "/main" ? (
                    <DashboardIcon style={{ color: "#55aa8b" }} />
                  ) : (
                    <DashboardIcon style={{ color: "#55aa8b" }}/>
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={"Dashboards"}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
            <ListItem
              key={"22"}
              onClick={(e) => navigate("/sites")}
              disablePadding
              sx={{ display: "block" }}
              style={
                location.pathname === "/sites" ||
                location.pathname === "/add-sites" ||
                location.pathname === "/edit-site"
                  ? { backgroundColor: "#55aa8b38" }
                  : null
              }
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {location.pathname === "/sites" ||
                  location.pathname === "/add-sites" ||
                  location.pathname === "/edit-site" ? (
                    <MapOutlinedIcon style={{ color: "#55aa8b" }} />
                  ) : (
                    <MapOutlinedIcon />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={"Sites"}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
            <ListItem
              key={"2"}
              onClick={(e) => navigate("/sensors")}
              disablePadding
              sx={{ display: "block" }}
              style={
                location.pathname === "/sensors" ||
                location.pathname === "/sensor-status" ||
                location.pathname === "/add-sensor" ||
                location.pathname === "/edit-sensor" ||
                location.pathname === "/track" ||
                location.pathname === "/add-track"
                  ? { backgroundColor: "#55aa8b38" }
                  : null
              }
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {location.pathname === "/sensors" ||
                  location.pathname === "/sensor-status" ||
                  location.pathname === "/add-sensor" ||
                  location.pathname === "/edit-sensor" ||
                  location.pathname === "/track" ||
                  location.pathname === "/add-track" ? (
                    <CellTowerOutlinedIcon style={{ color: "#55aa8b" }} />
                  ) : (
                    <CellTowerOutlinedIcon />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={"Sensors"}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
            <ListItem key={"3"} disablePadding sx={{ display: "block" }} onClick={(e) => navigate("/reports")}
             style={
              location.pathname === "/report" ||
              location.pathname === "/reports" ||
              location.pathname === "/get-report" ||
              location.pathname === "/sequestrationdaily-all" ||
              location.pathname === "/sensor-report" || 
              location.pathname === "/sequestrationdaily-custom" ||
              location.pathname === "/sequestrationmonthly-all" ||
              location.pathname === "/sequestrationmonthly-custom" ||
              location.pathname === "/carbon-count"
                ? { backgroundColor: "#55aa8b38" }
                : null
            }
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {location.pathname === "/reports" ? (
                    <AssessmentOutlinedIcon style={{ color: "#55aa8b" }} />
                  ) : (
                    <AssessmentOutlinedIcon />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={"Reports"}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
            <ListItem
              key={"4"}
              onClick={(e) => navigate("/users")}
              disablePadding
              sx={{ display: "block" }}
              style={
                location.pathname === "/users" ||
                location.pathname === "/create-user" ||
                location.pathname === "/user-profile" ||
                location.pathname === "/update-user"
                  ? { backgroundColor: "#55aa8b38" }
                  : null
              }
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {location.pathname === "/users" ||
                  location.pathname === "/create-user" ||
                  location.pathname === "/user-profile" ||
                  location.pathname === "/update-user" ? (
                    <PersonOutlineOutlinedIcon style={{ color: "#55aa8b" }} />
                  ) : (
                    <PersonOutlineOutlinedIcon />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={"Users"}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>

            <ListItem
              key={"4"}
              onClick={(e) => navigate("/projects")}
              disablePadding
              sx={{ display: "block" }}
              style={
                location.pathname === "/projects" ||
                location.pathname === "/projects-view" ||
                location.pathname === "/edit-projects" ||
                location.pathname === "/add-projects" || 
                location.pathname === "/project-analysis"
                  ? { backgroundColor: "#55aa8b38" }
                  : null
              }
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  { location.pathname === "/projects" ||
                location.pathname === "/projects-view" ||
                location.pathname === "/edit-projects" ||
                location.pathname === "/project-analysis"||
                location.pathname === "/add-projects" ? (
                    <AccountTreeIcon style={{ color: "#55aa8b" }} />
                  ) : (
                    <AccountTreeIcon />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={"Projects"}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>


            <ListItem key={"5"} disablePadding sx={{ display: "block" }}
             onClick={(e) => navigate("/flux-correction")}
             style={
               location.pathname === "/flux-correction"
                 ? { backgroundColor: "#55aa8b38" }
                 : null
             }
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {/* <ManageAccountsOutlinedIcon /> */}
                  <MemoryIcon />
                </ListItemIcon>
                <ListItemText primary={"AI/ML"} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          </List>
          
        </Drawertop>
        
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `` },
          ml: { sm: `${drawerWidth}px` },
        }}
        style={{left:0, width:60,backgroundColor: '#55AA8B', boxShadow:'none'}}
      >
        <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ ml: 0.3, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
      </AppBar>
      <div style={{display:'none'}}>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
        style={{display:'none'}}
      >
        <Drawer
          container={containerdrawer}
          variant="temporary"
          open={mobileOpen}
          onClick={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, 
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          <div className="menu-logo">
          <a
            onClick={(e) => navigate("/main")}
            style={{ marginLeft: "auto", marginRight: "auto", marginTop: 20 }}
          >
            <img width={40} src={ICONS.logo} />
          </a>
          </div>
         <List>
            <ListItem
              key={"1"}
              disablePadding
              sx={{ display: "block" }}
              onClick={(e) => navigate("/main")}
              style={
                location.pathname === "/main"
                  ? { backgroundColor: "#55aa8b38" }
                  : null
              }
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                style={{alignItems:'initial'}}
              >
                <div className="mobilemenu-items">
                <p>Dashboard</p>
                </div>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",              
                    justifyContent: "center",
                  }}
                >
                </ListItemIcon>
                <ListItemText
                  primary={"Dashboards"}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
            <ListItem
              key={"22"}
              onClick={(e) => navigate("/sites")}
              disablePadding
              sx={{ display: "block" }}
              style={
                location.pathname === "/sites" ||
                location.pathname === "/add-sites" ||
                location.pathname === "/edit-site"
                  ? { backgroundColor: "#55aa8b38" }
                  : null
              }
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                style={{alignItems:'initial'}}
              >
                <div className="mobilemenu-items">
                <p>Sites</p>
                </div>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                </ListItemIcon>
                <ListItemText
                  primary={"Sites"}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
            <ListItem
              key={"2"}
              onClick={(e) => navigate("/sensors")}
              disablePadding
              sx={{ display: "block" }}
              style={
                location.pathname === "/sensors" ||
                location.pathname === "/sensor-status" ||
                location.pathname === "/add-sensor" ||
                location.pathname === "/edit-sensor" ||
                location.pathname === "/track" ||
                location.pathname === "/add-track"
                  ? { backgroundColor: "#55aa8b38" }
                  : null
              }
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                style={{alignItems:'initial'}}
              >
                <div className="mobilemenu-items">
                <p>Sensors</p>
                </div>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                </ListItemIcon>
                <ListItemText
                  primary={"Sensors"}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
            <ListItem key={"3"} disablePadding sx={{ display: "block" }} onClick={(e) => navigate("/reports")}
             style={
              location.pathname === "/report" ||
              location.pathname === "/reports" ||
              location.pathname === "/get-report" ||
              location.pathname === "/sequestrationdaily-all" ||
              location.pathname === "/sensor-report" || 
              location.pathname === "/sequestrationdaily-custom" ||
              location.pathname === "/sequestrationmonthly-all" ||
              location.pathname === "/sequestrationmonthly-custom" ||
              location.pathname === "/carbon-count"
                ? { backgroundColor: "#55aa8b38" }
                : null
            }
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                style={{alignItems:'initial'}}
              >
                <div className="mobilemenu-items">
                <p>Reports</p>
                </div>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                </ListItemIcon>
                <ListItemText
                  primary={"Reports"}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
            <ListItem
              key={"4"}
              onClick={(e) => navigate("/users")}
              disablePadding
              sx={{ display: "block" }}
              style={
                location.pathname === "/users" ||
                location.pathname === "/create-user" ||
                location.pathname === "/user-profile" ||
                location.pathname === "/update-user"
                  ? { backgroundColor: "#55aa8b38" }
                  : null
              }
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                style={{alignItems:'initial'}}
              >
                <div className="mobilemenu-items">
                  <p>Users</p>
                  </div>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                </ListItemIcon>
                <ListItemText
                  primary={"Users"}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>

            <ListItem
              key={"4"}
              onClick={(e) => navigate("/projects")}
              disablePadding
              sx={{ display: "block" }}
              style={
                location.pathname === "/projects" ||
                location.pathname === "/projects-view" ||
                location.pathname === "/edit-projects" ||
                location.pathname === "/add-projects" || 
                location.pathname === "/project-analysis"
                  ? { backgroundColor: "#55aa8b38" }
                  : null
              }
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                style={{alignItems:'initial'}}
              >
                <div className="mobilemenu-items">
                  <p>Projects</p>
                  </div>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                </ListItemIcon>
                <ListItemText
                  primary={"Projects"}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>

            <ListItem key={"5"} disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                style={{alignItems:'initial'}}
              >
                <div className="mobilemenu-items">
                <p>Role</p>
                </div>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {/* <ManageAccountsOutlinedIcon /> */}
                </ListItemIcon>
                <ListItemText primary={"Role"} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          </List>
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        <Toolbar />
      </Box>
    </div>
        <div className=" container main-containerbody">
          <Header />
          <div className="main-content">
            <Outlet />
          </div>
        </div>
      </Box>
    </>
  );
};

function App(props) {
  const theme = useTheme();

  useEffect(() => {}, []);

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route path="/otp-verification" element={<OtpVerification />} />
            <Route path="/" element={<AppLayout {...props} />}>
            <Route path="/main" element={<Homepage />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/sensor" element={<SensorPage />} />
            <Route path="/sensor-status" element={<SensorStatus />} />
            <Route path="/users" element={<UserList />} />
            <Route path="/sensors" element={<SensorsList />} />
            <Route path="/sites" element={<SitesList />} />
            <Route path="/track" element={<SensorTrack />} />
            <Route path="/user-profile" element={<UserProfileView />} />
            <Route path="/create-user" element={<CreateUser />} />
            <Route path="/update-user" element={<UpdateUser />} />
            <Route path="/add-sites" element={<AddSites />} />
            <Route path="/edit-site" element={<EditSite />} />
            <Route path="/add-sensor" element={<AddSensor />} />
            <Route path="/edit-sensor" element={<EditSensor />} />
            <Route path="/add-track" element={<AddTrack />} />
            <Route path="/site-install" element={<SiteInstall />} />
            <Route path="/get-report" element={<GetSiteReport />} />
            <Route path="/report" element={<Report />} />
            <Route path="/sensor-report" element={<SensorReport />} />
            <Route path="/reports" element={<GetReport />} />
            <Route path="/all-daily-report" element={<DailySensorReport />} />
            <Route path="/carbon-flux" element={<CarbonFluxReport />} />
            <Route path="/sequestrationdaily-all" element={<SequestrationdailyAll />} />
            <Route path="/sequestrationmonthly-all" element={<SequestrationMonthlyAll />} />
            <Route path="/sequestrationdaily-custom" element={<SequestrationdailyCustom />} />
            <Route path="sequestrationmonthly-custom" element={<SequestrationMonthlyCustom />} />
            <Route path="/test-mapp" element={<TestMapImpliment />} />
            <Route path="/carbon-count" element={<CarbonCountFilter />} />
            <Route path="/site-analysis" element={<SiteAnalysis />} />
            <Route path="/flux-correction" element={<MissingData />} />
            <Route path="/add-projects" element={<AddProjects />} />
            {/* <Route path="/projects" element={<ProjectsLists />} /> */}
            <Route path="/projects-view" element={<ProjectView />} />
            <Route path="/edit-projects" element={<EditProject />} />
            <Route path="/projects" element={<ProjectCardView />} />
            <Route path="/project-analysis" element={<ProjectAnalysis />} />
          
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

const mapDispatchToProps = {
  saveLoginData: saveLoginData,
};
export default connect(null, mapDispatchToProps)(App);
