import React, { useState } from 'react';
import * as Yup from "yup";
import { Formik, Form, ErrorMessage, Field} from "formik";
import { TextInput } from '../../components/TextField';
import { SiteAreaInput } from '../../components/SiteAreaInput';
import { Button } from "@mui/material";
import axiosClient from '../../axios';
import axios from '../../axios';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from "react-router-dom";
import { HaInput } from '../../components/HaInput';
import { edit_site_data, view_carbon_types, view_organizations_list, view_site_info, view_soil_types, view_vegetation_types } from '../../features/Apis/Index';
const EditSite = () => {
    let nav = useNavigate();
    const params = useLocation();
    const [soiltypes, setsoiltypes] = useState([]);
    const [carbontypes, setcarbontypes] = useState([]);
    const [vegtypes, setvegtypes] = useState([]);
    const [sitetypes, setSiteTypes] = useState([]);
    const [siteIdCurr, setSiteId] = useState("")
    const [updatedOrg,setUpdatedOrg] = useState("");
    const [formikData, SetformikData] = useState({
      sitename:"",
      siteType: "",
      sitetypeid:'',
      sitelogitude: "",
      sitelatitude: "",
      shapefile: "",
      country:'',
      soilType: "",
      soiltypeid:'',
      carbonType: "",
      vegetationType: "",
      ownername: "",
      ownercontact: "",
      carbontypeid:'',
      sitearea:'',
      vegitativearea:'',
      nonvegitativearea:'',
      isactive:'',
      sitelocation:'',
      organization:'',
    });
    const [showOrganizations, setShowOrganizations] = useState([]);
    const user = useSelector(state => state.loginData.user)
    // get all soil type
    const getSoilTypes = () => {
        axios.get(view_soil_types).then(res => {
          let tepm = res.data.gaitObj
          setsoiltypes(tepm)
    
        })
      }
    // get all carbon
      const getCarbonTypes = () => {
        axios.get(view_carbon_types).then(res => {
          let tepm = res.data.gaitObj
    
          setcarbontypes(tepm)
    
        })
      }

      const getvegTypes = () => {
        axios.get(view_vegetation_types).then(res => {
          let tepm = res.data.gaitObj
          setvegtypes(tepm)
        })
      }

      const getSiteTypes = () => {
        axios.get("v1/gait/site/view_site_types").then(res => {
          let tepm = res.data.gaitObj
          setSiteTypes(tepm)
        })
      }

      const getOrganization = () => {
        axios.get(view_organizations_list).then((res) => {
          setShowOrganizations(res.data.gaitObj);
        });
      };
    
    //   edit site details 
    const prefillValues = (site) => {
        axiosClient.get(`${view_site_info} ${params.state.siteId.siteid}`).then((resp) => {
          let siteObj = resp.data.gaitObj;
          let soil = soiltypes.filter((val) => val.soiltype == siteObj.soiltype)[0];
          let carbon = carbontypes.filter((val) => val.carbontype == siteObj.carbontype)[0];
          let veg = vegtypes.filter((val) => val.vegitationtype == siteObj.vegitationtype)[0];
          let formikobj = {
            sitename:siteObj?.sitename,
            siteType: siteObj.sitetype,
            sitetypeid:siteObj.sitetypeid,
            sitelogitude: siteObj.sitelogitude,
            sitelatitude: siteObj.sitelatitude,
            country:siteObj.country,
            carbontypeid:siteObj.carbontypeid,
            soilType: siteObj.soiltype,
            carbonType: siteObj.carbontype,
            vegetationType: siteObj.vegitationtype[0],
            ownername: siteObj.owner,
            ownercontact: siteObj.ownercontact,
            sitelocation:siteObj.country,
            vegitativearea:siteObj.vegitativearea,
            nonvegitativearea:siteObj.nonvegitativearea,
            isactive:siteObj.isactive,
            soiltypeid:siteObj.soiltypeid,
            organization:siteObj.orgid,
            sitearea:siteObj.sitearea
          }
          SetformikData(formikobj);
          setSiteId(siteObj?.siteid)
        })
        
      }

      const updateSite = (val) => {
        axiosClient.put(edit_site_data, {
          
            "siteid":siteIdCurr,
            "sitename":val.sitename,
            "sitetypeidref":{
                "sitetypeid":val.sitetypeid
            },
            "soiltypeidref":{
                "soiltypeid":val.soiltypeid
            },
            "carbontypeidref":{
                "carbontypeid":val.carbontypeid
            },
            "vegitationtypes":val.vegetationType,
            "sitearea":val.sitearea,
            "sitelocation":val.sitelocation,
            "longitude":val.sitelogitude,
            "latitude":val.sitelatitude,
            "country":val.country,
            "ownername":val.ownername,
            "ownercontact":val.ownercontact,
            "vegtativearea":val.vegitativearea,
            "non_vegtativearea":val.nonvegitativearea,
            "isactive":val.isactive,
            "insertedby":user.orguserid,
            "modifiedby":user.orguserid,
            "shapefile":"NA",
            "orgidref":val.organization
        }).then(res => {
          
          if(res.status === 200){
            nav("/sites", {state:{
              flag: "update_site"}});
          }
        })
      }
      useEffect(()=>{
        getSoilTypes(); 
        getCarbonTypes();
        getvegTypes();
        getSiteTypes();
        prefillValues();
        getOrganization();
      },[])
    const validationSchema = Yup.object({
        sitename: Yup.string()
          .required("Site Name is require"),
          siteType : Yup.string()
          .required("Site Type is require"),
        sitelogitude: Yup.string().required("Site logitude is required"),
        sitelatitude: Yup.string().required("Site latitude is require"),
          soilType: Yup.string().required("Soil type in required"),
          carbonType: Yup.string().required("Carbon type is required"),
          vegetationType: Yup.string().required("Vegetation type is required"),
          ownercontact: Yup.string().required("Contact field is required."),
          ownername: Yup.string()
          .required("Owner name is required."),
          country:Yup.string().required("Country name is required"),
          sitearea:Yup.string()
          .required('Site area is required'),
          vegitativearea: Yup.string()
          .required('Vegitative area is required'),
          nonvegitativearea: Yup.string()
          .required('Non-Vegitative area is required'),
          isactive:Yup.number()
          .required("Status is required"),
      });
  return (
    <Formik
    initialValues={formikData}
    validationSchema={validationSchema}
    enableReinitialize ={true}
    onSubmit={(values) => {
        updateSite(values);
    }}
  >
    {(formik) => (
        <Form>
            <Button
            onClick={(e) => {
              nav("/sites");
            }}
            style={{
              marginLeft: "auto",
              display: "block",
              width: 140,
              background: "linear-gradient(0deg, #55AA8B, #55AA8B), #FEFEFE",
              borderRadius: 4,
              color: "white",
            }}
          >
            View All
          </Button>
            <div className="row form-top sites-content">
              <div className="col-md-3 col-sm-12">
                <TextInput label="Site Name"  name="sitename" type="text" placeholder="first name"/>
              </div>
              <div className="col-md-3 col-sm-12">
                <div className="d-flex justify-content-between align-item-center">
                  <div>
                  <label className="form-label">Site Type</label>
                  </div>
                  <div>
                  </div>
                </div>
                <Field as="select" name="siteType" className="form-select">
                  {sitetypes?.map((val, index) => {
                    return (
                      <option value={val.sitetypeid} key={index}>
                        {val.sitetype}
                      </option>
                    );
                  })}
                </Field>
                <ErrorMessage
                  name="siteType"
                  render={msg => <div className="error">
                    &nbsp;Please select organization </div>}
                />
              </div>
              <div className="col-md-6 col-sm-12">
              <span className='site-text'>Site Areas</span>
                  <div className='row'>
                    <div className='col-md-4 col-sm-12'>
                    <HaInput label="" name="sitearea" type="text" placeholder="Areas"/>
                    </div>
                    <div className='col-md-4 col-sm-12'>
                    <SiteAreaInput label="" name="vegitativearea" type="text" placeholder="Vegitation"/>
                    </div>
                    <div className='col-md-4 col-sm-12'>
                    <SiteAreaInput label="" name="nonvegitativearea" type="text" placeholder="Non-Vegitation"/>
                    </div>
                  </div>
              </div>

              <div className='row addsite-body'>
                <div className='col-md-3 col-sm-12'>
                <TextInput label="Site Location" name="sitelocation" type="text" placeholder="location"/>
                </div>
                <div className='col-md-3 col-sm-12'>
                <TextInput label="Longitude" name="sitelogitude" type="text" placeholder="longitude"/>
                </div>
                <div className='col-md-3 col-sm-12'>
                <TextInput label="Latitude" name="sitelatitude" type="text" placeholder="latitude"/>
                </div>
                <div className='col-md-3 col-sm-12'>
                <TextInput label="Country" name="country" type="text" placeholder="country"/>
                </div>
              </div>
              <div className='row addsite-body'>
              <div className="col-md-3 col-sm-12">
                <div className="d-flex justify-content-between align-item-center">
                  <div>
                  <label className="form-label">Soil Type</label>
                  </div>
                  <div>
                  </div>
                </div>
                <select name="soilType" className="form-select" >
                  {formik.values.soilType ? (
                    <option selected value={formik.values.soiltypeid}>{formik.values.soilType}</option>
                  ):""}
                  {soiltypes?.map((val,index) => {
                    return (
                      <option value={val.soiltypeid} key={index}>
                        {val.soiltype}
                      </option>
                    );
                  })}
                </select>
                <ErrorMessage
                  name="soilType"
                  render={msg => <div className="error">
                    &nbsp;Please select organization </div>}
                />
              </div>
              <div className="col-md-3 col-sm-12">
                <div className="d-flex justify-content-between align-item-center">
                  <div>
                  <label className="form-label">Carbon Type</label>
                  </div>
                  <div>
                  </div>
                </div>
                <select name="carbonType" className="form-select">
                  {formik.values.carbonType ? (
                    <option selected value={formik.values.carbontypeid}>{formik.values.carbonType}</option>
                  ):""}
                   {carbontypes?.map((val,index) => {
                    return (
                      <option value={val.carbontypeid} key={index}>
                        {val.carbontype}
                      </option>
                    );
                  })}
                </select>
                <ErrorMessage
                  name="carbonType"
                  render={msg => <div className="error">
                    &nbsp;Please select organization </div>}
                />
              </div>
              <div className="col-md-3 col-sm-12">
                <div className="d-flex justify-content-between align-item-center">
                  <div>
                  <label className="form-label">Vegitation Type</label>
                  </div>
                  <div>
                  </div>
                </div>
                <Field as="select" name="vegetationType" className="form-select">
                  {vegtypes?.map((val,index) => {
                    return (
                      <option value={val.vegitaiontypeid} key={index}>
                        {val.vegitationtype}
                      </option>
                    );
                  })}
                </Field>
                <ErrorMessage
                  name="vegetationType"
                  render={msg => <div className="error">
                    &nbsp;Please select vegetation type </div>}
                />
              </div>
              <div className="col-md-3 col-sm-12">
                <div className="d-flex justify-content-between align-item-center">
                  <div>
                  <label className="form-label">Status</label>
                  </div>
                  <div>
                  </div>
                </div>
                <Field as="select" name="isactive" className="form-select">
                  <option>-select-</option>
                  <option value={1}>Active</option>
                  <option value={0}>InActive</option>
                 
                </Field>
                <ErrorMessage
                  name="vegetationType"
                  render={msg => <div className="error">
                    &nbsp;Please select status </div>}
                />
              </div>
              </div>
              <div className='row addsite-body'>
                <div className='col-md-3 col-sm-12'>
                <TextInput label="Owner Name" name="ownername" type="text" placeholder="Owner name"/>
                </div>
                <div className='col-md-3 col-sm-12'>
                <TextInput label="Owner Contact" name="ownercontact" type="text" placeholder="Phone number"/>
                </div>
                <div className="col-md-3 col-sm-12">
                <div className="d-flex justify-content-between align-item-center">
                  <div>
                  <label className="form-label">Organization</label>
                  </div>
                  <div>
                  </div>
                </div>
                <select name="organization" className="form-select">
                    <option value={updatedOrg.orgid}>{updatedOrg.orgname}</option>
                  {showOrganizations?.map((val,index) => {
                    if(val.orgid === formik.values.organization){
                       setUpdatedOrg(val)
                    }
                    return (
                      <option value={val.orgid} key={index}>{val.orgname} </option>
                    );
                  })}
                  
                </select>
                <ErrorMessage
                  name="soilType"
                  render={msg => <div className="error">
                    &nbsp;Please select organization </div>}
                />
              </div>
              </div>
              <div className="d-flex justify-content-end bottom-button add-sitebtn">
              <Button
                style={{
                  marginLeft: 15,
                  display: "block",
                  width: 140,
                  borderRadius: 4,
                  borderColor: "#FF6E6E",
                  borderWidth: 1,
                  color: "#FF6E6E",
                  border: "1px solid",
                  fontFamily: "Poppins",
                  fontSize: 13,
                }}
                onClick={(e) => {
                  nav("/sites");
                }}
              >
                Cancel
              </Button>
              <Button
                style={{
                  marginLeft: 15,
                  display: "block",
                  width: 140,
                  background:
                    "linear-gradient(0deg, #55AA8B, #55AA8B), #FEFEFE",
                  borderRadius: 4,
                  color: "white",
                  fontFamily: "Poppins",
                  fontSize: 13,
                }}
                type="submit"
              >
                Create
              </Button>
            </div>
            </div>
        </Form>
    )}
    </Formik>
  )
}

export default EditSite;