
  import React from 'react';
  export const Catalogues = [
    {
      id: "01",
      name: "NDRE Zonal Statistics"
    },
    {
      id: "02",
      name: "NDVI Zonal Statistics"
    },
    {
      id: "03",
      name: "NDMI Zonal Statistics"
    },
    {
      id: "04",
      name: "Elevation"
    },
    {
      id: "05",
      name: "Canopy Height11"
    },
    {
      id: "06",
      name: "Slope"
    },
    {
      id: "07",
      name: "Hill Shade"
    }
  ];

  function Mock(props) {

     const Catalogues = [
      {
        id: "01",
        name: "NDRE Zonal Statistics"
      },
      {
        id: "02",
        name: "NDVI Zonal Statistics"
      },
      {
        id: "03",
        name: "NDMI Zonal Statistics"
      },
      {
        id: "04",
        name: "Elevation"
      },
      {
        id: "05",
        name: "Canopy Height11"
      },
      {
        id: "06",
        name: "Slope"
      },
      {
        id: "07",
        name: "Hill Shade"
      }
    ];
    return Catalogues;
  }
  
  export default Mock;