import React, { useState } from "react";
import * as Yup from "yup";
import { Formik, Form, ErrorMessage, Field } from "formik";
import { useNavigate } from "react-router-dom";
import { TextInput } from "../../components/TextField";
import { Button } from "@mui/material";
import axiosClient from "../../axios";
import axios from "../../axios";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RadioBtn } from "../../components/RadioBtn";
const AddSensor = () => {
  let nav = useNavigate();
  const [sensorTypes, setSensorTypes] = useState([]);
  const user = useSelector((state) => state.loginData.user);

  function getSensorTypes() {
    axios.get("/v1/gait/site/view_sensor_types").then(res => {
      let tepm = res.data.gaitObj

      setSensorTypes(tepm)

    })
  }
  useEffect(() => {
    getSensorTypes();
  }, []);
  const addSensor = (val) => {
    axiosClient.post("v1/gait/sensor/save_sensor_info", {

      "sensortypeidref": {
        "sensortypeid": val.sensortype
      },
      "sensormodel": val.sensormodel,
      "sensormake": val.make,
      "sensoryear": Number(val.sensoryear),
      "sensorprice": Number(val.price),
      "sensorlifespan": Number(val.lifespan),
      "isactive": Number(val.state),
      "insertedby": user.orguserid,
      "modifiedby": user.orguserid
    }).then(res => {
      alert("Sensor added successfully");
      localStorage.setItem('sensor', true);
      nav('/sensors');

    })

  }
  const validate = Yup.object({
    sensortype: Yup.string().required("Sensor type is require"),
    sensormodel: Yup.string().required("Sensor model is require"),
    make: Yup.string().required("Make is required"),
    sensoryear: Yup.string().required("Sensor year is required"),
    price: Yup.string().required("Price is require"),
    lifespan: Yup.string().required("Lifespan in required"),
    serialNo: Yup.string().required("Serial No is required"),
    installeddate: Yup.string().required("Installed date is required"),
    maintenancedate: Yup.string().required("Maintenance date is required."),
    state: Yup.string().required("Status is required."),
    Owner: Yup.string().required("Sensor Owner is required."),
  });
  return (
    <Formik
      initialValues={{
        sensortype:'',
        sensormodel:'',
        make:'',
        sensoryear:'',
        price:'',
        lifespan:'',
        serialNo:'',
        installeddate:'',
        maintenancedate:'',
        state:'',
        Owner:'',
      }}
      validationSchema={validate}
      onSubmit={(values) => {
        addSensor(values);
      }}
    >
      {(formik) => (
        <Form>
          <Button
            onClick={(e) => {
              nav("/sensors");
            }}
            style={{
              marginLeft: "auto",
              display: "block",
              width: 140,
              background: "linear-gradient(0deg, #55AA8B, #55AA8B), #FEFEFE",
              borderRadius: 4,
              color: "white",
            }}
          >
            View All
          </Button>
          <div className="row form-top sites-content">
          <div className="col-md-3 col-sm-12">
                <div className="d-flex justify-content-between align-item-center">
                  <div>
                  <label className="form-label">Sensor Type</label>
                  </div>
                  <div>
                  </div>
                </div>
                <Field as="select" name="sensortype" className="form-select">
                  <option>-select-</option>
                  {sensorTypes?.map((val, index) => {
                  return (<option value={val.sensortypeid} key={index}>{val.sensortype}</option>)
                })}
                 
                </Field>
                <ErrorMessage
                  name="sensortype"
                  render={msg => <div className="error">
                    &nbsp;Please select status </div>}
                />
          
              </div>
            <div className="col-md-3 col-sm-12">
              <TextInput
                label="Sensor Model"
                name="sensormodel"
                type="text"
                placeholder="sensor model"
              />
            </div>
            <div className="col-md-3 col-sm-12">
              <TextInput
                label="Sensor Make"
                name="make"
                type="text"
                placeholder="sensor make"
              />
            </div>

            <div className="row addsite-body">
              <div className="col-md-3 col-sm-12">
                <div className="d-flex justify-content-between align-item-center">
                  <div>
                    <label className="form-label">Sensor Year</label>
                  </div>
                  <div></div>
                </div>
                <Field as="select" name="sensoryear" className="form-select">
                <option >-Select-</option>
                <option value="2022">2022</option>
                <option value="2021">2021</option>
                <option value="2020">2020</option>
                <option value="2019">2019</option>
                <option value="2018">2018</option>
                <option value="2017">2017</option>
                <option value="2016">2016</option>
                <option value="2015">2015</option>
                <option value="2014">2014</option>
                <option value="2013">2013</option>
                <option value="2012">2012</option>
                <option value="2011">2011</option>
                <option value="2010">2010</option>
                  
                </Field>
                <ErrorMessage
                  name="sensoryear"
                  render={(msg) => (
                    <div className="error">&nbsp;Please select site type </div>
                  )}
                />
              </div>

              <div className="col-md-3 col-sm-12">
                <TextInput
                  label="Sensor Price ($)"
                  name="price"
                  type="text"
                  placeholder="Sensor Make"
                />
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="row">
                <label className="form-label">Sensor Lifespan</label>
                <div className="col-md-2 col-sm-12">
                  <div className="radio">
                  <RadioBtn 
                   label="1 Year"
                      type="radio"
                      name="lifespan"
                      value="1"/>
                  </div>
                  </div>
                  <div className="col-md-2 col-sm-12">
                  <div className="radio">
                  <RadioBtn  label="2 Year"
                      type="radio"
                      name="lifespan"
                      value="2"/>
                  </div>
                  </div>
                  <div className="col-md-2 col-sm-12">
                  <div className="radio">
                  <RadioBtn  label="5 Year"
                      type="radio"
                      name="lifespan"
                      value="5"/>
                  </div>
                  </div>
                  <div className="col-md-2 col-sm-12">
                  <div className="radio">
                    <RadioBtn  label="10 Year"
                      type="radio"
                      name="lifespan"
                      value="10"/>
                  </div>
                  </div>
                  <div className="col-md-3 col-sm-12">
                  <TextInput
                  label=""
                  name="lifespan"
                  type="text"
                  placeholder="sensor make"
                  style={{marginTop:-25}}
                />
                  </div>
                </div>
              </div>
            </div>

            <div className="row addsite-body">
              <div className="col-md-3 col-sm-12">
              <TextInput
                  label="Sensor Serial No"
                  name="serialNo"
                  type="text"
                  placeholder="Sensor Serial No"
                />
              </div>
              <div className="col-md-3 col-sm-12">
              <TextInput
                  label="Sensor Owner"
                  name="Owner"
                  type="text"
                  placeholder="Sensor Owner"
                />
              </div>
            </div>

            <div className="row addsite-body">
              <div className="col-md-3 col-sm-12">
                <TextInput
                  label="Install Date"
                  name="installeddate"
                  type="date"
                  placeholder="Install Date"
                />
              </div>
              <div className="col-md-3 col-sm-12">
                <TextInput
                  label="Maintenance Due Date"
                  name="maintenancedate"
                  type="date"
                  placeholder="Maintenance Due Date"
                />
              </div>
              <div className="col-md-3 col-sm-12">
                <div className="d-flex justify-content-between align-item-center">
                  <div>
                  <label className="form-label">Status</label>
                  </div>
                  <div>
                  </div>
                </div>
                <Field as="select" name="state" className="form-select">
                  <option>-select-</option>
                  <option value={1}>Active</option>
                  <option value={0}>InActive</option>
                 
                </Field>
                <ErrorMessage
                  name="state"
                  render={msg => <div className="error">
                    &nbsp;Please select status </div>}
                />
          
              </div>
            </div>
            <div className="d-flex justify-content-end bottom-button add-sitebtn">
              <Button
                style={{
                  marginLeft: 15,
                  display: "block",
                  width: 140,
                  borderRadius: 4,
                  borderColor: "#FF6E6E",
                  borderWidth: 1,
                  color: "#FF6E6E",
                  border: "1px solid",
                  fontFamily: "Poppins",
                  fontSize: 13,
                }}
                onClick={(e) => {
                  nav("/sensors");
                }}
              >
                Cancel
              </Button>

              <Button
                style={{
                  marginLeft: 15,
                  display: "block",
                  width: 140,
                  background:
                    "linear-gradient(0deg, #55AA8B, #55AA8B), #FEFEFE",
                  borderRadius: 4,
                  color: "white",
                  fontFamily: "Poppins",
                  fontSize: 13,
                }}
                type="submit"
              >
                Create
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddSensor;
