import { Alert, Snackbar } from '@mui/material';
import React, { useState } from 'react';
import "../css/bootstrap.min.css"
import "../css/custom.css"
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { saveLoginData } from '../features/counter/APIs';
import { connect, useSelector } from 'react-redux';
import axios from '../axios';
import { Formik, Form } from "formik";
import { TextInput } from '../components/TextField';
import * as Yup from "yup";
function ResetPassword(props) {
  const loginData = useSelector(state => state.loginData)
  const [newPassword, setnewPassword] = useState("")
  const [confirmPassword, setconfirmPassword] = useState("")
  const [enabled, setSubmitEnabled] = useState(false)
  const [open, setOpen] = React.useState(false);
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  const resetPassword = (val) => {
    axios.post(`/gait/um/resetpassword`,
      {
        "userid": loginData.user.id,
        "oldpassword": val.pass1,
        "newpassword": val.pass2,
        "resetpassword": val.pass3
      }).then((res) => {
      }).catch((err) => {
      })
  }
  const validate = Yup.object({
    pass1: Yup.string().required("Old password is required."),
    pass2: Yup.string()
    .required("New password is required.")
    .min(8, "Password is too short - should be 8 chars minimum.")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
    pass3: Yup.string()
      .required("Confirm password is required.")
      .oneOf([Yup.ref('pass2'), null], 'Passwords must match')
  });
  return (
    <div className='sign-in-bg' >
       {(newPassword !== "" && confirmPassword !== "") &&
      <Snackbar open={open} autoHideDuration={4000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleClose} severity={enabled ? "success" : "error"} sx={{ width: '100%' }}>
         { enabled ? "Passwords matched" :  "Passwords do not match, please recheck your passwords"}
        </Alert>
      </Snackbar>
      }
      <div className="overlay" >
        <main className="form-signin">
          <div className="container">
            <div className="row justify-content-center align-items-center h-100vh">
              <div className="col-lg-4">
                <div className="form-body p-md-5 p-3 py-5 w-100">
                   <Formik
      initialValues={{
        pass1: "",
        pass2: "",
        pass3: "",
      }}
      validationSchema={validate}
      onSubmit={(values) => {
        resetPassword(values);
      }}
    >
      {(formik) => (
        <div className="">
          <Form>
            <div className="row form-top">
              <div className="col-md-3 col-sm-12">
                <div className="d-flex justify-content-between align-item-center">
                  <div>
                  </div>
                </div>
              </div>
                <TextInput label="Old Password" name="pass1" type="text" placeholder="Old Password"/>
                <TextInput label="New Password" name="pass2" type="text" placeholder="New Password"/>
                <TextInput label="Confirm Password" name="pass3" type="text" placeholder="Confirm Password"/>
            </div>
              <Button
                style={{
                  display: "block",
                  width: '100%',
                  background:
                    "linear-gradient(0deg, #55AA8B, #55AA8B), #FEFEFE",
                  borderRadius: 4,
                  color: "white",
                  fontFamily: "Poppins",
                  fontSize: 13,
                }}
                type="submit"
              >
                Create
              </Button>
          </Form>
        </div>
      )}
    </Formik>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}
const mapDispatchToProps = {
  saveLoginData: saveLoginData,
}
export default connect(null, mapDispatchToProps)(ResetPassword);
