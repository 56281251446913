import { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { getAllSensors } from '../features/counter/APIs';
import { connect, useSelector } from 'react-redux';
import { Alert, Button, FormControlLabel, Grid, Menu, MenuItem, Snackbar, Switch, } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ICONS from '../features/images';
import axios from '../axios';
import EditIcon from '@mui/icons-material/Edit';
function SensorsList(props) {
  let params = useLocation();
  const [rows, setRows] = useState([])
  const [open, setOpen] = useState(-1)
  const [anchorEl, setAnchorEl] = useState(null);
  const [aopen, setAlertOpen] = useState(false);
  const [sensorOpen , setSensorOpen] = useState(false);
  const [updateaopen,setupdateAopen] = useState(false);
  const [loading, setLoading] = useState(false);
  const user = useSelector(state => state.loginData.user)
  let navigate = useNavigate();

  const handleUpdateSensor =(event, reason) =>{
    if (reason === 'clickaway') {
      return;
    }
    setupdateAopen(false);
  }
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlertOpen(false);
  };
  const handleCloseSensor = (event, reason) =>{
    if (reason === 'clickaway') {
      return;
    }
    setSensorOpen(false);
  }
  const columns = [
    { field: 'sensorid', headerName: 'ID', flex: 1, minWidth:100,   },
    { field: 'sensortype', headerName: 'Sensor Type', flex: 1, minWidth:100, },
    {
      field: 'sensormodel', headerName: 'Sensor Model',
      flex: 1,
      minWidth:100,
      renderCell: (val) => (
        <span className='text-green'>
          {val.value}
        </span>
      ),
    },
    {
      field: 'make',
      headerName: 'Make',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      flex: 1,
      minWidth:100,
    },
    {
      field: 'sensoryear',
      headerName: 'Sensor Year',
      flex: 1,
      minWidth:100,
      renderCell: (val) => (
        <span className='text-blue'>
          {val.value}
        </span>
      ),
    },
    {
      field: 'price',
      headerName: 'Price',
      flex: 1,
      minWidth:100,
      renderCell: (val) => (
        <span className='text-blue'>
          $ {val.value}
        </span>
      ),
    },
  ];
  if (user?.personIdref?.orgidref?.orgcode == "GAIT") {

    columns.push(
      {
        field: 'lifespan',
        headerName: 'Lifespan',
        description: 'This column has a value getter and is not sortable.',
        sortable: false,
        flex: 1,
        minWidth:100,
        renderCell: (val) => (
          <span className='text-purple'>
            {val.value} years
          </span>
        ),
      })
    columns.push(
      {
        field: 'isactive',
        headerName: 'Status',
        description: 'This column has a value getter and is not sortable.',
        minWidth:100,
        renderCell: (val) => (
          <>
            <span>
              <FormControlLabel className={val.value == 1 ? 'text-green' : ""} control={<Switch onChange={(e) => deactSensor(val.row)} color='success' checked={val.value == 0 ? false : true}
              />} label={val.value == 0 ? "Inactive" : "Active"} />

            </span>
          </>
        ),
        flex: 1,
      })
    columns.push(
      {
        field: 'action',
        headerName: 'Actions',
        flex: 1,
        minWidth:100,
        renderCell: (val) => (
          <>
            <Button
              color='inherit'
              id="basic-button"
              aria-haspopup="true"
              onClick={e => {
                setAnchorEl(e.currentTarget);
                setOpen(val.value)
              }}
              style={{fontFamily: 'Poppins'}}
            >
              <MoreVertIcon />
            </Button>
            <Menu
              onClose={e => {
                setAnchorEl(null);
                setOpen(-1)
              }}
              anchorEl={anchorEl}
              id="basic-menu"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'middle',
              }}
              open={(open == val.value) ? true : false}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem style={{ backgroundColor: "#000", opacity: 1, color: "#fff" }} disabled onClick={e => setOpen(-1)}>Actions</MenuItem>
              <MenuItem onClick={e => {
                navigate('/edit-sensor',{state:{
                  sensorId: val.row.sensorid} 
                } )
              }}>
                <EditIcon htmlColor='#4EB9E9'/> <span style={{marginLeft: 5}}>Edit</span></MenuItem>
              <MenuItem style={{backgroundColor: "#55AA8B5C"}} onClick={e => { setOpen(-1); navigate(`/track?sensorid=${val.row.sensorid}`) }}>View More</MenuItem>
            </Menu>
          </>
        ),

      })
  }
  const deactSensor = (sensor) => {
    if (sensor.isactive == 0) {

      axios.put(`v1/gait/sensor/change_sensor_status/${sensor.sensorid}/1`).then((res) => {
        getSensorAndupdate()
        setAlertOpen(true);
      })
    } else {
      axios.put(`v1/gait/sensor/change_sensor_status/${sensor.sensorid}/0`).then((res) => {
        getSensorAndupdate()
        setAlertOpen(true);
      })
    }
  }
  function getSensorAndupdate() {
    setLoading(true);
    getAllSensors(user?.personidref?.orgidref?.orgid, user?.personidref?.orgidref?.orgcode).then((res) => {
      setLoading(false);
      const tempRows = res.data.gaitObj.map((val, index) => {
        val.id = index
        val.action = index
        return (val)
      })
      setRows(tempRows)
      if(params?.state?.flag === "update_sensor"){
        setupdateAopen(true);
        window.history.replaceState({}, document.title)
      }
    })
  }
  useEffect(() => {
    getSensorAndupdate()
  }, []);
  return (
    <div style={{ height: 650, width: '100%', paddingRight: 20, marginTop: 20 }}>
      {loading ? (
        <div>
          <img src={ICONS.SignalGreen} style={{
            width: '5%',
            position: 'absolute',
            top: '50%',
            left: '50%',
          }}/>
        </div>
      ) : (
        <>
      <Grid container justifyContent={"end"}>
        <Grid>
          <Button onClick={e => { navigate("/sensor-status") }} variant={"outlined"} style={{
            marginLeft: "auto",
            display: "block",
            width: 180,
            marginLeft: 20,
            color: "#55AA8B",
            borderColor: "#55AA8B",
            borderRadius: 4,
            fontFamily: 'Poppins'
          }}>Sensor Status</Button>
        </Grid>
        <Grid>
          {user?.personIdref?.orgidref?.orgcode == "GAIT" &&
            <Button onClick={e =>  navigate('/add-sensor')} style={{
              marginLeft: 20,
              display: "block",
              width: 140,
              background: "linear-gradient(0deg, #55AA8B, #55AA8B), #FEFEFE",
              borderRadius: 4, color: "white",
              fontFamily: 'Poppins'
            }}>Add Sensor</Button>
          }
        </Grid>
      </Grid>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={10}
        rowSpacingType="margin"
        rowsPerPageOptions={[10]}
        disableSelectionOnClick={true}
        disableColumnMenu={true}
        style={{ marginTop: 20 }}
      />
        <Snackbar open={sensorOpen} autoHideDuration={2000} onClose={handleCloseSensor} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSensor} severity="info" sx={{ width: '100%' }}>
          Sensor created successfully.
        </Alert>
      </Snackbar>
      <Snackbar open={aopen} autoHideDuration={2000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Status changed successfully.
        </Alert>
      </Snackbar>
      <Snackbar open={updateaopen} autoHideDuration={2000} onClose={handleUpdateSensor} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleUpdateSensor} severity="success" sx={{ width: '100%' }}>
          Sensor updated successfully.
        </Alert>
      </Snackbar>
      </>
      )}
    </div>
  );
}
const mapDispatchToProps = {
  getAllSensors: getAllSensors,
}

export default connect(null, mapDispatchToProps)(SensorsList);
