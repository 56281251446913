import { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { getAllUsers } from '../features/counter/APIs';
import { connect, useSelector } from 'react-redux';
import axios from '../axios';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from "react-router-dom";
import { Alert, Button, FormControlLabel, Menu, MenuItem, Snackbar, Switch } from '@mui/material';
import ICONS from '../features/images'
import MoreVertIcon from '@mui/icons-material/MoreVert';

const UserList = props => {
  let nav = useNavigate()
  const [rows, setRows] = useState([])
  const [open, setOpen] = useState(-1)
  const [anchorEl, setAnchorEl] = useState(null);
  const [aopen, setAlertOpen] = useState(false);
  const [alertOpenUser, setAlertOpenUser] = useState(false);
  const [loading, setLoading] = useState(false);
 
  // const getAllUsersList = () => {
  //   props.getAllUsers(user?.personidref?.orgidref?.orgid, user?.personidref?.orgidref?.orgcode).then(ress => {
   
  //     const tempRows = usersList?.map((val, index) => {
  //       val.personIdref.id = index
  //       val.personIdref.orguserid = val.orguserid
  //       val.personIdref.email = val.personIdref.email
  //       val.personIdref.rolename = val.roleidref.rolename
  //       val.personIdref.organization = val.roleidref.orgidref.orgname
  //       val.personIdref.status = val.isactive == 1 ? "Active" : "Inactive"
  //       val.personIdref.createdBy = val.createdby
  //       val.personIdref.action = index
  //       val.personIdref.modifiedAt = new Date(val.modifiedat).toDateString()
  //       return (val.personIdref)
  //     })
  //     setRows(tempRows);
  //   })
 
  // }
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlertOpen(false);
  };
  const handleCloseUser = (event, reason) =>{
    if (reason === 'clickaway') {
      return;
    }
    setAlertOpenUser(false);
  }
  const user = useSelector(state => state.loginData?.user)
  let usersList = []
  const columns = [
    {
      field: 'orguserid', 
      headerName: 'ID',
      flex: 1,
      minWidth: 30,
      },
    {
      field: 'firstname', 
      headerName: 'Name',
      flex: 1,
      minWidth:100,    
    },
    {
      field: 'designation',
      headerName: 'Designation ',
      flex: 1 ,
      minWidth:100,  
    },
    {
      field: 'rolename',
      headerName: 'Role',
      minWidth:100,  

    }, {
      field: 'organization',
      headerName: 'Organisation',
      flex: 1,
      minWidth:100,  

    }, {
      field: 'mobilenum',
      headerName: 'Mobile No',
      flex: 1,
      minWidth:100,  
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1    ,
      minWidth:100,  
    },
      {
        field: 'isactive',
        headerName: 'Status',
        description: 'This column has a value getter and is not sortable.',
        minWidth:100,  
        renderCell: (val) => (
          <>
            <span>
              <FormControlLabel className={val.value == 1 ? 'text-green' : ""} control={<Switch onChange={(e) => userStatus(val.row)} color='success' checked={val.row.status == "Active" ? true : false}
              />} label={val.row.status == "Active" ? "Active" : "Inactive"} />
            </span>
          </>
        ),
        flex: 1,
        },
    {
      field: 'createdBy',
      headerName: 'Created By',
      flex: 1,
      minWidth:100,  
    },
    {
      field: 'modifiedAt',
      headerName: 'Modified At',
      flex: 1,
      minWidth:100,  
    },
 
    {
      field: 'action',
      headerName: 'Actions',
      flex: 1,
      minWidth:80,  
      renderCell: (val) => (
        <>
          <Button
           color='inherit'
            id="basic-button"
            aria-haspopup="true"
            onClick={e => {
              setAnchorEl(e.currentTarget);
              setOpen(val.value)
            }}
          >
            <MoreVertIcon/>
          </Button>
          <Menu
            onClose={e => {
              setAnchorEl(null);
              setOpen(-1)
            }}
            anchorEl={anchorEl}
            id="basic-menu"
            open={(open == val.value) ? true : false}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
              <MenuItem style={{ backgroundColor: "#000", opacity: 1, color: "#fff" }} disabled onClick={e => setOpen(-1)}>Actions</MenuItem>
                <MenuItem onClick={e => {
                nav('/update-user', {state:{
                  userId: val.row.orguserid} 
                })

              }}><EditIcon htmlColor='#4EB9E9'/> <span style={{marginLeft: 5}}>Edit</span></MenuItem>
                      
              <MenuItem onClick={e => {
                nav("/user-profile", {state:{
                  userId: val.row.orguserid} 
                })

              }}><AddIcon htmlColor='#2C4EA6'/> <span style={{marginLeft: 5}}>View More </span> </MenuItem>
          </Menu>
        </>
      ),
    },
  ];

  const userStatus = (status) => {
    // setLoading(true);
    if (status.status == "Active") {
    axios.put(`gait/um/deactivate_user/${status.orguserid}/0`).then((res) => {
      // setLoading(false);
    if(res.status === 200){
      setAlertOpen(true);
      props.getAllUsers(user?.personidref?.orgidref?.orgid, user?.personidref?.orgidref?.orgcode);
    }
    })
    } else {
      axios.put(`gait/um/deactivate_user/${status.orguserid}/1`).then((res) => {
        if(res.status === 200){
          setAlertOpen(true);
          // setLoading(false);
          props.getAllUsers(user?.personidref?.orgidref?.orgid, user?.personidref?.orgidref?.orgcode);
        }
      })
    }
  }
 
  usersList = useSelector(state => state.users?.gaitObj)
  useEffect(() => {
    // getAllUsersList();
    setLoading(true);
    props.getAllUsers(user?.personidref?.orgidref?.orgid, user?.personidref?.orgidref?.orgcode).then(ress => {
      
      const tempRows = usersList?.map((val, index) => {
        val.personIdref.id = index
        val.personIdref.orguserid = val.orguserid
        val.personIdref.email = val.personIdref.email
        val.personIdref.rolename = val.roleidref.rolename
        val.personIdref.organization = val.roleidref.orgidref.orgname
        val.personIdref.status = val.isactive == 1 ? "Active" : "Inactive"
        val.personIdref.createdBy = val.createdby
        val.personIdref.action = index
        val.personIdref.modifiedAt = new Date(val.modifiedat).toDateString()
        return (val.personIdref)
      })
      setRows(tempRows);
      setLoading(false);
    })
    
    userAlertPopUp(); 
  },[usersList]);

  const userAlertPopUp = () =>{
    let userCreate = localStorage.getItem('userCreated');
    if(userCreate === "true"){
      setAlertOpenUser(true);
      setTimeout(()=>{
        localStorage.removeItem('userCreated');
      }, 2000)
    }else{
      setAlertOpenUser(false);
    }
  }
  return (
    <div style={{ height: 650, width: '100%' }}>
        {loading ? (
        <div>
          <img src={ICONS.SignalGreen} style={{
            width: '5%',
            position: 'absolute',
            top: '50%',
            left: '50%',
          }}/>
        </div>
      ) : (
        <>
        <div style={{marginTop:20}}>
        {user?.personIdref?.orgidref?.orgcode == "GAIT" && <Button
         onClick={e => {
         nav('/create-user')
        }} style={{
          marginLeft: "auto",
          display: "block",
          width: 140,
          background: "linear-gradient(0deg, #55AA8B, #55AA8B), #FEFEFE",
          borderRadius: 4, color: "white",
        }}
        >Add User</Button> 
        }
         </div>
         <Snackbar open={aopen} autoHideDuration={2000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleClose} severity="info" sx={{ width: '100%' }}>
          Status changed successfully.
        </Alert>
      </Snackbar>

      <Snackbar open={alertOpenUser} autoHideDuration={2000} onClose={handleCloseUser} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseUser} severity="success" sx={{ width: '100%' }}>
          User created successfully.
        </Alert>
      </Snackbar>
    {(rows && rows.length > 0  ) && 
    <DataGrid
        rows={rows}
        columns={columns}
        pageSize={10}
        rowSpacingType="margin"
        rowsPerPageOptions={[10]}
        disableSelectionOnClick={true}
        disableColumnMenu={true}
        autoHeight
        style={{marginTop: 20}}
      />}  
     </>
)}
    </div>
  );
}
const mapDispatchToProps = {
  getAllUsers: getAllUsers,
}

export default connect(null, mapDispatchToProps)(UserList);
