export const v1_gait = `v1/gait/site/`;
export const only_gait = `v1/gait/`;
export const view_all_sites = `view_all_sites/`;
export const change_site_status = `change_site_status/`;
export const view_all_roles = `/gait/roles/viewallroles`;
export const add_user = `/gait/um/newuser`;
export const view_organizations_list = `/gait/org/view_organizations_list`;
export const view_profile = `/gait/um/view_profile/`;
export const updateprofile = `/gait/um/updateprofile`;
export const view_soil_types = `${v1_gait}view_soil_types`;
export const  view_carbon_types = `${v1_gait}view_carbon_types`;
export const view_vegetation_types = `${v1_gait}view_vegetation_types`;
export const view_site_types = `${v1_gait}view_site_types`;
export const viewallusers = `gait/um/viewallusers/`;
export const save_site_data = `${v1_gait}save_site_data`;
export const view_site_info = `${v1_gait}view_site_info?siteId=`;
export const edit_site_data= `${v1_gait}edit_site_data`;
export const view_inactive_sensors = `${only_gait}sensor/view_inactive_sensors`;
export const save_sensor_installation_info = `${only_gait}sensor/save_sensor_installation_info`