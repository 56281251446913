import { Alert, Snackbar } from '@mui/material';
import React, { useState,useEffect } from 'react';
import ICONS from '../features/images';
import "../css/bootstrap.min.css";
import "../css/custom.css";
import { useNavigate } from "react-router-dom";
import { saveLoginData } from '../features/counter/APIs';
import { connect } from 'react-redux';
import axios from "axios";
import { ClipLoader } from 'react-spinners';
import { useCookies } from 'react-cookie';
function LoginPage(props) {
  let navigate = useNavigate();
  const [cookies, setCookie] = useCookies(['user']);
  const [password, setPassword] = useState(cookies.password !="" ? cookies.password :"")
  const [username, setUserName] = useState(cookies.username !="" ? cookies.username :"")
  const [loading, setLoading] = useState(false)
  const [showSplash, SetshowSplash] = useState(true)
  const [open, setOpen] = React.useState(false);
  const [openWrongDetails, setOpenWrongDetails] = useState(false);
  const [error, setError] = useState(null);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleCloseWrongDetails = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenWrongDetails(false);
  };
useEffect(() => {
  setTimeout(() => {
    localStorage.removeItem('message');
    SetshowSplash(false)
  }, 3000);
  
  handleClick();
},[])

const handleClick = () => {
  let localVal  = localStorage.getItem("logOutDone");
  if(localVal === 'loginAgain'){
    setOpen(true);
  }
};

  const login = () => {
    if(password == null || password == undefined || password == ""){
      alert("Password field is required");
      return false;
    }
    if(username == null || username == undefined || username == ""){
      alert("Username field is required");
      return false;
    }
    setLoading(true)
    // axios.post(`https://api.gaitglobal.com:8082/v1/security/jwt/auth`,
    // axios.post(`http://54.206.48.250:8082/jwt/authenticate`,
    // axios.post(`http://54.206.48.250:8082/v1/security/jwt/auth`,
    axios.post(`http://api.gaitglobal.com:8082/v1/security/jwt/auth`,
      {
        "username": username,
        "password": password
      }).then((res) => {
        if(res.data.token === "OTP sent to mail"){
        if(res.data.status === "true"){
          navigate("/otp-verification", {state:{
            username: username , password:password}});
        }
      }else{
      localStorage.setItem("userInfo" , JSON.stringify(res.data.user));
        if(res.data.status == 401){
          alert("it's four");
        }
        if (res.data.status == "500" || res.data.status == "200L") {
          // axios.post(`https://api.gaitglobal.com:8082/v1/security/jwt/logout`,
          // axios.post(`http://54.206.48.250:8082/jwt/logout`,
          axios.post(`http://api.gaitglobal.com:8082/v1/security/jwt/logout`,
            {
              "username": username
            }).then((res) => {
              login()
            })
        } else {
          if (res.data.token !== null) {
            props.saveLoginData(res)
            if (res.data.token) {
              navigate("/main");
              setTimeout(()=>{
                localStorage.setItem("status" , "Shown");
              }, 2000);
            }
          } else {
            setOpen(true)
          }
        }
      }
      }).catch((err) => {
        if(err.response.status === 401){
          setOpenWrongDetails(true);
          setLoading(false)
        }
      })
  }
  const setRememberme = () =>{
      let uName = username;
      let uPassword = password;
      setCookie('username', uName, { path: '/' });
      setCookie('password', uPassword, { path: '/' });
  }
  return (
    <div className='sign-in-bg' style={{ backgroundImage: `url(${ICONS.gradient})`, backgroundRepeat: "no-repeat" }}>
      {showSplash &&
        <div style={{ width: "100vw", height: "100vh", position: "absolute" }}>
          <img src={ICONS.splash} style={{ width: "100%", objectFit: "cover", position: "absolute", height: "100%" }} />
        </div>
      }
      <Snackbar open={open} autoHideDuration={4000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
        Your session has timed out. Please login again.
        </Alert>
      </Snackbar>
      <Snackbar open={openWrongDetails} autoHideDuration={4000} onClose={handleCloseWrongDetails} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseWrongDetails} severity="error" sx={{ width: '100%' }}>
        You have entered wrong id or password.
        </Alert>
      </Snackbar>
      <div className="overlay" >
        <main className="form-signin">
          <div className="container login-container">
            <div className="row justify-content-end align-items-center h-100vh">
              <div className="col-lg-4 col-md-6 col-md-12">
                <div className="form-body p-md-5 p-3 py-5 w-100 form-area">
                  <form 
                  onSubmit={e => {
                    e.preventDefault();
                    login()
                  }}
                  >
                    <div className="row d-flex align-items-center ">
                      <div className="col-md-12 text-center">
                        <img className="mb-3" src={ICONS.Gait_complete_logo} alt="" />
                      </div>
                      {/* <div className="col-md-12 mb-4">
                        <h1 className="h3 fw-normal">Welcome !</h1>
                      </div> */}
                      <div className="col-md-12 mb-4">
                        <div className="form-group">
                          <i className="fa fa-user icon"></i>
                          <input type="text" value={username} onChange={e => setUserName(e.target.value)} className="form-control" placeholder="Username" />
                        </div>
                      </div>
                      <div className="col-md-12 mb-2">
                        <div className="form-group">
                          <i className="fa fa-lock icon"></i>
                          <input type="password" value={password} onChange={e => setPassword(e.target.value)} className="form-control" placeholder="Password" />
                        </div>
                      </div>
                      <div className="col-6 mb-4">
                        <div className="form-checkbox ">
                          <input type="checkbox" id="remember_me" onClick={() => setRememberme()}/>{" "}
                          <label htmlFor="remember_me"> Remember Me</label>
                        </div>
                      </div>
                      <div className="col-md-12 mb-4">
                        <button type="submit" onClick={() =>{
                        }} className="btn green-light-bg text-white w-100">LOGIN<ClipLoader color={"#fff"} loading={loading} size={18} />
                        </button>
                      </div>
                      <p style={{color:'red', fontSize:60}}>{error}</p>
                      <div className="col-md-12 text-center">
                        <p style={{marginBottom:0, fontFamily:'Poppins', fontSize:12}}>By continuing, you agree to <a className="weight-semibold" href="#">GAIT Global Terms&nbsp;of&nbsp;Service,Privacy Policy</a></p>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </main>

      </div>
    </div>
  )
}

const mapDispatchToProps = {
  saveLoginData: saveLoginData,

}

export default connect(null, mapDispatchToProps)(LoginPage);
