import React from "react";
import { Button } from "@mui/material";
import { PDFExport } from "@progress/kendo-react-pdf";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { MapContainer, Marker, GeoJSON, Popup, TileLayer } from "react-leaflet";
import ICONS from "../../features/images";
import { useState } from "react";
import { useEffect } from "react";
import { Catalogues } from "../../components/Mock";
import axiosClient from "../../axios";
import { useLocation, useNavigate } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import L from "leaflet";
import axios from "axios";
// import "../../tbl.css";
import {
  getAllSites,
  getSite,
  getSiteCarbonByYear,
  getSiteCarbonBySource,
} from "../../features/counter/APIs";
import dayjs from "dayjs";
import Box from "@mui/material/Box";
import { Line } from "react-chartjs-2";
const labels = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];
let analysisMaxValOfNdre = [];
let analysisMinValOfNdre = [];
let analysisMeanValOfNdre = [];
let analysisDateValOfNdre = [];
let checkAnalysisType = [];
let analysisDateValOfNdmi = [];
let analysisMinValOfNdmi = [];
let analysisMaxValOfNdmi = [];
let analysisMeanValOfNdvi = [];
let analysisDateValOfNdvi = [];
let analysisMinValOfNdvi = [];
let analysisMaxValOfNdvi = [];
let analysisMeanValOfNdmi = [];
function ProjectAnalysis(props) {
  const location = useLocation();
 
  const nav = useNavigate();
  const isCalledRef = React.useRef(false);
  isCalledRef.current = isCalledRef.current + 1;
  const pdfExportComponent = React.useRef(null);
  const [list, setList] = useState([]);
  const [newList, setNewList] = useState([]);
  const [geoJSONObj, setgeoJSONObj] = React.useState(null);
  const [bgColor, setBgColor] = React.useState("#55AA8B");
  const sensorgrey = L.icon({ iconUrl: ICONS.sgrey, iconSize: 25 });
  const sensorgreen = L.icon({ iconUrl: ICONS.sgreen, iconSize: 25 });
  const sensorred = L.icon({ iconUrl: ICONS.sred, iconSize: 25 });
  const currentSite = useSelector((state) => state.currentSite?.gaitObj);
  const sensorTable = useSelector((state) => state.sensorTable?.gaitObj);
  const siteCarbon = useSelector((state) => state.siteCarbon?.gaitObj);
  const user = useSelector((state) => state.loginData?.user);
  const [bounds, setbounds] = useState([
    [-90, -180],
    [90, 180],
    
  ]);
  const [Ndre, setNdre] = useState();
  const [loading, setLoading] = React.useState(false);
  const [objectKeysVal, setObjectKeysVal] = useState([]);
  const [analysisArrReport, setAnalysisArrReport] = useState([]);
  const [analysisReportLineChart, setAnalysisReportLineChart] = useState("");
  const [analysisReportLineChartNdmi, setAnalysisReportLineChartNdmi] =
    useState("");
  const [analysisReportLineChartNdvi, setAnalysisReportLineChartNdvi] =
    useState("");
  const [viewSingleProject , setViewSingleProject] = useState();
  const [barChartData, setChartData] = React.useState({
    labels: labels,
    datasets: [
      {
        type: "line",
        label: "Dataset 1",
        borderColor: "#259A6C",
        borderWidth: 2,
        fill: false,
        data: [123, 234, 434, 345, 45, 34, 232],
        borderWidth: 1,
      },
      {
        type: "bar",
        label: "",
        borderWidth: 1,
        data: [123, 234, 244, 345, 45, 34, 232],
      },
    ],
  });
  
  analysisMaxValOfNdre.filter((element, index) => {
    return analysisMaxValOfNdre.indexOf(element) === index;
  });
  const [ndreAlll,setndreAlll] = useState([]);
  const [ndreminAlll,setndreminAlll] = useState([]);
  const [ndreminmaxAlll,setndreminmaxAlll] = useState([]);
  const [ndreAllDate,setndreAllDate] = useState([]);
  const [uniqueNdmiMAxVal,setuniqueNdmiMAxVal] = useState([])
  const [uniqueNdmiMeanVal,setuniqueNdmiMeanVal] = useState([])
  const [uniqueNdmiMinVal,setuniqueNdmiMinVal] = useState([])
  const [uniqueNdmiDateVal,setuniqueNdmiDateVal] = useState([]);
  const [uniqueNdviMAxVal,setuniqueNdviMAxVal] = useState([]);
  const [uniqueNdviMeanVal,setuniqueNdviMeanVal] = useState([])
  const [uniqueNdviMinVal,setuniqueNdviMinVal] = useState([])
  const [tuniqueNdviDateVal,setuniqueNdviDateVal] = useState([])
  const setAllGraphData = () =>{
    console.log(location , "checek lcationn")
    setTimeout(() =>{
      // ndre
      var myArray = analysisMinValOfNdre;
      var uniqueMinVal = myArray.filter((v, i, a) => a.indexOf(v) === i);
      setndreAlll(uniqueMinVal)
      // ndremin
      var arrmin =analysisMeanValOfNdre  
      var uniqueMeanVal = arrmin.filter((v, i, a) => a.indexOf(v) === i);
      setndreminAlll(uniqueMeanVal)
      // ndre minmax
      var arrminmax =analysisMaxValOfNdre  
      var uniqueMinVal = arrminmax.filter((v, i, a) => a.indexOf(v) === i);
      setndreminmaxAlll(uniqueMinVal)
      // ndre date

      var arrDate =analysisDateValOfNdre  
      var uniqueDateVal = arrDate.filter((v, i, a) => a.indexOf(v) === i);
      setndreAllDate(uniqueDateVal)
    
    },3000)
   // usage example:

     var analysisreportLineChartForNdre = {
      datasets: [
        {
          backgroundColor: "black",
          borderColor: "black",
          fill: false,
          data: analysisMaxValOfNdre,
          // data: ndreminmaxAlll,
          id: "carbon",
          label: "Max",
          spanGaps: true
        },
        {
          backgroundColor: "#55aa8b",
          borderColor: "#55aa8b",
          fill: false,
          data: analysisMeanValOfNdre,
          // data: ndreminAlll,
          id: "carbon",
          label: "Mean",
          spanGaps: true
        },
        {
          backgroundColor: "blue",
          borderColor: "blue",
          fill: false,
          data: analysisMinValOfNdre,
          // data: ndreAlll,
          id: "carbon",
          label: "Min",
          spanGaps: true
        },
      ],
      // labels: analysisDateValOfNdre,
      labels: ndreAllDate,
    };
    
    setAnalysisReportLineChart(analysisreportLineChartForNdre);
    // NDMI
    setTimeout(() =>{
      // ndre
      var uniqueNDMIMax = analysisMaxValOfNdmi;
      var uniqueMax = uniqueNDMIMax.filter((v, i, a) => a.indexOf(v) === i);
      setuniqueNdmiMAxVal(uniqueMax)

      var uniqueNDMIMean = analysisMeanValOfNdmi;
      var uniqueMean = uniqueNDMIMean.filter((v, i, a) => a.indexOf(v) === i);
      setuniqueNdmiMeanVal(uniqueMean)

      var uniqueNDMIMin = analysisMinValOfNdmi;
      var uniqueMin = uniqueNDMIMin.filter((v, i, a) => a.indexOf(v) === i);
      setuniqueNdmiMinVal(uniqueMin)

      var uniqueNDMIdate = analysisDateValOfNdre;
      var uniquedate = uniqueNDMIdate.filter((v, i, a) => a.indexOf(v) === i);
      setuniqueNdmiDateVal(uniquedate)
    
    },3000)
    var analysisreportLineChartForNdmi = {
      datasets: [
        {
          backgroundColor: "black",
          borderColor: "black",
          fill: false,
          data: analysisMaxValOfNdmi,
          // data:uniqueNdmiMAxVal,
          id: "carbon",
          label: "Max",
          spanGaps: true,
        },
        {
          backgroundColor: "#55aa8b",
          borderColor: "#55aa8b",
          fill: false,
          data: analysisMeanValOfNdmi,
          // data: uniqueNdmiMeanVal,
          id: "carbon",
          label: "Mean",
          spanGaps: true,
        },
        {
          backgroundColor: "blue",
          borderColor: "blue",
          fill: false,
          data: analysisMinValOfNdmi,
          // data:uniqueNdmiMinVal,
          id: "carbon",
          label: "Min",
          spanGaps: true,
        },
      ],
      // labels: analysisDateValOfNdre,
      labels:uniqueNdmiDateVal,
    };
    setAnalysisReportLineChartNdmi(analysisreportLineChartForNdmi);
    setTimeout(() =>{
      // ndre
      var uniqueNDviMax = analysisMaxValOfNdvi;
      var uniqueMaxndvi = uniqueNDviMax.filter((v, i, a) => a.indexOf(v) === i);
      setuniqueNdviMAxVal(uniqueMaxndvi)

      var uniqueNDviMean = analysisMeanValOfNdvi;
      var uniqueMean = uniqueNDviMean.filter((v, i, a) => a.indexOf(v) === i);
      setuniqueNdviMeanVal(uniqueMean)

      var uniqueNDviMin = analysisMinValOfNdvi;
      var uniqueMin = uniqueNDviMin.filter((v, i, a) => a.indexOf(v) === i);
      setuniqueNdviMinVal(uniqueMin)

      var uniqueNDvidate = analysisDateValOfNdre;
      var uniquedate = uniqueNDvidate.filter((v, i, a) => a.indexOf(v) === i);
      setuniqueNdviDateVal(uniquedate)
    
    },3000)
    // NDMI
    var analysisreportLineChartForNdvi = {
      datasets: [
        {
          backgroundColor: "black",
          borderColor: "black",
          fill: false,
          data: analysisMaxValOfNdvi,
          // data:uniqueNdviMAxVal,
          id: "carbon",
          label: "Max",
          spanGaps: true,
        },
        {
          backgroundColor: "#55aa8b",
          borderColor: "#55aa8b",
          fill: false,
          data: analysisMeanValOfNdvi,
          // data:uniqueNdviMeanVal,
          id: "carbon",
          label: "Mean",
          spanGaps: true,
        },
        {
          backgroundColor: "blue",
          borderColor: "blue",
          fill: false,
          data: analysisMinValOfNdvi,
          // data:uniqueNdviMinVal,
          id: "carbon",
          label: "Min",
          spanGaps: true,
        },
      ],
      // labels: analysisDateValOfNdre,
      labels:tuniqueNdviDateVal,
    };
    setAnalysisReportLineChartNdvi(analysisreportLineChartForNdvi);
  }
  // useEffect(() =>{
  //   setAllGraphData()
  // },[ndreAlll])
  useEffect(() =>{
    setAllGraphData()
  },[ndreAlll]);
  useEffect(() => {
   
    setTimeout(()=>{
      window.localStorage.setItem('refresh', false);
    }, 1000)
    
    var refresh = window.localStorage.getItem('refresh');
    console.log(typeof refresh)
if (refresh==='true'){
    window.location.reload();
    window.localStorage.setItem('refresh', false);
}
    // refreshPage()
    setList(Catalogues);
    makeNewArrList();
    getSingleProject();
    if (siteCarbon) {
      let satelliteData = [];
      siteCarbon?.map((val) => {
        if (val.source == "Satellite") {
          satelliteData.push({ x: labels[val.month - 1], y: val.totalcarbon });
        }
      });

      let sensorData = [];
      siteCarbon?.map((val) => {
        if (val.source == "Sensor") {
          sensorData.push({ x: labels[val.month - 1], y: val.totalcarbon });
        }
      });
      let temp = {
        labels: labels,
        datasets: [
          {
            type: "line",
            label: "Soil Carbon",
            borderColor: "#259A6C",
            backgroundColor: "transparent",
            borderWidth: 2,
            fill: false,
            data: sensorData,
          },
          {
            type: "bar",
            label: "",
            borderColor: "#259A6C",
            backgroundColor: "#259A6C",
            data: sensorData,
          },
          {
            type: "bar",
            label: "",
            borderColor: "#75f0c0",
            backgroundColor: "#75f0c0",
            data: satelliteData,
          },
        ],
      };
      setChartData(temp);
    }

    if (siteCarbon) {
      let satelliteData = [];

      siteCarbon?.map((val) => {
        if (val.source == "Satellite") {
          satelliteData.push({ x: labels[val.month - 1], y: val.totalcarbon });
        }
      });

      let sensorData = [];
      siteCarbon?.map((val) => {
        if (val.source == "Sensor") {
          sensorData.push({ x: labels[val.month - 1], y: val.totalcarbon });
        }
      });
    }
    props.getAllSites(
      user?.personidref?.orgidref?.orgid,
      user?.personidref?.orgidref?.orgcode
    );
    props.getCurrentSite(location?.state?.siteId);
    getSite(location?.state?.siteId);
  },[]);
  // useEffect(() => {
   
  // }, []);

  const getSingleProject = () =>{
    setLoading(true);
    axiosClient.get(`v1/gait/project/byId/${location.state.projectId}`)
    .then(res =>{
      if(res.status === 200){
        setViewSingleProject(res)
        axios.get(res?.data?.shapefile).then((resp) => {
          setgeoJSONObj(resp?.data)
        });
      }
      setLoading(false)
    }).catch(err =>{

    })
    
    axiosClient
      .post(`v1/gait/siteAnalysis/filterby`, {
        id: location?.state?.projectId,
      })
      .then((res) => {
        setNdre(res?.data);
        setObjectKeysVal(Object.keys(res?.data));
        // test ndre
        let analysisArrReport = [];
        Object.keys(res?.data)?.map((val, index) => {
          analysisArrReport.push(val);
        });
        setAnalysisArrReport(analysisArrReport);

      });
  }
  const makeNewArrList = () => {
    let listVal = [];
    newList?.map((val, index) => {
      listVal.push({ id: `0${index + 1}`, name: val });
    });
    setList(listVal);
  };
  // const getAllSiteReports = () => {
  //   let T20 = [];
  //   let T21 = [];
  //   let T22 = [];

  //   axiosClient
  //     .post(`v1/gait/siteAnalysis/filterby`, {
  //       id: location?.state?.projectId,
  //     })
  //     .then((res) => {
  //       setNdre(res?.data);
  //       setObjectKeysVal(Object.keys(res?.data));
  //       // test ndre
  //       let analysisArrReport = [];
  //       Object.keys(res?.data)?.map((val, index) => {
  //         analysisArrReport.push(val);
  //       });
  //       setAnalysisArrReport(analysisArrReport);

  //     });
  // };

  var windOptions = {
    spanGaps: true,
    elements: {
      rectangle: {
        borderWidth: 2,
      },
    },
    layout: {
      padding: 0,
    },
    legend: {
      display: true,
      labels: {
        boxWidth: 50,
        fontSize: 25,
        fontColor: "gray",
      },
    },
    maintainAspectRatio: false,
    responsive: true,

    title: {
      display: false,
    },
    tooltips: {
      intersect: false,
      mode: "index",
      position: "nearest",
      callbacks: {},
    },
    scales: {
      y: {
        type: "linear",
        display: true,
        position: "left",
        title: { display: false, text: "MIN" },
      },
      y2: {
        type: "linear",
        display: false,
        position: "left",
        title: { display: true, text: "MEAN" },
      },
      y1: {
        type: "linear",
        display: false,
        position: "right",
        title: { display: true, text: "MAX" },
        grid: {
          drawOnChartArea: false,
        },
      },
      x: {
        display: true,
      },
    },
  };

  var windOptionsforEach = {
    elements: {
      rectangle: {
        borderWidth: 2,
      },
    },
    layout: {
      padding: 0,
    },
    legend: {
      display: true,
      labels: {
        boxWidth: 50,
        fontSize: 25,
        fontColor: "gray",
      },
    },
    maintainAspectRatio: false,
    responsive: true,

    title: {
      display: false,
    },
    tooltips: {
      intersect: false,
      mode: "index",
      position: "nearest",
      callbacks: {},
    },
    scales: {
      y: {
        display: false,
      },
      x: {
        display: true,
      },
    },
  };

  const columns = [
    {
      field: "year",
      headerName: "Year",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "quarter",
      headerName: "Quarter",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      flex: 1,
      minWidth: 100,
    },
    {
      field: "date",
      headerName: "Date",
      flex: 1,
      minWidth: 100,

      valueFormatter: (params) => dayjs(params?.value).format("DD-MMMM-YYYY"),
    },
    {
      field: "min",
      headerName: "Min",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "max",
      headerName: "Max",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      flex: 1,
      minWidth: 100,
    },
    {
      field: "mean",
      headerName: "Mean",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      flex: 1,
      minWidth: 100,
    },
    {
      field: "std",
      headerName: "Stdev",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      flex: 1,
      minWidth: 100,
    },
  ];
  const columns1 = [
    // { field: 'id', headerName: 'ID', width: 70 },
    {
      field: "analysis",
      headerName: "Name",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "min",
      headerName: "Min",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "max",
      headerName: "Max",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      flex: 1,
      minWidth: 100,
    },
    {
      field: "mean",
      headerName: "Mean",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      flex: 1,
      minWidth: 100,
    },
    {
      field: "std",
      headerName: "Stdev",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      flex: 1,
      minWidth: 100,
    },
  ];
  return (
    <>
      {loading ? (
        <div>
          <img
            src={ICONS.SignalGreen}
            style={{
              width: "5%",
              position: "absolute",
              top: "50%",
              left: "50%",
            }}
          />
        </div>
      ) : (
        <PDFExport
          paperSize="A2"
          ref={pdfExportComponent}
          margin={{ right: 5 }}
          fileName={`${viewSingleProject?.data?.siteName}.pdf`}
        >
          <div className="container">
            <div className="row">
              <div className="main-sitediv">
                <div className="d-flex justify-content-between flex-wrap sitea-report">
                  <div className="site-name" style={{ width: "60%" }}>
                    <p style={{ textAlign: "left" }}>Project Analysis Report</p>
                  </div>
                  <div className="download-site">
                    <Button
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                      onClick={() => {
                        if (pdfExportComponent.current) {
                          pdfExportComponent.current.save();
                        }
                      }}
                      style={{
                        marginLeft: 15,
                        display: "block",
                        width: 170,
                        background:
                          "linear-gradient(0deg, #55AA8B, #55AA8B), #FEFEFE",
                        borderRadius: 4,
                        color: "white",
                        fontFamily: "Poppins",
                        fontSize: 13,
                        margin: "auto",
                      }}
                    >
                      <FileDownloadOutlinedIcon /> Download PDF
                    </Button>
                  </div>
                </div>
                <div className="border-bottom"></div>
                <div className="site-information">
                  {/* <div className="row"> */}
                  <div className="d-flex justify-content-between flex-wrap testclssss">
                    <div className="report-siteinfo">
                      <h2>Site Information</h2>

                      <div className="site-infoborder"></div>
                    </div>
                    <div className="report-sitemap">
                      <h2 style={{ paddingBottom: 7 }}>Site Map</h2>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between flex-wrap align-items-center">
                    <div>
                      {/* <div className="col-md-5 col-sm-12"> */}

                      <div className="site-borderration"></div>
                      <div className="d-flex">
                        <div className="site-names">
                          <p>Site Name : </p>
                        </div>
                        <div className="site-namedy">
                          <p>&nbsp; {viewSingleProject?.data?.siteName}</p>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="site-names">
                          <p>Country : </p>
                        </div>
                        <div className="site-namedy">
                          <p> &nbsp;{viewSingleProject?.data?.country}</p>
                        </div>
                      </div>
                      {/* <div className="d-flex">
                        <div className="site-names">
                          <p>Coordinates : </p>
                        </div>
                        <div className="site-namedy">
                          <p>
                            &nbsp; {siteAllInfo?.[0]?.longitude} ,{" "}
                            {siteAllInfo?.[0]?.latitude}
                          </p>
                        </div>
                      </div> */}
                      <div className="d-flex">
                        <div className="site-names">
                          <p>Area (in Ha) : </p>
                        </div>
                        <div className="site-namedy">
                          <p>&nbsp; {viewSingleProject?.data?.areaInHectres} Ha</p>
                        </div>
                      </div>
                    </div>
                    <div style={{ width: "50%" }}>
                      {/* <div className="col-md-6"> */}

                      <div style={{ height: 290 }}>
                        {geoJSONObj && (
                          <MapContainer
                            key={Math.random()}
                            
                            center={
                              Array.isArray(geoJSONObj?.features?.[0]?.geometry?.coordinates?.[0]?.[0]?.[1]) === true ? [geoJSONObj?.features?.[0]?.geometry?.coordinates?.[0]?.[0]?.[1]?.[1] , geoJSONObj?.features?.[0]?.geometry?.coordinates?.[0]?.[0]?.[1]?.[0]] :
                              [geoJSONObj?.features?.[0]?.geometry?.coordinates?.[0]?.[0]?.[1] , geoJSONObj?.features?.[0]?.geometry?.coordinates?.[0]?.[0]?.[0]]
                            }
                            // center={[0, 0]}
                            zoom={13}
                            maxZoom={19}
                            minZoom={2}
                            bounceAtZoomLimits={true}
                            maxBoundsViscosity={12}
                            bounds={bounds}
                            // zoom={13}
                            trackResize={true}
                            scrollWheelZoom={false}
                            style={{
                              height: "100%",
                            }}
                          >
                            <>
                              <TileLayer
                                key={Math.random()}
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
                              />
                              
                              <GeoJSON
                                key={Math.random()}
                                data={geoJSONObj}
                                color={bgColor ? bgColor : "green"}
                              >
                                <Popup>
                                  <ul style={{ listStyle: "none" }}>
                                    <li>
                                      <b>Site info</b>
                                    </li>
                                    <li>
                                      <a>
                                        <b>Site ID :</b> 8888
                                      </a>
                                    </li>
                                    <li>
                                      <b>Site name :</b>
                                     66666
                                    </li>
                                    <li>
                                      <b>Total Area :</b>
                                      gjhf
                                      {"Ha "}
                                    </li>
                                    <li>
                                      <b>Elevation :</b>
                                     8765
                                    </li>
                                    <li>
                                      <b>Location:</b> gdgfgd
                                    </li>
                                    <li>
                                      <b>Country:</b> rthyrytr
                                    </li>
                                    <li>
                                      <b>Activation date :</b>{" "}
                                      {/* {new Date(
                                        currentSite.activationdate
                                      ).toLocaleDateString()} */}
                                      12/12/2222
                                    </li>
                                    <li>
                                      <b>No. Of installations :</b>{" "}
                                      {/* {currentSite.sensorcount} */}
                                      3546
                                    </li>
                                    <li>
                                      <b>Site Type:</b> iuytre
                                    </li>
                                    <li>
                                      <b>Carbon Type:</b>{" "}
                                      {/* {currentSite.carbontype} */}
                                      sdfgh
                                    </li>
                                    <li>
                                      <b>Site owner :</b> r55rf
                                    </li>
                                    <li>
                                      <b>Contact no :</b>{" "}
                                      {/* {currentSite.ownercontact} */}
                                      gdfyffhy
                                    </li>
                                  </ul>
                                </Popup>
                              </GeoJSON>
                              {sensorTable && (
                                <Marker
                                  position={[
                                    Number(sensorTable[0]?.latitude),
                                    Number(sensorTable[0]?.longitude),
                                  ]}
                                  icon={
                                    sensorTable[0]?.isactive == 1 &&
                                    sensorTable[0]?.livestatus == 1
                                      ? sensorgreen
                                      : sensorTable[0]?.isactive == 1 &&
                                        sensorTable[0]?.livestatus == 0
                                      ? sensorred
                                      : sensorgrey
                                  }
                                >
                                  <Popup>
                                    <ul>
                                      <li>
                                        {" "}
                                        Sensor Id : {sensorTable[0]?.sensorid}
                                      </li>
                                      <li>
                                        {" "}
                                        Sensor Status:{" "}
                                        {sensorTable[0]?.livestatus == 1
                                          ? "Active"
                                          : "Inactive"}
                                      </li>
                                      <li>
                                        {" "}
                                        Sensor Type:{" "}
                                        {sensorTable[0]?.sensortype}
                                      </li>
                                      <li>
                                        {" "}
                                        Location: {sensorTable[0]?.location}
                                      </li>
                                      <li>
                                        {" "}
                                        Country: {sensorTable[0]?.country}
                                      </li>
                                      <li>
                                        {" "}
                                        Last status :{" "}
                                        {new Date(
                                          sensorTable[0]?.lastcommunidate
                                        ).toLocaleDateString()}
                                      </li>
                                    </ul>
                                  </Popup>
                                </Marker>
                              )}
                            </>
                          </MapContainer>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>

          <>
            {analysisArrReport?.map((val, index) => {
              return (
                <>
                  {Ndre?.[val]?.map((afterVal, afterValIndex) => {
                    return (
                      <>
                        <div className="main-sitediv">
                          <div className="tbl-res">
                            <h2>{analysisArrReport?.[index]} </h2>
                            <div className="site-border"></div>
                          </div>
                          
                          {Object.keys(afterVal)?.map((zone, zoneIndex) => {
                            return (
                              <>
                              
                                <div
                                  className={`zone-border${zoneIndex}`}
                                ></div>
                                <div className="zone-section">
                                  <div className="row">
                                    <div className="tbl-res">
                                      <h2 className="zonal-statistics">
                                        Zonal Statistics {zone}
                                      </h2>
                                    </div>
                                    <>
                                      <table width={"100%"}>
                                        <thead className="tbl-head">
                                          <tr>
                                            <th>Year</th>
                                            <th>Quarter</th>
                                            <th>Date</th>
                                            <th>Min</th>
                                            <th>Max</th>
                                            <th>Mean</th>
                                            <th>Stdev</th>
                                          </tr>
                                        </thead>
                                        {/* filterSiteInfo */}
                                        {Object.keys(afterVal?.[zone])?.map(
                                          (yearVal, yearIndex) => {
                                            return (
                                              <>
                                                {/* {analysisMeanValOfNdmi.push(
                                                afterVal?.[zone][yearVal][yearIndex]?.mean
                                                )} */}
                                                <div
                                                  style={{ marginTop: 10 }}
                                                ></div>
                                                <tr className="tbl-bodytr">
                                                  <td className="td-firstchield">
                                                    {yearVal}
                                                  </td>
                                                  <td>
                                                 
                                                    {afterVal?.[zone]?.[
                                                      yearVal
                                                    ]?.map(
                                                      (mainval, mainindex) => {
                                                        return (
                                                          
                                                          <tr>
                                                             {console.log(
                                                    // Object. keys(afterVal?.[zone]),
                                                    afterVal?.[zone]?.[
                                                      yearVal
                                                    ].length,"checek orignalcoutn")}
                                                            <td>
                                                              {mainval?.quarter}
                                                            </td>
                                                          </tr>
                                                        );
                                                      }
                                                    )}
                                                  </td>

                                                  <td>
                                                    {afterVal?.[zone]?.[
                                                      yearVal
                                                    ]?.map(
                                                      (mainval, mainindex) => {
                                                        return (
                                                          <tr>
                                                            <td>
                                                              <span
                                                                style={{
                                                                  display:
                                                                    "none",
                                                                }}
                                                              >
                                                                {
                                                                
                                                                mainval?.analysis ===
                                                                "NDRE"
                                                                  ? analysisDateValOfNdre.push(
                                                                      dayjs(
                                                                        mainval?.date
                                                                      ).format(
                                                                        "DD-MM-YYYY"
                                                                      )
                                                                    )
                                                                  : "" 
                                                                      }
                                                                {mainval?.analysis ===
                                                                "NDMI"
                                                                  ? analysisDateValOfNdmi.push(
                                                                      dayjs(
                                                                        mainval?.date
                                                                      ).format(
                                                                        "DD-MM-YYYY"
                                                                      )
                                                                    )
                                                                  : ""}

                                                                {mainval?.analysis ===
                                                                "NDVI"
                                                                  ? analysisDateValOfNdvi.push(
                                                                      dayjs(
                                                                        mainval?.date
                                                                      ).format(
                                                                        "DD-MM-YYYY"
                                                                      )
                                                                    )
                                                                  : ""}
                                                              </span>
                                                              {dayjs(
                                                                mainval?.date
                                                              ).format(
                                                                "DD-MMM"
                                                              )}
                                                            </td>
                                                          </tr>
                                                        );
                                                      }
                                                    )}
                                                  </td>

                                                  <td>
                                                    {
                                                    afterVal?.[zone]?.[
                                                      yearVal
                                                    ]?.map(
                                                      (mainval, mainindex) => {
                                                        return (
                                                          <tr>
                                                              {/* {checekCount++} */}
                                                            <td>
                                                              <span
                                                                style={{
                                                                  display:
                                                                    "none",
                                                                }}
                                                              >
                                                                 
                                                                {
                                                                mainval.analysis ===
                                                                "NDRE"
                                                                  ? 
                                                                  // analysisMinValOfNdre.push(
                                                                  //     mainval.min
                                                                  //   )
                                                                    analysisMinValOfNdre.push(mainval.min) 
                                                                    
                                                                  : ""
                                                                  }
                                                                {mainval.analysis ===
                                                                "NDMI"
                                                                  ? analysisMinValOfNdmi.push(
                                                                      mainval.min
                                                                    )
                                                                  : ""}
                                                                {mainval.analysis ===
                                                                "NDVI"
                                                                  ? analysisMinValOfNdvi.push(
                                                                      mainval.min
                                                                    )
                                                                  : ""}
                                                              </span>
                                                              {mainval.min}
                                                            </td>
                                                          </tr>
                                                        );
                                                      }
                                                    )}
                                                  </td>

                                                  <td>
                                                    {afterVal?.[zone]?.[
                                                      yearVal
                                                    ]?.map(
                                                      (mainval, mainindex) => {
                                                        return (
                                                          <tr>
                                                            <td>
                                                              <span
                                                                style={{
                                                                  display:
                                                                    "none",
                                                                }}
                                                              >
                                                                {mainval?.analysis ===
                                                                "NDRE"
                                                                  && analysisMaxValOfNdre.push(
                                                                      mainval.max
                                                                    )
                                                                  }

                                                                {mainval.analysis ===
                                                                "NDMI"
                                                                  && analysisMaxValOfNdmi.push(
                                                                      mainval.max
                                                                    )
                                                                  }

                                                                {mainval.analysis ===
                                                                "NDVI"
                                                                  && analysisMaxValOfNdvi.push(
                                                                      mainval.max
                                                                    )}
                                                                  
                                                              </span>
                                                              {mainval.max}
                                                            </td>
                                                          </tr>
                                                        );
                                                      }
                                                    )}
                                                  </td>

                                                  <td>
                                                    {afterVal?.[zone]?.[yearVal]
                                                      ?.slice(0, 5)
                                                      .map(
                                                        (
                                                          mainval,
                                                          mainindex
                                                        ) => {
                                                          return (
                                                            <tr>
                                                              <td>
                                                                <span
                                                                  style={{
                                                                    display:
                                                                      "none",
                                                                  }}
                                                                >
                                                                  
                                                                  {checkAnalysisType.push(
                                                                    mainval.analysis
                                                                  )}
                                                                  {mainval.analysis ===
                                                                  "NDRE"
                                                                    ? analysisMeanValOfNdre.push(
                                                                        mainval?.mean
                                                                      )
                                                                    : ""}

                                                                  {mainval.analysis ===
                                                                  "NDVI"
                                                                    ? analysisMeanValOfNdvi.push(
                                                                        mainval?.mean
                                                                      )
                                                                    : ""}

                                                                  {mainval.analysis ===
                                                                  "NDMI"
                                                                    ? analysisMeanValOfNdmi.push(
                                                                        mainval?.mean
                                                                      )
                                                                    : ""}
                                                                </span>
                                                                {mainval?.mean}
                                                              </td>
                                                            </tr>
                                                          );
                                                        }
                                                      )}
                                                  </td>

                                                  <td>
                                                    {afterVal?.[zone]?.[
                                                      yearVal
                                                    ]?.map(
                                                      (mainval, mainindex) => {
                                                        return (
                                                          <tr>
                                                            <td>
                                                              {" "}
                                                              {mainval?.std}
                                                            </td>
                                                          </tr>
                                                        );
                                                      }
                                                    )}
                                                  </td>
                                                </tr>
                                              </>
                                            );
                                          }
                                        )}
                                        <div style={{ marginTop: 10 }}></div>
                                      </table>
                                      {val === "NDRE" ? (
                                        <div className="analysis-graph">
                                          <div className="linechart-heading">
                                            <h2 className="zonal-statistics">
                                              Zonal Variability {zone}
                                            </h2>
                                          </div>
                                          <Box
                                            sx={{
                                              height: 300,
                                              width: "100%",
                                            }}
                                          >
                                            <Line
                                              options={windOptions}
                                              data={analysisReportLineChart}
                                            />
                                          </Box>
                                        </div>
                                      ) : (
                                        ""
                                      )}

                                      {val === "NDMI" ? (
                                        <div className="analysis-graph">
                                          <div className="linechart-heading">
                                            <h2 className="zonal-statistics">
                                              Zonal Variability {zone}
                                            </h2>
                                          </div>
                                          <Box
                                            sx={{
                                              height: 300,
                                              width: "100%",
                                            }}
                                          >
                                            <Line
                                              options={windOptions}
                                              data={analysisReportLineChartNdmi}
                                            />
                                          </Box>
                                        </div>
                                      ) : (
                                        ""
                                      )}

                                      {val === "NDVI" ? (
                                        <div className="analysis-graph">
                                          <div className="linechart-heading">
                                            <h2 className="zonal-statistics">
                                              Zonal Variability {zone}
                                            </h2>
                                          </div>
                                          <Box
                                            sx={{
                                              height: 300,
                                              width: "100%",
                                            }}
                                          >
                                            <Line
                                              options={windOptions}
                                              data={analysisReportLineChartNdvi}
                                            />
                                          </Box>
                                        </div>
                                      ) : (
                                        ""
                                      )}

                                      {Object.keys(afterVal?.[zone])?.map(
                                        (yearVal, yearIndex) => {
                                          return (
                                            <>
                                              {afterVal?.[zone]?.[yearVal]?.map(
                                                (mainval, mainindex) => {
                                                  return (
                                                    <>
                                                      {mainval.analysis ===
                                                        "Slope" ||
                                                      mainval.analysis ===
                                                        "Elevation" ||
                                                      mainval.analysis ===
                                                        "Canopy Height" ? (
                                                        <div className="report-images">
                                                          <img
                                                            src={
                                                              mainval.imageurl
                                                            }
                                                            width="100%"
                                                            crossorigin="anonymous"
                                                          />
                                                        </div>
                                                      ) : (
                                                        <></>
                                                      )}
                                                    </>
                                                  );
                                                }
                                              )}
                                            </>
                                          );
                                        }
                                      )}
                                    </>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </>
                    );
                  })}
                </>
              );
            })}
          </>
          <div className="main-sitediv">
            <div className="container">
              <div className="glossary-heading">
                <h2>Glossary</h2>
              </div>
              <div className="border-bottom"></div>
              <div className="glossary-main">
                <div className="glossary-border"></div>
                <h4>Normalized difference red edge index (NDRE) </h4>
                <p>
                  NDRE Statistics Normalized difference red edge index (NDRE) is
                  a method of measuring the amount of chlorophyll in the plants.
                  The best time to apply the normalized difference red edge
                  index is when the crop is mature and ripening. NDRE
                  variability is computed for 3-year 2020 to 2022.
                </p>
              </div>

              <div className="glossary-main">
                <div className="glossary-border"></div>
                <h4>Normalized difference vegetation index(NDVI) </h4>
                <p>
                  NDVI (Normalized Difference Vegetation Index) defines values
                  from -1.0 to 1.0, basically representing greens, where
                  negative values are mainly formed from clouds, water and snow,
                  and values close to zero are primarily formed from rocks and
                  bare soil. NDVI variability is computed for 3-year 2020 to
                  2022.
                </p>
              </div>

              <div className="glossary-main">
                <div className="glossary-border"></div>
                <h4>Normalized difference moisture index(NDMI) </h4>
                <p>
                  The Normalized Difference Moisture Index (NDMI) detects
                  moisture levels in vegetation using a combination of
                  near-infrared (NIR) and short-wave infrared (SWIR) spectral
                  bands. It is a reliable indicator of water stress in crops.
                  NDMI variability is computed for 3-year 2020 to 2022.
                </p>
              </div>

              <div className="glossary-main">
                <div className="glossary-border"></div>
                <h4>Digital Elevation Model (DEM) Data </h4>
                <p>
                  Digital Elevation Model is the digital representation of the
                  topographic surface elevation with respect to any reference
                  datum.We have downloaded the SRTM data which has 30 m spatial
                  resolution.Slope is the steepness or the degree of incline of
                  a surface.The hill shade function produces a 3D representation
                  of the terrain surface, with the sun's relative position
                  considered for shading the image.
                </p>
              </div>

              <div className="glossary-main">
                <div className="glossary-border"></div>
                <h4>Canopy Height </h4>
                <p>
                  Canopy Height Model (CHM) is the residual distance between the
                  ground and the top of the objects above the ground. This
                  includes the actual heights of trees, buildings, and any other
                  objects on the earth’s surface. This CHM is created by
                  subtracting the DTM (Digital Terrain Model) from the DSM
                  (Digital Surface Model).
                </p>
              </div>
            </div>
          </div>
        </PDFExport>
      )}
    </>
  );
}

// export default SiteAnalysis;
const mapDispatchToProps = {
  getAllSites: getAllSites,
  getCurrentSite: getSite,
  getSiteCarbonByYear: getSiteCarbonByYear,
  getSiteCarbonBySource: getSiteCarbonBySource,
};
export default connect(null, mapDispatchToProps)(React.memo(ProjectAnalysis));
