import React from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
    Button
} from "@mui/material";
import dayjs from 'dayjs';
import { useState } from "react";
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import TextField from '@mui/material/TextField';    
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import axiosClient from "../../axios";
import { MultiSelect } from "react-multi-select-component";
const options = [
  { label: "Thing 1", value: 1 },
  { label: "Thing 2", value: 2 },
  { label: "Thing 3", value: 3 },
  { label: "Thing 4", value: 4 },
  { label: "Thing 5", value: 5 },
  { label: "Thing 6", value: 6 },
  { label: "Thing 7", value: 7 },
  { label: "Thing 8", value: 8 },
  { label: "Thing 9", value: 9 },
  { label: "Thing 12", value: 12 },
  { label: "Thing 11", value: 11 },
  { label: "Thing 21", value: 21 }
];
let checkValue = [];
const GetReport = (props) => {
  const [selected1, setSelected1] = useState([]);
  const options1 = [
    { label: "Grapes 🍇", value: "grapes" },
    { label: "Mango 🥭", value: "mango" },
    { label: "Strawberry 🍓", value: "strawberry" }
  ];
  let nav = useNavigate();
  const [site , setSite] = useState();
  const [sequestrationReportFrequency , setsequestrationReportFrequency] = useState(false);
  const [correlationReport , setcorrelationReport] = useState(false);
  const [sDate, setsDate] = useState(dayjs());
  const [eDate, seteDate] = useState(dayjs());
  const [carbonFluxDate, setcarbonFluxDate] = useState(dayjs())
  const [sequestrationdailyFilter , setsequestrationdailyFilter] = useState(false);
  const [sequestrationmonthlyFilter , setsequestrationmonthlyFilter] = useState(false);
  const [correlationReportmonthlyFilter , setcorrelationReportmonthlyFilter] = useState(false);
  const [correlationReportdailyFilter , setcorrelationReportdailyFilter] = useState(false);
  const [sequestrationdailyCustomValue , setsequestrationdailyCustomValue] = useState(false);
  const [sequestrationmonthlyCustomValue, setsequestrationmonthlyCustomValue] = useState(false);
  const [correlationReportdailyCustomValue,setcorrelationReportdailyCustomValue] = useState(false);
  const [correlationReportmonthlyCustomValue, setcorrelationReportmonthlyCustomValue] = useState(false);
  const [getValue , setGetValue] = useState('');
  const [getAllSensor, setgetAllSensor] = useState([]);
  const [carbonFluxRes , setcarbonFluxRes] = useState(false);
  const [getCarbonCountt , setgetCarbonCountt] = useState(false);
  const [siteAnalysis,setSiteAnalysis] = useState(false);
  const [carbonCountEDate , setcarbonCountEDate] = useState(dayjs());
  const [carbonCountSDate , setcarbonCountSDate] = useState(dayjs());
  const [selected, setSelected] = useState([]);
  const [sensorId,setSensorId] = useState('');
  const [sequestrationYear , setsequestrationYear] = useState("");
  const [newList , setNewList]  = useState([]);
  const [reportCommingArr,setReportCommingArr] = useState([]);
  const [allReportOpt,setAllReportOpt] = useState();
  const [list, setList] = useState([]);
  const [allSiteResult,setAllSiteResult] = useState({});
  const [Ndre,setNdre] = useState();
  const [getAllCheck , setGetAllCheck] = useState(true);
  const [reports , setReports] = useState({
    fruites: allReportOpt
  }
  )
  const handleChangeStartDate = (val) => {
    setsDate(val);
  };
  const handleChangeEndDate = (val) =>{
    seteDate(val);
  }
  
  const sites = useSelector((state) => state.sites?.gaitObj);
  const handleSiteFilter = (e) => {
    setSite(e.target.value);
    getSiteReport(e.target.value);
    axiosClient.get("v1/gait/site/view_site_sensors_list?siteId=" + e.target.value).then((resp) => {
      setgetAllSensor(resp.data.gaitObj);
    
  })
  };
  const handleSubmit = () =>{
        if(getValue === 'sequestrationmonthlyCustom'){
          nav("/sequestrationmonthly-custom", {state:{
            siteId: site, year:sequestrationYear} 
            })
        }
        if(getValue === 'sequestrationdailyCustom'){
          nav("/sequestrationdaily-custom", {state:{
            siteId: site, fromDate:dayjs(sDate).format('YYYY-MM-DD') , toDate:dayjs(eDate).format('YYYY-MM-DD')} 
            })
        }
        if(getValue === 'sequestrationmonthlyAll'){
          nav("/sequestrationmonthly-all", {state:{
            siteId: site, sensorId:sensorId} 
            })
        }
        if(getValue === 'sequestrationdailyAll'){
          nav("/sequestrationdaily-all", {state:{
            siteId: site, sensorId:sensorId} 
            })
        }
          if(getValue === 'fluxReport'){
          nav("/sensor-report", {state:{
            siteId: site, fluxDate:dayjs(carbonFluxDate).format('YYYY-MM-DD'), sensorId:sensorId} 
            })
          }
          if(getValue === 'carbonCount'){
            nav("/carbon-count", {state:{
              siteId: site, sensorId:sensorId, frmdate:dayjs(carbonCountSDate).format('YYYY-MM-DD') ,todate:dayjs(carbonCountEDate).format('YYYY-MM-DD'), fvalue:"22:10:10"} 
              })
          }
          if(getValue === 'siteAnalysis'){
            let makeNewSiteReportArr = [];
            selected?.map((val, index)=>{
              makeNewSiteReportArr.push(val.value);
            })
            // console.log(makeNewSiteReportArr , "makeNewSiteReportArr");
            nav("/site-analysis", {state:{
              siteId: site , reportArr:makeNewSiteReportArr} 
              })
          }

  }
  const handleChangeCarbonFluxDate = (val) =>{
    setcarbonFluxDate(val);
  }
  const typeOfReportFilter = (val) =>{
    setGetValue(val.target.value);
    if(val.target.value === "sequestrationReport"){
      setsequestrationReportFrequency(true);
      setcorrelationReport(false);
      setsequestrationdailyFilter(false);
      setsequestrationmonthlyFilter(false);
      setsequestrationdailyCustomValue(false);
      setsequestrationmonthlyCustomValue(false);
      setcorrelationReportdailyFilter(false);
      setcorrelationReportmonthlyFilter(false);
      setcorrelationReportmonthlyCustomValue(false);
      setcorrelationReportdailyCustomValue(false);
      setcarbonFluxRes(false);
      setgetCarbonCountt(false);
    }
    if(val.target.value === "correlationReport"){
      setcorrelationReport(true);
      setsequestrationReportFrequency(false);
      setsequestrationdailyFilter(false);
      setsequestrationmonthlyFilter(false);
      setsequestrationdailyCustomValue(false);
      setsequestrationmonthlyCustomValue(false);
      setcorrelationReportdailyFilter(false);
      setcorrelationReportmonthlyFilter(false);
      setcorrelationReportmonthlyCustomValue(false);
      setcorrelationReportdailyCustomValue(false);
      setcarbonFluxRes(false);
      setgetCarbonCountt(false);
    }
    if(val.target.value === "fluxReport"){
      setcarbonFluxRes(true);
      setcorrelationReport(false);
      setsequestrationReportFrequency(false);
      setgetCarbonCountt(false);
    }
    if(val.target.value === "carbonCount"){
      setgetCarbonCountt(true);
      setcorrelationReport(false);
      setsequestrationReportFrequency(false);
      setsequestrationdailyFilter(false);
      setsequestrationmonthlyFilter(false);
      setsequestrationdailyCustomValue(false);
      setsequestrationmonthlyCustomValue(false);
      setcorrelationReportdailyFilter(false);
      setcorrelationReportmonthlyFilter(false);
      setcorrelationReportmonthlyCustomValue(false);
      setcorrelationReportdailyCustomValue(false);
      setcarbonFluxRes(false);
    }
    if(val.target.value === "siteAnalysis"){
      setSiteAnalysis(true);
      setcorrelationReport(false);
      setsequestrationReportFrequency(false);
      setsequestrationdailyFilter(false);
      setsequestrationmonthlyFilter(false);
      setsequestrationdailyCustomValue(false);
      setsequestrationmonthlyCustomValue(false);
      setcorrelationReportdailyFilter(false);
      setcorrelationReportmonthlyFilter(false);
      setcorrelationReportmonthlyCustomValue(false);
      setcorrelationReportdailyCustomValue(false);
      setcarbonFluxRes(false);
    }
  }
  const handlesequestrationFrequency = (val) =>{
    setGetValue(val.target.value);
if(val.target.value === 'sequestrationdaily'){
  setsequestrationdailyFilter(true);
  setsequestrationmonthlyFilter(false);
  setsequestrationdailyCustomValue(false)
  setsequestrationmonthlyCustomValue(false);
}
if(val.target.value === 'sequestrationmonthly'){
  setsequestrationmonthlyFilter(true);
  setsequestrationdailyFilter(false);
  setsequestrationdailyCustomValue(false)
  setsequestrationmonthlyCustomValue(false);
  setcorrelationReportmonthlyCustomValue(false);
}
  }
  const handlesequestrationdaily = (val)=>{
    setGetValue(val.target.value);
    if(val.target.value === 'sequestrationdailyAll'){
      setsequestrationdailyCustomValue(false)
    }
    if(val.target.value === 'sequestrationdailyCustom'){
      setsequestrationdailyCustomValue(true)
    }
  }
  const handlesequestrationmonthly = (val)=>{
    setGetValue(val.target.value);
    if(val.target.value === 'sequestrationmonthlyAll'){
      setsequestrationmonthlyCustomValue(false);
    }
    if(val.target.value === 'sequestrationmonthlyCustom'){
      setsequestrationmonthlyCustomValue(true);
    }
  }
  const correlationFrequency = (val) =>{
    setGetValue(val.target.value);
    if(val.target.value === 'correlationReportdaily'){
      setcorrelationReportdailyFilter(true);
      setcorrelationReportmonthlyFilter(false);
    }
    if(val.target.value === "correlationReportmonthly"){
      setcorrelationReportmonthlyFilter(true);
      setcorrelationReportdailyFilter(false);
    }
  }
  const handlecorrelationReportmonthly = (val) =>{
    setGetValue(val.target.value);
    if(val.target.value === "correlationReportmonthlyAll"){
      setcorrelationReportmonthlyCustomValue(false);
      alert("correlationReportmonthlyAll");
      
    }
    if(val.target.value === "correlationReportmonthlyCustom"){
      setcorrelationReportmonthlyCustomValue(true);
    }
  }
  const handlecorrelationReportdaily = (val) =>{
    setGetValue(val.target.value);
    if(val.target.value === "correlationReportdailyAll"){
      setcorrelationReportdailyCustomValue(false);
      alert("correlationReportdailyAll");
    }
    if(val.target.value === "correlationReportdailyCustom"){
        setcorrelationReportdailyCustomValue(true);
    }
  }
  const handleSensorFilter = (val) =>{
    setSensorId(val.target.value);
  }
  const handlesequestrationyear = (val) =>{
    setsequestrationYear(val.target.value);
  }
  const handleCabonCountSdate = (val) =>{
    setcarbonCountSDate(val)
  }
  const handleCarbonCountEDate = (val) =>{
    setcarbonCountEDate(val)
    
  }
  React.useEffect(() =>{
    
      //  selected.map((val,index) =>{
         
      //  })
      

  },[])
  const getSiteReport = (siteID) => {
    
    let reportArr = [];
    let reportArrr = [];
    axiosClient.get(`v1/gait/siteAnalysis/analysises/${siteID}`).then((res) => {
      setNewList(res?.data?.list);
      res?.data?.list?.map((val,index) =>{
        reportArr.push({ label: val, value: val });
       });
       res?.data?.list?.map((val , index) =>{
        reportArrr.push({ id: index+1, value: val, isChecked: false, label:val })
      })
      setAllReportOpt(reportArrr);
      setReports(reportArrr);
      makeNewArrList();
       setReportCommingArr(reportArr)
    })
   
  }
  const handleClickCheckbox = (e) =>{
    setAllSiteResult([]);
    checkValue.push(e.target.name);
  }
  // const getSiteReport = () => {
  //   axiosClient.get(`v1/gait/siteAnalysis/analysises/${location.state.siteId}`).then((res) => {
  //     setNewList(res?.data?.list);
  //     let reportArrr = [];
  //     res?.data?.list?.map((val , index) =>{
  //       reportArrr.push({ id: index+1, value: val, isChecked: false, label:val })
  //     })
  //     console.log(reportArrr ,"reportArrrreportArrr")
  //     setAllReportOpt(reportArrr);
  //     setReports(reportArrr);
  //     makeNewArrList();
  //   })
  // }
  const makeNewArrList  = () =>{
    let listVal = [];
    newList.map((val, index) => {
      listVal.push({id:`0${index+1}`, name:val})
    })
    setList(listVal);
  }
  const handleAllChecked = event => {
    setNdre([]);
      setGetAllCheck(event.target.checked);    
    // let fruites = reports.fruites;    
    // fruites.forEach(fruite => (fruite.isChecked = event.target.checked));
    // setReports({ fruites: fruites });
  };
  return (

    <div className="report-input">
      <div>
        <label className="form-label">Site</label>
        <select className="form-select" name="site" onChange={val => handleSiteFilter(val)}>
        <option >-Select-</option>
          {sites?.map((val, index) => {
            return (
              <option value={val.siteid} key={index}>
                {val.sitename}{" "}
              </option>
            );
          })}
        </select>
      </div>

      <div style={{ marginTop: 20 }}>
        <label className="form-label">Report Type</label>
        <select className="form-select" onChange={val => typeOfReportFilter(val)}>
          <option >-Select-</option>
          <option value="sequestrationReport">Sequestration Report</option>
          <option value="fluxReport">Correlation Report</option>
          <option value="carbonCount">Diurnal Pattern</option>
          <option value="siteAnalysis">Site Analysis</option>
        </select>
      </div>
{sequestrationReportFrequency && 
      <div style={{ marginTop: 20 }}>
        <label className="form-label">Frequency</label>
        <select className="form-select"  onChange={val => handlesequestrationFrequency(val)}>
          <option >-Select-</option>
          <option value="sequestrationdaily">Daily</option>
          <option value="sequestrationmonthly">Monthly</option>
        </select>
      </div>
}

{sequestrationdailyFilter  && 
      <div style={{ marginTop: 20 }}>
        <label className="form-label">Filter</label>
        <select className="form-select"  onChange={val => handlesequestrationdaily(val)}>
          <option >-Select-</option>
          <option value="sequestrationdailyAll">All</option>
          <option value="sequestrationdailyCustom">Custom</option>
        </select>
      </div>
}
{sequestrationmonthlyFilter  && 
      <div style={{ marginTop: 20 }}>
        <label className="form-label">Filter</label>
        <select className="form-select"  onChange={val => handlesequestrationmonthly(val)}>
          <option >-Select-</option>
          <option value="sequestrationmonthlyAll">All</option>
          <option value="sequestrationmonthlyCustom">Custom</option>
        </select>
      </div>
}
{sequestrationdailyCustomValue &&
<>
<div style={{ marginTop: 20 }}>
<LocalizationProvider dateAdapter={AdapterDayjs}>
    <div className="d-flex justify-content-between">
<MobileDatePicker
          label="Start Date"
          inputFormat="MM/DD/YYYY"
          value={sDate}
          onChange={handleChangeStartDate}
          renderInput={(params) => <TextField {...params} />}
        />

<MobileDatePicker
          label="End Date"
          inputFormat="MM/DD/YYYY"
          value={eDate}
          onChange={handleChangeEndDate}
          renderInput={(params) => <TextField {...params} />}
        />
        </div>
        </LocalizationProvider>
</div>
</>
}
{sequestrationmonthlyCustomValue && 
<>
<div style={{ marginTop: 20 }}>
        <label className="form-label">Year</label>
        <select className="form-select" name="sequestrationyear" onChange={val => handlesequestrationyear(val)}>
          <option >-Select-</option>
          <option value="2021">2021</option>
          <option value="2022">2022</option>
        </select>
      </div>
</>
}
{/* corelation item */}
{correlationReport && 
      <div style={{ marginTop: 20 }}>
        <label className="form-label">Frequency</label>
        <select className="form-select"  onChange={val => correlationFrequency(val)}>
          <option >-Select-</option>
          <option value="correlationReportdaily">Daily</option>
          <option value="correlationReportmonthly">Monthly</option>
        </select>
      </div>
}
{correlationReportmonthlyFilter  && 
      <div style={{ marginTop: 20 }}>
        <label className="form-label">Filter</label>
        <select className="form-select"  onChange={val => handlecorrelationReportmonthly(val)}>
          <option >-Select-</option>
          <option value="correlationReportmonthlyAll">All</option>
          <option value="correlationReportmonthlyCustom">Custom</option>
        </select>
      </div>
}
{correlationReportdailyFilter  && 
      <div style={{ marginTop: 20 }}>
        <label className="form-label">Filter</label>
        <select className="form-select"  onChange={val => handlecorrelationReportdaily(val)}>
          <option >-Select-</option>
          <option value="correlationReportdailyAll">All</option>
          <option value="correlationReportdailyCustom">Custom</option>
        </select>
      </div>
}
{correlationReportdailyCustomValue && 
<>
<div style={{ marginTop: 20 }}>
<LocalizationProvider dateAdapter={AdapterDayjs}>
    <div className="d-flex justify-content-between">
<MobileDatePicker
          label="Start Date"
          inputFormat="MM/DD/YYYY"
          value={sDate}
          onChange={handleChangeStartDate}
          renderInput={(params) => <TextField {...params} />}
        />

<MobileDatePicker
          label="End Date"
          inputFormat="MM/DD/YYYY"
          value={eDate}
          onChange={handleChangeEndDate}
          renderInput={(params) => <TextField {...params} />}
        />
        </div>
        </LocalizationProvider>
</div>
</>
}
{correlationReportmonthlyCustomValue && 
<>
<LocalizationProvider dateAdapter={AdapterDayjs}>

<MobileDatePicker
          label="End Date"
          inputFormat="MM/DD/YYYY"
          value={eDate}
          onChange={handleChangeEndDate}
          renderInput={(params) => <TextField {...params} />}
        />
        </LocalizationProvider>
</>
}
{carbonFluxRes &&
<>
    <div style={{ marginTop: 20 }}>
        <label className="form-label">Sensor</label>
        <select className="form-select" name="site" onChange={val => handleSensorFilter(val)}>
        <option >-Select-</option>
          {getAllSensor?.map((val, index) => {
            return (
              <option value={val.sensorid} key={index}>
                {val.sensorid}
              </option>
            );
          })}
        </select>
      </div>

      <div style={{ marginTop: 20 }}>
<LocalizationProvider dateAdapter={AdapterDayjs}>

<MobileDatePicker
          label="Select Date"
          inputFormat="MM/DD/YYYY"
          value={carbonFluxDate}
          onChange={handleChangeCarbonFluxDate}
          renderInput={(params) => <TextField {...params} />}
        />
        </LocalizationProvider>
</div>
      </>
      
}

{getCarbonCountt &&
<>
    <div style={{ marginTop: 20 }}>
        <label className="form-label">Sensor</label>
        <select className="form-select" name="site" onChange={val => handleSensorFilter(val)}>
        <option >-Select-</option>
          {getAllSensor?.map((val, index) => {
            return (
              <option value={val.sensorid} key={index}>
                {val.sensorid}
              </option>
            );
          })}
        </select>
      </div>

      <div style={{ marginTop: 20 }} className="d-flex justify-content-between">
<LocalizationProvider dateAdapter={AdapterDayjs}>

<MobileDatePicker
          label="Start Date"
          inputFormat="MM/DD/YYYY"
          value={carbonCountSDate}
          onChange={handleCabonCountSdate}
          renderInput={(params) => <TextField {...params} />}
        />
        </LocalizationProvider>

        <LocalizationProvider dateAdapter={AdapterDayjs}>

<MobileDatePicker
          label="End Date"
          inputFormat="MM/DD/YYYY"
          value={carbonCountEDate}
          onChange={handleCarbonCountEDate}
          renderInput={(params) => <TextField {...params} />}
        />
        </LocalizationProvider>
</div>
      </>
}
{siteAnalysis && 
  <>
 

<div style={{ marginTop: 20 }}>
<label className="form-label">Analysis1</label>

{ selected &&
    <div className="d-flex justify-content-around">

      {
      selected ? 
      selected?.map((val,index) =>{
        return (
          <div className="select-report">
          <p>{val?.value}</p>
        </div>
        )
      }):
      (<div className="select-report"><p></p></div>)
      }
 
</div>
}
<MultiSelect
 options= {reportCommingArr}
 value={selected}
 onChange={setSelected}
 labelledBy="Select"
//  disable={false}
/>
{/* <pre>{JSON.stringify(selected)}</pre>
      <MultiSelect
        options={options1}
        selected={selected1}
        onChange={setSelected1}
        labelledBy={"Select"}
      /> */}
</div>
</>
}
      <div style={{marginTop:20}}>
      <Button
                style={{
                    marginLeft: 15,
                    display: "block",
                    width: 140,
                    background: "linear-gradient(0deg, #55AA8B, #55AA8B), #FEFEFE",
                    borderRadius: 4,
                    color: "white",
                    fontFamily: "Poppins",
                    fontSize: 13,
                    margin:'auto'
                }}
                onClick={() => handleSubmit()}
                >
                Get Report
            </Button>
            </div>
    </div>
  );
}
export default GetReport;
