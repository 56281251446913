import React from 'react';
import { ErrorMessage, useField } from 'formik';
import ICONS from '../features/images';
export const TextArea = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className="mb-2">
      <div className='d-flex justify-content-between align-item-center'>
        <div>
      <label htmlFor={field.name} className="form-label">{label}</label>
      </div>
      <div>
      {meta.touched && meta.error && field.name == "mobilenum" &&  field.name == "email" ? <img src={ICONS.WrongIcon}  /> : null ? <img src={ICONS.WrongIcon}  /> : 
      null
      }
     
      </div>
      </div>
      <textarea
        className={`form-control from-textarea shadow-none ${meta.touched && meta.error && 'is-invalid'}`}
        {...field} {...props}
        autoComplete="off"
      ></textarea>
      <div>{meta.touched && meta.error && <img src={ICONS.WarningIcon}  /> ? 
      <div className='d-flex align-items-center'>
        <img src={ICONS.WarningIcon} style={{maxWidth:10, width:'100%'}} />&nbsp;<ErrorMessage component="div" name={field.name} className="error" /> 
        </div> : 
      null
      } </div>
    </div>
  )
}