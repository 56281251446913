import React, { useState } from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import { useLocation, useNavigate } from "react-router-dom";
import axiosClient from "../../axios";
import { useEffect } from "react";
import ICONS from '../../features/images/index';
function ProjectView(props) {
    const nav = useNavigate();
    const parms = useLocation();
  const [viewSingleProject , setViewSingleProject] = useState();
  const [loading, setLoading] = useState(false);
    useEffect(()=>{
      getSingleProject();
    },[])

    const getSingleProject = () =>{
      setLoading(true);
      axiosClient.get(`v1/gait/project/byId/${parms.state.projectId}`)
      .then(res =>{
        if(res.status === 200){
          setViewSingleProject(res)
        }
        setLoading(false)
      }).catch(err =>{

      })
    }
  return (
    <div>
       {loading ? (
        <div>
          <img src={ICONS.SignalGreen} style={{
            width: '5%',
            position: 'absolute',
            top: '50%',
            left: '50%',
          }}/>
        </div>
      ) : (
        <>
      <div className="siteheading-div">
        <h1 className="siteheading">Congo</h1>
      </div>
      <div className='add-morebtn'>
                <div className='d-flex justify-content-end'>
                    <div className='btnadd'><button onClick={() =>nav('/edit-projects', {
                  state: {
                    projectId: viewSingleProject?.data?.id,
                  },
                })}>Edit </button></div>
                    <div className='delete-ico'><button><DeleteIcon /></button></div>
                </div>
            </div>
      <div className="projects-info">
        <h2 className="rowmain-heading">Project Info</h2>
        <div className="row">
          <div className="col-md-3 col-sm-12">
            <div className="project-label">
              <label>Project Name</label>
              <input
                type="text"
                name=""
                value={viewSingleProject?.data?.projectName}
                disabled
              />
            </div>
          </div>
          <div className="col-md-3 col-sm-12">
            <div className="project-label">
              <label>Site Name</label>
              <input
                type="text"
                name=""
                value={viewSingleProject?.data?.siteName}
                disabled
              />
            </div>
          </div>
          <div className="col-md-3 col-sm-12">
            <div className="project-label">
              <label>Site Area</label>
              <input
                type="text"
                name=""
                value= {viewSingleProject?.data?.siteArea ? viewSingleProject?.data?.siteArea : "N/A"}
                disabled
              />
            </div>
          </div>
          <div className="col-md-3 col-sm-12">
            <div className="project-label">
              <label>Site Type</label>
              <input
                type="text"
                name=""
                value= {viewSingleProject?.data?.siteType ? viewSingleProject?.data?.siteType : "N/A"}
                disabled
              />
            </div>
          </div>
          <div className="col-md-3 col-sm-12">
            <div className="project-label">
              <label>Project Category</label>
              <input
                type="text"
                name=""
                value= {viewSingleProject?.data?.projectCategory ? viewSingleProject?.data?.projectCategory : "N/A"}
                disabled
              />
            </div>
          </div>
          <div className="col-md-3 col-sm-12">
            <div className="project-label">
              <label>Annual Deforestation Rate </label>
              <input
                type="text"
                name=""
                value={viewSingleProject?.data?.annualDeforestationRate ? viewSingleProject?.data?.annualDeforestationRate :"N/A" }
                disabled
              />
            </div>
          </div>
          <div className="col-md-3 col-sm-12">
            <div className="project-label">
              <label>Annual New Plantation Rate</label>
              <input
                type="text"
                name=""
                value={viewSingleProject?.data?.annualNewPlantationRate ? viewSingleProject?.data?.annualNewPlantationRate : "N/A" }
                disabled
              />
            </div>
          </div>
        </div>
      </div>

      <div className="projects-info">
        <h2 className="rowmain-heading">Site Owner</h2>
        <div className="row">
          <div className="col-md-3 col-sm-12">
            <div className="project-label">
              <label>Site Owner Type</label>
              <input
                type="text"
                name=""
                value={viewSingleProject?.data?.siteOwnerType ? viewSingleProject?.data?.siteOwnerType:"N/A"}
                disabled
              />
            </div>
          </div>
          <div className="col-md-3 col-sm-12">
            <div className="project-label">
              <label>Site Owner Name</label>
              <input
                type="text"
                name=""
                value={viewSingleProject?.data?.siteOwnerName ? viewSingleProject?.data?.siteOwnerName : "N/A"}
                disabled
              />
            </div>
          </div>
          <div className="col-md-3 col-sm-12">
            <div className="project-label">
              <label>Site Owner Contact No</label>
              <input
                type="text"
                name=""
                value={viewSingleProject?.data?.siteOwnerContactNo ?viewSingleProject?.data?.siteOwnerContactNo :"N/A" }
                disabled
              />
            </div>
          </div>
          <div className="col-md-3 col-sm-12">
            <div className="project-label">
              <label>Site Owner Email</label>
              <input
                type="text"
                name=""
                value={viewSingleProject?.data?.siteOwnerEmail ? viewSingleProject?.data?.siteOwnerEmail : "N/A"}
                disabled
              />
            </div>
          </div>
          <div className="col-md-9 col-sm-12">
            <div className="project-label">
              <label>Address</label>
              <input
                type="text"
                name=""
                value={viewSingleProject?.data?.siteAddress ? viewSingleProject?.data?.siteAddress : "N/A"}
                disabled
              />
            </div>
          </div>
        </div>
      </div>

      <div className="projects-info">
        <h2 className="rowmain-heading">Shape File</h2>
        <div className="row">
          <div className="col-md-3 col-sm-12">
            <div className="sub-fieldcheck">  
              <p> Sub fields boundaries present</p>
              {viewSingleProject?.data?.subfieldsBoundariesPresent ? 
              (
                <input
                type="checkbox"
                id="vehicle2"
                name="vehicle2"
                checked
                disabled
              ></input>
              )
               : (<input
               type="checkbox"
               id="vehicle2"
               name="vehicle2"
               disabled
             ></input>)}
             
              <label for="vehicle1"> Available</label>
            </div>
          </div>
          <div className="col-md-3 col-sm-12">
            <div className="sub-fieldcheck">
              <p> Sub field Name/Label present</p>
              {viewSingleProject?.data?.subfieldNamePresent ? 
              (
                <input
                type="checkbox"
                id="vehicle2"
                name="vehicle2"
                checked
                disabled
              ></input>
              )
               : (<input
               type="checkbox"
               id="vehicle2"
               name="vehicle2"
               disabled
             ></input>)}
              <label for="vehicle1"> Available</label>
            </div>
          </div>
          <div className="col-md-3 col-sm-12">
            <div className="project-label">
              <label>Area (in hectares)*</label>
              <input
                type="text"
                name=""
                value={viewSingleProject?.data?.areaInHectres ? viewSingleProject?.data?.areaInHectres : "N/A"}
                disabled
              />
            </div>
          </div>
          <div className="col-md-3 col-sm-12">
            <div className="project-label">
              <p>Shape file</p>
              <button>View shape file</button>
            </div>
          </div>
        </div>
      </div>

      <div className="projects-info">
        <h2 className="rowmain-heading">Geo Reference Map</h2>
        <div className="row">
          <div className="col-md-3 col-sm-12">
            <div className="project-label">
              <label>Geo referenced site map image</label>
              <input
                type="text"
                name=""
                value="Raster dataset that aligns"
                placeholder="Congo"
                disabled
              />
            </div>
          </div>
          <div className="col-md-3 col-sm-12">
            <div className="project-label">
              <p>Site Map Image</p>
              <button>View site image</button>
            </div>
          </div>
        </div>
      </div>

      <div className="projects-info">
        <h2 className="rowmain-heading">Vegetation Info</h2>
        <div className="row">
          <div className="col-md-3 col-sm-12">
            <div className="project-label">
              <label>Vegetation Type</label>
              <input
                type="text"
                name=""
                value={viewSingleProject?.data?.vegetationType ? viewSingleProject?.data?.vegetationType : "N/A"
                }
                disabled
              />
            </div>
          </div>

          <div className="col-md-3 col-sm-12">
            <div className="project-label">
              <label>Plant Species</label>
              <input
                type="text"
                name=""
                value={viewSingleProject?.data?.plantSpecies ? viewSingleProject?.data?.plantSpecies :"N/A"}
                disabled
              />
            </div>
          </div>

          <div className="col-md-3 col-sm-12">
            <div className="project-label">
              <label>Age of vegetation</label>
              <input
                type="text"
                name=""
                value={viewSingleProject?.data?.ageOfVegetation ? viewSingleProject?.data?.ageOfVegetation : "N/A"}
                disabled
              />
            </div>
          </div>
        </div>
      </div>

      <div className="projects-info">
        <h2 className="rowmain-heading">Soil Information</h2>
        <div className="row">
          <div className="col-md-3 col-sm-12">
            <div className="project-label">
              <label>Soil Type</label>
              <input
                type="text"
                name=""
                value={viewSingleProject?.data?.soilType ? viewSingleProject?.data?.soilType : "N/A"}
                disabled
              />
            </div>
          </div>

          <div className="col-md-3 col-sm-12">
            <div className="project-label">
              <label>pH</label>
              <input
                type="text"
                name=""
                value="3.5-4.5"
                placeholder="Congo"
                disabled
              />
            </div>
          </div>

          <div className="col-md-3 col-sm-12">
            <div className="project-label">
              <label>Organic Carbon</label>
              <input
                type="text"
                name=""
                value={viewSingleProject?.data?.organicCarbon ? viewSingleProject?.data?.organicCarbon : "N/A"
                }
                disabled
              />
            </div>
          </div>

          <div className="col-md-3 col-sm-12">
            <div className="project-label">
              <label>OrBulk Density</label>
              <input
                type="text"
                name=""
                value={viewSingleProject?.data?.bulkDensity ? viewSingleProject?.data?.bulkDensity : "N/A" }
                disabled
              />
            </div>
          </div>
        </div>
      </div>
      </>
)}
    </div>
  );
}

export default ProjectView;
