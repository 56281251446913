import React, { useState, useEffect } from "react";
import { Grid, Button } from "@mui/material";
import ICONS from '../../features/images';
import axios from "../../axios";
import { useLocation, useNavigate } from "react-router-dom";
import { view_profile } from "../../features/Apis/Index";
const UserProfileView = (props) => {
  let nav = useNavigate();
  const params = useLocation();
  const [organization, setorganization] = useState("");
  const [firstname, setfirstname] = useState("");
  const [lastname, setlastname] = useState("");
  const [designation, setdesignation] = useState("");
  const [mobilenum, setmobilenum] = useState("");
  const [email, setemail] = useState("");
  const [rolename, setrolename] = useState("");
  const [status, setstatus] = useState("");
  const [username, setusername] = useState("");
  const [userOrgId, setuserOrgId] = useState("");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    viewProfile();
  }, []);
  const viewProfile = () => {
    setLoading(true);
    axios.get(view_profile + params.state.userId).then((res) => {
      setLoading(false);
      let userInfo = res.data.gaitObj.personIdref;
      setfirstname(userInfo.firstname);
      setlastname(userInfo.lastname);
      setdesignation(userInfo.designation);
      setmobilenum(userInfo.mobilenum);
      setemail(userInfo.email);
      setorganization(userInfo.orgidref.orgname);
      setstatus(userInfo.orgidref.isactive);
      setusername(res.data.gaitObj.username);
      setrolename(res.data.gaitObj.roleidref.rolename);
      setuserOrgId(res.data.gaitObj.orguserid);
    });
  };
  return (
    <div className="wrap-content">
      {loading ? (
        <div>
          <img
            src={ICONS.SignalGreen}
            style={{
              width: "5%",
              position: "absolute",
              top: "50%",
              left: "50%",
            }}
          />
        </div>
      ) : (
        <>
          <div className="d-flex justify-content-around flex-wrap">
            <Grid item xs={4} className="text-center">
              <div className="image-section">
                <div className="user-location">
                </div>
                <div className="user-image">
                  <div className="dp-background">
                    <img src={ICONS.DpBackground} width="100%" />
                  </div>
                  <div className="user-profileview">
                    <img src={ICONS.ProfileImg} />
                    <div className="user-name">
                      <p>
                        {firstname} {lastname}
                      </p>
                      <span>
                        {rolename.replace("_", " ")} at {organization}
                      </span>
                    </div>
                  </div>
                  <div className="user-basicsdetails">
                    <div className="social-icons"></div>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={4} className="text-center">
              <div className="user-info">
                <div>
                  <label>First Name</label>
                </div>
                <div>
                  <input value={firstname} disabled />
                </div>
                <div>
                  <label>Last Name</label>
                </div>
                <div>
                  <input value={lastname} disabled />
                </div>
                <div>
                  <label>Designation</label>
                </div>
                <div>
                  <input value={designation} disabled />
                </div>
                <div>
                  <label>Mobile Number</label>
                </div>
                <div>
                  <input value={mobilenum} disabled />
                </div>
              </div>
              <div className="user-info">
                <div>
                  <label>Status</label>
                </div>
                <div>
                  <input value={status == 1 ? "Active" : "Inactive"} disabled />
                </div>
              </div>
            </Grid>
            <Grid item xs={4} className="text-center">
              <div className="user-info">
                <div>
                  <label>Email</label>
                </div>
                <div>
                  <input value={email} disabled />
                </div>
                <div>
                  <label>Username</label>
                </div>
                <div>
                  <input value={username} disabled />
                </div>
                <div>
                  <label>Role</label>
                </div>
                <div>
                  <input value={rolename.replace("_", " ")} disabled />
                </div>
                <div>
                  <label>Organization Name</label>
                </div>
                <div>
                  <input value={organization} disabled />
                </div>
              </div>
            </Grid>
          </div>
          <div>
            <Button
              onClick={(e) =>
                nav("/update-user", {
                  state: {
                    userId: userOrgId,
                  },
                })
              }
              style={{
                marginLeft: "auto",
                display: "block",
                width: 140,
                background: "linear-gradient(0deg, #55AA8B, #55AA8B), #FEFEFE",
                borderRadius: 4,
                color: "white",
              }}
            >
              Edit
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default UserProfileView;
